import { Component, Input } from '@angular/core';
import { LoadingIndicatorService } from '../service/loading-indicator.service';

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html'
})
export class AppLoaderComponent {
  @Input() enableLoader
  constructor(public loaderService: LoadingIndicatorService) { 
  }
}
