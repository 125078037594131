<div class="mt-4 container-xl" *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div class="row">
        <div class="col-md-12">
            <h2>Virtual Terminal Audit Log</h2>

            <div class="card" id="audit-log-search">
                <div class="card-body pt-5">
                    <h6 class="pb-3">Select at least one criteria</h6>
                    <form [formGroup]="transactionForm" class="row d-flex align-items-center">
                        <div class="col-md-5 mb-4 form-controls">
                            <mat-form-field appearance="outline">
                                <mat-label>Transaction Type</mat-label>
                                <!-- <label class="form-label">Transaction Type</label> -->
                                <mat-select id="transaction" formControlName="transactionType"
                                    (selectionChange)="updateFilterJson('transactionType')">
                                    <mat-option disabled>Select...</mat-option>
                                    <mat-option value="all">ALL</mat-option>
                                    <mat-option *ngIf="profile.hasVtSaleAcc" value="SALE">Sale</mat-option>
                                    <mat-option *ngIf="profile.hasOsOverRefundAcc"
                                        value="STANDALONE OVERRIDE REFUND">Standalone Override Refund
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-5 mb-4 form-controls">
                            <mat-form-field appearance="outline">
                                <div class="clear-dates" (click)="clearDate()">
                                    Clear Date(s)
                                </div>
                                <mat-label>Select date range</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate placeholder="Start date" formControlName="createdFromDate"
                                        (dateInput)="updateFilterJson('transactionProcessDate')" />
                                    <input matEndDate placeholder="End date" formControlName="createdToDate"
                                        (dateInput)="updateFilterJson('transactionProcessDate')" />
                                </mat-date-range-input>
                                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-5 form-controls">
                            <mat-form-field appearance="outline">
                                <mat-label>Originating System Code</mat-label>
                                <mat-select id="originatingSystemCode" formControlName="originatingSystemCode"
                                    (selectionChange)="updateFilterJson('originatingSystemCode')">
                                    <mat-option disabled>Select...</mat-option>
                                    <mat-option value="ALL">ALL</mat-option>
                                    <mat-option *ngFor="let oscConfigCode of oscConfigCodes" [value]="oscConfigCode">
                                        {{oscConfigCode}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-5 form-controls">
                            <mat-form-field appearance="outline">
                                <mat-label>Created By/Email Address</mat-label>
                                <input matInput formControlName="createdByOrEmailAddress"
                                    (ngModelChange)="updateFilterJson('createdByOrEmailAddress')" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-primary mb-4 ms-3" (click)="viewAuditLog()"
                                [disabled]="!enableGoButton">
                                Go
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mt-4" [hidden]="!viewAuditLogResult">
                <div class="card-body">
                    <h3>Audit Log Result</h3>
                    <mat-table [dataSource]="dataSource" matSort class="table table-responsive table-bordered">
                        <ng-container matColumnDef="orderID">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                Order Id
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="link" (click)="navigateToOrderSearchDetails(element)">{{
                                    element.orderID }}</a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="originatingSystemCode">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                OSC
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.originatingSystemCode }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="transactionType">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                Transaction Type
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.transactionType }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createdByOrEmailAddress">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                Created By/Email Address
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.createdByOrEmailAddress }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                Amount
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.amount }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="transactionProcessDate">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                Transaction Process Date
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element?.transactionProcessDate | date: "MM/dd/yyyy" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="orderState">
                            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                                Order State
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element?.orderState }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </mat-table>
                </div>
                <mat-paginator [length]="100" [pageSize]="15" [pageSizeOptions]="[10, 15, 25]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8 alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to
        access the page.
    </div>
    <div class="col-2"></div>
</div>