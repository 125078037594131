export const PaymentMethods = [
    { value: 'ALIPAY', text: 'Alipay' },
    { value: 'american-express', text: 'American Express' },
    { value: 'BHARATQR', text: 'Bharat QR' },
    { value: 'CREDITCARD', text: 'Credit Card' },
    { value: 'DEBITCARD', text: 'Debit Card' },
    { value: 'diners-club', text: 'Diners Club International' },
    { value: 'discover', text: 'Discover' },
    { value: 'TELECHECK', text: 'Electronic Check' },
    { value: 'elo', text: 'ELO' },
    { value: 'jcb', text: 'JCB' },
    { value: 'mastercard', text: 'Master Card' },
    { value: 'NETBANKING', text: 'Net Banking' },
    { value: 'Other', text: 'Other' },
    { value: 'PAYPAL', text: 'PayPal' },
    { value: 'UPI', text: 'UPI' },
    { value: 'unionpay', text: 'UnionPay' },
    { value: 'visa', text: 'Visa' },
    { value: 'WALLET', text: 'Wallet' }
]

export const QuickDateSelect = [
    { value: 'today', text: 'Today' },
    { value: 'yesterday', text: 'Yesterday' },
    { value: 'week', text: 'This week' },
    { value: 'lastWeek', text: 'Last week' },
    { value: 'month', text: 'This month' },
    { value: 'lastMonth', text: 'Last month' }
]

export const OrderState = [
    { value: 'SALE', text: 'SALE' },
    { value: 'SALE RECONCILIATION PENDING', text: 'SALE RECONCILIATION PENDING' },
    { value: 'SALE SETTLED', text: 'SALE SETTLED' },
    { value: 'SALE ERROR', text: 'SALE ERROR' },
    { value: 'SALE REJECTED', text: 'SALE REJECTED' },
    { value: 'SALE PENDING AUTHORIZED', text: 'SALE PENDING AUTHORIZED' },
    { value: 'SALE NOMATCH SETTLED', text: 'SALE NOMATCH SETTLED' },
    { value: 'SALE INCOMPLETE', text: 'SALE INCOMPLETE' },
    { value: 'SALE UPDATED SETTLEMENT', text: 'SALE UPDATED SETTLEMENT' },
    { value: 'REFUND', text: 'REFUND' },
    { value: 'REFUND RECONCILIATION PENDING', text: 'REFUND RECONCILIATION PENDING' },
    { value: 'REFUND SETTLED', text: 'REFUND SETTLED' },
    { value: 'REFUND REJECTED', text: 'REFUND REJECTED' },
    { value: 'REFUND ERROR', text: 'REFUND ERROR' },
    { value: 'REFUND NOMATCH SETTLED', text: 'REFUND NOMATCH SETTLED' },
    { value: 'REFUND INCOMPLETE', text: 'REFUND INCOMPLETE' },
    { value: 'REFUND UPDATED SETTLEMENT', text: 'REFUND UPDATED SETTLEMENT' },
    { value: 'LINKED OVERRIDE REFUND', text: 'LINKED OVERRIDE REFUND' },
    { value: 'LINKED OVERRIDE REFUND INCOMPLETE', text: 'LINKED OVERRIDE REFUND INCOMPLETE' },
    { value: 'STANDALONE OVERRIDE REFUND', text: 'STANDALONE OVERRIDE REFUND' },
    { value: 'STANDALONE OVERRIDE REFUND INCOMPLETE', text: 'STANDALONE OVERRIDE REFUND INCOMPLETE' },
    { value: 'CHARGEBACK/DISPUTE', text: 'CHARGEBACK/DISPUTE' },
    { value: 'REVERSE CHARGEBACK', text: 'REVERSE CHARGEBACK' },
    { value: 'OVERRIDE CHARGEBACK', text: 'OVERRIDE CHARGEBACK' },
    { value: 'OVERRIDE REV CHARGEBACK', text: 'OVERRIDE REV CHARGEBACK' }
]


export const Decision = [
    { value: 'ACCEPT', text: 'ACCEPT' },
    { value: 'REJECT', text: 'REJECT' },
    { value: 'ERROR', text: 'ERROR' }
]

export const OrderAmountFilter = [
    { value: '&gt', text: 'Greater Than' },
    { value: '=', text: 'Equal To' },
    { value: '&lt;', text: 'Less Than' }
]

export const CardType = [
    { value: '001', text: 'Visa-001' },
    { value: '002', text: 'MasterCard-002' },
    { value: '003', text: 'Amex-003' },
    { value: '004', text: 'Discover-004' },
    { value: '005', text: 'Diners Club International-005' },
    { value: '006', text: 'UnionPay-006' },
    { value: '007', text: 'JCB-007' },
    { value: '008', text: 'Rupay Global-008' }
]

export const PaymentType = [
    { value: 'ALIPAY', text: 'Alipay' },
    { value: 'TELECHECK', text: 'Bank account' },
    { value: 'PAYMENT_CARD', text: 'Credit Card' },
    { value: 'FISERV_INDIA', text: 'FISERV_INDIA' },
    { value: 'PAYPAL', text: 'PayPal' }
]

export const TransactionType = [
    { value: 'Alipay Sale', text: 'Alipay Sale' },
    { value: 'Alipay Refund', text: 'Alipay Refund' },
    { value: 'Credit Card Sale', text: 'Credit Card Sale' },
    { value: 'Credit Card Refund', text: 'Credit Card Refund' },
    { value: 'Credit Card Chargeback', text: 'Credit Card Chargeback' },
    { value: 'Credit Card Reverse Chargeback', text: 'Credit Card Reverse Chargeback' },
    { value: 'Credit Card Request for Retrieval', text: 'Credit Card Request for Retrieval' },
    { value: 'Electronic Check Sale', text: 'Electronic Check Sale' },
    { value: 'Electronic Check Refund', text: 'Electronic Check Refund' },
    { value: 'Electronic Check Return', text: 'Electronic Check Return' },
    { value: 'PayPal Sale', text: 'PayPal Sale' },
    { value: 'PayPal Refund', text: 'PayPal Refund' },
    { value: 'PayPal Chargeback', text: 'PayPal Chargeback' },
    { value: 'PayPal Reverse Chargeback', text: 'PayPal Reverse Chargeback' },
    { value: 'Fiserv India Sale', text: 'Fiserv India Sale' },
    { value: 'Fiserv India Refund', text: 'Fiserv India Refund' },
    { value: 'Fiserv India Chargeback', text: 'Fiserv India Chargeback' },
    { value: 'Fiserv India Reverse Chargeback', text: 'Fiserv India Reverse Chargeback' }
]
