import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/constant/constants';
import {
  IDynamicDialogConfig,
  MatDialogComponentComponent,
} from 'src/app/mat-dialog-component/mat-dialog-component.component';
import { ExternalUser, User } from 'src/app/model/user';
import { SharedService } from 'src/app/service/shared.service';
import { UserManagementService } from 'src/app/service/user-management.service';

const dialogContent = {
  confirmationMessage: '<h5>User account has been successfully created. User will need to login to EPMS in order to be granted OSC(s).</h5> <strong>Note:</strong><br><Span>The user will receive two emails:</Span><br><ol><li>The first containing login and the EPMS Admin site.</li><li>The second containing the temporary password.</li></ol>',
  confirmation: 'confirmation',
  errorTitle: 'Create User Failed',
  failed: 'failed'
}
@Component({
  selector: 'app-manage-external-user',
  templateUrl: './manage-external-user.component.html',
  styleUrls: ['./manage-external-user.component.scss'],
})
export class ManageExternalUserComponent implements OnInit {
  loggedInUser: User;
  dropdownValue = 'default';
  disableButtonMode: boolean = true;
  selectedContextValue: string;
  createUser: FormGroup;
  selectedGroupInfo: any;
  viewForm: string;
  constructor(
    private sharedService: SharedService,
    private dialog: MatDialog,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        window.scrollTo(0, 0);
        this.createUser = new FormGroup({
          middleName:new FormControl('',[ Validators.minLength(1), Validators.maxLength(64)]),
          firstName: new FormControl('',[ Validators.minLength(1), Validators.maxLength(64), Validators.required]),
          lastName: new FormControl('',[ Validators.minLength(1), Validators.maxLength(64), Validators.required]),
          emailAddress: new FormControl('', [Validators.pattern(Constants.EMAIL_VALIDATOR_PATTERN), Validators.required]),
          userType: new FormControl('')
        });
      }
    });
  }
  disableButton() {
    this.disableButtonMode = this.dropdownValue == 'default' ? true : false;
  }

  viewSelected() {
    this.viewForm = this.dropdownValue;
  }

  createUserAction() {
    const createUserReq: ExternalUser = {} as ExternalUser;
    createUserReq.emailAddress = this.createUser.get('emailAddress').value;
    createUserReq.firstName = this.createUser.get('firstName').value;
    createUserReq.lastName = this.createUser.get('lastName').value;
    createUserReq.middleName = this.createUser.get('middleName').value;
    createUserReq.userType = this.createUser.get('userType').value;
    this.userManagementService.createExternalUser(createUserReq).subscribe({
      next: (data) => {
      this.openDialog(null, dialogContent.confirmation, dialogContent.confirmationMessage)
      },
      error: (err: HttpErrorResponse) => {
      this.openDialog(null, dialogContent.failed, null, err)
      },
   });
  }


  /*
  type - clear or exit Dialog
  state - create user confirmation or failed state
  message - confirmation or failed create message
  error - create user error
  */
  openDialog(type?:string, state?:string, message?:string, error?:any){
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      width: '50%',
      data: <IDynamicDialogConfig>{
        title: type ? 'Are you sure you want to ' + type + '?' : '',
        acceptButtonTitle: type ? 'Yes' : '',
        declineButtonTitle: type ? 'No' : '',
        htmlContent: message,
        actionState: state,
        error: error,
        errorTitle: error? dialogContent.errorTitle : ''
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if ((value !== 'noExit' && type) || (value === 'noExit' && state)) {
        if(type === 'exit'){
        this.viewForm = '';
        this.dropdownValue = 'default';
        }
        this.createUser.reset();
      } 
    });
  }
}
