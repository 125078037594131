import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../service/shared.service';
import { User } from '../model/user';
import { Profile } from '../model/profile-permissions';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { VirtualTerminalAuditLogService } from './virtual-terminal-audit-log.service';
import { OrderSearchService } from '../service/order-search.service';
import { OrderSearchResponse } from '../model/order-search-response';
import { DatePipe } from '@angular/common';

export interface auditLog {
  orderID: string;
  osc: string;
  transactionType: string;
  transactionId: string;
  createdByOrEmailAddress: string;
  amount: string;
  orderState: string;
  transactionProcessDate: string;
}

@Component({
  selector: 'app-virtual-terminal-audit-log',
  templateUrl: './virtual-terminal-audit-log.component.html',
  styleUrls: ['./virtual-terminal-audit-log.component.scss'],
})
export class VirtualTerminalAuditLogComponent implements OnInit {
  viewAuditLogResult: boolean;
  dataSource: MatTableDataSource<auditLog>;
  loggedInUser: User;
  profile: Profile;
  filterCriteriaSub: Subscription;
  filterCriteriaValue: string = '';
  startTime:string = '00:00';
  endTime:string = '23:59';
  datePipe = new DatePipe('en-US');
  displayedColumns: string[] = [
    'orderID',
    'originatingSystemCode',
    'transactionType',
    'createdByOrEmailAddress',
    'amount',
    'transactionProcessDate',
    'orderState',
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  transactionForm: FormGroup = new FormGroup({
    originatingSystemCode: new FormControl(''),
    transactionType: new FormControl(''),
    createdFromDate: new FormControl(''),
    createdToDate: new FormControl(''),
    createdByOrEmailAddress: new FormControl(''),
  });
  backAudit: any;
  enableGoButton: boolean;
  oscConfigCodes: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private virtualTerminalAuditLogService: VirtualTerminalAuditLogService,
    private OrderSearch: OrderSearchService
  ) {
    this.viewAuditLogResult = false;
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.oscConfigCodes = user.oscDetails.map((osc) => osc.oscCode).sort();
        this.route.queryParams.subscribe((data) => {
          this.backAudit = data['backAudit'];
        });
        this.filterCriteriaSub =
          this.virtualTerminalAuditLogService.currentFilterCriteria.subscribe(
            (value) => (this.filterCriteriaValue = value)
          );
        if (this.filterCriteriaValue && this.backAudit) {
          this.refillFilterForm(this.filterCriteriaValue);
          this.getAuditLogDataFromStore();
        }
        this.enableOrDisableGoButton();
      }
    });
    this.sharedService.profileInfo$.subscribe((profileInfo) => {
      this.profile = profileInfo;
    });
  }

  refillFilterForm(filterCriteriaValue: any) {
    try {
      const keys = Object.keys(filterCriteriaValue);
      keys.forEach((prop: string) => {
        this.transactionForm.controls[prop].setValue(filterCriteriaValue[prop]);
      });
    } catch (err) {
      console.error(
        'Failure in setting transactionForm: ' + JSON.stringify(err)
      );
    }
  }
  parseOrderSearchResponse(orderData: OrderSearchResponse[]) {
    const auditLogDataList = orderData.map((data) => {
      const logData = {
        orderID: data.epmsOrderId,
        originatingSystemCode: data.originatingSystemCode,
        transactionType: data.transactionTypeCode,
        transactionId: data.transactionId,
        createdByOrEmailAddress: data.createdBy,
        amount: data.usdAmount,
        transactionProcessDate: new Date(data.createdDate)
          .toISOString()
          .split('T')[0],
        orderState: data.orderState,
      };
      return logData;
    });
    this.setData(auditLogDataList);
  }

  updateFilterJson(type) {
    this.enableOrDisableGoButton();
  }

  navigateToOrderSearchDetails(orderData) {
    this.virtualTerminalAuditLogService.changeOrderFilterCriteria(
      this.transactionForm.value
    );
    this.router.navigate(['/order-search-details', orderData.transactionId], {
      queryParams: {
        orderState: orderData?.orderState ? orderData.orderState : '',
        audit: true,
      },
    });
  }

  getAuditLogData() {
    let searchData = [
      {
        field: 'hopIndicator',
        value: 'VTHOP',
      },
    ];
    const originatingSystemCode = this.transactionForm.get(
      'originatingSystemCode'
    ).value;
    searchData.push({
      field: 'originatingSystemCode',
      value:
        originatingSystemCode === 'ALL' || originatingSystemCode === ''
          ? this.oscConfigCodes?.join(',')
          : originatingSystemCode,
    });

    if (
      this.transactionForm.get('createdFromDate').value &&
      this.transactionForm.get('createdToDate').value
    ) {
      searchData.push({
        field: 'createdFromDate',
        value: this.datePipe.transform(this.transactionForm.get('createdFromDate').value, `yyyy-MM-ddT${this.startTime}ZZZZZ`),
      });
      searchData.push({
        field: 'createdToDate',
        value: this.datePipe.transform(this.transactionForm.get('createdToDate').value, `yyyy-MM-ddT${this.endTime}ZZZZZ`),
      });
    }
    const transactionType = this.transactionForm.get('transactionType').value || 'all';
    if (transactionType) {
      searchData.push({
        field: transactionType !== 'all' ? 'orderState' : 'allOrderState',
        value: transactionType,
      });
    }
    if (this.transactionForm.get('createdByOrEmailAddress').value) {
      searchData.push({
        field: 'createdBy',
        value: this.transactionForm.get('createdByOrEmailAddress').value,
      });
    }
    this.OrderSearch.orderSearch(JSON.stringify(searchData)).subscribe({
      next: (data) => {
        this.parseOrderSearchResponse(data?.['content']);
        this.virtualTerminalAuditLogService.storeFilterResult(
          data?.['content']
        );
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
        this.viewAuditLogResult = false;
      },
    });
  }

  setData(auditLogDataList) {
    this.dataSource = new MatTableDataSource(auditLogDataList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  clearDate() {
    this.transactionForm.controls.createdFromDate.setValue('');
    this.transactionForm.controls.createdToDate.setValue('');
    this.enableOrDisableGoButton();
  }

  viewAuditLog() {
    this.viewAuditLogResult = true;
    this.getAuditLogData();
  }

  getAuditLogDataFromStore() {
    this.viewAuditLogResult = true;
    this.virtualTerminalAuditLogService.currentFilterResultCriteria.subscribe(
      (value: any) => this.parseOrderSearchResponse(value)
    );
  }

  enableOrDisableGoButton() {
    if (
      this.transactionForm.get('originatingSystemCode').value ||
      this.transactionForm.get('transactionType').value ||
      this.transactionForm.get('createdFromDate').value ||
      this.transactionForm.get('createdToDate').value ||
      this.transactionForm.get('createdByOrEmailAddress').value
    ) {
      this.enableGoButton = true;
    } else {
      this.enableGoButton = false;
    }
  }
}
