export class UserRecordLoginSession {

  userId: number;
  userLoginTimestamp: string;
  createdBy: string;
  userLoginId;
  emailId;
  firstName;
  lastName;


  constructor() {
  }

}
