import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  continue: string;
  exit: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title ? data.title : null;
    this.message = data.message ? data.message : null;
    this.continue = data.continue ? data.continue : 'Continue';
    this.exit = data.exit ? data.exit : 'Exit';
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onExit(): void {
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  message: string;
  title?: string;
  continue?: string;
  exit?: string;
  
  constructor(message: string) {
    this.message = message;
  }
}
