import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Country} from '../model/country';
import {Observable, of, Subscriber} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CountryRegionService implements Resolve<any> {
  countryList = [];

  constructor(private http: HttpClient) {
  }

  loadUsaStates() {
    let states = this.countryList.filter(cntry => cntry.countryIsoCode === 'US')[0].stateProvince;
    states = states.map(st => {
      return {name: st.stateName, id: st.stateCode};
    });
    return of(states);
  }

  getCountryCodes() {
    return JSON.parse(sessionStorage.getItem('countries'));
  }

  getCountriesNameId(countryResult) {
    return countryResult.map((country) => {
      return { name: country.countryName, id: country.countryIsoCode };
    });
  }

  loadCaStates() {
    let states = this.countryList.filter(cntry => cntry.countryIsoCode === 'CA')[0].stateProvince;
    states = states.map(st => {
      return {name: st.stateName, id: st.stateCode};
    });
    return of(states);
  }

  loadCountries(subscriber?: Subscriber<any>) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.http.get<Country[]>(environment.ADMIN_API_HOSTNAME + `/config/countries`, httpOptions).subscribe(data => {
      const usaCountry = data.filter(cntry => cntry.countryIsoCode === 'US');
      const nonUsaCountry = data.filter(cntry => cntry.countryIsoCode !== 'US');
      this.countryList = [...usaCountry, ...nonUsaCountry];
      sessionStorage.setItem('countries', (JSON.stringify(this.getCountriesNameId(this.countryList))));
      if (subscriber) {
        subscriber.next(this.countryList);
        subscriber.complete();
      }
    }, err => {
      console.log('error while loading the countries');
      this.countryList = [];
      if (subscriber) {
        subscriber.next(this.countryList);
        subscriber.complete();
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const obs = new Observable(subscriber => {
      this.loadCountries(subscriber);
    });

    return obs;
  }
}
