import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/model/user';
import { SharedService } from 'src/app/service/shared.service';
import { payments_types, fraud_3ds_currency, avs_cvs_rules } from '../config';
import {
  IDynamicDialogConfig,
  MatDialogComponentComponent,
} from '../../mat-dialog-component/mat-dialog-component.component';
import { MasterHOPDetails } from 'src/app/model/master-hop-details';
import { Subject } from 'rxjs';
import { OSCHOPService } from 'src/app/service/osc-hop-config.service';
@Component({
  selector: 'app-hop-master-configuration',
  templateUrl: './hop-master-configuration.component.html',
  styleUrls: ['./hop-master-configuration.component.scss'],
})
export class HOPMasterConfigComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedContextValue;
  isDisabled = true;
  validationMessage: boolean;
  payments_types;
  fraud_3ds_currency;
  types: FormGroup;
  loggedInUser: User;
  avs_cvs_rules = [];
  selectedHOPData: MasterHOPDetails;
  onChanges = new Subject<SimpleChanges>();
  errMsg: string;
  constructor(
    private sharedService: SharedService,
    private dialog: MatDialog,
    private oscHOPService: OSCHOPService
  ) {}

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.updateForm();
      }
    });
  }

  updateForm() {
    this.types = new FormGroup({
      ccPaymentEnabled: new FormControl(null),
      visaEnabled: new FormControl(null),
      masterCardEnabled: new FormControl(null),
      amexEnabled: new FormControl(null),
      discoverEnabled: new FormControl(null),
      unionPayEnabled: new FormControl(null),
      dinersClubEnabled: new FormControl(null),
      jcbEnabled: new FormControl(null),
      rupayGlobalEnabled: new FormControl(null),
      paypalEnabled: new FormControl(null),
      visaCSCRequired: new FormControl(null),
      mastercardCSCRequired: new FormControl(null),
      amexCSCRequired: new FormControl(null),
      discoveryCSCRequired: new FormControl(null),
      unionPayCSCRequired: new FormControl(null),
      dinersClubCSCRequired: new FormControl(null),
      jcbCSCRequired: new FormControl(null),
      rupayGlobalCSCRequired: new FormControl(null),
      alipayEnabled: new FormControl(null),
      fraudDetectEnabled: new FormControl(null),
      dccEnabled: new FormControl(null),
      threeDSEnabled: new FormControl(null),
      visa3dsEnabled: new FormControl(null),
      mc3dsEnabled: new FormControl(null),
      amex3dsEnabled: new FormControl(null),
      discover3dsEnabled: new FormControl(null),
      unionPay3dsEnabled: new FormControl(null),
      diners3dsEnabled: new FormControl(null),
      jcb3dsEnabled: new FormControl(null),
      rupay3dsEnabled: new FormControl(null),
      allowAuthorizationOnlyRequest: new FormControl(null),
    });
    this.types.disable();
    this.viewSelected();
    this.onChanges.subscribe((data: SimpleChanges) => {
      if (data['selectedContextValue']) {
        this.viewSelected();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  ngOnDestroy() {
    this.onChanges.complete();
  }

  editConfig() {
    this.isDisabled = !this.isDisabled;
    this.types.enable();
    this.validationMessage = false;
    !this.selectedHOPData.ccPaymentEnabled
      ? this.enableOrDisableCreditCardOr3DS(false, 'ccPaymentEnabled')
      : '';
    !this.selectedHOPData.threeDSEnabled
      ? this.enableOrDisableCreditCardOr3DS(false, 'threeDSEnabled')
      : '';
  }
  saveExit() {
    this.isDisabled = !this.isDisabled;
    this.types.disable();
    const masterHopDetailsData = this.getMasterHopDetailsData();
    const obs = this.oscHOPService.saveMasterHOPDetails(masterHopDetailsData);
    obs.subscribe({
      next: (data) => {
        this.validationMessage = true;
        this.oscHOPService.getAndStoreMasterHOPDetails();
      },
      error: (err) => {
        console.log('error', err);
        this.errMsg =
          'Error while processing the request. ' + err?.error?.message;
      },
    });
  }

  getMasterHopDetailsData() {
    let masterHopDetails = {} as MasterHOPDetails;
    masterHopDetails.hopId = this.selectedContextValue;
    masterHopDetails.amexEnabled = this.types.controls['amexEnabled'].value;
    masterHopDetails.dinersClubEnabled =
      this.types.controls['dinersClubEnabled'].value;
    masterHopDetails.discoverEnabled =
      this.types.controls['discoverEnabled'].value;
    masterHopDetails.jcbEnabled = this.types.controls['jcbEnabled'].value;
    masterHopDetails.masterCardEnabled =
      this.types.controls['masterCardEnabled'].value;
    masterHopDetails.rupayGlobalEnabled =
      this.types.controls['rupayGlobalEnabled'].value;
    masterHopDetails.unionPayEnabled =
      this.types.controls['unionPayEnabled'].value;
    masterHopDetails.visaEnabled = this.types.controls['visaEnabled'].value;
    masterHopDetails.ccPaymentEnabled =
      this.types.controls['ccPaymentEnabled'].value;
    masterHopDetails.paypalEnabled = this.types.controls['paypalEnabled'].value;
    masterHopDetails.threeDSEnabled =
      this.types.controls['threeDSEnabled'].value;
    masterHopDetails.dccEnabled = this.types.controls['dccEnabled'].value;
    masterHopDetails.fraudDetectEnabled =
      this.types.controls['fraudDetectEnabled'].value;
    masterHopDetails.allowAuthorizationOnlyRequest =
      this.types.controls['allowAuthorizationOnlyRequest'].value;
    masterHopDetails.amexCSCRequired =
      this.types.controls['amexCSCRequired'].value;
    masterHopDetails.dinersClubCSCRequired =
      this.types.controls['dinersClubCSCRequired'].value;
    masterHopDetails.discoveryCSCRequired =
      this.types.controls['discoveryCSCRequired'].value;
    masterHopDetails.jcbCSCRequired =
      this.types.controls['jcbCSCRequired'].value;
    masterHopDetails.mastercardCSCRequired =
      this.types.controls['mastercardCSCRequired'].value;
    masterHopDetails.rupayGlobalCSCRequired =
      this.types.controls['rupayGlobalCSCRequired'].value;
    masterHopDetails.unionPayCSCRequired =
      this.types.controls['unionPayCSCRequired'].value;
    masterHopDetails.visaCSCRequired =
      this.types.controls['visaCSCRequired'].value;
    masterHopDetails.amex3dsEnabled =
      this.types.controls['amex3dsEnabled'].value;
    masterHopDetails.diners3dsEnabled =
      this.types.controls['diners3dsEnabled'].value;
    masterHopDetails.discover3dsEnabled =
      this.types.controls['discover3dsEnabled'].value;
    masterHopDetails.jcb3dsEnabled = this.types.controls['jcb3dsEnabled'].value;
    masterHopDetails.mc3dsEnabled = this.types.controls['mc3dsEnabled'].value;
    masterHopDetails.rupay3dsEnabled =
      this.types.controls['rupay3dsEnabled'].value;
    masterHopDetails.unionPay3dsEnabled =
      this.types.controls['unionPay3dsEnabled'].value;
    masterHopDetails.visa3dsEnabled =
      this.types.controls['visa3dsEnabled'].value;
    masterHopDetails.alipayEnabled = this.types.controls['alipayEnabled'].value;
    masterHopDetails.createdBy = this.selectedHOPData.createdBy;
    masterHopDetails.createdDate = this.selectedHOPData.createdDate;
    masterHopDetails.updatedBy = this.loggedInUser.emailId;
    masterHopDetails.updateDate = new Date().toISOString();
    return masterHopDetails;
  }

  exit() {
    this.openDialog();
  }

  openDialog() {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      data: <IDynamicDialogConfig>{
        title: 'Do you want to exit without saving?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value !== 'noExit') {
        this.isDisabled = !this.isDisabled;
        this.types.disable();
      }
    });
  }

  viewSelected() {
    this.validationMessage = false;
    this.errMsg = '';
    if (this.selectedContextValue === 'IHOP') {
      this.payments_types = [
        ...payments_types.slice(0, 1),
        ...avs_cvs_rules.slice(1, 2),
      ];
      this.fraud_3ds_currency = fraud_3ds_currency.slice(0, 2);
    } else {
      this.payments_types = payments_types.slice(0, 4);
      this.fraud_3ds_currency = fraud_3ds_currency;
      this.avs_cvs_rules = avs_cvs_rules;
    }
    this.isDisabled ? '' : this.types.enable();
    this.selectedHOPData = JSON.parse(
      sessionStorage.getItem('masterHOPDetails')
    )?.find((data) => data.hopId === this.selectedContextValue);
    this.setFormData();
  }

  setFormData() {
    if (this.selectedHOPData) {
      this.types.setValue({
        ccPaymentEnabled: this.selectedHOPData.ccPaymentEnabled,
        visaEnabled: this.selectedHOPData.visaEnabled,
        masterCardEnabled: this.selectedHOPData.masterCardEnabled,
        amexEnabled: this.selectedHOPData.amexEnabled,
        discoverEnabled: this.selectedHOPData.discoverEnabled,
        unionPayEnabled: this.selectedHOPData.unionPayEnabled,
        dinersClubEnabled: this.selectedHOPData.dinersClubEnabled,
        jcbEnabled: this.selectedHOPData.jcbEnabled,
        rupayGlobalEnabled: this.selectedHOPData.rupayGlobalEnabled,
        paypalEnabled: this.selectedHOPData.paypalEnabled,
        visaCSCRequired: this.selectedHOPData.visaCSCRequired,
        mastercardCSCRequired: this.selectedHOPData.mastercardCSCRequired,
        amexCSCRequired: this.selectedHOPData.amexCSCRequired,
        discoveryCSCRequired: this.selectedHOPData.discoveryCSCRequired,
        unionPayCSCRequired: this.selectedHOPData.unionPayCSCRequired,
        dinersClubCSCRequired: this.selectedHOPData.dinersClubCSCRequired,
        jcbCSCRequired: this.selectedHOPData.jcbCSCRequired,
        rupayGlobalCSCRequired: this.selectedHOPData.rupayGlobalCSCRequired,
        alipayEnabled: this.selectedHOPData.alipayEnabled,
        fraudDetectEnabled: this.selectedHOPData.fraudDetectEnabled,
        dccEnabled: this.selectedHOPData.dccEnabled,
        threeDSEnabled: this.selectedHOPData.threeDSEnabled,
        visa3dsEnabled: this.selectedHOPData.visa3dsEnabled,
        mc3dsEnabled: this.selectedHOPData.mc3dsEnabled,
        amex3dsEnabled: this.selectedHOPData.amex3dsEnabled,
        discover3dsEnabled: this.selectedHOPData.discover3dsEnabled,
        unionPay3dsEnabled: this.selectedHOPData.unionPay3dsEnabled,
        diners3dsEnabled: this.selectedHOPData.diners3dsEnabled,
        jcb3dsEnabled: this.selectedHOPData.jcb3dsEnabled,
        rupay3dsEnabled: this.selectedHOPData.rupay3dsEnabled,
        allowAuthorizationOnlyRequest:
          this.selectedHOPData.allowAuthorizationOnlyRequest,
      });
    }
  }

  enableOrDisableCreditCardOr3DS(value, type) {
    if (!value && type === 'ccPaymentEnabled') {
      this.types.disable();
      this.types.controls['ccPaymentEnabled'].enable();
      this.types.controls['paypalEnabled'].enable();
      this.types.controls['alipayEnabled'].enable();
      this.types.controls['fraudDetectEnabled'].enable();
      this.types.controls['dccEnabled'].enable();
      this.types.controls['allowAuthorizationOnlyRequest'].enable();
    } else if (!value && type === 'threeDSEnabled') {
      this.types.controls['visa3dsEnabled'].disable();
      this.types.controls['mc3dsEnabled'].disable();
      this.types.controls['amex3dsEnabled'].disable();
      this.types.controls['unionPay3dsEnabled'].disable();
      this.types.controls['diners3dsEnabled'].disable();
      this.types.controls['jcb3dsEnabled'].disable();
      this.types.controls['rupay3dsEnabled'].disable();
      this.types.controls['discover3dsEnabled'].disable();
    } else if (value && (type === 'threeDSEnabled' || type === 'ccPaymentEnabled')){
      this.types.enable();
    }
  }
}
