import { Component } from '@angular/core';
import {Router} from '@angular/router';
@Component({
    selector: 'app-search-fail',
    templateUrl: './search-fail.component.html',
    styleUrls: ['./search-fail.component.scss']
  })

export class SearchFailsComponent {

  constructor(private router: Router) { }
  onRevise(){
    this.router.navigate(['/order-search'],{queryParams:{'fromAction':'revise'}});
  }
}
