import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProgramGroup } from '../model/program-group';
import { RoleInfo } from '../model/role-info';
import { OrderService } from '../service/order.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatOption } from '@angular/material/core';
import { UserManagementService } from '../service/user-management.service';
import { AssignBusinessService } from '../service/assign-business.service';
import { SharedService } from '../service/shared.service';
import { User } from '../model/user';

@Component({
  selector: 'app-assign-role-and-business',
  templateUrl: './assign-role-and-business.component.html',
  styleUrls: ['./assign-role-and-business.component.scss'],
})
export class AssignRoleAndBusinessComponent implements OnInit {
  usersToAssign: any;
  roleInfoList: RoleInfo[] = [];
  AssignRoleBusiness: FormGroup;
  programGroupList: ProgramGroup[] = [];
  oscConfigCodes = [];
  dataSource: MatTableDataSource<any>;
  faTrash = faTrash;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('progAllSelected') progAllSelected: MatOption;
  @ViewChild('oscAllSelected') oscAllSelected: MatOption;
  displayedColumns: string[] = [
    'userLoginID',
    'firstName',
    'lastName',
    'programGroup',
    'osc',
    'role',
  ];
  usersUpdatedData: any;
  noChangesAdded: boolean;
  userInfo: User;

  constructor(
    private router: Router,
    private orderService: OrderService,
    private userManagementService: UserManagementService,
    private assignBusinessSVC: AssignBusinessService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe(user => {
        this.userInfo = user;
    });
    this.AssignRoleBusiness = new FormGroup({
      role: new FormControl(null),
      programGroup: new FormControl(null),
      osc: new FormControl(null),
    });
    this.GetSelectedUsersData();
    this.setTableData(this.usersToAssign);
    this.getRoleNameCodes();
    this.getAllProgramGroupNames();
    this.getOscConfigCodes();
    this.AssignRoleBusiness.controls['role'].setValue(
      this.usersToAssign[0].role
    );
  }

  /* navigate back to user-management with queryParams
  editSelectedUsers*/
  back() {
    this.router.navigate(['/user-management'], {
      queryParams: { editSelectedUsers: true },
    });
  }

  /* navigate back to user-management*/
  exit() {
    this.router.navigate(['/user-management']);
  }

   /* get selected users to modify role and osc from session storage*/
  GetSelectedUsersData() {
    this.usersToAssign = JSON.parse(
      sessionStorage.getItem('usersAssignRoleBusiness')
    );
  }

  /* get roles list*/
  getRoleNameCodes() {
    this.orderService.getRoleNameCodes().subscribe({
      next: (data) => {
        this.roleInfoList = data;
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
        this.roleInfoList = [];
      },
    });
  }

  /* get ProgramGroup list*/
  getAllProgramGroupNames() {
    this.orderService.getAllProgramGroupNames().subscribe({
      next: (data) => {
        this.programGroupList = data;
        this.setProgramsGroupData();
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
        this.programGroupList = [];
      },
    });
  }

  /* set ProgramGroup list in dropdown and disable osc dropdown if all programgroups selected*/
  setProgramsGroupData() {
    this.AssignRoleBusiness.controls['programGroup']?.setValue(Array.isArray(this.usersToAssign[0].programGroup)
      ? this.usersToAssign[0].programGroup
      : this.usersToAssign[0].programGroup.split(', '));
    if (this.AssignRoleBusiness.controls['programGroup']?.value?.length === this.programGroupList?.length) {
      this.AssignRoleBusiness.controls['programGroup']?.setValue(['ALL', ...this.AssignRoleBusiness.controls['programGroup']?.value]);
      this.AssignRoleBusiness.controls['osc'].enable();
    }
  }

  /* get osccodes list*/
  getOscConfigCodes() {
    this.orderService.getOscConfigCodes().subscribe({
      next: (data) => {
        this.oscConfigCodes = data;
        this.setOSCData();
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
        this.oscConfigCodes = [];
      },
    });
  }

   /* populate osccodes in dropdown*/
  setOSCData() {
    if (this.usersToAssign[0]?.osc.length > 0) {
      this.AssignRoleBusiness.controls['osc']?.setValue(Array.isArray(this.usersToAssign[0].osc)
        ? this.usersToAssign[0]?.osc.length > 0
        : this.usersToAssign[0].osc.split(', '));
    } else {
      this.AssignRoleBusiness.controls['osc']?.setValue([]);
    }
    if (this.AssignRoleBusiness.controls['osc']?.value?.length === this.oscConfigCodes?.length) {
      this.AssignRoleBusiness.controls['osc']?.setValue(['ALL', ...this.AssignRoleBusiness.controls['osc']?.value]);
    }
  }

  /*selected users data in table*/
  setTableData(userDataList) {
    this.updateDisplayedColumns(userDataList);
    this.dataSource = new MatTableDataSource(userDataList);
    this.dataSource.sort = this.sort;
  }

  /*Remove user column is adding only for bulk users update*/
  updateDisplayedColumns(userDataList) {
    if (
      userDataList?.length > 1 &&
      !this.displayedColumns.includes('removeUser')
    ) {
      this.displayedColumns.unshift('removeUser');
    } else if (
      userDataList?.length === 1 &&
      this.displayedColumns.includes('removeUser')
    ) {
      this.displayedColumns = this.displayedColumns.filter(
        (col) => col !== 'removeUser'
      );
    }
  }

   /*When ALL program groups or OSC selected, it will select all in the list
   Disable OSC dropdown, if all program grops selected*/
  toggleAllSelection(type, selectedList) {
    if (selectedList?.length > 0 && selectedList[0] === 'ALL') {
      this.AssignRoleBusiness.controls['programGroup'].setValue([
        'ALL',
        ...this.programGroupList.map((pgmgrp) => pgmgrp.programGroupName),
      ]);
      this.AssignRoleBusiness.controls['osc'].setValue([
        'ALL',
        ...this.oscConfigCodes,
      ]);
      if (type === 'programGroup') {
         this.AssignRoleBusiness.controls['osc'].enable();
      }
    } else {
      this.AssignRoleBusiness.controls['programGroup'].setValue([]);
      this.AssignRoleBusiness.controls['osc'].setValue([]);
      this.AssignRoleBusiness.controls['osc'].enable();
    }
  }

  /*update osc or program group list based on selection*/
  toggleOne(dropDownList, allSelected, selectedList, programGroupName?) {
    const isAllSelected =
      selectedList?.filter((item) => item !== 'ALL').length ==
      dropDownList.length;
    if (!isAllSelected) {
      allSelected.deselect();
    } else {
      allSelected.select();
    }
    if (programGroupName) {
      this.updateOSCSelectedCodes(programGroupName);
    }
  }

   /*update osc codes selected list based on program group selection*/
  updateOSCSelectedCodes(programGroupNameModified) {
    this.programGroupList.map((item) => {
      if (item.programGroupName === programGroupNameModified) {
        if (this.AssignRoleBusiness.controls['programGroup']?.value?.includes(item.programGroupName)) {
          this.AssignRoleBusiness.controls['osc']?.setValue([
            ...new Set([...this.AssignRoleBusiness.controls['osc']?.value, ...item['oscList']]),
          ]);
        } else {
          item['oscList'].push('ALL');
          this.AssignRoleBusiness.controls['osc']?.setValue(this.AssignRoleBusiness.controls['osc']?.value.filter(
            (val) => item['oscList'].indexOf(val) < 0
          ));
        }
      }
    });
    if (
      this.AssignRoleBusiness.controls['programGroup']?.value?.length > 0 &&
      this.AssignRoleBusiness.controls['programGroup']?.value[0] === 'ALL'
    ) {
      this.AssignRoleBusiness.controls['osc'].enable();
    } else {
      this.AssignRoleBusiness.controls['osc'].enable();
    }
  }

   /*update selected role, osc and programgroups into the table*/
  updateTableData() {
    this.usersUpdatedData = this.usersToAssign;
    this.usersUpdatedData.forEach((element, index) => {
      this.usersUpdatedData[index].role =
        this.AssignRoleBusiness.controls['role'].value;
      this.usersUpdatedData[index].osc = this.AssignRoleBusiness.controls['osc']?.value?.filter(val => val !== 'ALL')?.join(', ');
      this.usersUpdatedData[index].programGroup =
        this.AssignRoleBusiness.controls['programGroup']?.value?.filter(val => val !== 'ALL')?.join(', ');
    });
    this.setTableData(this.usersUpdatedData);
  }

   /*reset all osc, role and programgroup dropdowns and table data to original state before update*/
  reset() {
    this.GetSelectedUsersData();
    this.setTableData(this.usersToAssign);
    this.AssignRoleBusiness.controls['role'].setValue(
      this.usersToAssign[0].role
    );
    this.setProgramsGroupData();
    this.setOSCData();
  }

  /*remove selected user from table list*/
  removeUser(userLoginID) {
    this.usersToAssign = this.usersToAssign.filter(
      (user) => user.userLoginID !== userLoginID
    );
    this.setTableData(this.usersToAssign);
  }

  /*If role and/or osc modified, changes gets saved and exits to user management, otherwise throws an error*/
  saveAndExit() {
    this.GetSelectedUsersData();
    let UserUpdatedInfo = {
      updatedBy: this.userInfo?.emailId,
      userIds: this.usersToAssign.map((user) => {
        return user.userId;
      }),
    };
    if (this.usersToAssign[0]?.osc !== this.AssignRoleBusiness.controls['osc']?.value.join(', ')) {
      UserUpdatedInfo['oscCodes'] = this.AssignRoleBusiness.controls['osc']?.value.filter(val => val !== 'ALL');
    }
    const updatedRole = this.AssignRoleBusiness.controls['role'].value;
    if (this.usersToAssign[0]?.role !== updatedRole) {
      if(updatedRole=='null_role'){
        UserUpdatedInfo['role'] = this.usersToAssign[0]?.role;
        UserUpdatedInfo['removeRole'] =true;
      }else{
        UserUpdatedInfo['role'] = updatedRole;
      }

    }
    if (UserUpdatedInfo['oscCodes'] || UserUpdatedInfo['role']) {
      this.userManagementService.updateUsersOscRole(UserUpdatedInfo).subscribe({
        next: (data) => {
          this.assignBusinessSVC.sendMessage("All updates have been successfully applied.");
          this.router.navigate(['/user-management', 'assignRoleBusinessSuccess']);
        },
        error: (err: any) => {
          this.assignBusinessSVC.sendMessage(JSON.stringify(err));
          console.log('ERROR : '+ JSON.stringify(err));
          this.router.navigate(['/user-management', 'assignRoleBusinessError']);
        },
      });
    } else {
      this.noChangesAdded = true;
    }
  }
}
