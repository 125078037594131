<div class="mat-elevation-z8">


        <table mat-table [dataSource]="dataSource"  matSort class="table table-responsive table-bordered" >


          <ng-container matColumnDef="lineItem">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Line Item # </th>
            <td mat-cell *matCellDef="let element"> <a  class="link">{{element.lineNumber}}</a> </td>
          </ng-container>


          <ng-container matColumnDef="productSKU">
            <th  class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Product SKU </th>
            <td mat-cell *matCellDef="let element"> {{element.productSKU}} </td>
          </ng-container>

          <ng-container matColumnDef="productCode">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>Product Code</th>
            <td mat-cell *matCellDef="let element"> {{element.productCode}} </td>
          </ng-container>

          <ng-container matColumnDef="productName">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>Product Name</th>
            <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
          </ng-container>

          <ng-container matColumnDef="productDescription">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>Product Description</th>
            <td mat-cell *matCellDef="let element"> {{element.productDescription}} </td>
          </ng-container>

          <ng-container matColumnDef="totalLine">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Total Line item
                Amount </th>
            <td mat-cell *matCellDef="let element"> {{element.total?.toFixed(2)}} </td>
          </ng-container>
          <ng-container matColumnDef="transType">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Transaction Type </th>
            <td mat-cell *matCellDef="let element"> {{txData?.transactionTypeCode}} </td>
          </ng-container>

          <ng-container matColumnDef="transProcessDate">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Transaction Process Date </th>
            <td mat-cell *matCellDef="let element"> {{txData?.createdTimestamp|date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Details </th>
            <td mat-cell *matCellDef="let element"> <a href="#" (click)="$event.preventDefault(); openModal(logdetails,element)">Details</a> </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

      </div>


<ng-template #logdetails>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Additional Details </h4>
    <div align="end">
      <button mat-icon-button class="close-button" (click)="modalRef.hide()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <table>
      <tr>
        <th>
          Order ID
        </th>
        <td>
          {{orderId}}
        </td>
      </tr>
      <tr>
        <th>
          Line Item #
        </th>
        <td>
           {{lineItem?.lineNumber}}
        </td>
      </tr>
      <tr>
        <th>
          Product SKU
        </th>
        <td>
          {{lineItem?.productSKU}}
        </td>
      </tr>

      <tr>
        <th>
          Product code
        </th>
        <td>
          {{lineItem?.productCode}}
        </td>
      </tr>

      <tr>
        <th>
          Product Name

        </th>
        <td>
          {{lineItem?.productName}}
        </td>
      </tr>
      <tr>
        <th>
          Product Description

        </th>
        <td>
          {{lineItem?.productDescription}}
        </td>
      </tr>
      <tr>
        <th>
          Total Line item amount
        </th>
        <td>
          {{lineItem?.total?.toFixed(2)}}
        </td>
      </tr>
      <tr>
        <th>
          Transaction Type
        </th>
        <td>
          {{txData?.transactionTypeCode}}
        </td>
      </tr>
      <tr>
        <th>
          Transaction Process Date
        </th>
        <td>
          {{txData?.createdTimestamp|date:'medium'}}
        </td>
      </tr>
    </table>
    <hr>
    <table>
      <tr>
        <th>Quantity</th>
        <td>{{lineItem?.quantity}}</td>
      </tr>
      <tr>
        <th>Unit price</th>
        <td>{{lineItem?.unitPrice?.toFixed(2)}}</td>
      </tr>
      <tr>
        <th>Tax Amount</th>
        <td>{{lineItem?.taxAmount?.toFixed(2)}}</td>
      </tr>
      <tr>
        <th>Payment Method</th>
        <td>{{txData?.paymentMethodCode}}</td>
      </tr>
    </table>
  </div>

</ng-template>
