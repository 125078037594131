import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { combineLatest, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import {OrdMsg} from '../model/ord-msg';
import {RoleInfo} from '../model/role-info';
import {ProgramGroup} from '../model/program-group';
import { SharedService } from './shared.service';
import { User } from '../model/user';
import { OSCInfo } from '../model/osc-info';
import { AddOSCInfo } from '../model/add-osc-info';

@Injectable({
  providedIn: 'root',
})
export class OrderService {

  resetRefundForm = new Subject();
  constructor(private http: HttpClient,
    private sharedService: SharedService) {
  }

  sendOrderRequest(reqOrderFormData, showError?: boolean) {
    const configSignFields = {
      method: 'post',
      url: `${environment.ORDER_UI_SERVER_URL}/epms/createRequestSignFields`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify(reqOrderFormData),
    };

    const configToken = {
      method: 'get',
      url: `${environment.ORDER_UI_SERVER_URL}/epms/token`,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const configSignFieldsObs = this.http.post(
        configSignFields.url,
        configSignFields.data,
        {
          headers: configSignFields.headers,
        }
      );
      const configTokenObs = this.http.get(configToken.url, {
        headers: configToken.headers,
      });

      combineLatest([configTokenObs, configSignFieldsObs]).subscribe(
        ([configTokenResp, configSignFieldsResp]) => {
          if (Object.keys(configSignFieldsResp).length !== 0) {
            // extract signfield details
            reqOrderFormData['transactionSignature'] = configSignFieldsResp['transactionSignature'];
            reqOrderFormData['signedFields'] = configSignFieldsResp['signField'];
            reqOrderFormData['timestamp'] = configSignFieldsResp['timestamp'];
          }
          if (Object.keys(configTokenResp).length !== 0) {
            // extract token details
            this.sendOrderRequestWithToken(
              configTokenResp,
              qs.stringify(reqOrderFormData),
              showError
            );
          } else {
            this.sendOrderRequestWithToken(
              null,
              reqOrderFormData,
              showError
            );
          }
        },
        (err) => {
          console.log('error', err);
          if (showError) {
            this.sharedService.onOrderUiError(err);
          }
        }
      );
    } catch (error) {
      console.log('Error in handle form :' + error);
      if (showError) {
        this.sharedService.onOrderUiError(error);
      }
    }
  }

  getReqOrderFormData(orderFormData, virtualTerminalData, lineItems: Array<any>, userInfo: User) {
    orderFormData = orderFormData.value;
    const country = orderFormData.shippingInfo.country;
    if (country === 'CA') {
      orderFormData.shippingInfo.state = orderFormData.shippingInfo.province;
      orderFormData.shippingInfo.zip = orderFormData.shippingInfo.postalCA;
    }else if (country === 'US') {
      orderFormData.shippingInfo.state = orderFormData.shippingInfo.state;
      orderFormData.shippingInfo.zip = orderFormData.shippingInfo.zip;
    } else{
      orderFormData.shippingInfo.state = orderFormData.shippingInfo['state-province'];
      orderFormData.shippingInfo.zip = orderFormData.shippingInfo.zipPostal;
    }
    const reqOrderFormData = {};
    const sourceType = virtualTerminalData['transaction'];
    reqOrderFormData['orderAmount'] = orderFormData.paymentDetails.amount;
    reqOrderFormData['merchantDescriptor'] = orderFormData.paymentDetails.billingStatementText;
    reqOrderFormData['programSpecificKey'] = orderFormData.paymentDetails['program-spec-key'];
    reqOrderFormData['jeAccountNumber'] = orderFormData.shippingInfo['journal-entry-num'];
    reqOrderFormData['modeOfReceipt'] = orderFormData.paymentDetails.modeOfReceipt;
    reqOrderFormData['sourceType'] = sourceType;
    reqOrderFormData['originatingSystemCode'] = virtualTerminalData['OSC'];
    reqOrderFormData['hopIndicator'] = 'VTHOP';
    reqOrderFormData['oscSessionId'] = 'VT-' + uuidv4();
    reqOrderFormData['shippingAddressLine1'] = orderFormData.shippingInfo.streetAddress1;
    reqOrderFormData['shippingAddressLine2'] = orderFormData.shippingInfo.streetAddress2;
    reqOrderFormData['shippingAddressCity'] = orderFormData.shippingInfo.city;
    reqOrderFormData['shippingAddressCountry'] = orderFormData.shippingInfo.country;
    reqOrderFormData['shippingAddressState'] = orderFormData.shippingInfo.state;
    reqOrderFormData['glProductText'] = orderFormData.shippingInfo.product;
    reqOrderFormData['glActivityText'] = orderFormData.shippingInfo.activity;
    reqOrderFormData['glProjectText'] = orderFormData.shippingInfo.project;
    reqOrderFormData['resourceText'] = orderFormData.shippingInfo.resource;
    reqOrderFormData['shippingAddressRegion'] = orderFormData.shippingInfo.state;
    reqOrderFormData['shippingAddressPostalCode'] = orderFormData.shippingInfo.zip;
    reqOrderFormData['shippingAddressEmail'] = orderFormData.shippingInfo.email;
    reqOrderFormData['baseCurrency'] = 'USD';
    reqOrderFormData['shippingAddressPhoneNumber'] = orderFormData.shippingInfo.phone;
    reqOrderFormData['shippingAddressFirstName'] = orderFormData.shippingInfo.firstName;
    reqOrderFormData['shippingAddressLastName'] = orderFormData.shippingInfo.lastName;
    reqOrderFormData['shippingAdressCompany'] = orderFormData.shippingInfo.company;
    reqOrderFormData['customerID'] = orderFormData.shippingInfo.customerID ? orderFormData.shippingInfo.customerID : 'VTCustomer';

    if (lineItems) {
      lineItems.forEach(element => {
        const lineItemNumber = element.lineNumber;
        reqOrderFormData[`productName${lineItemNumber}`] = element.productName;
        reqOrderFormData[`productSKU${lineItemNumber}`] = element.productSKU;
        reqOrderFormData[`productCode${lineItemNumber}`] = element.productCode;
        reqOrderFormData[`unitPrice${lineItemNumber}`] = element.unitPrice;
        reqOrderFormData[`quantity${lineItemNumber}`] = element.quantity ? element.quantity : 1;
        reqOrderFormData[`taxAmount${lineItemNumber}`] = element.taxAmount;
      });
    }

    let saleOrRefundUrl;
    if (sourceType) {
      const urlParts = [];
      urlParts.push(location.href);
      urlParts.push(environment.CONFIRMATION_URL);
      saleOrRefundUrl = urlParts.join('/');
    }

    reqOrderFormData['merchantSuccessURL'] = saleOrRefundUrl;
    reqOrderFormData['merchantErrorURL'] = saleOrRefundUrl;
    reqOrderFormData['merchantRejectURL'] = saleOrRefundUrl;
    reqOrderFormData['createdBy'] = userInfo?.emailAddress;
    return reqOrderFormData;
  }

  sendOrderRequestWithToken(token, orderFormData, showError?: boolean) {
    const idasApiToken = token.access_token;
    const reqHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + idasApiToken,
    };

    const obs = this.http.post(environment.ORDER_API, orderFormData, {
      headers: reqHeaders,
    });

    obs.subscribe(
      (data) => {
        window.location.replace(data['Location']);
      },
      (err) => {
        console.log('error = ', err);
        if (showError&&err) {
          this.sharedService.onOrderUiError(err);
        }
      }
    );
  }

  getOscConfigCodes(): Observable<string[]> {
  return this.http.get<string[]>(`${environment.ADMIN_API_HOSTNAME}/config/oscCode`);
  }

  getOSCsInfo(): Observable<OSCInfo[]> {
    return this.http.get<OSCInfo[]>(`${environment.ADMIN_API_HOSTNAME}/config/oscs`);
  }
  saveOSCsInfo(oscInfo:OSCInfo) {
    return this.http.put(`${environment.ADMIN_API_HOSTNAME}/config/osc`,oscInfo);
  }

  addNewOSC(oscInfo:AddOSCInfo) {
    return this.http.post(`${environment.ADMIN_API_HOSTNAME}/config/osc`,oscInfo);
  }

  getOSCCodesByHop(hopId): Observable<string[]> {
    return this.http.get<string[]>(`${environment.ADMIN_API_HOSTNAME}/config/osccodebyhop/${hopId}`);
  }
  getOSCCodesByHopAndTxType(hopId,txType): Observable<string[]> {
    return this.http.get<string[]>(`${environment.ADMIN_API_HOSTNAME}/config/osccodebyhop/${hopId}?allow=${txType}`);
  }

  getRoleNameCodes(): Observable<RoleInfo[]> {
    return this.http.get<RoleInfo[]>(`${environment.ADMIN_API_HOSTNAME}/config/users/roles`);
  }

  getAllProgramGroupNames(): Observable<ProgramGroup[]> {
    return this.http.get<ProgramGroup[]>(`${environment.ADMIN_API_HOSTNAME}/config/programGroupList`);
  }
  getTxData(txId: string) {
    return this.http.get(
       `${environment.ADMIN_API_HOSTNAME}/data/transactions/${txId}`);
  }

  // OSC_ETS_API
  getETSData(oscCode) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/config/osc/${oscCode}`);
  }

  getLineItemsData(orderId) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/orders/${orderId}`);
  }

  getTranscationsData(orderId,txnId) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/orders/${orderId}/transactions/${txnId}`);
  }

  getRelatedOrdersData(orderId) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/orders/${orderId}/relatedOrders`);
  }

  getProcessorDetailsFromS3(transactionId: string, eventType: string, paymentType: string): Promise<any> {
    const type = eventType.indexOf('3ds') === 0 ? '3ds' : eventType;
    return this.http.get<any>(`${environment.ADMIN_API_HOSTNAME}/data/search/${transactionId}/${type}/${paymentType}`, {
      headers: {'Content-Type': 'application/json'}
    }).toPromise();
  }

  getAllMsgs(epmsOrderId): Observable<OrdMsg[]> {
     const url = environment.ADMIN_API_HOSTNAME + `/data/ordmsg/list/${epmsOrderId}`;
     return this.http.get<OrdMsg[]>(url);
  }

  save(ordMsg: OrdMsg) {
    const url = environment.ADMIN_API_HOSTNAME + `/data/ordmsg`;
    return this.http.post(url, ordMsg);
  }

  getPgFinalResponse(transactionId: string) {
    return this.http.get(`${environment.PGA_RESPONSE_API}/${transactionId}`);
  }
}
