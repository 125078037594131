import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  faCheckCircle, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
export interface IDynamicDialogConfig {
  title?: string;
  acceptButtonTitle?: string;
  declineButtonTitle?: string;
  dialogContent?: TemplateRef<any>;
  htmlContent?: any;
  actionState?: string,
  error?: any;
  errorTitle?: string;
}
@Component({
  selector: 'app-mat-dialog-component',
  templateUrl: './mat-dialog-component.component.html',
  styleUrls: ['./mat-dialog-component.component.scss']
})
export class MatDialogComponentComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  faExclamationTriangle=faExclamationTriangle;
  constructor(@Inject(MAT_DIALOG_DATA) public data: IDynamicDialogConfig, public dialogRef: MatDialogRef<MatDialogComponentComponent>){
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  exit() {
    this.dialogRef.close();
  }
  noExit() {
    this.dialogRef.close("noExit");
  }
}
