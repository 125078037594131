<div id="dropdown-arrow-mat" *ngIf="loggedInUser?.oscDetails?.length > 0">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a>OSC and HOP Configuration</a></li>
      <li class="breadcrumb-item">
        <a (click)="exit()">General Business/OSC Configuration</a>
      </li>
    </ol>
  </nav>
  <div class="container-xl mt-4 page">
    <div class="row">
      <div class="col-12 heading pt-1">
        <h2 *ngIf="!id || id === 'clone'">Add New Business/OSC Configuration</h2>
        <h2 *ngIf="id && id !== 'clone'">Edit Business/OSC Configuration</h2>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="col-md-12">
          <form [formGroup]="addForm">
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>OSC Details</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 mb-3">
                <label for="originatingSystemCode"
                  ><span class="text-danger">* </span>Originating System
                  Code</label
                >
                <input
                  type="text"
                  [readonly]="id && id !== 'clone'"
                  formControlName="originatingSystemCode"
                  class="form-control form-control-md"
                  (keyup)="
                    addForm.controls['originatingSystemCode'].setValue(
                      addForm.controls[
                        'originatingSystemCode'
                      ].value?.toUpperCase()
                    )
                  "
                />
                <span
                  *ngIf="
                    addForm.controls['originatingSystemCode'].invalid &&
                    addForm.controls['originatingSystemCode'].touched
                  "
                  class="text-danger"
                >
                  OSC is a required field and must be specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['originatingSystemCode'].hasError(
                      'minlength'
                    ) ||
                    addForm.controls['originatingSystemCode'].hasError(
                      'maxlength'
                    ) ||
                    addForm.controls['originatingSystemCode'].hasError(
                      'pattern'
                    )
                  "
                >
                  It must be alpha numeric with 3 characters length.
                </span>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="description"
                  ><span class="text-danger">* </span>Description</label
                >
                <input
                  type="text"
                  formControlName="description"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['description'].invalid &&
                    addForm.controls['description'].touched
                  "
                  class="text-danger"
                  >Description is a required field and must be specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['description'].hasError('minlength') ||
                    addForm.controls['description'].hasError('maxlength')
                  "
                >
                  Characters length must be between 1 and 25.
                </span>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="MerchantIDStoreID"
                  ><span class="text-danger">* </span>Merchant ID/Store ID
                </label>
                <input
                  type="text"
                  formControlName="MerchantIDStoreID"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['MerchantIDStoreID'].invalid &&
                    addForm.controls['MerchantIDStoreID'].touched
                  "
                  class="text-danger"
                  >Merchant ID/Store ID is a required field and must be
                  specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['MerchantIDStoreID'].hasError(
                      'minlength'
                    ) ||
                    addForm.controls['MerchantIDStoreID'].hasError('maxlength')
                  "
                >
                  Characters length must be between 1 and 25.
                </span>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="reportingID"
                  ><span class="text-danger">* </span>Reporting ID</label
                >
                <input
                  type="tel"
                  formControlName="reportingID"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['reportingID'].invalid &&
                    addForm.controls['reportingID'].touched
                  "
                  class="text-danger"
                  >Reporting ID is a required field and must be specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['reportingID'].hasError('minlength') ||
                    addForm.controls['reportingID'].hasError('maxlength')
                  "
                >
                  Characters length must be between 1 and 25.
                </span>
              </div>
              <div class="col-lg-4 col-12 mb-3" id="interface_name">
                <label for="interfaceName"
                  ><span class="text-danger">* </span>Interface Name<mat-icon
                    class="colorInfo"
                    matTooltip="This field is used to direct the OSC to a certain interface"
                  >
                    help_outline</mat-icon
                  ></label
                >
                <input
                  type="tel"
                  formControlName="interfaceName"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['interfaceName'].invalid &&
                    addForm.controls['interfaceName'].touched
                  "
                  class="text-danger"
                  >Interface Name is a required field and must be
                  specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['interfaceName'].hasError('minlength') ||
                    addForm.controls['interfaceName'].hasError('maxlength')
                  "
                >
                  Characters length must be between 1 and 35.
                </span>
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-md-6 col-12 mb-3">
                <label for="selectProgramHierarchyGrp"
                  ><span
                    *ngIf="
                      addForm.controls['addProgramHierarchyGrp'].value === ''
                    "
                    class="text-danger"
                    >* </span
                  >Select Existing Program Hierarchy Group</label
                >
                <mat-select
                  formControlName="selectProgramHierarchyGrp"
                  class="form-select form-select-md"
                >
                  <mat-option value="" disabled>- Select -</mat-option>
                  <mat-option [value]="prg" *ngFor="let prg of programNames">
                    {{ prg }}
                  </mat-option>
                </mat-select>
                <span
                  *ngIf="
                    addForm.controls['addProgramHierarchyGrp'].value === '' &&
                    addForm.controls['selectProgramHierarchyGrp'].invalid &&
                    addForm.controls['selectProgramHierarchyGrp'].touched
                  "
                  class="text-danger"
                  >Select Existing Program Hierarchy Group or Add New Program
                  Hierarchy Group. Required field</span
                >
              </div>
              <div class="col-lg-1 col-md-1 col-12 mb-3">
                <h5 class="pt-4 text-center">OR</h5>
              </div>
              <div class="col-lg-3 col-md-5 col-12 mb-3">
                <label for="addProgramHierarchyGrp"
                  ><span
                    *ngIf="addForm.controls['addProgramHierarchyGrp'].value"
                    class="text-danger"
                    >* </span
                  >Add New Program Hierarchy Group</label
                >
                <input
                  (change)="onAddProgramHierarchyGrpChange()"
                  type="tel"
                  formControlName="addProgramHierarchyGrp"
                  class="form-control form-control-md"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['addProgramHierarchyGrp'].hasError(
                      'minlength'
                    ) ||
                    addForm.controls['addProgramHierarchyGrp'].hasError(
                      'maxlength'
                    )
                  "
                >
                  Characters length must be between 1 and 25.
                </span>
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>OSC PayPal Merchant Account Details</h4>
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-12 mb-3">
                <label for="payPalMerchantAccountID"
                  >
                  <!-- <span class="text-danger">* </span> -->
                  PayPal Merchant Account ID</label>
                <mat-select
                  formControlName="payPalMerchantAccountID"
                  class="form-select form-select-md"
                >
                  <mat-option disabled>- Select -</mat-option>
                  <mat-option value="">PayPal not Supported</mat-option>
                  <mat-option
                    [value]="paypalMerchantAccountName"
                    *ngFor="
                      let paypalMerchantAccountName of paypalMerchantAccountNames
                    "
                  >
                    {{ paypalMerchantAccountName }}
                  </mat-option>
                </mat-select>
                <span
                  *ngIf="
                    addForm.controls['payPalMerchantAccountID'].invalid &&
                    addForm.controls['payPalMerchantAccountID'].touched
                  "
                  class="text-danger"
                  >PayPal Merchant Account ID is a required field and must be
                  specified</span
                >
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="payPalMerchantemailAddress">
                  <!-- <span class="text-danger">* </span> -->
                  PayPal Merchant e-mail Address</label>
                <mat-select
                  formControlName="payPalMerchantemailAddress"
                  class="form-select form-select-md"
                >
                  <mat-option disabled>- Select -</mat-option>
                  <mat-option value="">PayPal not Supported</mat-option>
                  <mat-option
                    [value]="paypalMerchantAccountEmail"
                    *ngFor="
                      let paypalMerchantAccountEmail of paypalMerchantAccountEmails
                    "
                  >
                    {{ paypalMerchantAccountEmail }}
                  </mat-option>
                </mat-select>
                <span
                  *ngIf="
                    addForm.controls['payPalMerchantemailAddress'].invalid &&
                    addForm.controls['payPalMerchantemailAddress'].touched
                  "
                  class="text-danger"
                  >PayPal Merchant Account e-mail address is a required field
                  and must be specified</span
                >
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>Service Establishments</h4>
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-12 mb-3">
                <label for="americanExpressServiceEstablishment"
                  >
                  <!-- <span class="text-danger">* </span> -->
                  American Express Service
                  Establishment (SE) #</label
                >
                <input
                  formControlName="americanExpressServiceEstablishment"
                  class="form-control form-control-md"
                />
                <!-- <span
                  *ngIf="
                    addForm.controls['americanExpressServiceEstablishment']
                      .invalid &&
                    addForm.controls['americanExpressServiceEstablishment']
                      .touched
                  "
                  class="text-danger"
                  >American Express Service Establishment (SE) # is a required
                  field and must be specified.</span
                > -->
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls[
                      'americanExpressServiceEstablishment'
                    ].hasError('minlength') ||
                    addForm.controls[
                      'americanExpressServiceEstablishment'
                    ].hasError('maxlength') ||
                    addForm.controls[
                      'americanExpressServiceEstablishment'
                    ].hasError('pattern')
                  "
                >
                  It must be numeric characters with length between 1 and 20.
                </span>
              </div>
            </div>

            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>PeopleSoft Details</h4>
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-12 mb-3">
                <label for="defaultPeopleSoftProjectPJ"
                  ><span class="text-danger">* </span>Default Program Specific
                  PeopleSoft Project/PJ
                </label>
                <input
                  type="text"
                  formControlName="defaultPeopleSoftProjectPJ"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['defaultPeopleSoftProjectPJ'].invalid &&
                    addForm.controls['defaultPeopleSoftProjectPJ'].touched
                  "
                  class="text-danger"
                  >Default Program Specific PeopleSoft Project/PJ is a required
                  field and must be specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['defaultPeopleSoftProjectPJ'].hasError(
                      'minlength'
                    ) ||
                    addForm.controls['defaultPeopleSoftProjectPJ'].hasError(
                      'maxlength'
                    ) ||
                    addForm.controls['defaultPeopleSoftProjectPJ'].hasError(
                      'pattern'
                    )
                  "
                >
                  It must be only numeric characters with length 5.
                </span>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="peopleSoftInterface"
                  ><span class="text-danger">* </span>PeopleSoft
                  Interface</label
                >
                <mat-select
                  formControlName="peopleSoftInterface"
                  class="form-select form-select-md"
                >
                  <mat-option disabled>- Select -</mat-option>
                  <mat-option
                    [value]="extractFileFormatCode"
                    *ngFor="
                      let extractFileFormatCode of peopleSoftInterfaceCodes
                    "
                  >
                    {{ extractFileFormatCode }}
                  </mat-option>
                </mat-select>
                <span
                  *ngIf="
                    addForm.controls['peopleSoftInterface'].invalid &&
                    addForm.controls['peopleSoftInterface'].touched
                  "
                  class="text-danger"
                  >PeopleSoft Interface is a required field and must be
                  specified</span
                >
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>Reconciliation ID and Prefix</h4>
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="assignsOrderReconciliationID"
                  formControlName="assignsOrderReconciliationID"
                  value="assignsOrderReconciliationID"
                  (click)="fieldsChange($event, 'reconciliationIDPrefix', true)"
                />
                <label for="assignsOrderReconciliationID"
                  >Assigns Order ID/Reconciliation ID</label
                >
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="reconciliationIDPrefix"
                  ><span
                    *ngIf="isRequiredField('reconciliationIDPrefix')"
                    class="text-danger"
                    >* </span
                  >Reconciliation ID Prefix</label
                >
                <input
                  type="text"
                  formControlName="reconciliationIDPrefix"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['reconciliationIDPrefix'].invalid &&
                    addForm.controls['reconciliationIDPrefix'].touched
                  "
                  class="text-danger"
                  >Reconciliation ID Prefix is a required field and must be
                  specified.</span
                >
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>Merchant Transaction Settings</h4>
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-12 mb-3">
                <label for="defaultMerchantDescriptor"
                  ><span class="text-danger">* </span>Default Merchant
                  Descriptor/Cardholder Statement Text</label
                >
                <input
                  type="text"
                  formControlName="defaultMerchantDescriptor"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['defaultMerchantDescriptor'].invalid &&
                    addForm.controls['defaultMerchantDescriptor'].touched
                  "
                  class="text-danger"
                  >Default Merchant Descriptor/Cardholder Statement Text is a
                  required field and must be specified</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['defaultMerchantDescriptor'].hasError(
                      'minlength'
                    ) ||
                    addForm.controls['defaultMerchantDescriptor'].hasError(
                      'maxlength'
                    )
                  "
                >
                  Characters length must be between 1 and 25.
                </span>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="merchantDescriptorContact"
                  ><span class="text-danger">* </span>Merchant Descriptor
                  Contact Telephone Number</label
                >
                <input
                  formControlName="merchantDescriptorContact"
                  class="form-control form-control-md"
                />
                <span
                  *ngIf="
                    addForm.controls['merchantDescriptorContact'].invalid &&
                    addForm.controls['merchantDescriptorContact'].touched
                  "
                  class="text-danger"
                  >Merchant Descriptor Contact is a required field and must be
                  specified.</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    addForm.controls['merchantDescriptorContact'].hasError(
                      'minlength'
                    ) ||
                    addForm.controls['merchantDescriptorContact'].hasError(
                      'maxlength'
                    ) ||
                    addForm.controls['merchantDescriptorContact'].hasError(
                      'pattern'
                    )
                  "
                >
                  It must be numeric characters with length between 10 and 20.
                </span>
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>AVS and CSV Rules</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="ignoreAVS"
                  formControlName="ignoreAVS"
                  value="ignoreAVS"
                  (click)="fieldsChange($event, 'avsDeclineFlags', false)"
                />
                <label for="ignoreAVS">Ignore AVS</label>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="ignoreCSV"
                  formControlName="ignoreCSV"
                  value="ignoreCSV"
                  (click)="fieldsChange($event, 'csvDeclineFlags', false)"
                />
                <label for="ignoreCSV"
                  >Ignore Card Security Value Response (CSV)</label
                >
              </div>
            </div>
            <div class="row margin">
              <div class="col-lg-4 col-12 mb-3">
                <label for="avsDeclineFlags"
                  ><span
                    *ngIf="isRequiredField('avsDeclineFlags')"
                    class="text-danger"
                    >* </span
                  >AVS Decline Flags</label
                >
                <input
                  type="text"
                  formControlName="avsDeclineFlags"
                  class="form-control form-control-md"
                  maxlength="20"
                />
                <span
                  *ngIf="
                    addForm.controls['avsDeclineFlags'].invalid &&
                    addForm.controls['avsDeclineFlags'].touched
                  "
                  class="text-danger"
                  >AVS Decline Flags is a required field and must be
                  specified</span
                >
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <label for="csvDeclineFlags"
                  ><span
                    *ngIf="isRequiredField('csvDeclineFlags')"
                    class="text-danger"
                    >* </span
                  >Card Security Value Decline Flags</label
                >
                <input
                  type="text"
                  formControlName="csvDeclineFlags"
                  class="form-control form-control-md"
                  maxlength="20"
                />
                <span
                  *ngIf="
                    addForm.controls['csvDeclineFlags'].invalid &&
                    addForm.controls['csvDeclineFlags'].touched
                  "
                  class="text-danger"
                  >Card Security Value Decline Flags is a required field and
                  must be specified</span
                >
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>Transaction Type</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="sale"
                  formControlName="sale"
                />
                <label for="sale">Sale</label>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="overrideRefund"
                  formControlName="overrideRefund"
                />
                <label for="overrideRefund">Override Refund</label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="refund"
                  formControlName="refund"
                />
                <label for="refund">Refund</label>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="chargeback"
                  formControlName="chargeback"
                />
                <label for="chargeback">Chargeback</label>
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-12">
                <h4>HOPs Available</h4>
                <p *ngIf="id && id === 'clone'">When cloning, the HOPs are intentionally not cloned and must be selected manually.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="cfhop"
                  formControlName="cfhop"
                />
                <label for="cfhop">CFHOP</label>
              </div>
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="ihop"
                  formControlName="ihop"
                />
                <label for="ihop">IHOP</label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 mb-3">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="vthop"
                  formControlName="vthop"
                />
                <label for="vthop">VTHOP</label>
              </div>
            </div>
            <button class="btn btn-primary btn-function" (click)="exit()">
              Exit
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-primary"
              (click)="saveAndExit()"
              [disabled]="!addForm.valid"
            >
              Save and Exit
            </button>
            <div class="error">
              {{ errMsg }}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
  <div class="col-2"></div>
  <div class="col-8 alert alert-danger" role="alert">
    Originating System Code or Program Group need to be associated in order to
    access the page.
  </div>
  <div class="col-2"></div>
</div>
