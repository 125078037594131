import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OSCInfo } from 'src/app/model/osc-info';
import { User } from 'src/app/model/user';
import { OrderService } from 'src/app/service/order.service';
import { SharedService } from 'src/app/service/shared.service';
export interface OSCDetails {
  osc: string;
  description: string;
  updatedDate: string;
  createdDate: string;
}
@Component({
  selector: 'app-osc-specific-hop-config',
  templateUrl: './osc-specific-hop-config.component.html',
  styleUrls: ['./osc-specific-hop-config.component.scss'],
})
export class OSCSpecificHOPConfigComponent implements OnInit {
  isDisabled: boolean;
  loggedInUser: User;
  displayedColumns: string[] = [
    'osc',
    'description',
    'createdDate',
    'updatedDate',
  ];
  dataSource;
  @ViewChild('osc') osc:ElementRef;
  @ViewChild('createdDate', {
    read: MatInput
  }) createdDate: MatInput;

  @ViewChild('updatedDate', {
    read: MatInput
  }) updatedDate: MatInput;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  oscInfoList: OSCInfo[];
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private orderService: OrderService,
  ) {}


  parseOSCListResponse(oscInfoList) {
    const oscDataList = oscInfoList.map((oscData) => {
      const programGroupList = new Set();
      const oscSet = new Set();

      if (oscData.oscDetails) {
        oscData.oscDetails.forEach((oscDetail) => {
          programGroupList.add(oscDetail.programOsc);
          oscSet.add(oscDetail.oscCode);
        });
      }
      let createDate = Date.parse(oscData.createdDate);
      let updatedDate = Date.parse(oscData.updateDate);
      const oscsData = {
        osc: oscData.oscCode,
        description: oscData.oscDescription,
        createdDate: new Date(createDate).toISOString().split('T')[0],
        updatedDate:
          oscData.updateDate !== undefined
            ? new Date(updatedDate).toISOString().split('T')[0]
            : oscData.updateDate,
      };
      return oscsData;
    });
    this.setData(oscDataList);
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.getOscsData(user?.oscDetails);
      }
    });
  }

  applyFilter(filterValue, id, date?: boolean) {
    const tableFilters = [];
    tableFilters.push({
      id: id,
      value: date
        ? filterValue && filterValue.toISOString().split('T')[0]
        : filterValue && filterValue.trim().toLowerCase(),
    });
    this.dataSource.filter = JSON.stringify(tableFilters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setData(oscDataList) {
    this.dataSource = new MatTableDataSource(oscDataList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  addCustomSort(sort: Sort) {
    let sortedData;
    const data = this.dataSource.data.slice();
    if (!sort.active ) {
      sortedData = data;
    } else {
      sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'osc':
            return this.compare((a.osc || '').toLowerCase(), (b.osc || '').toLowerCase(), isAsc);
          case 'description':
            return this.compare((a.description  || '').toLowerCase(), (b.description  || '').toLowerCase(), isAsc);
          case 'createdDate':
            return this.compare((a.createdDate  || '').toLowerCase(), (b.createdDate  || '').toLowerCase(), isAsc);
          case 'updatedDate':
            return this.compare((a.updatedDate  || '').toLowerCase(), (b.updatedDate || '').toLowerCase(), isAsc);
          default:
            return 0;
        }
      });
    }

    this.dataSource = new MatTableDataSource(sortedData);
    this.dataSource.sort = sort;
    this.dataSource.paginator = this.paginator;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getOscsData(oscsAssignedToUser){
    this.orderService.getOSCsInfo().subscribe({
      next: (oscInfoList) => {
      this.oscInfoList = oscInfoList?.filter(oscData => oscsAssignedToUser?.some(data => oscData?.oscCode === data?.oscCode ));
      sessionStorage.setItem('oscHopList', JSON.stringify(this.oscInfoList?.map((osc) => osc?.oscCode)?.sort()));
      this.parseOSCListResponse(this.oscInfoList);
      this.dataSource.filterPredicate = (
        data: OSCDetails,
        filtersJson: string
      ) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);

        filters.forEach((filter) => {
          const val =
            data[filter.id] === null
              ? ''
              : data[filter.id]
              ? data[filter.id]
              : '';
          matchFilter.push(
            val.toLowerCase().includes(filter.value.toLowerCase())
          );
        });
        return matchFilter.every(Boolean);
      };
    },
    error: (err: any) => {
      console.log('err =' + JSON.stringify(err));
      this.oscInfoList = [];
    },
  });
  }

  navigate(osc){
    sessionStorage.setItem('oscConfigDetails', JSON.stringify(this.oscInfoList.filter(data => data.oscCode === osc)));
    this.router.navigate(['/view-business-osc-config']);
  }

  editConfig() {
    this.router.navigate(['edit-osc-specific-hop-config']);
  }

  filterReset(){
    this.dataSource.filter = '';
    this.osc.nativeElement.value = '';
    this.createdDate.value = '';
    this.updatedDate.value = '';
  }

}
