<div class="mt-4 container-xl" *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div class="row">
        <div class="col-12">
            <h2>
                Manage All Users
            </h2>
            <p></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12" id="accordion">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>
                                <fa-icon [icon]="faSearch"></fa-icon> Search Users
                            </h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="card" id="dropdown-arrow-mat">
                        <div class="card-body ps-5">
                            <form [formGroup]="userSearch">
                                <div id="dropdown" class="row">
                                    <div class="col-md-4 mb-3">
                                        <label>User Login ID</label>
                                        <input class="form-control form-control-md" formControlName="userLoginID">
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>First Name</label> <span class="text-danger">* </span>
                                        <input class="form-control form-control-md" formControlName="firstName">
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>Last Name</label> <span class="text-danger">* </span>
                                        <input class="form-control form-control-md" formControlName="lastName">
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>User ID</label>
                                        <input class="form-control form-control-md" formControlName="userID">
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>Program Group</label>
                                        <mat-select class="form-select form-select-md" formControlName="prgGrp"
                                            (selectionChange)="onProgramGroupNameChange($event.value)"
                                            [attr.disabled]="oscCodeChange">
                                            <mat-option [value]="null" disabled>Select...</mat-option>
                                            <mat-option value="">ALL</mat-option>
                                            <ng-container
                                                *ngFor="let programGroup of programGroupList |sort:'asc':'programGroupName'">
                                                <mat-option
                                                    [value]="programGroup.programGroupName">{{programGroup.programGroupName}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>Originating System Code (OSC)</label>
                                        <mat-select class="form-select form-select-md" formControlName="OSC"
                                            (selectionChange)="onOscCodeChange($event.value)">
                                            <mat-option [value]="null" disabled>Select...</mat-option>
                                            <mat-option value="">ALL</mat-option>
                                            <ng-container *ngFor="let oscCode of oscConfigCodes ">
                                                <mat-option [value]="oscCode">{{oscCode}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>Role Name</label>
                                        <mat-select class="form-select form-select-md"
                                            (selectionChange)="onRoleNameChange($event.value)"
                                            formControlName="roleName" [attr.disabled]="roleDescChage"
                                            [value]="selectedRoleInfo">
                                            <mat-option [value]="null" disabled>Select...</mat-option>
                                            <mat-option value="">ALL</mat-option>
                                            <ng-container *ngFor="let roleInfo of roleInfoList  |sort:'asc':'roleName'">
                                                <mat-option [value]="roleInfo.roleId">{{roleInfo.roleName}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>Role Desc</label>
                                        <mat-select class="form-select form-select-md" [value]="selectedRoleInfo"
                                            (selectionChange)="onRoleDescChange($event.value)"
                                            [attr.disabled]="roleNameChage" formControlName="roleDesc">
                                            <mat-option value="">ALL</mat-option>
                                            <ng-container *ngFor="let roleInfo of roleInfoList|sort:'asc':'roleDesc' ">
                                                <mat-option [value]="roleInfo.roleId">{{roleInfo.roleDesc}}</mat-option>
                                            </ng-container>
                                        </mat-select>

                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label>User Account Type</label>
                                        <mat-select class="form-select form-select-md" formControlName="accountType">
                                            <mat-option *ngIf="loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_max'" value="">ALL</mat-option>
                                            <mat-option *ngIf="loggedInUser.accountType === 'internal' || loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_max'" value="internal">Internal</mat-option>
                                            <mat-option *ngIf="loggedInUser.accountType === 'external' || loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_max'" value="external">External</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Status</label>
                                        <mat-select class="form-select form-select-md" formControlName="status">
                                            <mat-option value="">ALL</mat-option>
                                            <mat-option value="active">Active</mat-option>
                                            <mat-option value="inactive">Inactive</mat-option>
                                        </mat-select>
                                    </div>

                                  <div class="col-md-4  mb-3 user-date-range" >
                                    <div class="clear-dates" (click)="clearDate()">
                                      Clear Date(s)
                                    </div>
                                    <label class="date-range-lbl">Created Date Range</label>
                                    <mat-form-field appearance="outline">
                                      <mat-date-range-input [rangePicker]="picker" class="custom-mat-date-range-input">
                                        <input matStartDate formControlName="createdDateFrom"
                                               placeholder="Start date"
                                               matTooltip="Enter Month Day Year format"
                                               (dateInput)="addCreateDateEvent()" (dateChange)="addCreateDateEvent()">
                                        <input matEndDate formControlName="createdDateTo" placeholder="End date"
                                               matTooltip="Enter Month Day Year format"
                                               (dateInput)="addCreateDateEvent()" (dateChange)="addCreateDateEvent()">
                                      </mat-date-range-input>
                                      <mat-hint align="start">(MM/DD/YYYY) -
                                        (MM/DD/YYYY)
                                      </mat-hint>
                                      <mat-datepicker-toggle matSuffix [for]="picker"
                                                             matTooltip="Select date range from start date to end date">
                                      </mat-datepicker-toggle>
                                      <mat-date-range-picker #picker>
                                      </mat-date-range-picker>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-4 user-date-range">
                                    <div class="clear-dates" (click)="clearQuickDate()">
                                      Clear Date(s)
                                    </div>
                                    <label class="date-range-lbl">Login Date Range</label>
                                    <mat-form-field appearance="outline">
                                      <mat-date-range-input [rangePicker]="loginDtpicker" class="date-range-fld custom-mat-date-range-input">
                                        <input matStartDate formControlName="loginDateFrom"
                                               placeholder="Start date"
                                               matTooltip="Enter Month Day Year format"
                                               (dateInput)="addLoginDateEvent()" (dateChange)="addLoginDateEvent()">
                                        <input matEndDate formControlName="loginDateTo" placeholder="End date"
                                               matTooltip="Enter Month Day Year format"
                                               (dateInput)="addLoginDateEvent()" (dateChange)="addLoginDateEvent()">
                                      </mat-date-range-input>
                                      <mat-hint align="start">(MM/DD/YYYY) -
                                        (MM/DD/YYYY)
                                      </mat-hint>
                                      <mat-datepicker-toggle matSuffix [for]="loginDtpicker"
                                                             matTooltip="Select date range from start date to end date">
                                      </mat-datepicker-toggle>
                                      <mat-date-range-picker #loginDtpicker>
                                      </mat-date-range-picker>
                                    </mat-form-field>
                                  </div>

                                </div>

                            </form>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <button class="btn btn-primary" (click)="onGoClicks()">Go</button>
                                    <button class="btn btn-primary clear-btn" (click)="clearForm()">Clear</button>
                                    <div class="text-danger text-end">*- Wildcarding is allowed by utilizing a % symbol
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="card mt-3">
                <div class="card-body">
                    <h3>User List</h3>
                    <div class="alert alert-danger" role="alert" *ngIf="showError">
                        {{showErrorInfo}}
                    </div>
                    <div class="alert alert-info" role="alert" *ngIf="showSuccess">
                        {{showSuccessInfo}}
                    </div>
                    <div class="row mt-4 mb-4">
                        <div class="col-md-12 d-flex justify-content-between">
                            <button class="btn btn-primary" (click)="onAssign()">Assign Role and Business</button>
                            <button class="btn btn-primary" (click)="openModal(exportSave)">Export</button>
                        </div>
                        <div class="text-danger pt-1">* If multiple users are selected, any changes made to Role, OSC or
                            Business on the next screen will be applied to all users selected.</div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-internal-external-list [userSearchInfo]="userSearchInfo"
                                #internalExternalUserComponent></app-internal-external-list>
                        </div>
                    </div>

                    <ng-template #exportSave>
                        <div class="modal-header">
                            <h4 class="modal-title pull-left">
                                <fa-icon [icon]="faDownload"></fa-icon> Export Internal and External User(s) List
                            </h4>
                            <div align="end">
                            <button mat-icon-button class="close-button" (click)="modalRef.hide()">
                                <mat-icon class="close-icon">close</mat-icon>
                            </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            Please select a file format.

                            <div>
                                <mat-radio-group aria-labelledby="export-radio-group-label" class="export-radio-group"
                                    [(ngModel)]="exportOption">
                                    <mat-radio-button class="export-radio-button" value="xls">
                                        Excel file (.xls)
                                    </mat-radio-button>
                                    <mat-radio-button class="export-radio-button" value="csv">
                                        Comma separated values (.csv)
                                    </mat-radio-button>

                                </mat-radio-group>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary btn-function" (click)="modalRef.hide()">Exit</button> <button
                                class="btn btn-primary" (click)="internalExternalUser?.exportData(exportOption)">Export</button>
                        </div>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8  alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to access the page.
    </div>
    <div class="col-2"></div>
</div>
