export class ChargebackRequest {
  transactionId: string;
  createdBy: string;
  chargebackAmount: number;
  reverseChargeback: boolean;
  processorCode: string;
  currencyCode: string;
  constructor() {
  }
}
