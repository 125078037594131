import { Component } from '@angular/core';
import { ScrollToService } from '../service/scroll-to-service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  constructor(public scrollToService: ScrollToService) { }
}
