import { Component, OnInit } from '@angular/core';
import {
  faPhone,
  faCheckCircle,
  faEnvelope,
  faTimesCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from "../../service/order.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  isSale:boolean;
  faPhone = faPhone;
  faCheckCircle = faCheckCircle;
  faEnvelope = faEnvelope;
  faTimesCircle = faTimesCircle;
  faExclamationTriangle = faExclamationTriangle;
  private sub: any;
  txId: string;
  txData: any;
  txStatus: string;
  showSuccess: boolean = false;
  showError: boolean = false;
  showReject: boolean = false;
  showDecline: boolean = false;

  constructor(private route: ActivatedRoute, private orderService: OrderService) { }

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.txId = params['t'];
      this.txStatus = params['status'];
      this.isSale = params['sourceType'] === 'SALE' ? true: false;
      this.showDivOnTxStatus();
      this.loadTransactionData();
    });
  }

  showDivOnTxStatus() {
    if(this.txStatus) {
      switch (this.txStatus.toLowerCase()) {
        case 'accept':
          this.showSuccess = true;
          break;        
        case 'reject':
          this.showReject = true;
          this.showError = true;
          break;
        case 'decline': 
          this.showDecline = true;
          this.showError = true;
          break;
        case 'error':
        default:
          this.showError = true;
          break;        
      }
    }
  }

  loadTransactionData() {
    const obs = this.orderService.getTxData(this.txId);
    obs.subscribe(data => {
      this.txData = data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
