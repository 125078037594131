import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {faChevronLeft, faSearch,faDownload} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router'
import {UserManagementService} from '../../service/user-management.service';
import {User} from "../../model/user";
import {UserAuditInfo} from "../../model/user-audit-info";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatTableExporterDirective} from "mat-table-exporter";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  faSearch = faSearch;
  faDownload = faDownload;
  faChevronLeft = faChevronLeft;
  userData: User;
  userLoginID;
  userAuditList:UserAuditInfo[];
  dataSource;
  loginHistoryDataSource;
  exportOption = 'xls';
  loginHistoryDataSourceSortDescDir=true;
  modalRef: BsModalRef;
  exportActivityLog = true;

  @ViewChild('userAccountActivityLog', {read:MatSort,static: false}) sort: MatSort;
  @ViewChild('loginHistory', {read:MatSort, static: false}) loginHistorySort: MatSort;
  @ViewChild('userAccountActivityLog',{read:MatTableExporterDirective,static: false}) userAccountActivityLogExporter: MatTableExporterDirective;
  @ViewChild('loginHistory',{read:MatTableExporterDirective,static: false}) loginHistoryExporter: MatTableExporterDirective;

  displayedColumns: string[] = ['userId','userLoginId','firstName','lastName','emailId','actionDtm','userNam','actionTxt','oldValue','newValue'];
  loginHistoryDisplayedColumns: string[] = ['userId','userLoginId','firstName','lastName','emailId','userLoginTimestamp'];


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userMgmtService: UserManagementService,private modalService: BsModalService,) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.userLoginID = data['userLoginID'];

    });
  }

  loadAuditInfo() {
    const obs = this.userMgmtService.getUserAuditDetails(this.userData.userId);
    obs.subscribe(data => {
      for(let item of data){
        item.userLoginId = this.userData.userLoginId;
        item.emailId = this.userData.emailId;
        item.firstName = this.userData.firstName;
        item.lastName = this.userData.lastName;
        item.userId = this.userData.userId;
      }
      this.userAuditList = data;
      this.setData(data);
    })
  }
  loadLastLoginData(){
    const obs = this.userMgmtService.getUserRecordLoginDetails(this.userData.userId);
    obs.subscribe(data => {
      for(let item of data){
        item.userLoginId = this.userData.userLoginId;
        item.emailId = this.userData.emailId;
        item.firstName = this.userData.firstName;
        item.lastName = this.userData.lastName;
      }
      this.loginHistoryDataSource = new MatTableDataSource(data);
      this.loginHistoryDataSource.sort=this.loginHistorySort;
    })
  }

  sortLoginHistoryData(sort:Sort){
    debugger;
    let data=this.loginHistoryDataSource.data;

    this.loginHistoryDataSourceSortDescDir=!this.loginHistoryDataSourceSortDescDir;

    if( this.loginHistoryDataSourceSortDescDir){
      data=data.sort((a,b)=>{
        return a.userLoginTimestamp<b.userLoginTimestamp?1:-1;
      });
    }else{
      data=data.sort((a,b)=>{
        return a.userLoginTimestamp<b.userLoginTimestamp?-1:1;
      });
    }
    this.loginHistoryDataSource = new MatTableDataSource([...data]);
  }

  setData(auditDataList) {
    this.dataSource = new MatTableDataSource(auditDataList);
    this.dataSource.sort = this.sort;

  }

  ngOnInit(): void {
    const obs = this.userMgmtService.getUserByLoginId(this.userLoginID);
    obs.subscribe(userData => {
      this.userData = userData;
      this.loadAuditInfo();
      this.loadLastLoginData();
    });
  }

  getOscCodes(user: User) {

    const oscSet = new Set();

    if (user.oscDetails) {
      user.oscDetails.forEach(oscDetail => {

        oscSet.add(oscDetail.oscCode);
      });
    }
    return Array.from(oscSet)?.sort().join(', ');
  }

  getProgramGroupList(user: User) {
    const programGroupList = new Set();

    if (user.oscDetails) {
      user.oscDetails.forEach(oscDetail => {
        programGroupList.add(oscDetail.programName);

      });
    }
    return Array.from(programGroupList).join(',');
  }

  back() {
    this.router.navigate(['/user-management']);
  }

  openModal(exportSave: TemplateRef<any>) {
    this.exportActivityLog=true;
    this.modalRef = this.modalService.show(exportSave);
  }
  openLoginHistoryExportModal(exportSave: TemplateRef<any>) {
    this.exportActivityLog=false;
    this.modalRef = this.modalService.show(exportSave);
    return false;
  }

  exportData(exportOption) {
    if(this.exportActivityLog){
      this.exportAccountActivityLog(exportOption);
    } else{
      this.exportLoginHistoryData(exportOption);
    }
  }
  exportAccountActivityLog(exportOption) {
    switch (exportOption) {
      case 'xls':
        this.userAccountActivityLogExporter.exportTable('xlsx', {fileName: `UserAccountActivityLog`, sheet: 'UserAccountActivityLog'});
        break;
      case 'csv':
        this.userAccountActivityLogExporter.exportTable('csv', {fileName: `UserAccountActivityLog`, sheet: 'UserAccountActivityLog'});
        break;
    }
  }

  exportLoginHistoryData(exportOption) {
    switch (exportOption) {
      case 'xls':
        this.loginHistoryExporter.exportTable('xlsx', {fileName: `LoginHistory`, sheet: 'LoginHistory'});
        break;
      case 'csv':
        this.loginHistoryExporter.exportTable('csv', {fileName: `LoginHistory`, sheet: 'LoginHistory'});
        break;
    }
  }
}
