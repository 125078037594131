import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: '<h1 style="text-align: center;">You have been logged out successfully.</h1>',
})
export class LogoutComponent implements OnInit, OnDestroy {
  private unsubscribeAll$: Subject<any>;

  constructor(private location: LocationStrategy) {
     //preventing back button in angular application
     history.pushState(null, null, window.location.href);
     this.location.onPopState(() => {
       history.pushState(null, null, window.location.href);
     });
    // end logic for preventing back button
    this.unsubscribeAll$ = new Subject();
  }


  ngOnInit(): void {
    this.processLogoutMessage();
  }

  processLogoutMessage() {
    /*
    * Its needed due to user behaviour of not closing the browser tab after log off or reusing the same tab to login again.
    * The browser never clears the session storage as the browser tab with *.ets.org is never closed or changed.
    * Clearing session here is very safe as its the sign-out page.
    */
    sessionStorage.clear();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll$.unsubscribe();
    this.unsubscribeAll$.complete();
  }

}
