export class UserSearchInfo {

  userLoginId: string;

  userId: number;

  firstName: string;

  lastName: string;

  roleName: string;

  roleDesc: string;

  state: string;

  prgGrp: string;

  oscCde: string;

  accountType: string;

  createdStartDate: string;

  createdEndDate: string;

  loginStartDate: string;

  loginEndDate: string;

  loggedInUserPrivilegeLevelCode : number;

  constructor() {
  }

}
