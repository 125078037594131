import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-txt-truncate',
  templateUrl: './txt-truncate.component.html',
  styleUrls: ['./txt-truncate.component.scss']
})
export class TxtTruncateComponent {


  inputData;

  showMore = true;

  constructor() {
  }

  @Input()
  set data(txt) {
    this.inputData = txt;
  }

}
