import {Component, Input, OnInit} from '@angular/core';
import {OrderService} from "../../service/order.service";
import {OrdMsg} from "../../model/ord-msg";

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  dateCreated = 'MM/DD/YYYY HH:MM:SS';
  createdBy = 'MPI System';
  message = 'Linked Override Refund Performed';

  @Input()
  txData: any;
  @Input()
  parentRef: any;
  ordMsgList: OrdMsg[] = [];

  constructor(private orderService: OrderService) {
  }

  ngOnInit(): void {
    const obs = this.orderService.getAllMsgs(this.txData.order.epmsOrderId);
    obs.subscribe(data => {
      this.ordMsgList = data;
    }, err => {
      console.log('error = ' + err);
    });
  }
}
