import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService } from './auth-service';

const urlsToSkip = ['/epms/order'];
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request &&
      request?.url &&
      !urlsToSkip.some((url) => request.url.includes(url))
    ) {
      return from(this.authService.getSession()).pipe(
        switchMap((session) => {
          let requestWithToken = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + session.tokens.idToken,
            },
          });
          return next.handle(requestWithToken);
        })
      );
    }
    return next.handle(request);
  }
}
