import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard.service';
import { ReconciliationFiles } from '../model/reconciliation-files';

@Component({
  selector: 'app-reconciliation-dashboard-by-file',
  templateUrl: './reconciliation-dashboard-by-file.component.html',
  styleUrls: ['./reconciliation-dashboard-by-file.component.scss'],
})
export class ReconciliationDashboardByFileComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'reconciliationType',
    'fileProcessed',
    'fileDate',
    'processedDate',
    'totalRecords',
    'errorRecords',
    'successRecords',
    'noMatchRecords',
    'status',
  ];

  selectFileOptions = [
    { value: 'fiserv-cc-credit', label: 'Fiserv-CC-Credit' },
    { value: 'fiserv-cc-chargeback', label: 'Fiserv-CC-Chargeback' },
    { value: 'fiserv-cc-failures', label: 'Fiserv-CC-Failures' },
    { value: 'fiserv-dcc-credit', label: 'Fiserv-DCC-Credit' },
    { value: 'fiserv-dcc-chargeback', label: 'Fiserv-DCC-Chargeback' },
    { value: 'fiserv-dcc-failures', label: 'Fiserv-DCC-Failures' },
    { value: 'paypal-ets-credit', label: 'PayPal-ETS-Credit' },
    { value: 'paypal-ets-chargeback', label: 'PayPal-ETS-Chargeback' },
    { value: 'paypal-gre-credit', label: 'PayPal-GRE-Credit' },
    { value: 'paypal-gre-chargeback', label: 'PayPal-GRE-Chargeback' },
    { value: 'paypal-praxis-credit', label: 'PayPal-Praxis-Credit' },
    { value: 'paypal-praxis-chargeback', label: 'PayPal-Praxis-Chargeback' },
    { value: 'paypal-toefl-credit', label: 'PayPal-TOEFL-Credit' },
    { value: 'paypal-toefl-chargeback', label: 'PayPal-TOEFL-Chargeback' },
    { value: 'paypal-failures', label: 'PayPal-Failures' },
    { value: 'amex-grrcn', label: 'AMEX-GRRCN' },
    { value: 'amex-cbnot', label: 'AMEX-CBNOT' },
    { value: 'amex-failures', label: 'AMEX-Failures' },
    { value: 'cfs-extract', label: 'CFS-Extract' },
    { value: 'cfs-lite-extract', label: 'CFS-Lite-Extract' },
    { value: 'cfs-india-extract', label: 'CFS-India-Extract' },
    { value: 'commerce-connect-cc-credit', label: 'Commerce-Connect-CC-Credit' },
    { value: 'commerce-connect-wallet-credit', label: 'Commerce-Connect-Wallet-Credit' },
    { value: 'commerce-connect-cc-chargeback', label: 'Commerce-Connect-CC-Chargeback' },
    { value: 'commerce-connect-wallet-chargeback', label: 'Commerce-Connect-Wallet-Chargeback' },
    { value: 'commerce-connect-failures', label: 'Commerce-Connect-Failures' },
    { value: 'alipay-ets', label: 'Alipay-ETS' },
    { value: 'alipay-gre', label: 'Alipay-GRE' },
    { value: 'alipay-praxis', label: 'Alipay-Praxis' },
    { value: 'alipay-toefl', label: 'Alipay-TOEFL' },
    { value: 'alipay-failures', label: 'Alipay-Failures' }
  ];

  range = new FormGroup({
    startDate: new FormControl<Date | null>(null),
    endDate: new FormControl<Date | null>(null),
  });
  reconciliationType = new FormControl<string | null>(null, Validators.required);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<ReconciliationFiles>();

  constructor(private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.sort.sort({ id: 'processedDate', start: 'asc' } as MatSortable);
    this.dataSource.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
  }

  ngOnInit(): void {
    const startDate = this.route.snapshot.queryParamMap.get('startDate');
    const endDate = this.route.snapshot.queryParamMap.get('endDate');
    if (startDate && endDate) {
      this.range.setValue({
        startDate: this.setSelectedDate(startDate),
        endDate: this.setSelectedDate(endDate),
      });
      this.reconciliationType.setValue(this.route.snapshot.queryParamMap.get('reconciliationType'));
      this.getData();
    }
  }

  getData() {
    if (this.range.value.startDate && this.range.value.endDate && this.reconciliationType) {
      const reconciliationSummaryData =
        this.dashboardService.getReconciliationSummaryDataByFile(this.getFormatedDate(this.range.value.startDate),
          this.getFormatedDate(this.range.value.endDate), this.reconciliationType.value);
      reconciliationSummaryData.subscribe((data) => {
        if (data) {
          this.dataSource.data = this.processStatus(data);
          this.dataSource.paginator = this.paginator;
        } else {
          console.log('No data found for reconciliationSummary by file');
        }
      });
    }
  }

  processStatus(data) {
    let fileData = [];
    data.forEach((element) => {
      if (element.errorRecords > 0 && element.fileProcessed) {
        element['status'] = 'yellow';
      } else if (element.fileProcessed && element.errorRecords === 0) {
        element['status'] = 'green';
      } else if (!element.fileProcessed) {
        element['status'] = 'red';
      }
      fileData.push(element);
    });
    return fileData;
  }

  getFormatedDate(dateToFormat: Date): string {
    if (dateToFormat) {
      return new Date(
        dateToFormat.getTime() - dateToFormat.getTimezoneOffset() * 60000
      ).toISOString().split('T')[0];
    }
  }

  setSelectedDate(queryParamDate: string): Date {
    const currentDate = new Date(queryParamDate)
    return new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * -60000);
  }

  getFileDate(fileDate: string): string {
    return fileDate.split('T')[0];
  }

  clearDates() {
    this.range.reset();
    this.dataSource.data = [];
  }
}
