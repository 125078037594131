<table class="table">
  <tr>
    <th>Date Created</th>
    <th>Created By</th>
    <th>Notes</th>
  </tr>
  <tr>
    <td>{{ dateCreated | date: "medium" }}</td>
    <td>{{ createdBy }}</td>
    <td class="audit-msg">{{ message }}</td>
  </tr>
</table>
<form [formGroup]="auditMessage">
  <div class="mb-3">
    <h3>Post Notes</h3>
    <textarea class="form-control form-control-md" maxlength="120" formControlName="message"></textarea>
  </div>
  <div class="row col-lg-12">
    <button class="btn btn-primary btn-function col-lg-1 mt-2" (click)="parentRef.close()">
      Exit
    </button>
    <button class="btn btn-primary btn-function col-lg-2 ms-2 mt-2" (click)="reset()">
      Reset Form
    </button>
    <button class="btn btn-primary col-lg-2 ms-2 mt-2" (click)="saveMsg()">
      Post Note
    </button>
  </div>
</form>
