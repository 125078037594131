import { RoleCapabilities } from './user';

/** maps the capabilities into profile object. */
export class Profile {
    // Virtual terminal access
    hasVtAccess = false;
    hasVtSaleAcc = false;
    hasVtRefundAcc = false;
    hasVtChargebkAcc = false;
    hasVtAuditAcc = false;

    // Osc configs
    hasOscConfigAcc = false;
    hasGlobalOscAcc = false;
    hasSpecificOscAcc = false;

    // order search
    hasOrderSearchAccess = false;
    hasActionLinkAcc = false;
    hasOsOverRefundAcc = false;
    hasOsRefundAcc = false;
    hasOsChargebkAcc = false;
    hasOsRetReqAcc = false;
    hasOsAuditAcc = false;
    hasOsPostAuditAcc = false;

    // User management
    hasUserMgmtAccess = false;
    hasCvaToolAccess = false;
    hasDashboardAccess = false;

    constructor(capabilities?: Array<RoleCapabilities>) {
        if (capabilities?.length > 0) {
            this.assignPermissionsBasedOnRole(capabilities);
        }
    }

    /**
     * Map role capabilites to profile component.
     * @param capabilities role capabilites from db.
     *
     */
    private assignPermissionsBasedOnRole(capabilities: Array<RoleCapabilities>) {
        // TODO: delete this
        for (const capability of capabilities) {
            switch (capability.capabilityName) {
                case 'virtual_terminal':
                    this.hasVtAccess = true;
                    break;
                case 'vt_sale':
                    this.hasVtSaleAcc = true;
                    break;
                case 'vt_override_refund':
                    this.hasVtRefundAcc = true;
                    break;
                case 'vt_chargeback':
                    this.hasVtChargebkAcc = true;
                    break;
                case 'vt_audit':
                    this.hasVtAuditAcc = true;
                    break;
                case 'osc_hop_configuration':
                    this.hasOscConfigAcc = true;
                    break;
                case 'osc_master_configuration':
                    this.hasGlobalOscAcc = true;
                    break;
                case 'osc_specific_configuration':
                    this.hasSpecificOscAcc = true;
                    break;
                case 'order_search':
                    this.hasOrderSearchAccess = true;
                    break;
                case 'os_action_links':
                    this.hasActionLinkAcc = true;
                    break;
                case 'os_linked_override_refund':
                    this.hasOsOverRefundAcc = true;
                    break;
                case 'os_refund':
                    this.hasOsRefundAcc = true;
                    break;
                case 'os_chargeback':
                    this.hasOsChargebkAcc = true;
                    break;
                case 'os_linked_request_for_retrival':
                    this.hasOsRetReqAcc = true;
                    break;
                case 'os_linked_audit_log':
                    this.hasOsAuditAcc = true;
                    break;
                case 'os_linked_post_audit_message':
                    this.hasOsPostAuditAcc = true;
                    break;
                case 'user_management':
                    this.hasUserMgmtAccess = true;
                    break;
                case 'cva_tool':
                    this.hasCvaToolAccess = true;
                    break;
                case 'dashboard':
                    this.hasDashboardAccess = true;
                    break;
            }
        }
    }
}
