// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

export const environment = {
  production: false,
  ORDER_API: 'https://epms-dev.ets.org/epms/order',
  ORDER_UI_SERVER_URL: 'https://epms-dev.ets.org',
  CONFIRMATION_URL: 'confirmation',
  PGA_RESPONSE_API: 'https://epms-dev.ets.org/epms/pg/response',
  ADMIN_API_HOSTNAME: 'https://epms-dev.ets.org/epms/admin',
  CUSTOM_IDENTITY_PROVIDER: 'ets-dev-iad-epms-idp',
  CUSTOM_IDENTITY_PROVIDER_EXT: 'ets-dev-iad-epms-ext-idp',
  // https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
  cognito: {
    aws_region: "us-east-1",
    user_pool_id: "us-east-1_Igt0uCSPt",
    user_pool_client_id: "29ieudi3o1ojiuo750segrvdn7",
    oauth: {
      domain: 'auth-epmsadmin-dev-ui.auth.us-east-1.amazoncognito.com',
      redirect_sign_in_uri: ['https://epmsadmin-dev-ui.ets.org/epms-admin-home'],
      redirect_sign_out_uri: ['https://epmsadmin-dev-ui.ets.org/logout/'],
      response_type: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code,
      identity_providers : ['ets-dev-iad-epms-idp'],
      scopes: ['email' , 'openid' , 'phone' , 'email' , 'profile' , 'aws.cognito.signin.user.admin']
    }
  },
  cognitoExt: {
    aws_region: 'us-east-1',
    user_pool_id: 'us-east-1_FYvXSE1EG',
    user_pool_client_id: '3v1h789bgmk871upaitdd9kvub',
    mandatorySignIn: false,
    oauth: {
      domain: 'auth-epmsadmin-ext-dev-ui.auth.us-east-1.amazoncognito.com',
      redirect_sign_in_uri: ['https://epmsadmin-ext-dev-ui.ets.org/epms-admin-home'],
      redirect_sign_out_uri: ['https://epmsadmin-ext-dev-ui.ets.org/logout/'],
      response_type: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code,
      identity_providers : ['ets-dev-iad-epms-ext-idp'],
      scopes: ['email' , 'openid' , 'phone' , 'email' , 'profile' , 'aws.cognito.signin.user.admin']
    }
  }
};
