<div id="assign-role-and-business" class="mt-4 container-xl">
  <div class="col-md-12 pt-5">
    <button class="btn btn-primary btn-function" (click)="back()">
      <i class="arrow left"></i> Back
    </button>
  </div>
  <div class="alert alert-danger mt-3" role="alert" *ngIf="noChangesAdded">
    * No updates were made or saved
  </div>
  <div class="col-md-12 mt-4 mb-2">
    <h2>Assign Role, OSC and/or Program Group</h2>
    <p>Please select Role, Originating System Code (OSC) and/or Program Group as appropriate from the dropdown. To
      remove a previously selected item, uncheck the item from the dropdown and click on Update. To save the changes,
      click on Save & Exit.</p>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="AssignRoleBusiness">
          <div class="row">
            <div id="dropdown-arrow-mat" class="col-md-4">
              <label>Select Role</label>
              <mat-select placeholder="Select..." class="form-select form-select-md" formControlName="role">
                <ng-container *ngFor="
                    let roleInfo of roleInfoList | sort : 'asc' : 'roleName'
                  ">
                  <mat-option [value]="roleInfo.roleName">{{
                    roleInfo.roleName
                    }}</mat-option>
                </ng-container>
                <mat-option value="null_role">
                  Remove Role
                </mat-option>
              </mat-select>
            </div>
            <div class="col-md-4">
              <label>Select Program Group</label>
              <mat-select placeholder="Select..." class="form-select form-select-md"
                formControlName="programGroup" multiple>
                <mat-option #progAllSelected (click)="toggleAllSelection('programGroup', AssignRoleBusiness.controls['programGroup']?.value)"
                  value="ALL">ALL</mat-option>
                <ng-container *ngFor="
                    let programGrp of programGroupList
                      | sort : 'asc' : 'programGroupName'
                  ">
                  <mat-option
                    (click)="toggleOne( programGroupList, progAllSelected, AssignRoleBusiness.controls['programGroup']?.value, programGrp.programGroupName)"
                    [value]="programGrp.programGroupName">{{
                      programGrp.programGroupName
                    }}</mat-option>
                </ng-container>
              </mat-select>
            </div>
            <div class="col-md-4">
              <label>Originating System Code (OSC)</label>
              <mat-select placeholder="Select..." class="form-select form-select-md"
                formControlName="osc" multiple>
                <mat-option #oscAllSelected (click)="toggleAllSelection('osc', AssignRoleBusiness.controls['osc']?.value)"
                  value="ALL">ALL</mat-option>
                <ng-container *ngFor="let oscCode of oscConfigCodes">
                  <mat-option (click)="toggleOne(oscConfigCodes, oscAllSelected, AssignRoleBusiness.controls['osc']?.value)"
                    [value]="oscCode">{{ oscCode }}</mat-option>
                </ng-container>
              </mat-select>
            </div>
            <div class="col-md-12 mt-4">
              <button class="btn btn-primary me-4" (click)="updateTableData()" type="submit">Update</button>
            </div>
            <div class="table-responsive mt-4">
              <table mat-table [dataSource]="dataSource" matSort class="table table-bordered">
                <ng-container *ngIf="usersToAssign?.length > 1" matColumnDef="removeUser">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    Remove <br />
                    User
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a (click)="removeUser(element.userLoginID)"> <fa-icon [icon]="faTrash" class="check"></fa-icon></a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="userLoginID">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    User Login ID
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a class="link" [routerLink]="['/user-management/user-details']"
                      [queryParams]="{ userLoginID: element.userLoginID }">
                      {{ element.userLoginID }}</a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    First <br />
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.firstName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    Last <br />
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.lastName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="programGroup">
                  <th class="header content-td-header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    Program Group
                  </th>
                  <td class="content-td-header content-td" mat-cell *matCellDef="let element">
                    {{ element.programGroup }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="osc">
                  <th class="header content-td-header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    OSC
                  </th>
                  <td class="content-td-header content-td" mat-cell *matCellDef="let element">
                    {{ element.osc }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="role">
                  <th class="header content-td-header" mat-header-cell mat-sort-header *matHeaderCellDef>
                    Role
                  </th>
                  <td class="content-td-header content-td" mat-cell *matCellDef="let element">
                    {{ element.role=='null_role'?'': element.role}}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div class="row col-md-12 mt-4">
              <button (click)="exit()" class="col-md-1 ms-2 mt-2 btn btn-primary btn-function">
                Exit
              </button>
              <button (click)="reset()" class="col-md-1 mt-2 ms-2 btn btn-primary">Reset</button>
              <button (click)="saveAndExit()" class="btn btn-primary col-md-2 mt-2 ms-2">
                Save and Exit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
