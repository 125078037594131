<div class="mt-4 container-xl" id="top">
  <div class="row">
    <div class="col-lg-12">
      <h2>Order Search Details</h2>
    </div>
    <div class="col-md-10">
  <button *ngIf="!audit" class="btn btn-primary btn-function mb-4" (click)="reviseSearch()">
    <fa-icon [icon]="faChevronLeft"></fa-icon> Back to Search Results
  </button>
  <button *ngIf="audit" class="btn btn-primary btn-function mb-4" (click)="reviseAuditLog()">
    <fa-icon [icon]="faChevronLeft"></fa-icon> Back to virtual terminal audit log
  </button>
</div>
  <div class="col-md-2 d-flex float-content-end mb-2">
    <button class="btn btn-primary" (click)="exportPdf()" >
      Export to PDF
    </button>
  </div>
</div>
  <app-nav-bar></app-nav-bar>

  <div id="order-details" class="row">
    <div class="col-12">
      <div class="card">
        <div class="row card-header d-flex justify-content-between">
          <h3 class="col-md-10">Order Details</h3>
          <div *ngIf="txData" class="action-link col-md-2 d-flex float-content-end">
            <app-action-links *ngIf="profile.hasActionLinkAcc" [txData]="txData" [calculatedRefundAmount]="matLineItemSummaryDetails?.calculatedRefundAmount" ></app-action-links>
          </div>
        </div>
        <div class="card-body" id="orderDetailsBody">
          <div class="row mb-4">
            <div class="col-md-3 pb-2">
              <strong>Originating System Code (OSC)</strong>
              <span class="ps-4">{{
                txData?.order?.originatingSystemCode
                }}</span>
            </div>
            <div class="col-md-3 pb-2">
              <strong>Order ID</strong>
              <span class="ps-4">{{ txData?.order?.epmsOrderId }}</span>
            </div>
            <div class="col-md-3 pb-2" >
              <strong>Transaction Attempt Count</strong>
              <span class="ps-4" *ngIf="!txData?.order?.epmsOrderId.startsWith('F')">{{ txData?.transactionAttemptCount }}</span>
            </div>
            <div class="col-md-3 pb-2">
              <strong>Order State </strong> <span class="ps-4">{{orderState}}</span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="order-detail">
              <div class="row col-12 border-bottom">
                <div class="col-12 mb-2">
                  <h3>Ship to Customer Information</h3>
                </div>
                <div class="col-md-4 mb-4">
                  <table>
                    <tr class="mb-4">
                      <th>Ship to First Name</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.firstName }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to Street Address 1</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.addressLine1 }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to State/Province</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.region }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to email</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingContact?.email }}
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="col-md-4 mb-4">
                  <table>
                    <tr class="mb-4">
                      <th>Ship to Last Name</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.lastName }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to Street Address 2</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.addressLine2 }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to Zip/Postal Code</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.postalCode }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to Phone #</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingContact?.phoneNumber }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-4 mb-4">
                  <table>
                    <tr class="mb-4">
                      <th>Ship to Company</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.company }}
                      </td>
                    </tr>
                    <tr class="mb-4">
                      <th>Ship to City</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.city }}
                      </td>
                    </tr>

                    <tr class="mb-4">
                      <th>Ship to Country Code</th>
                      <td class="ps-4">
                        {{ txData?.order?.shippingAddress?.country }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2">
                  <h3>Bill to Customer Information</h3>
                </div>

                <div class="row  col-12 border-bottom order-details-item">
                  <div class="col-md-4 mb-4">
                    <table>
                      <tr class="mb-4">
                        <th>Bill to First Name</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.firstName }}
                        </td>
                      </tr>
                      <tr class="mb-4">
                        <th>Bill to Street Address 1</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.addressLine1 }}
                        </td>
                      </tr>
                      <tr class="mb-4">
                        <th>Bill to State/Province</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.region }}
                        </td>
                      </tr>
                      <tr class="mb-4">
                        <th>Bill to email</th>
                        <td class="ps-4">
                          {{ txData?.billingContact?.email }}
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="col-md-4 mb-4">
                    <table>
                      <tr>
                        <th>Bill to Last Name</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.lastName }}
                        </td>
                      </tr>
                      <tr>
                        <th>Bill to Street Address 2</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.addressLine2 }}
                        </td>
                      </tr>
                      <tr>
                        <th>Bill to Zip/Postal Code</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.postalCode }}
                        </td>

                      </tr>
                      <tr>
                        <th>Bill to Phone #</th>
                        <td class="ps-4">
                          {{ txData?.billingContact?.phoneNumber }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4 mb-4">
                    <table>
                      <tr>
                        <th>Bill to Company</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.company }}
                        </td>
                      </tr>
                      <tr>
                        <th>Bill to City</th>
                        <td class="ps-4">{{ txData?.billingAddress?.city }}</td>
                      </tr>

                      <tr>
                        <th>Bill to Country Code</th>
                        <td class="ps-4">
                          {{ txData?.billingAddress?.country }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2">
                  <h3>Payment Information</h3>
                </div>

                <div class="row  col-12 border-bottom order-details-item">
                  <div class="col-md-4 mb-4">
                    <table>
                      <tr>
                        <th>Order Amount </th>
                        <td class="ps-4">
                          {{
                          txData?.amount?.toFixed(2)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Local Currency Amount</th>
                        <td class="ps-4">
                          <ng-container *ngIf="txData?.amountDetail?.dccopted">
                            {{ txData?.amountDetail?.foreignCurrencyAmount?.toFixed(2) }}
                          </ng-container>
                        </td>
                      </tr>
                      <tr>
                        <th>Settlement Amount</th>
                        <td class="ps-4">{{ txData?.amountDetail?.settlementAmount?.toFixed(2)}}</td>
                      </tr>
                      <tr>
                        <th>Bin</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.bin }}
                        </td>
                      </tr>
                      <tr>
                        <th>Bank Account Type</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.accountType }}
                        </td>
                      </tr>
                      <tr>
                        <th>Payment Type</th>
                        <td class="ps-4">
                          {{ txData?.paymentTypeCode }}
                        </td>
                      </tr>
                      <tr>
                        <th>Card Type</th>
                        <td class="ps-4">{{ txData?.paymentMethodDetail?.cardType}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4 mb-4">
                    <table>
                      <tr>
                        <th>Base Currency</th>
                        <td class="ps-4">{{ txData?.amountDetail?.baseCurrencyCode}}</td>
                      </tr>
                      <tr>
                        <th>FX Rate</th>
                        <td class="ps-4">
                          {{ txData?.amountDetail?.exchangeRate }}
                        </td>
                      </tr>
                      <tr>
                        <th>Masked Account Number</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.maskedAccountNumber }}
                        </td>
                      </tr>
                      <tr>
                        <th>EPMS Subscription ID</th>
                        <td class="ps-4">
                          {{ txData?.subscriptionDetail?.subscriptionDetailId }}
                        </td>
                      </tr>
                      <tr>
                        <th>Bank ABA/Routing Number</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.routingNumber }}
                        </td>
                      </tr>
                      <tr>
                        <th>Payment Method</th>
                        <td class="ps-4">{{ txData?.paymentMethodCode }}</td>
                      </tr>
                      <tr>
                        <th>PaymentJS Brand</th>
                        <td class="ps-4">{{ txData?.paymentMethodDetail?.jsCardType}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4 mb-4">
                    <table>
                      <tr>
                        <th>Local (Presentment) Currency </th>
                        <td class="ps-4">
                          <ng-container *ngIf="txData?.amountDetail?.dccopted">
                          {{ txData?.amountDetail?.foreignCurrencyCode }}
                          </ng-container>
                        </td>
                      </tr>
                      <tr>
                        <th>Settlement Currency</th>
                        <td class="ps-4">{{ txData?.amountDetail?.settlementCurrencyCode}}</td>
                      </tr>
                      <tr>
                        <th>Card Expiration Date</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.expiryMonthYear }}
                        </td>
                      </tr>
                      <tr>
                        <th>Token</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.cardToken }}
                        </td>
                      </tr>
                      <tr>
                        <th>Customer Bill to E-mail Address</th>
                        <td class="ps-4">
                          {{ txData?.billingContact?.email }}
                        </td>
                      </tr>
                      <tr>
                        <th>Brand Name</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.brandName }}
                        </td>
                      </tr>
                      <tr>
                        <th>TC-40 Flag</th>
                        <td class="ps-4">
                          {{ txData?.fraudIndicator }}
                        </td>
                      </tr>
                      <tr>
                        <th>ARN/RRN No</th>
                        <td class="ps-4">
                          {{ txData?.paymentMethodDetail?.arnRrnNo }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2">
                  <h3>Order Detail Information</h3>
                </div>

                <div class="row col-12 border-bottom order-details-item">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>
                          Mode of Receipt</th>
                        <td class="ps-4">
                          {{txData?.order?.modeOfReceipt}}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Last Reason Description</th>
                        <td class="ps-4">{{txData?.pgmResponseMessage}}</td>
                      </tr>
                      <tr>
                        <th>
                          ECI</th>
                        <td class="ps-4">{{txData?.authenticationDetail?.eciValue}}</td>
                      </tr>

                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>
                          Last Reason Code</th>
                        <td class="ps-4" *ngIf="isChargeBack()">{{txData?.chargeBackReasonCode}}</td>
                        <td class="ps-4" *ngIf="!isChargeBack()">{{txData?.pgmResponseCode}}</td>
                      </tr>
                      <tr>
                        <th>Chargeback Indicator</th>
                        <td class="ps-4">{{txData?.order?.chargebackIndicator}}</td>
                      </tr>
                      <tr>
                        <th>
                          Refund Indicator</th>
                        <td class="ps-4">{{txData?.order?.refundIndicator}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Sale Processed Date</th>
                        <td class="ps-4">{{txData?.createdTimestamp|date:'medium'}}</td>
                      </tr>
                      <tr>
                        <th>Fraud Score</th>
                        <td class="ps-4">{{txData?.fraudDetail?.score}}</td>
                      </tr>
                      <tr>
                        <th>Created By</th>
                        <td class="pl-4">{{txData?.createdBy}}</td>
                      </tr>
                    </table>
                  </div>
                </div>

              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2">
                  <h3>GL Information</h3>
                </div>

                <div class="row col-12 border-bottom order-details-item">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Project</th>
                        <td class="ps-4">{{txData?.glProjectText}}</td>
                      </tr>
                      <tr>
                        <th>Resource</th>
                        <td class="ps-4">{{txData?.resourceText}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Product</th>
                        <td class="ps-4">{{txData?.glProductText}}</td>
                      </tr>
                      <tr>
                        <th>Activity</th>
                        <td class="ps-4">{{txData?.glActivityText}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>JE Account</th>
                        <td class="ps-4">{{txData?.order?.jeAccountNumber}}</td>
                      </tr>
                    </table>
                  </div>

                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2">
                  <h3>Program Specific Information</h3>
                </div>

                <div class="row col-12 border-bottom order-details-item">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Customer ID</th>
                        <td class="ps-4">{{ txData?.order?.customerID }}</td>
                      </tr>
                      <tr>
                        <th>Test Center Country</th>
                        <td class="ps-4">
                          {{ txData?.order?.testCenterCountryCode }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Program Defined Date</th>
                        <td class="ps-4">{{ txData?.programDefinedDate }}</td>
                      </tr>
                      <tr>
                        <th>Fd Customer Type</th>
                        <td class="ps-4">{{ txData?.order?.fraudCustomerType }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Program Specific Key</th>
                        <td class="ps-4">{{ txData?.programSpecificKey }}</td>
                      </tr>
                      <tr>
                        <th>No Match Vendor Supplied Order ID</th>
                        <td class="ps-4">{{ txData?.noMtchVndrSuppliedId }}</td>
                      </tr>

                    </table>
                  </div>
                  <div class="row  border-bottom order-details-item pb-20">
                    <div class="col-md-12 ps-0">
                      <table>
                      </table>
                      <tr>
                        <th>OSC Session ID</th>
                        <td class="ps-4">{{ txData?.order?.oscSessionId }}</td>
                      </tr>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2" style="margin-top: 5px">
                  <h3>3DS Payer Authentication</h3>
                </div>
                <div class="row col-12 border-bottom order-details-item">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>transaction id</th>
                        <td class="ps-4">{{txData?.orderTransactionId}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Method notification status code</th>
                        <td class="ps-4">{{txData?.authenticationDetail?.methodNotificationStatus}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>3d secure transaction id</th>
                        <td class="ps-4">{{txData?.authenticationDetail?.secure3dTransId}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Merchant data </th>
                        <td class="ps-4">{{txData?.authenticationDetail?.merchantData}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>3d secure response code</th>
                        <td class="ps-4">{{txData?.authenticationDetail?.secure3dResponseCode}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>3ds version</th>
                        <td class="ps-4">{{txData?.authenticationDetail?.version}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>3ds session data</th>
                        <td class="ps-4">
                          <app-txt-truncate *ngIf="txData?.authenticationDetail?.threeDSSessionData"
                            [data]="txData?.authenticationDetail?.threeDSSessionData">
                          </app-txt-truncate>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>eci value</th>
                        <td class="ps-4">{{txData?.authenticationDetail?.eciValue}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Authentication type code</th>
                        <td class="ps-4">

                          <app-txt-truncate *ngIf="txData?.authenticationDetail?.authenticationType"
                            [data]="txData?.authenticationDetail?.authenticationType">
                          </app-txt-truncate>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>cres</th>
                        <td class="ps-4">

                          <app-txt-truncate *ngIf="txData?.authenticationDetail?.cres"
                            [data]="txData?.authenticationDetail?.cres"></app-txt-truncate>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>creq</th>
                        <td class="ps-4">
                          <app-txt-truncate *ngIf="txData?.authenticationDetail?.creq"
                            [data]="txData?.authenticationDetail?.creq"></app-txt-truncate>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Payer Authentication Request </th>
                        <td class="ps-4">

                          <app-txt-truncate *ngIf="txData?.authenticationDetail?.payerAuthenticationRequest"
                            [data]="txData?.authenticationDetail?.payerAuthenticationRequest"></app-txt-truncate>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Payer Authentication response data </th>
                        <td class="ps-4">

                          <app-txt-truncate *ngIf="txData?.authenticationDetail?.payerAuthenticationResponse"
                            [data]="txData?.authenticationDetail?.payerAuthenticationResponse">
                          </app-txt-truncate>

                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-12 mb-2">
                  <h3>Processing Dates</h3>
                </div>
                <div class="row col-12 border-bottom order-details-item">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Created Date <mat-icon class='colorInfo'
                          matTooltip="The date the transaction was added to the EPMS System.">
                          help_outline</mat-icon></th>
                        <td class="ps-4">{{txData?.createdTimestamp|date:'medium'}}</td>
                      </tr>
                      <tr>
                        <th>CFS Date <mat-icon class='colorInfo'
                          matTooltip="The date the transaction was sent to CFS, if empty transaction was not sent to CFS.">
                          help_outline</mat-icon></th>
                        <td class="ps-4">{{txData?.cfsSettledTimestamp|date:'medium'}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Reconciliation Date <mat-icon class='colorInfo'
                          matTooltip="The date EPMS processed the settlement file and updated the EPMS System.">
                          help_outline</mat-icon></th>
                        <td class="ps-4">{{txData?.reconciliationTimeStamp|date:'medium'}}</td>
                      </tr>
                      <tr>
                        <th>Updated Date <mat-icon class='colorInfo'
                          matTooltip="The date the transaction was last updated in the EPMS system.">
                          help_outline</mat-icon></th>
                        <td class="ps-4">{{txData?.updatedTimestamp | date:'medium'}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <th>Settlement Date <mat-icon class='colorInfo'
                          matTooltip=" The date the transaction was settled, and funds were deposited or withdrawn from the bank.">
                          help_outline</mat-icon></th>
                        <td class="ps-4">{{txData?.settledTimeStamp|date:'medium'}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="line-details" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3>Line Item Summary and Details</h3>
          <a (click)="scrollToService?.scrollTo('top')">
            <div class="arrow">
              <img src="../../assets/img/up-arrow.png" />
            </div>
          </a>
        </div>
        <div class="card-body">

          <app-mat-line-item-summary #matLineItemSummaryDetails *ngIf="txData" [orderId]="txData?.order?.epmsOrderId"></app-mat-line-item-summary>
        </div>
      </div>
    </div>
  </div>
  <div id="transaction-details" class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3>Transaction and Details</h3>
          <a (click)="scrollToService?.scrollTo('top')">
            <div class="arrow">
              <img src="../../assets/img/up-arrow.png" />
            </div>
          </a>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-bordered">
            <tr>
              <th>Service</th>
              <th>Created Date</th>
              <th>Reason/Response Code</th>
              <th>Status</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Local Currency Amount</th>
              <th>Details</th>
            </tr>

            <tr *ngFor="let txDataItem of txDataList">
              <td>{{txDataItem.service}}</td>

              <td>
                <ng-container *ngIf="txDataItem.service=='SALE_SETTLEMENT' || txDataItem.service=='REFUND_SETTLEMENT';else notSettlement">
                  {{txDataItem.reconciliationTimeStamp|date:'medium'}}
                </ng-container>
                <ng-template #notSettlement>
                  {{txDataItem.date|date:'medium'}}
                </ng-template>
               </td>
              <td>{{txDataItem.reasonCode}}</td>
              <td>{{txDataItem.status}}</td>
              <td>
                <ng-container *ngIf="txDataItem?.dccopted">
                  {{txDataItem.currency}}
                </ng-container>
                <ng-container *ngIf="!txDataItem?.dccopted">
                  {{txDataItem?.baseCurrencyCode}}
                </ng-container>
              </td>
              <td>

                {{txDataItem?.amountUSD?.toFixed(2)}}

              </td>
              <td>
                <ng-container *ngIf="txDataItem?.dccopted">
                  {{txDataItem?.amountLC?.toFixed(2)}}
                </ng-container>
              </td>
              <td>
                <div id="details-button">
                <button type="button" class="btn btn-primary" aria-label="Details"
                  (click)="showSettlementDetails(txDataItem, settlementDetailTemplate)">Details</button>
                </div>
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>

  <div id="related-orders" class="row" *ngIf="relatedOrdersData?.length>0">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3>Related Orders</h3>
          <a (click)="scrollToService?.scrollTo('top')">
            <div class="arrow">
              <img src="../../assets/img/up-arrow.png" />
            </div>
          </a>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-bordered">
            <tr>
              <th>Order Id</th>
              <th>Transaction Attempt Count</th>
              <th>Amount</th>
              <th>Transaction Process Date</th>
            </tr>
            <tr *ngFor="let relatedOrder of relatedOrdersData">
              <td><a class="link" (click)="loadOrderState(relatedOrder.epmsOrderId, relatedOrder.orderTransactionId, true)">
                  {{relatedOrder.epmsOrderId}}</a></td>
              <td>
                <ng-container *ngIf="!relatedOrder.epmsOrderId.startsWith('F')">
                {{relatedOrder.transactionAttemptCount}}
                </ng-container>
              </td>
              <td>{{relatedOrder.amount.toFixed(2)}}</td>
              <td>{{relatedOrder.date|date:'medium'}}</td>

            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div id="related-orders" class="row" *ngIf="!relatedOrdersData||relatedOrdersData.length===0">
    <div class="col-12">
      <div class="card">

        <div class="card-header d-flex justify-content-between">
          <h3>Related Orders</h3>
          <a (click)="scrollToService?.scrollTo('top')">
            <div class="arrow">
              <img src="../../assets/img/up-arrow.png" />
            </div>
          </a>
        </div>
        <div class="card-body">
          <h3>No Related Order(s) Found</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- raw detail modal -->
<ng-template #settlementDetailTemplate>
  <div class="modal-header d-inline" align="end">
    <button mat-icon-button class="close-button" (click)="modalRef.hide()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="modal-body" style="white-space: break-spaces;overflow-wrap: break-word;">
    {{settlementDetails|json}}
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-function" [cdkCopyToClipboard]="settlementDetails|json">Copy to clipboard</button>
    <button class="btn btn-primary btn-function" (click)="modalRef.hide()">Exit</button>
  </div>

</ng-template>
