import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalConfigInfoModel} from "../model/global-config-info.model";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {OschopInfo} from "../model/oschop-info";


@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService {


  constructor(private http: HttpClient) {
  }

  getConfigInfo() {
    return this.http.get<GlobalConfigInfoModel>(`${environment.ADMIN_API_HOSTNAME}/config/global`);
  }

  saveConfigInfo(globalConfigInfoModel: GlobalConfigInfoModel) {
    return this.http.put(`${environment.ADMIN_API_HOSTNAME}/config/global`, globalConfigInfoModel, {
      responseType: 'text'
    });
  }

  getOscHops(oscCode: string):Observable<OschopInfo[]> {
    return this.http.get<OschopInfo[]>(`${environment.ADMIN_API_HOSTNAME}/config/osc/${oscCode}/hopdetails`);
  }


}
