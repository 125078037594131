import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/model/user';
import { SharedService } from 'src/app/service/shared.service';
import { payments_types, fraud_3ds_currency } from '../config';
import {
  IDynamicDialogConfig,
  MatDialogComponentComponent,
} from '../../mat-dialog-component/mat-dialog-component.component';
import { GlobalConfigService } from '../../service/global-config.service';
import { GlobalConfigInfoModel } from '../../model/global-config-info.model';

@Component({
  selector: 'app-master-global-config',
  templateUrl: './master-global-config.component.html',
  styleUrls: ['./master-global-config.component.scss'],
})
export class MasterGlobalConfigComponent implements OnInit {
  isDisabled = true;
  validationMessage: boolean;
  payments_types;
  types: FormGroup;
  loggedInUser: User;
  configInfoModel: GlobalConfigInfoModel;
  fraud_3ds_currency;
  errMsg: string;

  constructor(
    private sharedService: SharedService,
    private dialog: MatDialog,
    private globalConfigService: GlobalConfigService
  ) {
    this.payments_types = payments_types.slice(0, 4);
    this.fraud_3ds_currency = fraud_3ds_currency;
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.createCardTypes();
      }
    });
    this.globalConfigService.getConfigInfo().subscribe((data) => {
      this.configInfoModel = data;
      this.populateFormValues();
    });
  }

  resetPage() {
    this.createCardTypes();
    this.populateFormValues();
  }

  createCardTypes() {
    this.types = new FormGroup({
      ccPaymentEnabled: new FormControl(null),
      threeDSEnabled: new FormControl(null),
      visaEnabled: new FormControl(null),
      amexEnabled: new FormControl(null),
      dinersClubEnabled: new FormControl(null),
      discoverEnabled: new FormControl(null),
      jcbEnabled: new FormControl(null),
      masterCardEnabled: new FormControl(null),
      rupayGlobalEnabled: new FormControl(null),
      unionPayEnabled: new FormControl(null),
      amex3dsEnabled: new FormControl(null),
      diners3dsEnabled: new FormControl(null),
      discover3dsEnabled: new FormControl(null),
      jcb3dsEnabled: new FormControl(null),
      mc3dsEnabled: new FormControl(null),
      rupay3dsEnabled: new FormControl(null),
      unionPay3dsEnabled: new FormControl(null),
      visa3dsEnabled: new FormControl(null),
      paypalEnabled: new FormControl(null),
      dccEnabled: new FormControl(null),
      fraudDetectEnabled: new FormControl(null),
      alipayEnabled: new FormControl(null),
    });
    this.types.disable();
  }

  populateFormValues() {
    const entries = Object.entries(this.configInfoModel);
    for (const entry of entries) {
      if (this.types.get(entry[0])) {
        this.types.get(entry[0]).setValue(entry[1]);
      }
    }
  }

  editConfig() {
    this.isDisabled = !this.isDisabled;
    this.validationMessage = false;
    this.errMsg = '';
    this.types.enable();
    !this.configInfoModel.ccPaymentEnabled
      ? this.enableOrDisableCreditCardOr3DS(false, 'ccPaymentEnabled')
      : '';
    !this.configInfoModel.threeDSEnabled
      ? this.enableOrDisableCreditCardOr3DS(false, 'threeDSEnabled')
      : '';
  }

  saveExit() {
    this.types.disable();
    this.isDisabled = !this.isDisabled;
    this.validationMessage = true;
    const entries = Object.entries(this.configInfoModel);
    for (const entry of entries) {
      if (this.types.get(entry[0])) {
        this.configInfoModel[entry[0]] = this.types.get(entry[0]).value;
      }
    }
    this.configInfoModel.updatedBy = this.loggedInUser.emailId;
    const obs = this.globalConfigService.saveConfigInfo(this.configInfoModel);
    obs.subscribe({
      next: (data) => {
        console.log('global config saved ' + data);
      },
      error: (err) => {
        console.log('error', err);
        this.errMsg =
          'Error while processing the request. ' + err?.error?.text;
      },
    });
  }

  exit() {
    this.openDialog();
  }

  openDialog() {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      data: <IDynamicDialogConfig>{
        title: 'Do you want to exit without saving?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value !== 'noExit') {
        this.isDisabled = !this.isDisabled;
        this.resetPage();
      }
    });
  }

  enableOrDisableCreditCardOr3DS(value, type) {
    if (!value && type === 'ccPaymentEnabled') {
      this.types.disable();
      this.types.controls['ccPaymentEnabled'].enable();
      this.types.controls['paypalEnabled'].enable();
      this.types.controls['alipayEnabled'].enable();
      this.types.controls['fraudDetectEnabled'].enable();
      this.types.controls['dccEnabled'].enable();
    } else if (!value && type === 'threeDSEnabled') {
      this.types.controls['visa3dsEnabled'].disable();
      this.types.controls['mc3dsEnabled'].disable();
      this.types.controls['amex3dsEnabled'].disable();
      this.types.controls['unionPay3dsEnabled'].disable();
      this.types.controls['diners3dsEnabled'].disable();
      this.types.controls['jcb3dsEnabled'].disable();
      this.types.controls['rupay3dsEnabled'].disable();
      this.types.controls['discover3dsEnabled'].disable();
    } else if (
      value &&
      (type === 'threeDSEnabled' || type === 'ccPaymentEnabled')
    ) {
      this.types.enable();
    }
  }
}
