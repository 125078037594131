export class ReconciliationResponce {
    recordId?: number;
    fileId?: string;
    rawData?: string;
    recordType?: string;
    sourceFileType?: string;
    errorType?: string;
    ccpsFlag?: boolean;
    createdTimeStamp?: number;
    hierarchyId?: string;
    hierarchyLevelNo?: number;
    locationId?: string;
    locationDBAName?: string;
    storeNumber?: number;
    externalId?: number;
    cardType?: string;
    cardNumber?: string;
    terminalId?: string;
    batchNumber?: string;
    batchDate?: string;
    authorizationCode?: string;
    invoiceId?: string;
    referenceNumber?: string;
    transactionId?: string;
    transactionDate?: string;
    transactionAmountSign?: string;
    transactionAmount?: number;
    submittedCurrencyAmountSign?: string;
    submittedCurrencyAmount?: number;
    submittedCurrencyCode?: string;
    transactionMode?: string;
    authorizationDate?: string;
    transactionTime?: string;
    expirationDate?: string;
    authorizationSICCode?: string;
    specialReference1?: string;
    specialReference2?: string;
    subMerchantId?: string;
    softDescriptor?: string;
    bankReferenceNumber?: string;
    ipgTransactionId?: string;
    networkIndicator?: string;
    networkId?: string;
    customerNumber?: string;
    billToName?: string;
    productCode?: string;
    statusDate?: string;
    adjustmentDate?: string;
    receiveDate?: string;
    settlementDate?: string;
    currencyConversionRate?: string;
    transactionStatus?: string;
    responseCode?: string;
    processedCurrencyCode?: string;
    rejectIndicator?: string;
    rejectReason?: string;
    entryMode?: string;
    pcTerminalCapability?: string;
    pinlessDebit?: string;
    planCode?: string;
    billbackReason?: string;
    reclassCode?: string;
    partialAuthIndicator?: string;
    transactionType?: string;
    transactionSource?: string;
    authorizationAmountSign?: string;
    authorizationAmount?: string;
    avsFlag?: string;
    cvc2Result?: string;
    cvv2Result?: string;
    salesTaxAmountSign?: string;
    salesTaxAmount?: string;
    authorizationResponseCode?: string;
    convenienceFeeAmountSign?: string;
    convenienceFeeAmount?: string;
    authSource?: string;
    catLevel?: string;
    cardholderIDMethod?: string;
    cardPresenceIndicator?: string;
    merchantReferenceNumber?: string;
    totalAuthAmountSign?: string;
    totalAuthAmount?: string;
    marketSpecificIndicator?: string;
    serviceDevelop?: string;
    purchaseIdentifierFormat?: string;
    purchaseIdentifier?: string;
    chipConditionCode?: string;
    mcSecurityProtocol?: string;
    mcCardholderAuthentication?: string;
    productId?: string;
    transactionCountry?: string;
    transactionChargesSign?: string;
    transactionCharges?: string;
    captureMethod?: string;
    serviceFeeAmount?: string;
    discountAmount?: string;
    processingDate?: string;
    message?: string;
    match: false;
    settled: true;
    disputeType?: string;
    requestType?: string;
    statusDescription?: string;
    chargebackCurrencyCode?: string;
    reasonCode?: string;
    reasonCodeDescription?: string;
    idsCaseNumber?: string;
    dueDate?: string;
    chargebackCycle?: string;
    firstChargebackDate?: string;
    secondChargebackDate?: string;
    processedTransactionCurrencyCode?: string;
    processedTransactionAmountSign?: string;
    adjustmentAmountSign?: string;
    transactionServiceCode?: string;
    transactionPosEntryMode?: string;
    workType?: string;
    loanNumber?: string;
    workOrderNumber?: string;
    mobileIndicator?: string;
    currentStatus?: string;
    respondedOnDate?: string;
    debitNetworkDescription?: string;
    currency?: string;
    fromSystem?: string;
    rejectsToSystem?: string;
    disputesToSystem?: string;
    resolutionNumber?: string;
    supportToFollow?: string;
    billToAddress?: string;
    billToCityState?: string;
    billToZip?: string;
    airlineTicketNumber?: string;
    fincapBatchNumber?: string;
    fincapBatchDate?: string;
    epmsOrderId?: string;
    disputeDate?: string;
    acquirerReferenceNumber?: string;
    adjustmentAmount?: number;
    processedTransactionAmount?: number;
    chargebackAmount?: number;
}

export const summary_bar_data = [
    {
      name: 'Total Records',
      key: 'totalRecords',
    },
    {
      name: 'Total Errors',
      key: 'errorRecords',
    },
    {
      name: 'Files Received',
      key: 'successRecords',
    },
    {
      name: 'No Match',
      key: 'noMatchRecords',
    },
  ];

export enum displayedColumnNames { recordId = "Record Id",
fileId = "File Id",recordType = "Record Type",
sourceFileType = "Source File Type",
errorType = "Error Type",
ccpsFlag = "CCPS Flag",
createdTimeStamp = "Created Time Stamp",
hierarchyId = "Hierarchy Id",
hierarchyLevelNo = "Hierarchy Level No",
locationId = "Location Id",
locationDBAName = "Location DBA Name",
storeNumber = "Store Number",
externalId = "External Id",
cardType = "Card Type",
cardNumber = "Card Number",
terminalId = "Terminal Id",
batchNumber = "Batch Number",
batchDate = "Batch Date",
authorizationCode = "Authorization Code",
invoiceId = "Invoice Id",
referenceNumber = "Reference Number",
transactionId = "Transaction Id",
transactionDate = "Transaction Date",
transactionAmountSign = "Transaction Amount Sign",
transactionAmount = "Transaction Amount",
submittedCurrencyAmountSign = "Submitted Currency Amount Sign",
submittedCurrencyAmount = "Submitted Currency Amount",
submittedCurrencyCode = "Submitted Currency Code",
transactionMode = "Transaction Mode",
authorizationDate = "Authorization Date",
transactionTime = "Transaction Time",
expirationDate = "Expiration Date",
authorizationSICCode = "Authorization SIC Code",
specialReference1 = "Special Reference 1",
specialReference2 = "Special Reference 2",
subMerchantId = "Sub Merchant Id",
softDescriptor = "Soft Descriptor",
bankReferenceNumber = "Bank Reference Number",
ipgTransactionId = "IPG Transaction Id",
networkIndicator = "Network Indicator",
networkId = "Network Id",
customerNumber = "Customer Number",
billToName = "Bill to Name",
productCode = "Product Code",
statusDate = "Status Date",
adjustmentDate = "Adjustment Date",
receiveDate = "Receive Date",
settlementDate = "Settlement Date",
currencyConversionRate = "Currency Conversion Rate",
transactionStatus = "Transaction Status",
responseCode = "Response Code",
processedCurrencyCode = "Processed Currency Code",
rejectIndicator = "Reject Indicator",
rejectReason = "Reject Reason",
entryMode = "Entry Mode",
pcTerminalCapability = "PC Terminal Capability",
pinlessDebit = "Pinless Debit",
planCode = "Plan Code",
billbackReason = "Bill back Reason",
reclassCode = "Reclass Code",
partialAuthIndicator = "Partial Auth Indicator",
transactionType = "Transaction Type",
transactionSource = "Transaction Source",
authorizationAmountSign = "Authorization Amount Sign",
authorizationAmount = "Authorization Amount",
avsFlag = "AVS Flag",
cvc2Result = "CVC2 Result",
cvv2Result = "CVV2 Result",
salesTaxAmountSign = "Sales Tax Amount Sign",
salesTaxAmount = "Sales Tax Amount",
authorizationResponseCode = "Authorization Response Code",
convenienceFeeAmountSign = "Convenience Fee Amount Sign",
convenienceFeeAmount = "Convenience Fee Amount",
authSource = "Auth Source",
catLevel = "CAT Level",
cardholderIDMethod = "Card holder ID Method",
cardPresenceIndicator = "Card Presence Indicator",
merchantReferenceNumber = "Merchant Reference Number",
totalAuthAmountSign = "Total Auth Amount Sign",
totalAuthAmount = "Total Auth Amount",
marketSpecificIndicator = "Market Specific Indicator",
serviceDevelop = "Service Develop",
purchaseIdentifierFormat = "Purchase Identifier Format",
purchaseIdentifier = "Purchase Identifier",
chipConditionCode = "Chip Condition Code",
mcSecurityProtocol = "MC Security Protocol",
mcCardholderAuthentication = "MC Cardholder Authentication",
productId = "Product Id",
transactionCountry = "Transaction Country",
transactionChargesSign = "Transaction Charges Sign",
transactionCharges = "Transaction Charges",
captureMethod = "Capture Method",
serviceFeeAmount = "Service Fee Amount",
discountAmount = "Discount Amount",
processingDate = "Processing Date",
message = "Message",
match = "Match",
settled = "Settled",
disputeType = "Dispute Type",
requestType = "Request Type",
statusDescription = "Status Description",
chargebackCurrencyCode = "Chargeback Currency Code",
reasonCode = "Reason Code",
reasonCodeDescription = "Reason Code Description",
idsCaseNumber = "Ids Case Number",
dueDate = "Due Date",
chargebackCycle = "Chargeback Cycle",
firstChargebackDate = "First Chargeback Date",
secondChargebackDate = "Second Chargeback Date",
processedTransactionCurrencyCode = "Processed Transaction Currency Code",
processedTransactionAmountSign = "Processed Transaction Amount Sign",
processedTransactionAmount = "Processed Transaction Amount",
adjustmentAmountSign = "Adjustment Amount Sign",
adjustmentAmount = "Adjustment Amount",
transactionServiceCode = "Transaction Service Code",
transactionPosEntryMode = "Transaction Pos Entry Mode",
workType = "Work Type",
loanNumber = "Loan Number",
workOrderNumber = "Work Order Number",
mobileIndicator = "Mobile Indicator",
currentStatus = "Current Status",
respondedOnDate = "Responded On Date",
debitNetworkDescription = "Debit Network Description",
currency = "Currency",
fromSystem = "From System",
rejectsToSystem = "Rejects To System",
disputesToSystem = "Disputes To System",
chargebackAmount = "Chargeback Amount",
resolutionNumber = "Resolution Number",
supportToFollow = "Support To Follow",
billToAddress = "Bill To Address",
billToCityState = "Bill To City State",
billToZip = "Bill To Zip",
airlineTicketNumber = "Airline Ticket Number",
fincapBatchNumber = "Fincap Batch Number",
fincapBatchDate = "Fincap Batch Date",
epmsOrderId = "Epms Order Id",
disputeDate = "Dispute Date",
acquirerReferenceNumber = "Acquirer Reference Number"};

export enum cfsColumnNames {
  paymentId = "Payment Id",
  transactionId = "Transaction Id",
  transactionName = "Transaction Name",
  description = "Description",
  paymentMethod = "Payment Method",
  cardType = "Card Type",
  transactionDttm =  "Transaction Date Time",
  transactionAmount = "Transaction Amount",
  currencyCode = "Currency Code",
  settlementAmt = "Settlement Amount",
  foreignAmount = "Foreign Currency Amount",
  currencyCdBase = "Base Currency Code",
  etsFxRate = 'ETS Foriegn Exchange Rate',
  shipCustFirstName = "Shipping Customer First Name",
  shipCustLastName =  "Shipping Customer Last Name",
  etsOscCode = "ETS OSC Code",
  etsOrderNumber = "ETS Order Number",
  etsOrderLine =  "ETS Order Line",
  productType =  "Product Type",
  productId =  "Product ID",
  quantity = "Quantity",
  unitPrice = "Unit Price",
  foreignCurrencyUnitPrice = "Foreign Currency Unit Price",
  taxAmount = "Tax Amount",
  total =  "Total Amount",
  subTotal =  "Sub Total"
};
