<div class="container-xl mt-4">
    <div class="row">
        <div class="col-12">
          <ng-container *ngIf="loggedInUser">
            <h3 class="mb-2 pt-5">Welcome, {{loggedInUser?.firstName}} {{loggedInUser?.lastName}}</h3>
          <div class="row" *ngIf="userFound">
            <div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0) || !(loggedInUser?.role?.roleName)">
                <div class="col-8">
                    <p>The account is not associated to {{constructMessage(loggedInUser)}}. Please contact the EPMSBusinessOps e-mail distribution group at
                        <a href="mailto:epmsbusinessops@ets.org">epmsbusinessops&#64;ets.org</a> to complete the setup.
                    </p>
                    <p>Thank you!</p>
                </div>
            </div>
          </div>
          </ng-container>
          <div class="row" *ngIf="!userFound">
            <div class="col-8">
              <p>You are not set up in EPMS.</p>
              <p>Thank you!</p>
            </div>
          </div>

        </div>
    </div>
</div>
