<div *ngIf="loggedInUser?.oscDetails?.length > 0">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a>OSC and HOP Configuration</a></li>
      <li class="breadcrumb-item">
        <a (click)="exit()">General Business/OSC Configuration</a>
      </li>
    </ol>
  </nav>
  <div class="container-xl mt-4">
    <div class="row">
      <div class="col-12 padding">
        <h2>View Business/OSC Configuration</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-start">
        <button
          class="btn btn-primary btn-function col-lg-2 col-md-4 p-2 col-12 me-2 mb-3"
          (click)="exit()"
        >
          <i class="arrow left"></i> Back to Businesses
        </button>
        <button
          class="col-lg-1 col-md-2 col-12 btn btn-primary mb-3"
          (click)="edit()"
        >
          Edit
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="col-md-12">
          <div class="row">
            <div class="col-12">
              <h4>OSC Details</h4>
            </div>
          </div>
          <div class="row margin">
            <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
              Originating System Code
            </div>
            <div class="col-lg-2 col-md-2 col-5 text-start padding">
              {{ oscData?.oscCode }}
            </div>

            <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
              Description
            </div>
            <div class="col-lg-2 col-md-2 col-5 text-start padding">
              {{ oscData?.oscDescription }}
            </div>

            <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
              Merchant ID/Store ID
            </div>
            <div class="col-lg-2 col-md-2 col-5 text-start padding">
              {{ oscData?.fiservStoreId }}
            </div>
            <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
              Interface Name
            </div>
            <div class="col-lg-2 col-md-2 col-5 text-start padding">
              {{ oscData?.applicationName }}
            </div>

            <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
              Reporting ID
            </div>

            <div class="col-lg-3 col-md-3 col-5 text-start padding">
              {{ oscData?.reportStoreNumber }}
            </div>
            <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
              Select Existing Program Hierarchy Group
            </div>
            <div class="col-lg-2 col-md-2 col-5 text-start padding">
              {{ oscData?.programGroup?.programGroupName }}
            </div>
            <div class="row margin border-bottom"></div>

            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>OSC PayPal Merchant Account Details</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                PayPal Merchant Account ID
              </div>
              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.paypalMerchantAccountName }}
              </div>

              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                PayPal Merchant e-mail Address
              </div>

              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.paypalMerchantAccountEmail }}
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>Service Establishments</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                American Express Service Establishment (SE) #
              </div>
              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.amexServiceEstablishmentNumber }}
              </div>
            </div>

            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>PeopleSoft Details</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                Default Program Specific PeopleSoft Project/PJ
              </div>

              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.defaultPeopleSoftProjCode }}
              </div>
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                PeopleSoft Interface
              </div>

              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.extractFileFormatCode }}
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>Reconciliation ID and Prefix</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="assignsOrderReconciliationID"
                >Assigns Order ID/Reconciliation ID</label
              >
              <div class="col-lg-2 col-md-2 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="assignsOrderReconciliationID"
                  name="assignsOrderReconciliationID"
                  [checked]="assignsOrderReconciliationID"
                  disabled
                />
              </div>
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                Reconciliation ID Prefix
              </div>

              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.orderIdPrefix }}
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>Merchant Transaction Settings</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                Default Merchant Descriptor/Cardholder Statement Text
              </div>
              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.defaultMerchantDesc }}
              </div>

              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                Merchant Descriptor Contact Telephone Number
              </div>
              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.merchantPhoneNumber }}
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>AVS and CSV Rules</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="ignoreAVS"
                >Ignore AVS</label
              >
              <div class="col-lg-2 col-md-2 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="ignoreAVS"
                  name="ignoreAVS"
                  [checked]="ignoreAVS"
                  disabled
                />
              </div>
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="ignoreCSV"
                >Ignore Card Security Value Response (CSV)</label
              >
              <div class="col-lg-3 col-md-3 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="ignoreCSV"
                  name="ignoreCSV"
                  [checked]="ignoreCSV"
                  disabled
                />
              </div>
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                AVS Decline Flags
              </div>
              <div class="col-lg-2 col-md-2 col-5 text-start">
                {{ oscData?.avsDeclineCodeList }}
              </div>
              <div class="col-lg-3 col-md-3 col-7 text-end padding fontWeight">
                Card Security Value Decline Flags
              </div>
              <div class="col-lg-2 col-md-2 col-5 text-start padding">
                {{ oscData?.csvDeclineCodeList }}
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>Transaction Type</h4>
              </div>
            </div>
            <div class="row margin border-bottom">
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="sale"
                >Sale</label
              >
              <div class="col-lg-2 col-md-2 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="sale"
                  name="sale"
                  [checked]="sale"
                  disabled
                />
              </div>
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="overrideRefund"
                >Override Refund</label
              >
              <div class="col-lg-3 col-md-3 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="overrideRefund"
                  name="overrideRefund"
                  [checked]="overrideRefund"
                  disabled
                />
              </div>
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="refund"
                >Refund</label
              >
              <div class="col-lg-2 col-md-2 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="refund"
                  name="refund"
                  [checked]="refund"
                  disabled
                />
              </div>
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="chargeback"
                >Chargeback</label
              >
              <div class="col-lg-3 col-md-3 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="chargeback"
                  name="chargeback"
                  [checked]="chargeback"
                  disabled
                />
              </div>
            </div>
            <div class="row mt-4 mb-2">
              <div class="col-md-12">
                <h4>HOPs Available</h4>
              </div>
            </div>
            <div class="row margin">
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="cfhop"
                >CFHOP</label
              >
              <div class="col-lg-2 col-md-2 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="cfhop"
                  name="cfhop"
                  [checked]="oscData.cfhop"
                  disabled
                />
              </div>
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="ihop"
                >IHOP</label
              >
              <div class="col-lg-3 col-md-3 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="ihop"
                  name="ihop"
                  [checked]="oscData.ihop"
                  disabled
                />
              </div>
              <label
                class="col-lg-3 col-md-3 col-7 text-end padding fontWeight"
                for="vthop"
                >VTHOP</label
              >
              <div class="col-lg-3 col-md-3 col-5 float-start padding">
                <input
                  class="checkbox"
                  type="checkbox"
                  id="vthop"
                  name="vthop"
                  [checked]="oscData.vthop"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Conditionally Rendered-->
    <div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
      <div class="col-2"></div>
      <div class="col-7 alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order
        to access the page.
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</div>
