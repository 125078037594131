import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { OrderSearchComponent } from './order-search/order-search.component';
import { VirtualTerminalComponent } from './virtual-terminal/virtual-terminal.component';
import { OSCHopConfigComponent } from './oschop-config/oschop-config.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule, CurrencyPipe, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { CurrencyInputDirective } from './shared/currency-input.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { OrderSearchResultComponent } from './order-search-result/order-search-result.component';
import { OrderSearchDetailsComponent } from './order-search-result/order-search-details/order-search-details.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ActionLinksComponent } from './action-links/action-links.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {AuditLogComponent} from './action-links/audit-log/audit-log.component';
import {AuditMessageComponent} from './action-links/audit-message/audit-message.component';
import {LinkedOverrideRefundComponent} from './action-links/linked-override-refund/linked-override-refund.component';
import {RefundComponent} from './action-links/refund/refund.component';
import {ReverseChargebackComponent} from './action-links/manual-reverse-chargeback/manual-reverse-chargeback.component';
import {RetrievalComponent} from './action-links/request-for-retrieval/request-for-retrieval.component';
import {SearchFailsComponent} from './order-search-result/search-fail/search-fail.component';
import { UserDetailsComponent } from './user-management/user-details/user-details.component';
// import {OSCFormComponent} from './assign-business/osc-form/osc-form.component';
// import {AssignOSCComponent} from './assign-business/osc-form/assign-osc/assign-osc.component';
// import {AssignProgramComponent} from './assign-business/osc-form/assign-program/assign-program.component';
import {MasterGlobalConfigComponent} from './oschop-config/master-global-config/master-global-config.component';
import {GeneralBusinessConfigComponent} from './oschop-config/general-business-config/general-business-config.component';
import { MatFiterTableComponent } from './mat-fiter-table/mat-fiter-table.component';
import {MaterialModule} from './material.module';
import {MatLineItemSummaryDetailsComponent} from './mat-line-item-summary/mat-line-item-summary.component';
import {TransactionComponent} from '../app/order-search-result/order-search-details/transaction/transaction.component';
import {InternalExternalUserComponent} from './user-management/internal-external-list/internal-external-list.component';
import { TruncateTextPipe } from './pipes/truncatetext.pipe';
import { AuthenticationService } from './service/authentication.service';
import { LoadingIndicatorService } from './service/loading-indicator.service';
import { LoaderInterceptor } from './service/loading-interceptor.service';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import {TxtTruncateComponent} from "./txt-truncate/txt-truncate.component";
import {SortPipe} from "./pipes/sort.pipe";
import { MasterVirtualTerminalHOPConfigComponent } from './oschop-config/master-and-virtual-terminal-hop-config/master-and-virtual-terminal-hop-config.component';
import { OSCSpecificHOPConfigComponent } from './oschop-config/osc-specific-hop-config/osc-specific-hop-config.component';
import { MatDialogComponentComponent } from './mat-dialog-component/mat-dialog-component.component';
import { AddEditBusinessOscConfigComponent } from './oschop-config/add-edit-business-osc-config/add-edit-business-osc-config.component';
import { ViewBusinessOscConfigComponent } from './oschop-config/view-business-osc-config/view-business-osc-config.component';
import { HOPMasterConfigComponent } from './oschop-config/hop-master-configuration/hop-master-configuration.component';
import { VirtualTerminalConfigurationComponent } from './oschop-config/virtual-terminal-configuration/virtual-terminal-configuration.component';
import { OrderUiComponent } from './order-ui/order-ui.component';
import { RefundUiComponent } from './refund-ui/refund-ui.component';
import { SubscriptionUiComponent } from './subscription-ui/subscription-ui.component';
import { EditOSCSpecificHOPConfigComponent } from './oschop-config/edit-osc-specific-hop-config/edit-osc-specific-hop-config.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { VirtualTerminalAuditLogComponent } from './virtual-terminal-audit-log/virtual-terminal-audit-log.component';
import { VirtualTerminalAuditLogService } from './virtual-terminal-audit-log/virtual-terminal-audit-log.service';
import { LineItemsComponent } from './line-items/line-items.component';
import { ConfirmationComponent } from './virtual-terminal/confirmation/confirmation.component';
import { PaymentProcessingComponent } from './virtual-terminal/payment-processing/payment-processing.component';
import { ActionLinksModalComponent } from './action-links/action-links-modal/action-links-modal.component';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { ReconciliationDashboardComponent } from './dashboard/reconciliation-dashboard/reconciliation-dashboard.component';
import { ReconciliationDashboardByFileComponent } from './dashboard/reconciliation-dashboard-by-file/reconciliation-dashboard-by-file.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ReconciliationDashboardDetailsComponent } from './dashboard/reconciliation-dashboard-details/reconciliation-dashboard-details.component';
import { TokenInterceptorService } from './service/token-interceptor.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthService } from './service/auth-service';
import { ManageExternalUserComponent } from './user-management/manage-external-user/manage-external-user.component';
import { AssignRoleAndBusinessComponent } from './assign-role-and-business/assign-role-and-business.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AdminHomeComponent,
    OrderSearchComponent,
    VirtualTerminalComponent,
    OSCHopConfigComponent,
    UserManagementComponent,
    ManageExternalUserComponent,
    PaymentProcessingComponent,
    CurrencyInputDirective,
    ConfirmationComponent,
    OrderSearchResultComponent,
    OrderSearchDetailsComponent,
    NavBarComponent,
    ActionLinksComponent,
    LinkedOverrideRefundComponent,
    RefundComponent,
    ReverseChargebackComponent,
    RetrievalComponent,
    AuditLogComponent,
    AuditMessageComponent,
    SearchFailsComponent,
    UserDetailsComponent,
    AssignRoleAndBusinessComponent,
    ReconciliationDashboardComponent,
    ReconciliationDashboardByFileComponent,
    // OSCFormComponent,
    // AssignOSCComponent,
    // AssignProgramComponent,
    MasterGlobalConfigComponent,
    HOPMasterConfigComponent,
    MasterVirtualTerminalHOPConfigComponent,
    OSCSpecificHOPConfigComponent,
    GeneralBusinessConfigComponent,
    MatFiterTableComponent,
    MatLineItemSummaryDetailsComponent,
    TransactionComponent,
    InternalExternalUserComponent,
    TruncateTextPipe,
    AppLoaderComponent,
    TxtTruncateComponent,
    SortPipe,
    MatDialogComponentComponent,
    AddEditBusinessOscConfigComponent,
    ViewBusinessOscConfigComponent,
    VirtualTerminalConfigurationComponent,
    OrderUiComponent,
    RefundUiComponent,
    SubscriptionUiComponent,
    EditOSCSpecificHOPConfigComponent,
    ContactFormComponent,
    VirtualTerminalAuditLogComponent,
    LineItemsComponent,
    ConfirmDialogComponent,
    ReconciliationDashboardDetailsComponent,
    ActionLinksModalComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxPageScrollModule,
    // NgMultiSelectDropDownModule.forRoot(),
    MaterialModule,
    NgCircleProgressModule.forRoot({}),
    NgxChartsModule,
  ],
  providers: [
    CurrencyPipe,
    VirtualTerminalAuditLogService,
    AuthenticationService,
    LoadingIndicatorService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: 'America/New_York'}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
