<div id="dropdown-arrow-mat" *ngIf="loggedInUser?.oscDetails?.length > 0">
  <div class="breadcrumb row">
    <span>Global Configuration </span>
  </div>
  <div class="container-xl mt-4 page">
    <div class="col-12 heading">
      <h2>Global OSC Master and Virtual Terminal HOP Configuration</h2>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 col-9">
              <label>Select a Context
              </label>
              <mat-select class="form-select form-select-md" [(ngModel)]="dropdownValue" (selectionChange)="disableButton()">
                <mat-option disabled value="default" selected>Select a Context</mat-option>
                <mat-option value="CFHOP">CFHOP Master</mat-option>
                <mat-option value="IHOP">IHOP Master</mat-option>
                <mat-option value="VirtualTerminal">Virtual Terminal</mat-option>
              </mat-select>
            </div>
            <div class="col-md-4 col-3 button">
              <button class="btn btn-primary" [disabled]=disableButtonMode (click)="viewSelected()">Go</button>
            </div>

          </div>
        </div>
      </div>
    </div>
<ng-container *ngIf="selectedContextValue === 'CFHOP' || selectedContextValue === 'IHOP'">
    <app-hop-master-configuration [selectedContextValue]="selectedContextValue"></app-hop-master-configuration>
</ng-container>
  </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
  <div class="col-2"></div>
  <div class="col-8 alert alert-danger" role="alert">
    Originating System Code or Program Group need to be associated in order to
    access the page.
  </div>
  <div class="col-2"></div>
</div>
