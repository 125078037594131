import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MasterHOPDetails } from '../model/master-hop-details';
@Injectable({
  providedIn: 'root',
})
export class OSCHOPService {
  constructor(private http: HttpClient) {}

  getMasterHOPDetails(): Observable<MasterHOPDetails[]> {
    return this.http.get<MasterHOPDetails[]>(
      `${environment.ADMIN_API_HOSTNAME}/config/osc/masterhopdetails`
    );
  }

  saveMasterHOPDetails(masterHOPDetails:MasterHOPDetails) {
    return this.http.put(`${environment.ADMIN_API_HOSTNAME}/config/osc/masterhopdetails`, masterHOPDetails);
  }

  getAndStoreMasterHOPDetails() {
    this.getMasterHOPDetails().subscribe({
      next: (data) => {
        sessionStorage.setItem('masterHOPDetails', JSON.stringify(data));
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
      },
    });
  }
}
