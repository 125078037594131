import {Injectable} from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OrderSearchDetailsPdfService {

  datePipe: DatePipe;

  maxChars = 24;

  charSize = 9;

  constructor() {

    this.datePipe = new DatePipe('en-US');
  }

  createPdf(txData: any, orderState: string, lineItemsData: any, txDataList: any, relatedOrdersData: any) {
    const format = 'a4';
    const orientation = 'landscape';
    const initialX = 20;
    const initialY = 40;
    const doc = new jsPDF(orientation, 'pt', format, true);

    let data = this.createOrderDetails(doc, txData, initialX, initialY, orderState);
    data = this.createShipToCustomerInfo(doc, txData, initialX, data.y + 50);
    doc.addPage(format, orientation);
    data = this.createBillToCustomerInfo(doc, txData, initialX, initialY);
    doc.addPage(format, orientation);
    data = this.createPaymentInformation(doc, txData, initialX, initialY);

    doc.addPage(format, orientation);
    data = this.createOrderDetailInformation(doc, txData, initialX, initialY);
    data = this.createGLInformation(doc, txData, initialX, data.y + 50);

    doc.addPage(format, orientation);
    data = this.createProgramSpecificInformation(doc, txData, initialX, initialY);
    doc.addPage(format, orientation);
    data = this.create3DSPayerAuthentication(doc, txData, initialX, initialY);

    //doc.addPage(format, orientation);
    //this.createCres(doc, txData, initialX, initialY);

    // doc.addPage(format, orientation);
    //this.createCreq(doc, txData, initialX, initialY);

    doc.addPage(format, orientation);
    this.createLineItemSummaryAndDetails(doc, lineItemsData, txData, initialX, initialY);

    doc.addPage(format, orientation);
    this.createTransactionAndDetails(doc, txDataList, initialX, initialY);

    doc.addPage(format, orientation);
    this.createRelatedOrders(doc, relatedOrdersData, initialX, initialY);

    doc.save('OrderSearchDetails.pdf');
  }

  /*createCres(doc: jsPDF, txData: any, x: number, y: number) {

    const docFontSize = doc.getFontSize();
    doc.setFontSize(20);
    doc.text('CRES', x, y);

    doc.setFontSize(docFontSize);
    doc.text(txData?.authenticationDetail?.cres, x, y + 50);
  }*/

  /*createCreq(doc: jsPDF, txData: any, x: number, y: number) {
    const docFontSize = doc.getFontSize();
    doc.setFontSize(20);

    doc.text("CREQ", x, y);
    doc.setFontSize(docFontSize);
    doc.text(txData?.authenticationDetail?.creq, x, y + 50);
  }*/

  createOrderDetails(doc: jsPDF, txData: any, x: number, y: number, orderState: string) {


    const rowsList = [];
    const row1 = [{label: 'Originating System Code (OSC):', value: txData?.order?.originatingSystemCode},
                  {label: 'Order State:', value: orderState}];
    const row2 = [{label: 'Order ID:', value: txData?.order?.epmsOrderId},

                  {label: 'Transaction Attempt Count:', value: txData?.order?.epmsOrderId.startsWith('F')?"": txData?.transactionAttemptCount}];



    rowsList.push(row1);
    rowsList.push(row2);

    const data = this.createPanelData(doc, 'Order Details ', rowsList, x, y);
    return data;

  }

  createShipToCustomerInfo(doc: jsPDF, txData: any, x: number, y: number) {

    const rowsList = [];
    const row1 = [
      {label: 'Ship to First Name:', value: txData?.order?.shippingAddress?.firstName},
      {
        label: 'Ship to Last Name:',
        value: txData?.order?.shippingAddress?.lastName
      }];
    const row2 = [{label: 'Ship to Street Address 1:', value: txData?.order?.shippingAddress?.addressLine1}, {
      label: 'Ship to Street Address 2:',
      value: txData?.order?.shippingAddress?.addressLine2
    }];
    const row3 = [{label: 'Ship to State/ Province:', value: txData?.billingAddress?.region}, {
      label: 'Ship to Zip/Postal Code', value: txData?.billingAddress?.postalCode
    }];
    const row4 = [{label: 'Ship to email:', value: txData?.order?.shippingContact?.email}, {
      label: 'Ship to Phone #:', value: txData?.order?.shippingContact?.phoneNumber
    }];
    const row5 = [{label: 'Ship to Company:', value: txData?.order?.shippingAddress?.company}, {
      label: 'Ship to City:', value: txData?.order?.shippingAddress?.city
    }];
    const row6 = [{label: 'Ship to Country Code:', value: txData?.order?.shippingAddress?.country}
    ];

    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    rowsList.push(row4);
    rowsList.push(row5);
    rowsList.push(row6);

    return this.createPanelData(doc, 'Ship to Customer Information', rowsList, x, y);

  }

  createBillToCustomerInfo(doc: jsPDF, txData: any, x: number, y: number) {
    const rowsList = [];
    const row1 = [
      {label: 'Bill to First Name:', value: txData?.billingAddress?.firstName},
      {
        label: 'Bill to Last Name:',
        value: txData?.billingAddress?.lastName
      }];
    const row2 = [{label: 'Bill to State/Province:', value: txData?.billingAddress?.region}, {
      label: 'Bill to email:',
      value: txData?.billingContact?.email
    }];
    const row3 = [{label: 'Bill to Street Address 1:', value: txData?.billingAddress?.addressLine1}, {
      label: 'Bill to Zip/Postal Code:', value: txData?.billingAddress?.postalCode
    }];
    const row4 = [{label: 'Bill to Street Address 2:', value: txData?.billingAddress?.addressLine2}, {
      label: 'Bill to Phone #:', value: txData?.billingContact?.phoneNumber
    }];
    const row5 = [{label: 'Bill to Company:', value: txData?.billingAddress?.txData?.billingAddress?.company}, {
      label: 'Bill to City:', value: txData?.billingAddress?.city
    }];
    const row6 = [{label: 'Bill to Country Code:', value: txData?.billingAddress?.country}];

    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    rowsList.push(row4);
    rowsList.push(row5);
    rowsList.push(row6);

    return this.createPanelData(doc, 'Bill to Customer Information', rowsList, x, y);
  }

  // Payment Information
  createPaymentInformation(doc: jsPDF, txData: any, x: number, y: number) {
    const rowsList = [];
    const row1 = [
      {label: 'Order Amount :', value: txData?.amountDetail?.baseCurrencyAmount?.toFixed(2)},
      {label: 'Local Currency Amount:', value: txData?.amountDetail?.foreignCurrencyAmount?.toFixed(2)}
      ];
    const row2 = [{label: 'Settlement Amount:', value: txData?.amountDetail?.settlementAmount?.toFixed(2)}, {
      label: 'Settlement Currency:',
      value: txData?.amountDetail?.settlementCurrencyCode
    }];

    const row3 = [{label: 'FX rate:', value: txData?.amountDetail?.exchangeRate}, {
      label: 'EPMS Subscription ID:',
      value: txData?.subscriptionDetail?.subscriptionDetailId
    }];
    const row4 = [{label: 'Token:', value: txData?.amountDetail?.txData?.paymentMethodDetail?.cardToken}, {
      label: 'Masked Account Number:',
      value: txData?.paymentMethodDetail?.maskedAccountNumber
    }];
    const row5 = [{label: 'Local Currency :', value: txData?.amountDetail?.foreignCurrencyCode}, {
      label: 'Bank Account Type:',
      value: txData?.paymentMethodDetail?.accountType
    }];
    const row6 = [{label: 'Payment Method:', value: txData?.paymentMethodCode}, {
      label: 'Card Type:',
      value: txData?.paymentMethodDetail?.cardType
    }];
    const row7 = [{
      label: 'Card Expiration Date:',
      value: txData?.paymentMethodDetail?.expiryMonthYear
    }, {
      label: 'Bank ABA/Routing Number:',
      value: txData?.paymentMethodDetail?.routingNumber
    }];
    const row8 = [{label: 'Payment Type:', value: txData?.paymentTypeCode}, {
      label: 'PayPal Customer Bill to e-mail address:',
      value: txData?.billingContact?.email
    }];
    const row9 = [{
      label: 'Brand Name:', value: txData?.paymentMethodDetail?.brandName
    }, {
      label: 'TC-40 Flag:', value: txData?.fraudIndicator?.toString()
    }];
    const row10 = [{
      label: 'Bin:', value: txData?.paymentMethodDetail?.bin
    }, {
      label: 'Base Currency:', value: txData?.amountDetail?.baseCurrencyCode
    }];
    const row11 = [{
      label: 'PaymentJS Brand:', value: txData?.paymentMethodDetail?.jsCardType
    },{
      label: 'ARN/RRN', value: txData?.paymentMethodDetail?.arnRrnNo
    }];



    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    rowsList.push(row4);
    rowsList.push(row5);
    rowsList.push(row6);
    rowsList.push(row7);
    rowsList.push(row8);
    rowsList.push(row9);
    rowsList.push(row10);
    rowsList.push(row11);

    return this.createPanelData(doc, 'Payment Information', rowsList, x, y);
  }

  // Order Detail Information
  createOrderDetailInformation(doc: jsPDF, txData: any, x: number, y: number) {
    const rowsList = [];
    const row1 = [
      {label: 'Mode of Receipt:', value: txData?.order?.modeOfReceipt},
      {
        label: 'Last Reason Description:',
        value: txData?.pgmResponseMessage
      }];
    const row2 = [{label: 'ECI:', value: txData?.authenticationDetail?.eciValue}, {
      label: 'Last Reason Code:',
      value: txData?.pgmResponseCode
    }];
    const row3 = [{label: 'Chargeback Indicator:', value: txData?.order?.chargebackIndicator}, {
      label: 'Refund Indicator:',
      value: txData?.order?.refundIndicator
    }];
    const row4 = [{
      label: 'Sale Processed Date:',
      value: this.datePipe.transform(txData?.createdTimestamp, 'yyyy-MM-dd HH:mm:ss')
    }, {
      label: 'Fraud Score:',
      value: txData?.fraudDetail?.score
    }];
    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    rowsList.push(row4);
    return this.createPanelData(doc, 'Order Detail Information', rowsList, x, y);
  }

// GL Information
  createGLInformation(doc: jsPDF, txData: any, x: number, y: number) {
    const rowsList = [];
    const row1 = [
      {label: 'Project:', value: txData?.glProjectText},
      {
        label: 'Resource:',
        value: txData?.resourceText
      }];
    const row2 = [{label: 'Activity:', value: txData?.glActivityText}, {
      label: 'Product:',
      value: txData?.glProductText
    }];
    const row3 = [{
      label: 'JE Account:', value: txData?.order?.jeAccountNumber
    }];
    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    return this.createPanelData(doc, 'GL Information', rowsList, x, y);
  }

  createProgramSpecificInformation(doc: jsPDF, txData: any, x: number, y: number) {
    const rowsList = [];
    const row1 = [
      {label: 'Customer ID:', value: txData?.order?.customerID},
      {label: 'Program Defined Date:', value: txData?.programDefinedDate
      }];
    const row2 = [{label: 'Program Specific Key:',
      value: txData?.programSpecificKey},
      {label: 'Test Center Country:', value: txData?.order?.testCenterCountryCode
    }];
    const row3 = [{
      label: 'Fd Customer Type:', value: txData?.order?.fraudCustomerType
    },{
      label: 'No Match Vendor Supplied Order ID:', value: txData?.noMtchVndrSuppliedId}
      ];
    const row4 = [{
      label: 'OSC Session ID:', value: txData?.order?.oscSessionId}];

    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    rowsList.push(row4);
    return this.createPanelData(doc, 'Program Specific Information', rowsList, x, y);
  }

  // 3DS Payer Authentication
  create3DSPayerAuthentication(doc: jsPDF, txData: any, x: number, y: number) {
    const rowsList = [];
    const row1 = [
      {label: 'transaction id:', value: txData?.orderTransactionId},
      {
        label: 'Method notification status code:',
        value: txData?.authenticationDetail?.methodNotificationStatus
      }];
    const row2 = [{label: '3d secure transaction id:', value: txData?.authenticationDetail?.secure3dTransId}, {
      label: 'Merchant data:',
      value: txData?.authenticationDetail?.merchantData
    }];
    const row3 = [{label: '3d secure response code:', value: txData?.authenticationDetail?.secure3dResponseCode}, {
      label: '3ds version:',
      value: txData?.authenticationDetail?.version
    }];
    const row4 = [{label: '3ds session data:', value: txData?.authenticationDetail?.threeDSSessionData}, {
      label: 'eci value:',
      value: txData?.authenticationDetail?.eciValue
    }];

    const row5 = [{
      label: 'Authentication type code:', value: txData?.authenticationDetail?.authenticationType
    }];
    const row6 = [{
      label: 'Payer Authentication Request:',
      value: txData?.authenticationDetail?.payerAuthenticationRequest
    }, {
      label: 'Payer Authentication response data:',
      value: txData?.authenticationDetail?.payerAuthenticationResponse
    }];

    const row7 = [{
      label: 'creq:', value: txData?.authenticationDetail?.creq
    }, {
      label: 'cres:',
      value: txData?.authenticationDetail?.cres
    }];
    rowsList.push(row1);
    rowsList.push(row2);
    rowsList.push(row3);
    rowsList.push(row4);
    rowsList.push(row5);

    if (txData?.authenticationDetail?.payerAuthenticationRequest || txData?.authenticationDetail?.payerAuthenticationResponse) {
      rowsList.push(row6);
    } else {
      rowsList.push(row7);
    }

    return this.createPanelData(doc, '3DS Payer Authentication', rowsList, x, y);
  }

  // Line Item Summary and Details
  createLineItemSummaryAndDetails(doc: jsPDF, lineItemsData: any, txData: any, x: number, y: number) {
    // autoTable(doc, {html: '#tableData'});
    if (!lineItemsData || lineItemsData.length === 0) {
      lineItemsData = [];
      /*lineItemsData.push({
        lineNumber: 1,
        productSKU: 'productSKU1',
        productCode: 'productCode1',
        total: '23',
        transactionTypeCode: 'transactionTypeCode1',
        createdTimestamp: new Date(),
      });*/
    }
    lineItemsData = lineItemsData.map(item => {
      const cloneItem = {...item};
      cloneItem.createdTimestamp = this.datePipe.transform(txData.createdTimestamp, 'yyyy-MM-dd HH:mm:ss');
      cloneItem.transactionTypeCode = txData?.transactionTypeCode;

      return cloneItem;
    });

    const tableCols = [
      {header: 'Line Item # ', dataKey: 'lineNumber'},
      {header: 'Product SKU', dataKey: 'productSKU'},
      {header: 'Product Code', dataKey: 'productCode'},
      {header: 'Product Name', dataKey: 'productName'},
      {header: 'Product Description', dataKey: 'productDescription'},
      {header: 'Total Line item Amount ', dataKey: 'total'},
      {header: ' Transaction Type', dataKey: 'transactionTypeCode'},
      {header: ' Transaction Process Date', dataKey: 'createdTimestamp'},
      {header: 'Details', dataKey: 'details'},
    ];
    this.createTable('Line Item Summary and Details', doc, lineItemsData, tableCols, x, y);

  }


  // Transaction and Details
  createTransactionAndDetails(doc: jsPDF, txDataList: any, x: number, y: number) {
    const tableCols = [
      {header: 'Service ', dataKey: 'service'},
      {header: 'Created Date', dataKey: 'date'},
      {header: 'Reason/Response Code', dataKey: 'reasonCode'},
      {header: 'Status', dataKey: 'status'},
      {header: 'Currency', dataKey: 'currency'},
      {header: 'Amount', dataKey: 'amountUSD'},
      {header: 'Local Currency Amount', dataKey: 'amountLC'},
      {header: 'Details', dataKey: 'details'},
    ];
    this.createTable('Transaction and Details', doc, txDataList, tableCols, x, y);
  }

  // Related Orders
  createRelatedOrders(doc: jsPDF, relatedOrdersData: any, x: number, y: number) {
debugger;
    relatedOrdersData= relatedOrdersData.map(relOrd=>{
      relOrd={...relOrd};
      if(relOrd?.epmsOrderId?.startsWith('F')){
        relOrd.transactionAttemptCount='';
      };
      return relOrd;
    })

    const tableCols = [
      {header: 'Order Id ', dataKey: 'epmsOrderId'},
      {header: 'Transaction Attempt Count ', dataKey: 'transactionAttemptCount'},
      {header: 'Amount', dataKey: 'amount'},
      {header: 'Transaction Process Date', dataKey: 'date'},

    ];
    this.createTable('Related Orders', doc, relatedOrdersData, tableCols, x, y);
  }

  getMaxLabelAndValueWidth(doc: jsPDF, dataList: any) {
    const defaultLabelWidth = this.maxChars * this.charSize;
    const defaultValueWidth = this.maxChars * this.charSize;
    let maxLabelWidth = 0;
    let maxValueWidth = 0;

    let label;
    let value;
    for (const row of dataList) {
      for (const col of row) {
        label = (col.label || '') + '';
        value = (col.value || '') + '';
        // maxLabelWidth = Math.max(maxLabelWidth, doc.getTextWidth(col.label));
        // maxValueWidth = Math.max(maxValueWidth, doc.getTextWidth(col.value));
        maxLabelWidth = Math.max(maxLabelWidth, label.length * this.charSize);
        maxValueWidth = Math.max(maxValueWidth, value.length * this.charSize);
      }
    }
    maxLabelWidth = Math.round(maxLabelWidth);
    maxValueWidth = Math.round(maxValueWidth);
    return {
      maxLabelWidth: defaultLabelWidth,
      maxValueWidth: defaultValueWidth,
    };
  }

  wordWrap(str, maxWidth) {

    const newLineStr = '\r\n';

    let res = '';

    while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
        if (this.testWhite(str.charAt(i))) {
          res = res + [str.slice(0, i), newLineStr].join('');
          str = str.slice(i + 1);
          found = true;
          break;
        }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res += [str.slice(0, maxWidth), newLineStr].join('');
        str = str.slice(maxWidth);
      }

    }
    const finalStr = res + str;
    const wrapInfo = {
      wrapStr: finalStr,
      lineCnt: finalStr.split(newLineStr).length
    };
    return wrapInfo;
  }

  testWhite(x) {
    const white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  }

  createPanelData(doc: jsPDF, panelHeader: string, dataList: any, x: number, y: number) {


    const fontName = doc.getFont().fontName;
    const docFontSize = doc.getFontSize();
    doc.setFontSize(20);

    doc.text(panelHeader, x, y);
    doc.setFontSize(docFontSize);

    const {maxLabelWidth, maxValueWidth} = this.getMaxLabelAndValueWidth(doc, dataList);

    let rowX = x;
    let rowY = y + 50;

    let label;
    let value;
    for (const row of dataList) {
      let labelWordInfo;
      let valueWordInfo;
      for (const col of row) {
        labelWordInfo = null;
        valueWordInfo = null;
        // doc.setFont(fontName, "bold");
        doc.setFontSize(10);
        label = (col.label || '') + '';
        labelWordInfo = this.wordWrap(label, this.maxChars);
        label = labelWordInfo.wrapStr;
        doc.text(label, rowX, rowY);
        rowX = rowX + maxLabelWidth;

        doc.setFont(fontName, 'normal');
        value = (col.value || '') + '';
        valueWordInfo = this.wordWrap(value, this.maxChars);
        value = valueWordInfo.wrapStr;
        doc.text(value, rowX, rowY);
        rowX = rowX + maxValueWidth;
        doc.setFontSize(docFontSize);
      }
      rowX = x;
      let lineHeight = 0;
      if (labelWordInfo && valueWordInfo) {
        lineHeight = Math.max(labelWordInfo.lineCnt, valueWordInfo.lineCnt);
        if (lineHeight > 1) {
          lineHeight = lineHeight * this.charSize;
        }
      }
      rowY = rowY + lineHeight + 30;
    }

    // doc.line(rowX, rowY, 100, 20);
    return {x: rowX, y: rowY};
  }

  createTable(title: string, doc: jsPDF, taleData: any, tableCols: any, x: number, y: number) {
    const docFontSize = doc.getFontSize();
    doc.setFontSize(20);

    doc.text(title, x, y);
    doc.setFontSize(docFontSize);

    autoTable(doc, {
      body: taleData,
      startY: y + 50,
      columns: tableCols
    });
  }
}
