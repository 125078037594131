import { Component } from '@angular/core';

@Component({
  selector: 'app-oschop-config',
  templateUrl: './oschop-config.component.html',
  styleUrls: ['./oschop-config.component.scss']
})
export class OSCHopConfigComponent {

  constructor() { }

}
