<div id="reconciliation-by-file" class="mt-4 container-xl">
  <h2 class="pt-5">Reconciliation Dashboard by File</h2>
  <div class="row mt-4">
    <div class="col-md-12 pe-0">
      <div class="card grey">
        <div class="card-body">
          <div class="row">
            <div id="select-date" class="float-start pt-3 ps-5 col-lg-4 col-md-5 col-12" id="select-date">
              <mat-form-field appearance="outline" [formGroup]="range">
                <mat-label>Select Date Range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="startDate" placeholder="Processed Start date">
                  <input matEndDate formControlName="endDate" placeholder="Processed End date">
                </mat-date-range-input>
                <mat-hint><span class="date-hint">MM/DD/YYYY – MM/DD/YYYY</span></mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"
                    matTooltip="Select date range from start date to end date"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div id="select-file" class="col-lg-4 col-md-5 col-12 pt-3 ps-5">
              <mat-form-field style="width: 150px" appearance="outline">
                <mat-label>Select Reconciliation File</mat-label>
                <mat-select [formControl]="reconciliationType" required>
                  <mat-option *ngFor="let option of selectFileOptions" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="reconciliationType.hasError('required')">Please choose Reconciliation File Type</mat-error>
              </mat-form-field>
            </div>
            <div id="form-submit" class="col-lg-4 col-md-2 col-12 pt-4 ps-5 mb-3">
              <button class="btn btn-primary" (click)="getData()">Go</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 card grey mt-3">
          <mat-table [dataSource]="dataSource" class="table table-responsive table-bordered" matSort>

            <!-- File Name(s) -->
            <ng-container matColumnDef="reconciliationType">
              <th class="header" mat-header-cell *matHeaderCellDef> File Name(s)</th>
              <td mat-cell *matCellDef="let element"><a [routerLink]="['/reconciliation-dashboard-details']" class="link"
                                                        [queryParams]="{ reconciliationType: element.reconciliationType, date: getFileDate(element.fileDate), recordType: 'all', startDate: getFormatedDate(this.range.value.startDate), endDate: getFormatedDate(this.range.value.endDate),totalRecords:element.totalRecords}"> {{element.reconciliationType}}</a>
              </td>
            </ng-container>

            <!-- File Sent/Received -->
            <ng-container matColumnDef="fileProcessed">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> File Sent/Received </th>
              <td mat-cell *matCellDef="let element"> {{element.fileProcessed ? 'yes' : 'no'}} </td>
            </ng-container>

            <!-- Start Date/Time -->
            <ng-container matColumnDef="fileDate">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> File Date </th>
              <td mat-cell *matCellDef="let element"> {{element.fileDate}} </td>
            </ng-container>

            <!-- End Date/Time -->
            <ng-container matColumnDef="processedDate">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> Processed Date </th>
              <td mat-cell *matCellDef="let element"> {{element.processedDate}} </td>
            </ng-container>

            <!-- # of Records -->
            <ng-container matColumnDef="totalRecords">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> # of Records </th>
              <td mat-cell *matCellDef="let element"><a class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                        [queryParams]="{ reconciliationType: element.reconciliationType, date: getFileDate(element.fileDate), processedDate: getFileDate(element.processedDate), recordType: 'all', startDate: getFormatedDate(this.range.value.startDate), endDate: getFormatedDate(this.range.value.endDate),totalRecords:element.totalRecords }"> {{element.totalRecords}} </a></td>
            </ng-container>

            <!-- # of Errors -->
            <ng-container matColumnDef="errorRecords">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> # of Errors </th>
              <td mat-cell *matCellDef="let element"><a class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                        [queryParams]="{ reconciliationType: element.reconciliationType, date: getFileDate(element.fileDate), processedDate: getFileDate(element.processedDate), recordType: 'error', startDate: getFormatedDate(this.range.value.startDate), endDate: getFormatedDate(this.range.value.endDate),totalRecords:element.errorRecords }"> {{element.errorRecords}}</a> </td>
            </ng-container>

            <!-- # of Sucessfull -->
            <ng-container matColumnDef="successRecords">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> # of Successful </th>
              <td mat-cell *matCellDef="let element"><a class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                        [queryParams]="{ reconciliationType: element.reconciliationType, date: getFileDate(element.fileDate), processedDate: getFileDate(element.processedDate), recordType: 'success', startDate: getFormatedDate(this.range.value.startDate), endDate: getFormatedDate(this.range.value.endDate),totalRecords:element.successRecords }"> {{element.successRecords}}</a> </td>
            </ng-container>

            <!-- # of No Match -->
            <ng-container matColumnDef="noMatchRecords">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> # of No Match</th>
              <td mat-cell *matCellDef="let element"><a class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                        [queryParams]="{ reconciliationType: element.reconciliationType, date: getFileDate(element.fileDate), processedDate: getFileDate(element.processedDate), recordType: 'nomatch', startDate: getFormatedDate(this.range.value.startDate), endDate: getFormatedDate(this.range.value.endDate),totalRecords:element.noMatchRecords }"> {{element.noMatchRecords}}</a>
              </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
              <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> Status (R/Y/G) </th>
              <td mat-cell [class]="element.status + ' status'" *matCellDef="let element">
                <div class="recon-table-status">{{element.status && element.status.charAt(0).toUpperCase()}}</div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </mat-table>
          <ng-container *ngIf="dataSource.data.length === 0">
            <div class="p-3 fs-6 fw-bold">Select or Modify Dates and Reconciliation Type.</div>
        </ng-container>
          <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>
      </div>

    </div>
  </div>
