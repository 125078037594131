<div class="mt-4 container-xl" *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="!isSubscription; else subscriptionHeader">
                <h1 class="padding">Refund Form</h1>
              </div>
              <ng-template #subscriptionHeader>
                <h1 class="padding">Subscription Form</h1>
              </ng-template>
            <p></p>
            <div class="card">
                <div id="dropdown-arrow-mat" class="card-body">
                    <form name="refundForm" [formGroup]="refundFormGroup" class="row d-flex align-items-center">
                        <div class="col-md-6 mb-3">
                            <label for="originatingSystemCode"><span class="required-asterisk">*</span> Originating System Code</label>
                            <mat-select placeholder="Select..." id="originatingSystemCode" class="form-select form-select-md" formControlName="originatingSystemCode" required>
                                <mat-option value="null" disabled>Select...</mat-option>
                                <ng-container *ngFor="let oscCode of oscConfigCodes">
                                    <mat-option [id]="oscCode" [value]="oscCode">{{oscCode}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="oscSessionId"><span *ngIf="isSubscription" class="required-asterisk">*</span> OSC Session Id</label>
                            <input class="form-control form-control-md" formControlName="oscSessionId" [required]="isSubscription" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="epmsOrderId"><span *ngIf="!isSubscription" class="required-asterisk">*</span> Order Id</label>
                            <input class="form-control form-control-md" formControlName="epmsOrderId" [required]="!isSubscription" />
                        </div>
                        <div class="col-md-6 mb-3" *ngIf="isSubscription">
                            <label for="epmsSubscriptionId"><span class="required-asterisk">*</span> Subscription Id</label>
                            <input class="form-control form-control-md" formControlName="epmsSubscriptionId" required/>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="orderAmount"> Amount</label>
                            <input class="form-control form-control-md" formControlName="orderAmount" />
                        </div>
                        <div class="col-md-12">
                            <h4>Program Application Details: </h4>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="glProjectText"><span class="required-asterisk">*</span> Project</label>
                            <input class="form-control form-control-md" formControlName="glProjectText" required />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="resourceText"> Resource Text</label>
                            <input class="form-control form-control-md" formControlName="resourceText" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="glProductText"> GL Product</label>
                            <input class="form-control form-control-md" formControlName="glProductText" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="glActivityText"> GL Activity</label>
                            <input class="form-control form-control-md" formControlName="glActivityText" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="programSpecificKey"> Program Specific Key</label>
                            <input class="form-control form-control-md" formControlName="programSpecificKey" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="serviceCode"> Service Code</label>
                            <input class="form-control form-control-md" formControlName="serviceCode" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="programDefinedDate"> Program Defined Date</label>
                            <input class="form-control form-control-md" formControlName="programDefinedDate" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="merchantDescriptor"> Merchant Descriptor</label>
                            <input class="form-control form-control-md" formControlName="merchantDescriptor" />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="modeOfReceipt"> Mode Of Receipt</label>
                            <mat-select placeholder="Select..." id="modeOfReceipt" formControlName="modeOfReceipt" class="form-select form-select-md">
                                <mat-option id="Internet" value="N">Internet</mat-option>
                                <mat-option id="Fax" value="F">Fax</mat-option>
                                <mat-option id="InPerson" value="I">In Person</mat-option>
                                <mat-option id="Mail" value="M">Mail</mat-option>
                                <mat-option id="Telephone" value="T">Telephone</mat-option>
                                <mat-option id="VRS" value="V">VRS</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-12">
                            <h4>Line Item Details: </h4>
                        </div>
                        <div class="col-md-12">
                            <mat-card class="mat-elevation-z8">
                                <mat-card-actions>
                                    <button (click)="addLineItem()" [disabled]="!allowOrderItems">
                                        <mat-icon aria-hidden="true" aria-label="Add Lineitem icon">add</mat-icon>
                                    </button>
                                </mat-card-actions>
                                <mat-table #table class="mat-elevation-z0" [dataSource]="lineItemDataSource"
                                    [trackBy]="trackRows">
                                    <!-- Action Column -->
                                    <ng-container matColumnDef="action">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <button (click)="deleteLineItem(i)">
                                                    <mat-icon aria-hidden="true" aria-label="Delete Lineitem icon">delete</mat-icon>
                                                </button>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Product Column -->
                                    <ng-container matColumnDef="productSKU">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label>Product SKU</mat-label>
                                                    <input matInput placeholder="Product SKU" id="productSku_{{i}}"
                                                        name="productSku_{{i}}" formControlName="productSKU" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="productName">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Product Name</mat-label>
                                                    <input matInput placeholder="Product Name" id="productName{{i}}"
                                                        name="productName{{i}}" formControlName="productName" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="productCode">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Product Code</mat-label>
                                                    <input matInput placeholder="Product Code" id="productCode{{i}}"
                                                        name="productCode{{i}}" formControlName="productCode" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Quantity</mat-label>
                                                    <input matInput placeholder="Quantity" id="quantity{{i}}"
                                                        name="quantity{{i}}" formControlName="quantity" type="number"/>
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="unitPrice">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label><span class="required-asterisk">*</span> Unit Price</mat-label>
                                                    <input matInput placeholder="Unit Price" id="unitPrice{{i}}" type="number"
                                                        name="unitPrice{{i}}" formControlName="unitPrice" />
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="taxAmount">
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <ng-container [formGroup]="row">
                                                <mat-form-field>
                                                    <mat-label> Tax Amount</mat-label>
                                                    <input matInput placeholder="Tax Amount" id="taxAmount{{i}}"
                                                        name="taxAmount{{i}}" formControlName="taxAmount" type="number"/>
                                                </mat-form-field>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: lineItemDetailColumns"></mat-row>
                                </mat-table>
                            </mat-card>
                        </div>
                        <div class="col-md-12" *ngIf="isSubscription">
                            <h4>Shipping Information: </h4>
                            <app-contact-form formControlName="shippingInfo"></app-contact-form>
                        </div>
                        <div class="col-md-12">
                            <br>
                            <button class="btn btn-primary me-3" type="submit" [disabled]="enableDisableSubmit()"
                                (click)="onSubmit(refundResponseTemplate)">Submit</button>
                            <button class="btn btn-primary btn-function" (click)="resetCvaRefundForm()">Clear</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8  alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to access the page.
    </div>
    <div class="col-2"></div>
</div>

<!-- raw detail modal -->
<ng-template #refundResponseTemplate>
    <div class="modal-header d-inline" align="end">
        <button mat-icon-button class="close-button" (click)="modalRef.hide()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
    <div class="modal-body" style="white-space: break-spaces;overflow-wrap: break-word;">
        {{refundResponseDetails|json}}
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-function" [cdkCopyToClipboard]="refundResponseDetails|json">Copy to
            clipboard</button>
        <button class="btn btn-primary btn-function" (click)="modalRef.hide()">Exit</button>
    </div>
</ng-template>
