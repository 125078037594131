<form [formGroup]="overrideRefund">
  <div class="row">
    <div class="mb-3 col-md-4">
    <div class="refund-lbl">
      <b  >Amount available to be refunded : </b> {{refundAmt.toFixed(2)}}
    </div>
    </div>
  </div>
  <div class="row">
    <div class="mb-3 col-md-4">
      <label for="amount"> Amount</label>
      <input id="amount" name="amount" class="form-control form-control-md" formControlName="amount" />
    </div>
    <div class="mb-3 col-md-4">
      <label for="product">Product</label>
      <input id="product" name="product" class="form-control form-control-md" formControlName="product" />
    </div>
    <div class="mb-3 col-md-4">
      <label for="projectText"><span class="required-asterisk">*</span> Project</label>
      <input id="projectText" name="projectText" class="form-control form-control-md" formControlName="projectText" />
    </div>
  </div>
  <div class="row">
    <div class="mb-3 col-md-4">
      <label for="activity">Activity</label>
      <input id="activity" name="activity" class="form-control form-control-md" formControlName="activity" />
    </div>
    <div class="mb-3 col-md-4">
      <label for="journalAccNo"><span class="required-asterisk">*</span> Journal Entry Acct #
      </label>
      <input id="journalAccNo" name="journalAccNo" class="form-control form-control-md" formControlName="journalAccNo" />
    </div>
    <div class="mb-3 col-md-4">
      <label for="resource">Resource</label>
      <input id="resource" name="resource" class="form-control form-control-md" formControlName="resource" />
    </div>
  </div>
  <div class="row">
    <div class="mb-3 col-md-4">
      <label for="merchantDescriptor"><span class="required-asterisk">*</span> Billing Statement Text</label>
      <input id="merchantDescriptor" name="merchantDescriptor" class="form-control form-control-md"
        formControlName="merchantDescriptor" />
    </div>
    <div class="mb-3 col-md-4">
      <label for="programSpecificKey">Program Specific Key</label>
      <input id="programSpecificKey" name="programSpecificKey" class="form-control form-control-md"
        formControlName="programSpecificKey" />
    </div>
    <div class="mb-3 col-md-4">
      <label for="customerID">Customer ID</label>
      <input id="customerID" name="customerID" class="form-control form-control-md" formControlName="customerID" />
    </div>
  </div>
  <app-line-items (lineItems)="lineItemData($event)" [allowOrderItems]="allowOrderItems"></app-line-items>
  <div class="row col-md-12">
    <button class="col-md-1 mt-2 btn btn-primary btn-function" (click)="parentRef.close()">Exit</button>
    <button class="col-md-2 mt-2 ms-2 btn btn-primary btn-function" (click)="resetOverrideRefundForm()">Reset
      Form</button>
    <button class="col-md-3 mt-2 ms-2 btn btn-primary" [disabled]="isDisabledOverrdieRefund()"
      (click)="confirmLinkOverrideRefund()">Process Link Override Refund</button>
    </div>
</form>
