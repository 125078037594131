import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from 'src/app/model/user';
import {SharedService} from 'src/app/service/shared.service';
import {GlobalConfigService} from "../../service/global-config.service";
import {OschopInfo} from "../../model/oschop-info";

@Component({
  selector: 'view-business-osc-config',
  templateUrl: './view-business-osc-config.component.html',
  styleUrls: ['./view-business-osc-config.component.scss'],
})
export class ViewBusinessOscConfigComponent implements OnInit {
  loggedInUser: User;
  ignoreAVS = true;
  ignoreCSV = true;
  assignsOrderReconciliationID = true;
  sale = true;
  refund = true;
  overrideRefund = true;
  chargeback = true;
  oscData: any;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private globalConfigService: GlobalConfigService
  ) {
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.oscData = JSON.parse(sessionStorage.getItem('oscConfigDetails'))
        this.sale = this.oscData.allowSale;
        this.refund = this.oscData.allowRefund;
        this.overrideRefund = this.oscData.allowOverrideRefund;
        this.chargeback = this.oscData.allowChargebacks;
        this.ignoreAVS = this.oscData.ignoreAvsDeclineCodes;
        this.ignoreCSV = this.oscData.ignoreCsvDeclineCodes;
        this.assignsOrderReconciliationID = this.oscData.orderReconIdGeneration;
        this.loadOscHopDetails();
      }
    });
  }

  loadOscHopDetails() {
    const obs = this.globalConfigService.getOscHops(this.oscData.oscCode);
    obs.subscribe((data: OschopInfo[]) => {
      for (const oschopInfo of data) {
        if (oschopInfo.hopId.toUpperCase() === 'CFHOP') {
          this.oscData.cfhop = true;
        } else if (oschopInfo.hopId.toUpperCase() === 'IHOP') {
          this.oscData.ihop = true;
        } else if (oschopInfo.hopId.toUpperCase() === 'VTHOP') {
          this.oscData.vthop = true;
        }
      }
      sessionStorage.setItem('oscConfigDetails', JSON.stringify(this.oscData));
    })
  }

  exit() {
    this.router.navigate(['general-business-config']);
  }

  edit() {
    const id = this.oscData.oscCode;
    this.router.navigate(['add-edit-business-osc-config', id]);
  }
}
