export const payments_types = [
  {
    name: 'Credit Card',
    class: 'col-md-12 col-lg-5',
    virtual_term_class: 'col-md-8',
    items: [
      {
        id: 'ccPaymentEnabled',
        label: 'Credit Card',
        class: 'col-lg-5 col-md-3',
        subCategories: [
          {
            id: 'visaEnabled',
            label: 'Visa',
            requireAndDisplay: 'Require and display CVV2',
            requireAndDisplayID: 'visaCSCRequired'
          },
          {
            id: 'masterCardEnabled',
            label: 'MasterCard',
            requireAndDisplay: 'Require and display CVC2',
            requireAndDisplayID: 'mastercardCSCRequired'
          },
          {
            id: 'amexEnabled',
            label: 'American Express',
            requireAndDisplay: 'Require and display CID',
            requireAndDisplayID: 'amexCSCRequired'

          },
          {
            id: 'discoverEnabled',
            label: 'Discover',
            requireAndDisplay: 'Require and display CID',
            requireAndDisplayID: 'discoveryCSCRequired'
          },
          {
            id: 'unionPayEnabled',
            label: 'China UnionPay',
            requireAndDisplay: 'Require and display CID',
            requireAndDisplayID: 'unionPayCSCRequired'
          },
          {
            id: 'dinersClubEnabled',
            label: 'Diners Club',
            requireAndDisplay: 'Require and display CID',
            requireAndDisplayID: 'dinersClubCSCRequired'
          },
          {
            id: 'jcbEnabled',
            label: 'JCB',
            requireAndDisplay: 'Require and display CVN',
            requireAndDisplayID: 'jcbCSCRequired'
          },
          {
            id: 'rupayGlobalEnabled',
            label: 'RuPay Global',
            requireAndDisplay: 'Require and display CID',
            requireAndDisplayID: 'rupayGlobalCSCRequired'
          },
        ],
      },
    ],
  },
  {
    name: '3DS',
    class: 'col-md-5 col-lg-3',
    virtual_term_class: 'col-md-8',
    items: [
      {
        id: 'threeDSEnabled',
        label: 'Enable 3DS',
        class: 'col-12',
        subCategories: [
          {
            id: 'visa3dsEnabled',
            label: 'Visa 3DS'
          },
          {
            id: 'mc3dsEnabled',
            label: 'MasterCard 3DS'
          },
          {
            id: 'amex3dsEnabled',
            label: 'American Express 3DS'

          },
          {
            id: 'discover3dsEnabled',
            label: 'Discover 3DS'
          },
          {
            id: 'unionPay3dsEnabled',
            label: 'China UnionPay 3DS'
          },
          {
            id: 'diners3dsEnabled',
            label: 'Diners Club 3DS'
          },
          {
            id: 'jcb3dsEnabled',
            label: 'JCB 3DS'
          },
          {
            id: 'rupay3dsEnabled',
            label: 'RuPay Global 3DS'
          },
        ],
      },
    ],
  },
  {
    name: 'PayPal',
    class: 'col-md-4 col-lg-2',
    virtualTerminal: 'disabled',
    items: [
      {
        id: 'paypalEnabled',
        label: 'PayPal/Pay Later',
      }
    ],
  },
  {
    name: 'Alipay',
    class: 'col-md-3 col-lg-1',
    virtualTerminal: 'disabled',
    items: [
      {
        id: 'alipayEnabled',
        label: 'Alipay',
      },
    ],
  }
];

export const fraud_3ds_currency = [
  {
    name: 'Fraud Detect',
    id: 'fraudDetectEnabled',
    label: 'Enable Fraud Detect',
  },
  {
    name: 'Dynamic Currency Conversion',
    id: 'dccEnabled',
    label: 'Enable Dynamic Currency Conversion',
  }
];

export const fraud_detect_3ds_currency = [
  {
    name: 'Dynamic Currency Conversion',
    id: 'dccEnabled',
    label: 'Enable Dynamic Currency Conversion',
  }
];

export const avs_cvs_rules = [
  {
    name: 'AVS, CVN, CSV and CID Rules',
    class: 'col-md-8',
    items: [
      {
        id: 'ignoreAvsDeclineCodes',
        label: 'Ignore AVS',
        inputId: 'avsDeclineCodeList',
        inputLabel: 'AVS Decline Flags',
      },
      {
        id: 'ignoreCsvDeclineCodes',
        label: 'Ignore CVN/CSV/CID',
        inputId: 'csvDeclineCodeList',
        inputLabel: 'CVN Decline Flags',
      },
    ],
  },
  {
    name: 'Transaction Settings',
    class: 'col-md-4',
    items: [
      {
        id: 'allowAuthorizationOnlyRequest',
        label: 'Allow Authorization Only',
      },
    ],
  },
];

export const billing_information = [
  {
    name: 'Billing Information',
    class: 'col-md-6',
    items: [
      {
        id: 'displayBillingName',
        label: 'Billing Name Information Display/Require',
      },
      {
        id: 'displayBillingAddress',
        label: 'Billing Address Information Display/Require ',
      },
    ],
  },
  {
    name: 'Shipping Information',
    class: 'col-md-6',
    items: [
      {
        id: 'displayShpngAsBillngNam',
        label: 'Show Use Shipping Name For Billing Name',
      },
      {
        id: 'displayShippingAsBillingAddr',
        label: 'Show Use Shipping Address for Billing Address',
      },
      {
        id: 'displayShippingFlds',
        label: 'Shipping Fields Display/Require',
      },
      {
        id: 'editShippingFields',
        label: 'Shipping Fields Edit',
      },
    ],
  },
];

export const attemps_timeout_options = [
  {
    name: 'Attempts and Timeout Options',
    class: 'col-md-6',
    items: [
      {
        id: 'maxRejectAtmptCount',
        label: 'Maximum Reject Attempts',
      },
      {
        id: 'sessionTimeoutMnts',
        label: 'Session Timeout (Minutes)',
      },
    ],
  },
];

export const addresses_and__options = [
  {
    name: 'Addresses and Options',
    inputs: [
      {
        items: [
          {
            id: 'merchantPostUrl',
            label: 'Merchant Post URL',
            class: 'col-md-8',
          },
        ],
      },
      {
        items: [
          {
            id: 'successUrl',
            label: 'Success URL',
            class: 'col-md-8',
          },
        ],
      },
      {
        items: [
          {
            id: 'maxRejectAtmptCount',
            label: 'Maximum Reject Attempts',
            class: 'col-md-3',
          },
          {
            id: 'rejectUrl',
            label: 'Reject URL',
            class: 'col-md-8',
          },
        ],
      },
      {
        items: [
          {
            id: 'errorUrl',
            label: 'Error URL',
            class: 'col-md-8',
          },
        ],
      },
      {
        items: [
          {
            id: 'sessionTimeoutMnts',
            label: 'Session Timeout (Minutes)',
            class: 'col-md-3',
          },
          {
            id: 'timeoutUrl',
            label: 'Timeout URL',
            class: 'col-md-8',
          },
        ],
      },
    ],
  },
];

export const merchant_defined_fields = [
  {
    seqNo: 1,
    displayLabel: 'Merchant Defined Field # 1 Display',
    displayLabelId: 'displayMerchantDefinedField_1',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_1',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_1',
    inputLabel: 'Merchant Defined Field # 1 Label',
    inputLabelId: 'merchantDefinedFieldName_1',
  },
  {
    seqNo: 2,
    displayLabel: 'Merchant Defined Field # 2 Display',
    displayLabelId: 'displayMerchantDefinedField_2',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_2',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_2',
    inputLabel: 'Merchant Defined Field # 2 Label',
    inputLabelId: 'merchantDefinedFieldName_2',
  },
  {
    seqNo: 3,
    displayLabel: 'Merchant Defined Field # 3 Display',
    displayLabelId: 'displayMerchantDefinedField_3',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_3',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_3',
    inputLabel: 'Merchant Defined Field # 3 Label',
    inputLabelId: 'merchantDefinedFieldName_3',
  },
  {
    seqNo: 4,
    displayLabel: 'Merchant Defined Field # 4 Display',
    displayLabelId: 'displayMerchantDefinedField_4',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_4',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_4',
    inputLabel: 'Merchant Defined Field # 4 Label',
    inputLabelId: 'merchantDefinedFieldName_4',
  },
  {
    seqNo: 5,
    displayLabel: 'Merchant Defined Field # 5 Display',
    displayLabelId: 'displayMerchantDefinedField_5',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_5',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_5',
    inputLabel: 'Merchant Defined Field # 5 Label',
    inputLabelId: 'merchantDefinedFieldName_5',
  },
  {
    seqNo: 6,
    displayLabel: 'Merchant Defined Field # 6 Display',
    displayLabelId: 'displayMerchantDefinedField_6',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_6',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_6',
    inputLabel: 'Merchant Defined Field # 6 Label',
    inputLabelId: 'merchantDefinedFieldName_6',
  },
  {
    seqNo: 7,
    displayLabel: 'Merchant Defined Field # 7 Display',
    displayLabelId: 'displayMerchantDefinedField_7',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_7',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_7',
    inputLabel: 'Merchant Defined Field # 7 Label',
    inputLabelId: 'merchantDefinedFieldName_7',
  },
  {
    seqNo: 8,
    displayLabel: 'Merchant Defined Field # 8 Display',
    displayLabelId: 'displayMerchantDefinedField_8',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_8',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_8',
    inputLabel: 'Merchant Defined Field # 8 Label',
    inputLabelId: 'merchantDefinedFieldName_8',
  },
  {
    seqNo: 9,
    displayLabel: 'Merchant Defined Field # 9 Display',
    displayLabelId: 'displayMerchantDefinedField_9',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_9',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_9',
    inputLabel: 'Merchant Defined Field # 9 Label',
    inputLabelId: 'merchantDefinedFieldName_9',
  },
  {
    seqNo: 10,
    displayLabel: 'Merchant Defined Field # 10 Display',
    displayLabelId: 'displayMerchantDefinedField_10',
    fieldLabel: 'Required',
    fieldLabelId: 'merchantDefinedFieldRequired_10',
    allowEditLabel: 'Allow Edit',
    allowEditLabelId: 'editMerchantDefinedField_10',
    inputLabel: 'Merchant Defined Field # 10 Label',
    inputLabelId: 'merchantDefinedFieldName_10',
  },
];

export const PaymentMethodsHOPConfig = [
  { value: 'ALIPAY', text: 'Alipay' },
  { value: 'Amex', text: 'American Express' },
  { value: 'BHARATQR', text: 'Bharat QR' },
  { value: 'CREDITCARD', text: 'Credit Card' },
  { value: 'DEBITCARD', text: 'Debit Card' },
  { value: 'Diners Club', text: 'Diners Club International' },
  { value: 'Discover', text: 'Discover' },
  { value: 'Bank Account', text: 'Bank Account' },
  { value: 'RuPay Global', text: 'RuPay Global' },
  { value: 'JCB', text: 'JCB' },
  { value: 'MasterCard', text: 'Master Card' },
  { value: 'NETBANKING', text: 'Net Banking' },
  { value: 'Other', text: 'Other' },
  { value: 'Paypal', text: 'PayPal' },
  { value: 'UPI', text: 'UPI' },
  { value: 'UnionPay', text: 'UnionPay' },
  { value: 'Visa', text: 'Visa' },
  { value: 'WALLET', text: 'Wallet' }
]
