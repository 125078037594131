import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingIndicatorService } from './loading-indicator.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoadingIndicatorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(request);
    this.loaderService.isLoading.next(true);
    return next.handle(request).pipe(
        finalize(
            () => {
                this.loaderService.isLoading.next(false);
            }
        )
    );
  }
}