import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Profile } from '../model/profile-permissions';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private userInfo = new BehaviorSubject<User>(null);
  private profileInfo = new BehaviorSubject<Profile>(new Profile());
  private resetLineItems = new BehaviorSubject<boolean>(false);
  private orderUiError = new BehaviorSubject<any>(null);
  private vtHopOscConfigCodes = new BehaviorSubject<string[]>(null);

  readonly userInfo$ = this.userInfo.asObservable();
  readonly profileInfo$ = this.profileInfo.asObservable();
  readonly resetLineItems$ = this.resetLineItems.asObservable();
  readonly orderUiError$ = this.orderUiError.asObservable();
  readonly vtHopOscConfigCodes$ = this.vtHopOscConfigCodes.asObservable();

  public onLoggedInUser(user: User) {
    this.userInfo.next(user);
    if (user && user.role) {
      this.profileInfo.next(new Profile(user.role.capabilities));
    }
  }

  public onProfilePermission(profile: Profile) {
    this.profileInfo.next(profile);
  }

  public vtHopOscConfigCodesData(codes: string[]) {
    this.vtHopOscConfigCodes.next(codes);
  }

  public onResetLineItems(isReset: boolean) {
    this.resetLineItems.next(isReset);
  }

  public onOrderUiError(error: any) {
    this.orderUiError.next(error);
  }
}
