import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderService} from '../../service/order.service';
import {CountryRegionService} from '../../service/country-region.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogModel,} from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/model/user';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss'],
})
export class PaymentProcessingComponent implements OnInit {
  step: any = 1;
  selectedRegion = '';
  selectedProvince = '';
  selectedCountry = '';
  countryList: any;
  regionList: any;
  provinceList: any;
  processForm: FormGroup;
  formSubmitted = false;
  isLineItemValid = false;
  lineItems: Array<any>;
  userInfo: User;

  @Input() virtualTerminalData;
  @Input() processType: string;
  isSale: boolean;

  allowOrderItems=true;

  constructor(
    private orderService: OrderService,
    private countryRegionService: CountryRegionService,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {}

  @Input()
  set txData(txData) {
    if (txData) {
      try {
        const paymentDetailsForm = this.processForm.get('paymentDetails');
        const amountDetailResp = txData['amountDetail'];
        const orderResp = txData['order'];
        const shippingInfo = this.processForm.get('shippingInfo');

        if (amountDetailResp) {
          paymentDetailsForm
            .get('amount')
            .setValue(amountDetailResp['baseCurrencyAmount'].toFixed(2));
        }
        if (orderResp) {
          const shippingAddress = orderResp['shippingAddress'];
          const shippingContact = orderResp['shippingContact'] || {};
          paymentDetailsForm
            .get('modeOfReceipt')
            .setValue(orderResp['modeOfReceipt']);

          shippingInfo.get('firstName').setValue(shippingAddress['firstName']);
          shippingInfo.get('lastName').setValue(shippingAddress['lastName']);

          shippingInfo
            .get('streetAddress1')
            .setValue(shippingAddress['addressLine1']);
          shippingInfo
            .get('streetAddress2')
            .setValue(shippingAddress['addressLine2']);

          shippingInfo.get('city').setValue(shippingAddress['city']);
          shippingInfo.get('country').setValue(shippingAddress['country']);
          this.selectedCountry = shippingAddress['country'];

          this.setState(shippingInfo, shippingAddress);

          shippingInfo.get('company').setValue(shippingAddress['company']);
          shippingInfo.get('phone').setValue(shippingContact['phoneNumber']);
          shippingInfo.get('project').setValue(txData['glProjectText']);
          shippingInfo.get('product').setValue(txData['glProductText']);
          shippingInfo.get('email').setValue(shippingContact['email']);
          if (this.isSale) {
            shippingInfo
              .get('journal-entry-num')
              .setValue(sessionStorage.getItem('journal-entry-num'));
          } else {
            shippingInfo
              .get('journal-entry-num')
              .setValue(orderResp['jeAccountNumber']);
          }
          shippingInfo.get('activity').setValue(txData['glActivityText']);
          shippingInfo.get('resource').setValue(txData['resourceText']);
          shippingInfo.get('customerID').setValue(txData['customerID']);
          this.setPostalCode(shippingInfo, shippingAddress);
        }
        if (this.isSale) {
          paymentDetailsForm
            .get('billingStatementText')
            .setValue(sessionStorage.getItem('billingStatementText'));
        } else {
          paymentDetailsForm
            .get('billingStatementText')
            .setValue(txData['merchantDescriptor']);
        }
        paymentDetailsForm
          .get('program-spec-key')
          .setValue(txData['programSpecificKey']);
      } catch (err) {
        console.log(err);
      }
    }
  }

  setState(shippingInfo, shippingAddress) {
    if (this.selectedCountry === 'US') {
      shippingInfo.get('state').setValue(shippingAddress['region']);
    } else if (this.selectedCountry === 'CA') {
      shippingInfo.get('province').setValue(shippingAddress['region']);
    } else {
      shippingInfo.get('state-province').setValue(shippingAddress['region']);
    }
    this.loadStates();
  }

  setPostalCode(shippingInfo, shippingAddress) {
    if (this.selectedCountry === 'US') {
      shippingInfo.get('zip').setValue(shippingAddress['postalCode']);
    } else if (this.selectedCountry === 'CA') {
      shippingInfo.get('postalCA').setValue(shippingAddress['postalCode']);
    } else {
      shippingInfo.get('zipPostal').setValue(shippingAddress['postalCode']);
    }
  }

  createForm() {
    this.processForm = new FormGroup({
      paymentDetails: new FormGroup({
        amount: new FormControl(null),
        modeOfReceipt: new FormControl('F', Validators.required),
        billingStatementText: new FormControl(null, [Validators.maxLength(25)]),
        'program-spec-key': new FormControl(null),
      }),
      shippingInfo: new FormGroup({
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, Validators.required),
        streetAddress1: new FormControl(null, Validators.required),
        streetAddress2: new FormControl(null),
        city: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        province: new FormControl(null),
        state: new FormControl(null),
        'state-province': new FormControl(null),
        zip: new FormControl(null),
        postalCA: new FormControl(null),
        zipPostal: new FormControl(null),
        company: new FormControl(null),
        phone: new FormControl(null),
        email: new FormControl(null, Validators.email),
        project: new FormControl(null, [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern('^[0-9]*$'),
        ]),
        product: new FormControl(null, [
          Validators.minLength(5),
          Validators.pattern('^[0-9]*$'),
        ]),
        'journal-entry-num': new FormControl(null, [
          Validators.minLength(6),
          Validators.pattern('^[0-9]*$'),
        ]),
        activity: new FormControl(null, [
          Validators.minLength(3),
          Validators.pattern('^[0-9]*$'),
        ]),
        resource: new FormControl(null, [
          Validators.minLength(2),
          Validators.pattern('^[0-9]*$'),
        ]),
        customerID: new FormControl(null, [Validators.maxLength(16)]),
      }),
    });

    if (!this.isSale) {
      this.processForm
        .get('paymentDetails.amount')
        .setValidators(Validators.required);
      this.processForm
        .get('shippingInfo.state')
        .setValidators(Validators.required);
      this.processForm
        .get('shippingInfo.zip')
        .setValidators(Validators.required);
      this.processForm
        .get('shippingInfo.journal-entry-num')
        .setValidators(Validators.required);
    }
    this.processForm
      .get('shippingInfo.country')
      .valueChanges.subscribe((value) => {
        this.onCountryChange(value);
        //  this.processForm.
      });
    this.addListeners();
  }

  onCountryChange(value) {
    const stateCtrl = this.processForm.get('shippingInfo.state');
    const zipCtrl = this.processForm.get('shippingInfo.zip');
    const zipPostalCtrl = this.processForm.get('shippingInfo.zipPostal');
    const provinceCtrl = this.processForm.get('shippingInfo.province');
    const postalCACtrl = this.processForm.get('shippingInfo.postalCA');
    if (value === 'US') {
      stateCtrl.setValidators([Validators.required]);
      zipCtrl.setValidators([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(9),
        Validators.pattern('^[0-9]*$'),
      ]);
    } else if (value === 'CA') {
      provinceCtrl.setValidators([Validators.required]);
      postalCACtrl.setValidators([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(9),
        Validators.pattern('^[A-z0-9\\s]*([A-z0-9\\s])*$'),
      ]);
    } else {
      this.clearValidators(stateCtrl);
      this.clearValidators(zipPostalCtrl);
      this.clearValidators(provinceCtrl);
      this.clearValidators(postalCACtrl);
      this.clearValidators(zipCtrl);
    }
  }

  clearValidators(ctrl) {
    ctrl.setErrors(null);
    // ctrl.reset();
    ctrl.clearValidators();
  }

  ngOnInit(): void {
    this.countryList = this.countryRegionService.getCountryCodes();
    this.sharedService.userInfo$.subscribe((userInfo) => {
      this.userInfo = userInfo;
    })
    this.isSale = this.processType === 'SALE' ? true : false;
    this.createForm();
  }

  clearForm() {
    this.processForm.reset();
  }

  async onSubmit() {
    this.formSubmitted = true;
    if (this.isSale) {
      const processFormValue = this.processForm.value;
      sessionStorage.setItem(
        'billingStatementText',
        processFormValue['paymentDetails']['billingStatementText'] || ''
      );
      sessionStorage.setItem(
        'journal-entry-num',
        processFormValue['shippingInfo']['journal-entry-num'] || ''
      );
      if (
        !processFormValue['paymentDetails']['billingStatementText'] ||
        !processFormValue['shippingInfo']['journal-entry-num']
      ) {
        const etsData = await this.orderService
          .getETSData(this.virtualTerminalData.OSC)
          .toPromise();

        const defaultMerchantDesc = etsData['defaultMerchantDesc'];
        const defaultPeopleSoftAccCode = etsData['defaultPeopleSoftAccCode'];

        if (!processFormValue['paymentDetails']['billingStatementText']) {
          processFormValue['paymentDetails']['billingStatementText'] =
            defaultMerchantDesc;
        }

        if (!processFormValue['shippingInfo']['journal-entry-num']) {
          processFormValue['shippingInfo']['journal-entry-num'] =
            defaultPeopleSoftAccCode;
        }
      }
    }
    this.orderService.sendOrderRequest(
      this.orderService.getReqOrderFormData(
        this.processForm,
        this.virtualTerminalData,
        this.lineItems,
        this.userInfo
      )
    );
  }

  onExit() {
    location.href = location.pathname;
  }

  changeRegion(e) {
    this.selectedRegion = e.value;
  }

  changeProvince(e) {
    this.selectedProvince = e.value;
  }

  changeCountry(e) {
    this.selectedCountry = e.value;
    this.loadStates();
  }

  loadStates() {
    if (this.selectedCountry === 'US') {
      this.countryRegionService
        .loadUsaStates()
        .subscribe((data) => (this.regionList = data));
    } else if (this.selectedCountry === 'CA') {
      this.countryRegionService
        .loadCaStates()
        .subscribe((data) => (this.provinceList = data));
    }
  }

  isProcessFormValid() {
    return this.processForm.invalid || this.formSubmitted;
  }

  lineItemData(event) {
    this.isLineItemValid = event.status === 'INVALID' ? true : false;
    this.lineItems = event.value;

    const paymentDetailsForm = this.processForm.get('paymentDetails');
    const amountField= paymentDetailsForm.get('amount');
    if(this.lineItems && this.lineItems.length>0){
      amountField.disable();
    } else{
      amountField.enable();
    }
  }

  confirmSale(): void {
    if (this.lineItems && this.lineItems.length > 0 && this.isLineItemValid) {
      const message = `You are attempting to process line item details with out a
                      Product SKU or Product Code.  Press continue to submit your
                      transaction or exit to correct your line items`;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: new ConfirmDialogModel(message),
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.onSubmit();
        }
      });
    } else {
      this.onSubmit();
    }
  }

  addListeners(){
    const paymentDetailsForm = this.processForm.get('paymentDetails');
    paymentDetailsForm.get('amount').valueChanges.subscribe(amount => {

      if (amount){
        this.allowOrderItems = false;
      }else{
        this.allowOrderItems = true;
      }
    });
  }

  isDisabledOverrdieRefund(){

    if (this.lineItems && this.lineItems.length >0){
      for (const lineItem of this.lineItems){
        if (!lineItem.unitPrice){
          return true;
        }
      }

    }else{
      const paymentDetailsForm = this.processForm.get('paymentDetails');
      const amountField = paymentDetailsForm.get('amount');
      return !amountField.value ;
    }

  }

  isInValidForm() {

    if (this.isSale) {
      return this.isProcessFormValid();
    } else {
      return this.isProcessFormValid() || this.isDisabledOverrdieRefund();
    }

  }
}
