import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard.service';
import {
  cfsColumnNames,
  displayedColumnNames,
  ReconciliationResponce,
} from '../model/reconciliation';
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-reconciliation-dashboard',
  templateUrl: './reconciliation-dashboard-details.component.html',
  styleUrls: ['./reconciliation-dashboard-details.component.scss'],
})
export class ReconciliationDashboardDetailsComponent implements OnInit {
  displayedColumns: string[] = Object.keys(displayedColumnNames);
  columnsToDisplay: string[] = this.displayedColumns.slice();
  dataSource :ReconciliationResponce[];
  dataSourceTable:MatTableDataSource<ReconciliationResponce>;
  reconciliationType: string;
  displayedColumnNames: any;
  colName: string;
  exportOption = 'xls';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isDashboardDetailsByFile: boolean;
  backButtonLabel: string;

  pageSize=25;
  pageInx=0;
  totalRecords:any;

  ngOnInit(): void {
    this.reconciliationType =
      this.route.snapshot.queryParamMap.get('reconciliationType');

    const startDate = this.route.snapshot.queryParamMap.get('startDate');
    const endDate = this.route.snapshot.queryParamMap.get('endDate');
    this.isDashboardDetailsByFile = startDate && endDate ? true : false;

    this.displayedColumnNames = displayedColumnNames;
    this.backButtonLabel = this.isDashboardDetailsByFile ? `Return to Reconciliation Dashboard By File` : `Return to Reconciliation Dashboard`;
    // this.loadData();
    this.colName = this.route.snapshot.queryParamMap.get('colName');
    this.totalRecords = this.route.snapshot.queryParamMap.get('totalRecords')||0;
    this.loadData();

  }

  loadData(){
    const date = this.route.snapshot.queryParamMap.get('date');
    const recordType = this.route.snapshot.queryParamMap.get('recordType');

    let reconciliationDetailData ;
    if(this.reconciliationType.startsWith('cfs')){
      const processedDate = this.route.snapshot.queryParamMap.get('processedDate');
      if(this.isDashboardDetailsByFile){
        reconciliationDetailData = this.dashboardService.getCFSDetailsByFile(this.reconciliationType, date, recordType, processedDate,this.pageInx, this.pageSize);
      }else{
        reconciliationDetailData = this.dashboardService.getCFSDetails(this.reconciliationType, date, recordType,this.pageInx, this.pageSize);
      }
      this.displayedColumnNames = cfsColumnNames;
      this.displayedColumns = Object.keys(cfsColumnNames);
      this.columnsToDisplay = this.displayedColumns.slice();
    }else {

       reconciliationDetailData = this.isDashboardDetailsByFile ? this.dashboardService.getReconciliationDetailByFileData(this.reconciliationType, date, recordType, this.pageInx, this.pageSize) :
        this.dashboardService.getReconciliationDetailData(
          this.reconciliationType,
          date,
          recordType, this.pageInx, this.pageSize
        );
    }
    reconciliationDetailData.subscribe((data) => {
      if (data) {
        this.dataSource =data;
        this.dataSourceTable=new MatTableDataSource(data);



      } else {
        console.log('No data found for reconciliationDetail');
      }
    });
  }

  back() {
    if (this.isDashboardDetailsByFile) {
      this.router.navigate(['/reconciliation-dashboard-by-file'], {
        queryParams: {
          startDate: this.route.snapshot.queryParamMap.get('startDate'),
          endDate: this.route.snapshot.queryParamMap.get('endDate'),
          reconciliationType: this.route.snapshot.queryParamMap.get('reconciliationType')
        },
      });
    } else {
      this.router.navigate(['/reconciliation-dashboard'], {
        queryParams: { date: this.route.snapshot.queryParamMap.get('date') },
      });
    }
  }

  handlePaging(event:PageEvent){
    this.pageSize=event.pageSize;
    this.pageInx=event.pageIndex;
    this.loadData();
  }

  applyFilter(event: string) {
    this.dataSourceTable.filter = event.trim().toLowerCase();
  }

  exportData() {
    const date = this.route.snapshot.queryParamMap.get('date');
    const recordType = this.route.snapshot.queryParamMap.get('recordType');
    const totalRecords = this.route.snapshot.queryParamMap.get('totalRecords');
    let exportObs;

    if(this.reconciliationType.startsWith('cfs')) {
      const processedDate = this.route.snapshot.queryParamMap.get('processedDate');
      if(this.isDashboardDetailsByFile){
        exportObs = this.dashboardService.exportCFSDetailsByFile(this.reconciliationType, date, recordType, processedDate);
      }else{
        exportObs = this.dashboardService.exportCFSDetails(this.reconciliationType, date, recordType);
      }
    }else{
      exportObs=this.isDashboardDetailsByFile?this.dashboardService.exportReconciliationDetailByFileData(this.reconciliationType,date,
        recordType,totalRecords):this.dashboardService.exportReconciliationDetail(this.reconciliationType,date,recordType,totalRecords);
    }


    exportObs.subscribe((data:any) => {
    //  let blob:any=new Blob([data.blob()]);
      const url=window.URL.createObjectURL(data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = `reconciliationDetail_${new Date().toISOString()}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  }
  getTableData() {
    const clonedDataList = this.dataSource.map((row) => {
      const cloneRow = { ...row };
      return cloneRow;
    });
    return clonedDataList;
  }
}
