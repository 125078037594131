import { Injectable } from '@angular/core';
import { fetchAuthSession } from '@aws-amplify/auth';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}
  public getSession(): Promise<any> {
    return fetchAuthSession();
  }
}
