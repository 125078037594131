<div id="dropdown-arrow-mat" *ngIf="loggedInUser?.oscDetails?.length > 0" class="mt-4 container-xl">
    <div class="row heading">
        <div class="col-12">
            <h2>
                Order Search
            </h2>
            <h3>Search Options
            </h3>
        </div>
    </div>
<div *ngIf="searchError" class="text-danger pt-1 pb-1">* Could not filter records. Please add an additional element to refine the search and try again.</div>
<div id="order-search" class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="dateSelectForm">
                <div class="row">
                    <div class="col-lg-3">
                        <h4>Date Range <mat-icon class='colorInfo'
                                matTooltip="All dates and times reported in EPMS Admin UI Order Search are in the North America Eastern Time Zone">
                                help_outline</mat-icon>
                        </h4>
                        <strong>
                            <span *ngIf="!selectedDate || selectedDate === 'none'">Transaction Date
                                ({{dateSelectForm.value.fromDate | date:fullFormat}} - {{dateSelectForm.value.toDate
                                | date:fullFormat}})</span>
                            <span *ngIf="selectedDate === 'today' || selectedDate === 'yesterday'">Transaction Date
                                ({{quickDate}})</span>
                            <span *ngIf="selectedDate === 'week'">Transaction Date ({{today}} -
                                {{quickDate}})</span>
                            <span *ngIf="selectedDate === 'lastWeek'">Transaction Date ({{lastWeek}} -
                                {{yesterday}})</span>
                            <span *ngIf="selectedDate === 'month'">Transaction Date ({{today}} -
                                {{quickDate}})</span>
                            <span *ngIf="selectedDate === 'lastMonth'">Transaction Date ({{lastMonth}} -
                                {{yesterday}})</span>
                        </strong>
                    </div>
                    <div class="col-lg-9">
                            <div class="col-lg-3 form-check form-check-inline">
                                <label for="createdDate"> <input class="form-check-input mt-2" type="radio" (change)="onDateChange()" name="dateFor"
                                        formControlName="dateFor" value="created" id="createdDate" />
                                    Created Date <mat-icon class='colorInfo'
                                        matTooltip="The date the transaction was added to the EPMS System.">
                                        help_outline</mat-icon></label>
                            </div>
                            <div class="col-lg-3 form-check form-check-inline">
                                <label for="reconciliationDate"> <input class="form-check-input mt-2" type="radio" name="dateFor"
                                        formControlName="dateFor" value="reconcilation" (change)="onDateChange()" id="reconciliationDate" />
                                    Reconciliation Date <mat-icon class='colorInfo'
                                        matTooltip="The date EPMS processed the settlement file and updated the EPMS System.">
                                        help_outline</mat-icon></label>
                            </div>
                            <div class="col-lg-3 form-check form-check-inline">
                                <label for="settlementDate"> <input class="form-check-input mt-2" type="radio" (change)="onDateChange()" name="dateFor"
                                        formControlName="dateFor" value="settlement" id="settlementDate" />
                                    Settlement Date <mat-icon class='colorInfo'
                                        matTooltip="The date the transaction was settled, and funds were deposited or withdrawn from the bank.">
                                        help_outline</mat-icon></label>
                            </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12 ">
                        <div class="card grey">
                            <div class="card-body">

                                    <div class="row">
                                        <div class="col-lg-3 mb-3">
                                            <label class="form-label date-range">Date Range Select</label>
                                            <div class="clear-dates" (click)="clearDates()">
                                                Clear Date(s)
                                            </div>
                                            <mat-form-field>
                                                <mat-date-range-input [formGroup]="dateSelectForm"
                                                    [disabled]="dateTimeDisabled" [rangePicker]="picker">
                                                    <input matStartDate formControlName="fromDate"
                                                        placeholder="Start date"
                                                        matTooltip="Enter Month Day Year format"
                                                        (dateInput)="addDateEvent()" (dateChange)="addDateEvent()">
                                                    <input matEndDate formControlName="toDate" placeholder="End date"
                                                        matTooltip="Enter Month Day Year format"
                                                        (dateInput)="addDateEvent()" (dateChange)="addDateEvent()">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="picker"
                                                    [disabled]="dateTimeDisabled"
                                                    matTooltip="Select date range from start date to end date">
                                                </mat-datepicker-toggle>
                                                <mat-date-range-picker #picker [disabled]="dateTimeDisabled">
                                                </mat-date-range-picker>
                                            </mat-form-field>
                                            <mat-hint align="start"><span class="date-hint">(MM/DD/YYYY) -
                                                    (MM/DD/YYYY)</span></mat-hint>
                                        </div>
                                        <div class="col-lg-3 mb-3">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label class="form-label">From Time</label>
                                                    <timepicker formControlName="fromTime" [minuteStep]=1 [mousewheel]=true>
                                                    </timepicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 mb-3">
                                            <div class="col-6">
                                                <label class="form-label">To Time</label>
                                                <timepicker formControlName="toTime" [minuteStep]=1 [mousewheel]=true>
                                                </timepicker>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 mb-3">
                                            <label>Quick Date Select</label>
                                          <span class="quick-clear-dates" (click)="clearQuickDate()">
                                            Clear Date(s)
                                          </span>
                                          <div>
                                            <mat-select (selectionChange)="onDateChange()" class="form-select form-select-md select-date"
                                                formControlName="quickDateSelect">
                                                <mat-option *ngFor="let quickDate of QuickDateSelect" [value]="quickDate.value">
                                                    {{quickDate.text}}
                                                  </mat-option>
                                            </mat-select>
                                          </div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
                <div class="row">
                    <div class="col-lg-12">
                        <form [formGroup]="searchForm">
                            <div class="row mt-4 mb-2">
                                <div class="col-12">
                                    <h4>Order Details Information</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="orderState">Order State</label>
                                    <mat-select formControlName="orderState" class="form-select form-select-md">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option *ngFor="let orderState of OrderState" [value]="orderState.value">
                                            {{orderState.text}}
                                          </mat-option>
                                    </mat-select>

                                </div>

                                <div class="col-md-4 form-controls mb-3">
                                    <label for="decision">Decision</label>
                                    <mat-select formControlName="decision" class="form-select form-select-md">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option *ngFor="let decision of Decision" [value]="decision.value">
                                            {{decision.text}}
                                          </mat-option>
                                    </mat-select>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4 form-controls">
                                    <div class="row">
                                        <div class="col-md-7 mb-3">
                                            <label>Order Amount Filter</label>
                                            <mat-select formControlName="amountFilter" class="form-select form-select-md">
                                                <mat-option disabled>- Select -</mat-option>
                                                <mat-option *ngFor="let orderAmountFilter of OrderAmountFilter" [value]="orderAmountFilter.value">
                                                    {{orderAmountFilter.text}}
                                                  </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-md-5 mb-3">

                                            <label>Order Amount</label>
                                            <input currencyInput formControlName="amount" class="form-control form-control-md" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label><span class="text-danger">* </span>Auth Response or Reason Code</label>
                                    <input formControlName="reasonCode" class="form-control form-control-md" />
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label><span class="text-danger">* </span>Last Reason Code</label>
                                    <input formControlName="lastReasonCode" class="form-control form-control-md" />
                                </div>
                            </div>
                            <div class="row mt-4 mb-2">
                                <div class="col-12">
                                    <h4>Bill to & Ship to Customer Information</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="billToFirstName"><span class="text-danger">* </span>Bill to First
                                        Name</label>
                                    <input type="text" formControlName="billToFirstName" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="billToLastName"><span class="text-danger">* </span>Bill to Last
                                        Name</label>
                                    <input type="text" formControlName="billToLastName" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="customerId"><span class="text-danger">* </span>Customer ID</label>
                                    <input type="text" formControlName="customerId" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="billToPhone"><span class="text-danger">* </span>Bill to Customer
                                        Phone</label>
                                    <input type="tel" formControlName="billToPhone" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="billToEmail"><span class="text-danger">* </span>Bill to Customer
                                        email</label>
                                    <input type="email" formControlName="billToEmail" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="billToCity">Bill to City</label>
                                    <input type="text" formControlName="billToCity" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="billToCountry">Bill to Country</label>
                                    <mat-select class="form-select form-select-md" name="billToCountry" formControlName="billToCountry">
                                        <mat-option [value]="">- Select -</mat-option>
                                        <mat-option *ngFor="let country of countryList" [value]="country.id">
                                            {{country.name}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="shipToFirstName"><span class="text-danger">* </span>Ship to First
                                        Name</label>
                                    <input type="text" formControlName="shipToFirstName" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="shipToLastName"><span class="text-danger">* </span>Ship to Last
                                        Name</label>
                                    <input type="text" formControlName="shipToLastName" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="shipToEmail"><span class="text-danger">* </span>Ship to Customer
                                        Email</label>
                                    <input type="text" formControlName="shipToEmail" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="shipToCity">Ship to City</label>
                                    <input type="text" formControlName="shipToCity" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="shipToCountry">Ship to Country</label>
                                    <mat-select class="form-select form-select-md" name="shipToCountry" formControlName="shipToCountry">
                                        <mat-option [value]="">- Select -</mat-option>
                                        <mat-option *ngFor="let country of countryList" [value]="country.id">
                                            {{country.name}}</mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="testCenterCountry">Test Center Country</label>
                                    <mat-select class="form-select form-select-md" name="testCenterCountry"
                                        formControlName="testCenterCountry">
                                        <mat-option [value]="">- Select -</mat-option>
                                        <mat-option *ngFor="let country of countryList" [value]="country.id">
                                            {{country.name}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="row mt-4 mb-2">
                                <div class="col-12">
                                    <h4>Program Specific Information</h4>
                                </div>
                            </div>

                            <div class="row">
                                <div id="osc" class="col-md-4 form-controls mb-3" matAutocompleteOrigin #origin="matAutocompleteOrigin">
                                    <label for="originatingSystemCode">Originating System Code (OSC)</label>
                                    <mat-select class="form-select form-select-md" name="originatingSystemCode"
                                        formControlName="originatingSystemCode" multiple >
                                        <mat-option value="ALL" (click)="toggleAllOscCodeSelection(searchForm.controls['originatingSystemCode']?.value)">All</mat-option>
                                        <mat-option *ngFor="let oscConfigCode of oscConfigCodes" [value]="oscConfigCode">
                                            {{oscConfigCode}}</mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="programSpecificDate">Program Specific Date</label>
                                    <input type="text" formControlName="programSpecificDate" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="programSpecificKey"><span class="text-danger">* </span>Program Specific
                                        Key</label>
                                    <input type="text" formControlName="programSpecificKey" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="oscSessionId"><span class="text-danger">* </span>OSC Session ID</label>
                                    <input type="text" formControlName="oscSessionId" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="epmsOrderId"><span class="text-danger">* </span>Order (Reconciliation)
                                        ID</label>
                                    <input type="text" formControlName="orderId" class="form-control form-control-md">
                                </div>
                              <div class="col-md-4 form-controls mb-3">
                                <label for="transactionAttemptCount">Transaction Attempt Count <ng-container *ngIf="!isVldTxAmt()">
                                  <div ><span  class="text-danger">Order(Reconciliation)ID is required </span></div>
                                </ng-container>

                                </label>
                                <input type="text" formControlName="transactionAttemptCount" class="form-control form-control-md">
                              </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="subscriptionId">Subscription ID</label>
                                    <input type="text" formControlName="subscriptionId" class="form-control form-control-md">
                                </div>

                               <div class="col-md-4 form-controls mb-3">
                                <label for="fraudCustomerType">Fd Customer Type</label>
                                <input type="text" formControlName="fraudCustomerType" class="form-control form-control-md">
                                </div>
                              <div class="col-md-4 form-controls mb-3">
                                <label for="cfsFlag">CFS</label>
                                <mat-select name="cfsFlag" id="cfsFlag" formControlName="cfsFlag"
                                            class="form-select form-select-md">
                                  <mat-option disabled>- Select -</mat-option>
                                  <mat-option value="true">True</mat-option>
                                  <mat-option value="false">False</mat-option>
                                </mat-select>
                              </div>
                              <div class="col-md-4 mb-3">
                                <label for="noMtchVndrSuppliedId"> No Match Vendor Supplied Order ID </label>
                                <input type="text" formControlName="noMtchVndrSuppliedId" class="form-control form-control-md">
                              </div>
                            </div>
                            <div class="row mt-4 mb-2">
                                <div class="col-12">
                                    <h4>Payment Information</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="paymentMethod">Payment Method</label>
                                    <mat-select name="paymentMethod" id="paymentMethod" formControlName="paymentMethod"
                                        class="form-select form-select-md">
                                       <mat-option disabled>- Select -</mat-option>
                                       <mat-option *ngFor="let paymentMethod of PaymentMethods" [value]="paymentMethod.value">
                                        {{paymentMethod.text}}
                                      </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="cardType">Card Type</label>
                                    <mat-select name="cardType" id="cardType" formControlName="cardType"
                                        class="form-select form-select-md">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option *ngFor="let cardType of CardType" [value]="cardType.value">
                                            {{cardType.text}}
                                          </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="paymentType">Payment Type</label>
                                    <mat-select name="paymentType" id="paymentType" formControlName="paymentType"
                                        class="form-select form-select-md">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option *ngFor="let paymentType of PaymentType" [value]="paymentType.value">
                                            {{paymentType.text}}
                                          </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="maskedAccountNumber"><span class="text-danger">* </span>Masked Account
                                        Number</label>
                                    <input type="text" formControlName="maskedAccountNumber" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="last4DigitOfCreditCard"><span class="text-danger">* </span>Last 4 Digits
                                        of Credit Card</label>
                                    <input type="text" formControlName="last4DigitOfCreditCard" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="currency">Currency</label>
                                    <input type="text" formControlName="currency" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="transactionType">Transaction Type</label>
                                    <mat-select name="transactionType" id="transactionType"
                                        formControlName="transactionType" class="form-select form-select-md">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option *ngFor="let transactionType of TransactionType" [value]="transactionType.value">
                                            {{transactionType.text}}
                                          </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="bankAaccountNumber"><span class="text-danger">* </span>Bank Account
                                        Number</label>
                                    <input formControlName="bankAaccountNumber" class="form-control form-control-md" type="text">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="bankRoutingNumber"><span class="text-danger">* </span>Bank Routing
                                        Number</label>
                                    <input formControlName="bankRoutingNumber" class="form-control form-control-md" type="text">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="bin"><span class="text-danger">* </span>Bank Identification Number
                                        (BIN)</label>
                                    <input type="text" formControlName="bin" class="form-control form-control-md">
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="fraudIndicator">TC-40 Flag</label>
                                    <mat-select name="fraudIndicator" id="fraudIndicator" formControlName="fraudIndicator"
                                        class="form-select form-select-md">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option value="true">True</mat-option>
                                        <mat-option value="false">False</mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 form-controls mb-3">
                                    <label for="arnRrnNo">ARN/RRN</label>
                                    <input type="text" formControlName="arnRrnNo" class="form-control form-control-md">
                                </div>
                            </div>
                        </form>
                        <button class="btn btn-primary btn-function" (click)="resetForm()">
                            Clear
                        </button>
                        &nbsp;&nbsp;
                        <button class="btn btn-primary" (click)="onSubmit()"
                            [disabled]="!(searchForm.valid || dateSelectForm.valid) || !isVldTxAmt()">
                            Search
                        </button>
                        <div class="text-danger text-end">*- Wildcarding is allowed by utilizing a % symbol</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8  alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to access the page.
    </div>
    <div class="col-2"></div>
</div>
