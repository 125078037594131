import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from '../constant/constants';
import { User } from '../model/user';
import { CountryRegionService } from '../service/country-region.service';
import { OrderService } from '../service/order.service';
import { SharedService } from '../service/shared.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-order-ui',
  templateUrl: './order-ui.component.html',
  styleUrls: ['./order-ui.component.css']
})
export class OrderUiComponent implements OnInit {
  oscConfigCodes = [];
  loggedInUser: User;
  orderResponseDetails: any;
  modalRef: BsModalRef;
  formSubmitted = false;
  countryList: any;
  transactionResponse: any;

  private CVA_ORDER_REDIRECT_URL = `${window.location.origin}/order-ui/virtual-terminal`;
  private MERCHANT_POSTBACK_URL = environment.ORDER_UI_SERVER_URL+`/epms/postbackResponse`;
  private sub: any;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild('orderResponseTemplate') orderResponseTemplateRef: TemplateRef<any>;

  lineItemDetailColumns = ['action', 'productName', 'productDescription', 'productSKU', 'productCode', 'unitPrice', 'quantity', 'taxAmount'];
  lineItemDataSource: MatTableDataSource<AbstractControl>;
  enableLoader = false;

  constructor(private fb: FormBuilder,
    private sharedService: SharedService,
    private orderService: OrderService,
    private modalService: BsModalService,
    private countryRegionService: CountryRegionService,
    private route: ActivatedRoute,
    private router: Router) {
    this.lineItemDataSource = new MatTableDataSource(this.lineItems.controls);
    this.countryList = this.countryRegionService.getCountryCodes();
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.oscConfigCodes = user.oscDetails.map(osc => osc.oscCode).sort();
        this.loggedInUser = user;
      }
    });
    this.sub = this.route.queryParams.subscribe(params => {
      const transactionId = params['t'];
      this.loadTransactionData(transactionId);
    });
    this.handleOrderUiErrorResponse();
  }

  orderFormGroup = this.fb.group({
    originatingSystemCode: [null],
    oscSessionId: [null],
    epmsOrderId: [null],
    orderAmount: [null],
    glProjectText: [Constants.GL_PROJECT_TEXT],
    glProductText: [null],
    glActivityText: [null],
    programSpecificKey: [null],
    serviceCode: [null],
    programDefinedDate: [null],
    merchantDescriptor: [Constants.MERCHANT_DESCRIPTOR],
    modeOfReceipt: [Constants.RECEIPT_MODE],
    resourceText: [null],
    lineItems: this.fb.array([]),
    baseCurrency: [Constants.USD],
    baseTaxAmount: [0],
    displayLanguage: [Constants.DEFAULT_LANGUAGE],
    sourceType: [Constants.SALE],
    testCenterCountry: [null],
    shippingInfo: [this.getDefaultShippingInfo()],
    jeAccountNumber: [null],
    customerID: [Constants.DEFAULT_CUSTOMER_ID],
    merchantDescriptorContact: [null],
    fdCustomerType: [null],
    customerType: [null],
    registrationDate: [null],
    createSubscriptionID: [null],
    frequency: [null],
    numberOfPayments: [null],
    subscriptionRenew: [null],
    startDate: [null],
    subscriptionAmount: [null],
    merchantDefinedField1: [null],
    merchantDefinedField2: [null],
    merchantDefinedField3: [null],
    merchantDefinedField4: [null],
    merchantDefinedField5: [null],
    merchantDefinedField6: [null],
    merchantDefinedField7: [null],
    merchantDefinedField8: [null],
    merchantDefinedField9: [null],
    merchantDefinedField10: [null],
    merchantSuccessUrl: [this.CVA_ORDER_REDIRECT_URL],
    merchantRejectUrl: [this.CVA_ORDER_REDIRECT_URL],
    merchantErrorUrl: [this.CVA_ORDER_REDIRECT_URL],
    merchantTimeoutUrl: [this.CVA_ORDER_REDIRECT_URL],
    merchantPostUrl: [this.MERCHANT_POSTBACK_URL]
  });

  resetCvaOrderForm() {
    this.orderFormGroup.reset({
      glProjectText: Constants.GL_PROJECT_TEXT,
      merchantDescriptor: Constants.MERCHANT_DESCRIPTOR,
      modeOfReceipt: Constants.RECEIPT_MODE,
      baseCurrency: Constants.USD,
      baseTaxAmount: 0,
      displayLanguage: Constants.DEFAULT_LANGUAGE,
      sourceType: Constants.SALE,
      shippingInfo: this.getDefaultShippingInfo(),
      customerID: Constants.DEFAULT_CUSTOMER_ID,
    });
    this.lineItems.clear();
    this.table?.renderRows();
  }

  get lineItems() {
    return this.orderFormGroup.get('lineItems') as FormArray;
  }

  addLineItem() {
    const lintItem = this.lineItems;
    lintItem.push(this.fb.group({
      lineNumber: this.lineItems.length,
      productCode: [null],
      productName: [null],
      productDescription: [null],
      productSKU: [null],
      quantity: [null],
      unitPrice: [null, Validators.required],
      taxAmount: [null]
    }));
    this.table.renderRows();
  }

  onSubmit() {
    this.enableLoader = true;
    this.formSubmitted = true;
    const reqOrderFormData = {};
    reqOrderFormData['orderAmount'] = this.orderFormGroup.value.orderAmount;
    reqOrderFormData['merchantDescriptor'] = this.orderFormGroup.value.merchantDescriptor;
    reqOrderFormData['programSpecificKey'] = this.orderFormGroup.value.programSpecificKey;
    reqOrderFormData['jeAccountNumber'] = this.orderFormGroup.value.jeAccountNumber;
    reqOrderFormData['modeOfReceipt'] = this.orderFormGroup.value.modeOfReceipt;
    reqOrderFormData['sourceType'] = this.orderFormGroup.value.sourceType;
    reqOrderFormData['originatingSystemCode'] = this.orderFormGroup.value.originatingSystemCode;
    reqOrderFormData['oscSessionId'] = this.orderFormGroup.value.oscSessionId;
    reqOrderFormData['shippingAddressLine1'] = this.orderFormGroup.value.shippingInfo?.address1;
    reqOrderFormData['shippingAddressLine2'] = this.orderFormGroup.value.shippingInfo?.address2;
    reqOrderFormData['shippingAddressCity'] = this.orderFormGroup.value.shippingInfo?.city;
    reqOrderFormData['shippingAddressCountry'] = this.orderFormGroup.value.shippingInfo?.country;
    reqOrderFormData['shippingAddressState'] = this.orderFormGroup.value.shippingInfo?.state;
    reqOrderFormData['shippingAddressRegion'] = this.orderFormGroup.value.shippingInfo?.state;
    reqOrderFormData['shippingAddressPostalCode'] = this.orderFormGroup.value.shippingInfo?.zip;
    reqOrderFormData['shippingAddressEmail'] = this.orderFormGroup.value.shippingInfo?.email;
    reqOrderFormData['shippingAddressPhoneNumber'] = this.orderFormGroup.value.shippingInfo?.phoneNumber;
    reqOrderFormData['shippingAddressFirstName'] = this.orderFormGroup.value.shippingInfo?.firstName;
    reqOrderFormData['shippingAddressLastName'] = this.orderFormGroup.value.shippingInfo?.lastName;
    reqOrderFormData['shippingAdressCompany'] = this.orderFormGroup.value.shippingInfo?.company;
    reqOrderFormData['glProductText'] = this.orderFormGroup.value.glProductText;
    reqOrderFormData['glActivityText'] = this.orderFormGroup.value.glActivityText;
    reqOrderFormData['glProjectText'] = this.orderFormGroup.value.glProjectText;
    reqOrderFormData['resourceText'] = this.orderFormGroup.value.resourceText;
    reqOrderFormData['customerID'] = this.orderFormGroup.value.customerID;
    reqOrderFormData['epmsOrderId'] = this.orderFormGroup.value.epmsOrderId;
    reqOrderFormData['serviceCode'] = this.orderFormGroup.value.serviceCode;
    reqOrderFormData['programDefinedDate'] = this.orderFormGroup.value.programDefinedDate;
    reqOrderFormData['baseCurrency'] = this.orderFormGroup.value.baseCurrency;
    reqOrderFormData['baseTaxAmount'] = this.orderFormGroup.value.baseTaxAmount;
    reqOrderFormData['displayLanguage'] = this.orderFormGroup.value.displayLanguage;
    reqOrderFormData['testCenterCountry'] = this.orderFormGroup.value.testCenterCountry;
    reqOrderFormData['merchantDescriptorContact'] = this.orderFormGroup.value.merchantDescriptorContact;
    reqOrderFormData['customerType'] = this.orderFormGroup.value.customerType;
    reqOrderFormData['registrationDate'] = this.orderFormGroup.value.registrationDate;
    reqOrderFormData['createSubscriptionID'] = this.orderFormGroup.value.createSubscriptionID;
    reqOrderFormData['frequency'] = this.orderFormGroup.value.frequency;
    reqOrderFormData['numberOfPayments'] = this.orderFormGroup.value.numberOfPayments;
    reqOrderFormData['subscriptionRenew'] = this.orderFormGroup.value.subscriptionRenew;
    reqOrderFormData['startDate'] = this.orderFormGroup.value.startDate;
    reqOrderFormData['subscriptionAmount'] = this.orderFormGroup.value.subscriptionAmount;
    reqOrderFormData['merchantDefinedField1'] = this.orderFormGroup.value.merchantDefinedField1;
    reqOrderFormData['merchantDefinedField2'] = this.orderFormGroup.value.merchantDefinedField2;
    reqOrderFormData['merchantDefinedField3'] = this.orderFormGroup.value.merchantDefinedField3;
    reqOrderFormData['merchantDefinedField4'] = this.orderFormGroup.value.merchantDefinedField4;
    reqOrderFormData['merchantDefinedField5'] = this.orderFormGroup.value.merchantDefinedField5;
    reqOrderFormData['merchantDefinedField6'] = this.orderFormGroup.value.merchantDefinedField6;
    reqOrderFormData['merchantDefinedField7'] = this.orderFormGroup.value.merchantDefinedField7;
    reqOrderFormData['merchantDefinedField8'] = this.orderFormGroup.value.merchantDefinedField8;
    reqOrderFormData['merchantDefinedField9'] = this.orderFormGroup.value.merchantDefinedField9;
    reqOrderFormData['merchantDefinedField10'] = this.orderFormGroup.value.merchantDefinedField10;
    reqOrderFormData['merchantSuccessURL'] = this.orderFormGroup.value.merchantSuccessUrl;
    reqOrderFormData['merchantRejectURL'] = this.orderFormGroup.value.merchantRejectUrl;
    reqOrderFormData['merchantErrorURL'] = this.orderFormGroup.value.merchantErrorUrl;
    reqOrderFormData['merchantTimeoutURL'] = this.orderFormGroup.value.merchantTimeoutUrl;
    reqOrderFormData['merchantPostURL'] = this.orderFormGroup.value.merchantPostUrl;
    reqOrderFormData['fdCustomerType'] = this.orderFormGroup.value.fdCustomerType;
    if (this.orderFormGroup.value?.lineItems) {
      this.orderFormGroup.value.lineItems.forEach(element => {
        const lineItemNumber = element['lineNumber'];
        reqOrderFormData[`productName${lineItemNumber}`] = element['productName'];
        reqOrderFormData[`productDescription${lineItemNumber}`] = element['productDescription'];
        reqOrderFormData[`productSKU${lineItemNumber}`] = element['productSKU'];
        reqOrderFormData[`productCode${lineItemNumber}`] = element['productCode'];
        reqOrderFormData[`unitPrice${lineItemNumber}`] = element['unitPrice'];
        reqOrderFormData[`quantity${lineItemNumber}`] = element['quantity'];
        reqOrderFormData[`taxAmount${lineItemNumber}`] = element['taxAmount'];
      });
    }
    this.orderService.sendOrderRequest(reqOrderFormData, true);
  }

  deleteLineItem(rowIndex) {
    this.lineItems.removeAt(rowIndex);
    this.table.renderRows();
  }

  trackRows(index: number, row: AbstractControl) {
    return row.value.uid;
  }

  loadTransactionData(transactionId: string) {
    if (transactionId) {
      const obs = this.orderService.getPgFinalResponse(transactionId);
      obs.subscribe(data => {
        this.orderResponseDetails = data; //JSON.stringify(data);
        this.showResponseModal();
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private showResponseModal() {
    this.modalRef = this.modalService.show(
      this.orderResponseTemplateRef, { class: 'gray modal-lg', ignoreBackdropClick: true }
    );
  }

  private getDefaultShippingInfo() {
    return {
      firstName: Constants.DEFAULT_FIRST_NAME,
      lastName: Constants.DEFAULT_LAST_NAME,
      address1: Constants.DEFAULT_ADDRESS1,
      address2: Constants.DEFAULT_ADDRESS2,
      city: Constants.DEFAULT_CITY,
      country: Constants.DEFAULT_COUNTRY,
      state: Constants.DEFAULT_STATE,
      zip: Constants.DEFAULT_ZIP,
      phoneNumber: Constants.DEFAULT_PHONE,
      email: Constants.DEFAULT_EMAIL,
      company: Constants.DEFAULT_COMPANY
    };
  }

  enableDisableSubmit(): boolean {
    return this.formSubmitted || !this.orderFormGroup.valid
    || ((this.orderFormGroup.value.orderAmount === null || this.orderFormGroup.value.orderAmount === "")
      && this.orderFormGroup.value.lineItems.length === 0) || !this.isShippingInfoValidState();
  }

  isShippingInfoValidState(){
    if(this.orderFormGroup.value.shippingInfo.country == "US" ||this.orderFormGroup.value.shippingInfo.country == "CA"){
      return !!this.orderFormGroup.value.shippingInfo.state;
    }else{
      return true;
    }
  }

  private handleOrderUiErrorResponse() {
    this.sharedService.orderUiError$.subscribe(res => {
      if (res) {
        this.enableLoader = false;
        this.orderResponseDetails = res;
        this.showResponseModal();
        this.sharedService.onOrderUiError(null);
        this.formSubmitted = false;
      }
    });
  }

  closeResponseModal() {
    this.router.navigate(['/order-ui']);
    this.modalRef.hide();
  }
}
