import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class Constants {
  public static readonly LOGOUT_REASON_LOGOUT = 'Regular Logout';
  public static readonly LOGOUT_REASON_BROWSER_CLOSE = 'Browser Close';
  public static readonly LOGOUT_REASON_NEW_SESSION = 'New Session';
  public static readonly LOGOUT_REASON_INACTIVE = 'Inactive Session';
  public static readonly SESSION_CONTEXT = 'SessionContext';
  public static readonly AUTH_TOKEN = 'authToken';
  public static readonly CONNECT = 'connect';
  public static readonly POLL_INTERVAL = 20000;
  public static readonly ACTIVE = 'ACTIVE';
  public static readonly EPMS_System = 'EPMS System';
  public static readonly EXTERNAL = 'EXTERNAL';
  public static readonly INTERNAL = 'INTERNAL';
  public static readonly SPLIT_CAMELCASE_REGEX = /([a-z])([A-Z])/g;
  public static readonly EMAIL_VALIDATOR_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  public static readonly NUMERIC_PATTERN = '^[0-9]*$';
  public static readonly ALPHA_NUMERIC_PATTERN = '^[a-zA-Z0-9]*$';
  
  // Rest API Calls
  public static readonly GET = 'GET';
  public static readonly POST = 'POST';
  public static readonly PUT = 'PUT';
  public static readonly DELETE = 'DEL';

  // Navigation URL Constants
  public static readonly SYSTEM_CHECK = '/secured/system-check';
  public static readonly NO_SCHEDULES = '/secured/no-schedules';

  static LOG_OUT_MSG: string = "LOG_OUT_MSG";
  static DFLT_PGM: string = "DFLT";

  static readonly EST_TZ: string = "America/New_York";

  // cva tool constants
  static readonly GL_PROJECT_TEXT = '01401';
  static readonly MERCHANT_DESCRIPTOR = 'ETS Testing Service';
  static readonly RECEIPT_MODE = 'N';
  static readonly USD = 'USD';
  static readonly DEFAULT_LANGUAGE = 'en';
  static readonly SALE = 'sale';
  static readonly DEFAULT_FIRST_NAME = 'CVA-First';
  static readonly DEFAULT_LAST_NAME = 'CVA-Last';
  static readonly DEFAULT_ADDRESS1 = '600 Rosedale Road';
  static readonly DEFAULT_ADDRESS2 = 'street 2';
  static readonly DEFAULT_CITY = 'Princeton';
  static readonly DEFAULT_COUNTRY = 'US';
  static readonly DEFAULT_STATE = 'NJ';
  static readonly DEFAULT_ZIP = '08541';
  static readonly DEFAULT_PHONE = '6097346000';
  static readonly DEFAULT_EMAIL = 'CVAcustomer@ets.org';
  static readonly DEFAULT_COMPANY = 'Educational Testing';
  static readonly DEFAULT_CUSTOMER_ID = '123456789';

}
