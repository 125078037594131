import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActionLinksModalComponent,
  IDynamicDialogConfig,
} from '../action-links/action-links-modal/action-links-modal.component';
import { ActionLink } from '../constant/action-links';

@Injectable({
  providedIn: 'root',
})
export class ActionLinkService {
  constructor(private dialog: MatDialog) {}

  openDialog(type, state, txData?, calculatedRefundAmount?, errorData?) {
    this.dialog.open(ActionLinksModalComponent, {
      width: '100%',
      data: <IDynamicDialogConfig>{
        txData: txData,
        modalContent: ActionLink[type],
        actionType: type,
        actionState: state,
        calculatedRefundAmount: calculatedRefundAmount,
        errorData: errorData
      },
    });
  }
}
