<div class="bread-crumb">
    <div class="container-xl">
        <div class="row">
            <div class="col-12 pt-2 pb-2">
                <a [routerLink]="['/virtual-terminal']">Virtual Terminal</a> /
                <a *ngIf="!isSale" [routerLink]="['/virtual-terminal/confirmation']">Confirmation Refund</a>
                <a *ngIf="isSale" [routerLink]="['/virtual-terminal/confirmation']">Sale Confirmation</a>
            </div>
        </div>
    </div>
</div>
<div class="container-xl mt-4">
    <div class="row">
        <div class="col-md-12 mb-4">
            <h2 class="page-title">Confirmation</h2>
        </div>
    </div>
    <!--Success-->
    <div *ngIf="showSuccess" class="row">
        <div class="col-md-12">
            <div class="alert alert-success" type="success">
                <div class="alert-message">
                    <div class="row">
                        <div class="col-md-1">
                            <fa-icon [icon]="faCheckCircle" class="check"></fa-icon>
                        </div>
                        <div *ngIf="!isSale" class="col-11">
                            <strong>Override Refund Result</strong><br />
                            <span class="message">Override Refund Successfully Processed.</span>
                        </div>
                        <div *ngIf="isSale" class="col-11">
                            <strong>Sale Transaction Result</strong><br />
                            <span class="message">Sale Transaction is sucessfully processed.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card receiving-information mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <table>
                                <tr>
                                    <td class="pe-4"><strong>Amount</strong></td>
                                    <td>{{ txData?.order?.orderAmount?.toFixed(2) }}</td>
                                </tr>
                                <tr>
                                    <td class="pe-4"><strong>Order Number</strong></td>
                                    <td>{{ txData?.order?.epmsOrderId }}</td>
                                </tr>
                                <tr>
                                    <td class="pe-4">
                                        <strong>Credit Card Last 4 digits</strong>
                                    </td>
                                    <td>{{ txData?.paymentMethodDetail?.cardNumberLast4 }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <h3>Shipping/Contact Information</h3>
                            <div>
                                <span>{{ txData?.order?.shippingAddress?.firstName }}</span>&nbsp;&nbsp;
                                <span>{{ txData?.order?.shippingAddress?.lastName }}</span>
                            </div>
                            <div>{{ txData?.order?.shippingAddress?.addressLine1 }}</div>
                            <div>{{ txData?.order?.shippingAddress?.addressLine2 }}</div>
                            <div>{{ txData?.order?.shippingAddress?.company }}</div>
                            <div>{{ txData?.order?.shippingAddress?.city }}</div>
                            <div>{{ txData?.order?.shippingAddress?.region }}</div>
                            <div>{{ txData?.order?.shippingAddress?.postalCode }}</div>
                            <div>{{ txData?.order?.shippingAddress?.country }}</div>
                            <div *ngIf="txData?.order?.shippingContact?.phoneNumber" class="mt-4">
                                <fa-icon [icon]="faPhone"></fa-icon>
                                {{ txData?.order?.shippingContact?.phoneNumber }}
                            </div>
                            <div *ngIf="txData?.order?.shippingContact?.email">
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                                {{ txData?.order?.shippingContact?.email }}
                            </div>
                            <div class="mt-4">
                                <small>This is the name and address of the person who will receive
                                    the product/service (e.g., the person who will be taking a
                                    test or is requesting scores); or, if you ordered items to be
                                    shipped, they will be sent to this address.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Error-->
    <div *ngIf="showError" class="row">
        <div class="col-12">
            <div class="card">
                <div *ngIf="showError" class="card-body">
                    <h2 *ngIf="!isSale" class="error">Override Transaction Error</h2>
                    <h2 *ngIf="isSale" class="error">Transaction Error</h2>
                    <div class="row">
                        <div class="col-12">
                            <h3 class="error" *ngIf="!isSale" >
                                <fa-icon [icon]="faExclamationTriangle"></fa-icon> Override
                                Refund Transaction Result
                            </h3>
                            <h3 class="error" *ngIf="isSale" >
                                <fa-icon [icon]="faExclamationTriangle"></fa-icon> Sale
                                Transaction Result
                            </h3>
                        </div>
                    </div>

                    <div *ngIf="showDecline" class="error-message">
                        <span *ngIf="!isSale" class="error">Your Refund was Declined.</span>
                        <span *ngIf="isSale" class="error">Your payment was Declined.</span>
                    </div>

                    <div *ngIf="showReject" class="error-message">
                        <span *ngIf="!isSale" class="error">Your Refund was Rejected.</span>
                        <span *ngIf="isSale" class="error">Your payment was Rejected.</span>
                    </div>

                    <div *ngIf="showError && !showReject && !showDecline" class="error-message">
                        <span *ngIf="!isSale" class="error">Your Refund was in Error.</span>
                        <span *ngIf="isSale" class="error">Your payment was in Error.</span>
                    </div>

                    <div class="grey-box p-4">
                        <table>
                            <tr *ngIf="!isSale">
                                <th>Amount</th>
                                <td>{{ txData?.order?.orderAmount?.toFixed(2) }}</td>
                            </tr>
                            <tr *ngIf="!isSale">
                                <th>OSC</th>
                                <td>{{ txData?.order?.originatingSystemCode }}</td>
                            </tr>
                            <tr *ngIf="!isSale">
                                <th>Order ID</th>
                                <td>{{ txData?.order?.epmsOrderId }}</td>
                            </tr>
                            <tr>
                                <th>Decision</th>
                                <td>{{ txStatus?.toUpperCase() }}</td>
                            </tr>
                            <tr>
                                <th>PGM Response Code</th>
                                <td>{{ txData?.pgmResponseCode }}</td>
                            </tr>
                            <tr>
                                <th>PGM Message</th>
                                <td>{{ txData?.pgmResponseMessage }}</td>
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="isSale" class="col-md-6">
                        <h3>Shipping/Contact Information</h3>
                        <div>
                            <span>{{ txData?.order?.shippingAddress?.firstName }}</span>&nbsp;&nbsp;
                            <span>{{ txData?.order?.shippingAddress?.lastName }}</span>
                        </div>
                        <div>{{ txData?.order?.shippingAddress?.addressLine1 }}</div>
                        <div>{{ txData?.order?.shippingAddress?.addressLine2 }}</div>
                        <div>{{ txData?.order?.shippingAddress?.company }}</div>
                        <div>{{ txData?.order?.shippingAddress?.city }}</div>
                        <div>{{ txData?.order?.shippingAddress?.region }}</div>
                        <div>{{ txData?.order?.shippingAddress?.postalCode }}</div>
                        <div>{{ txData?.order?.shippingAddress?.country }}</div>
                        <div *ngIf="txData?.order?.shippingContact?.phoneNumber" class="mt-4">
                            <fa-icon [icon]="faPhone"></fa-icon>
                            {{ txData?.order?.shippingContact?.phoneNumber }}
                        </div>
                        <div *ngIf="txData?.order?.shippingContact?.email">
                            <fa-icon [icon]="faEnvelope"></fa-icon>
                            {{ txData?.order?.shippingContact?.email }}
                        </div>
                        <div class="mt-4">
                            <small>This is the name and address of the person who will receive the
                                product/service (e.g., the person who will be taking a test or
                                is requesting scores); or, if you ordered items to be shipped,
                                they will be sent to this address.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
