<div class="card-body" >
  <table class="table table-bordered">
    <tr>
      <th>Date Created</th>
      <th>Created By</th>
      <th>Notes</th>
    </tr>
    <tr *ngFor="let ordMsg of ordMsgList">
      <td>{{ordMsg.createdTimestamp |date:'medium'}}</td>
      <td>{{ordMsg.createdBy}}</td>
      <td class="audit-msg">{{ordMsg.message}}</td>
    </tr>

  </table>

  <div class="float-end pt-5">
    <button class="btn btn-primary btn-function" (click)="parentRef.close()">
      Exit
    </button>
  </div>
</div>
