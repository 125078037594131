<div class="mt-4 container-xl">
  <div class="row mt-4">
    <div class="col-12">
      <h2>User Details and Activity</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary btn-function mt-2" (click)="back()">
        <fa-icon [icon]="faChevronLeft"></fa-icon> Back
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12" id="accordion">
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>
                <fa-icon [icon]="faSearch"></fa-icon> User Details for <span class="text-capitalize">{{
                  userData?.firstName }} {{ userData?.lastName }}</span>
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="card">
            <div class="card-body ps-5">
              <div class="row" *ngIf="userData">
                <div class="col-md-6">
                  <table>
                    <tr>
                      <th class="pe-4">User ID</th>
                      <td>{{ userData?.userId }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">User Login ID</th>
                      <td>{{ userData?.userLoginId }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Last Name</th>
                      <td>{{ userData?.lastName }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">First Name</th>
                      <td>{{ userData?.firstName }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Email Address</th>
                      <td>{{ userData?.emailId }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Associated OSC</th>
                      <td>{{ getOscCodes(userData) }}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table>
                    <tr>
                      <th class="pe-4">Associated Program Group</th>
                      <td>{{ getProgramGroupList(userData) }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Account Type</th>
                      <td>{{ userData?.accountType }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Status</th>
                      <td>{{ userData?.state }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Role Name</th>
                      <td>{{ userData?.role?.roleName }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Role Desc</th>
                      <td>{{ userData?.role?.roleDesc }}</td>
                    </tr>
                    <tr>
                      <th class="pe-4">Created Date</th>
                      <td>{{ userData?.createdDate| date }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12 d-flex justify-content-between pb-2">
            <h3>User Account Activity Log</h3>
            <button class="btn btn-primary" (click)="openModal(exportSave)">Export</button>
          </div>

          <div class="table-responsive">
            <mat-table #userAccountActivityLog matTableExporter class="table table-responsive table-bordered" [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="userId">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [hidden]="true">
                  User ID
                </th>
                <td mat-cell *matCellDef="let element" [hidden]="true">
                  {{ element.userId}}
                </td>
              </ng-container>
              <ng-container matColumnDef="userLoginId">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [hidden]="true">
                  User Login Id
                </th>
                <td mat-cell *matCellDef="let element"  [hidden]="true">
                  {{ element.userLoginId}}
                </td>
              </ng-container>
              <ng-container matColumnDef="firstName" >
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef  [hidden]="true">
                  First Name
                </th>
                <td mat-cell *matCellDef="let element"  [hidden]="true">
                  {{ element.firstName  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="lastName" >
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef  [hidden]="true">
                  Last Name
                </th>
                <td mat-cell *matCellDef="let element"  [hidden]="true">
                  {{ element.lastName  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="emailId" >
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef  [hidden]="true">
                  Email Id
                </th>
                <td mat-cell *matCellDef="let element"  [hidden]="true">
                  {{ element.emailId  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actionDtm">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  Entry Date
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.actionDtm | date }}
                </td>
              </ng-container>

              <ng-container matColumnDef="userNam">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  Updated By
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.userNam }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actionTxt">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  Action Taken
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.actionTxt }}
                </td>
              </ng-container>

              <ng-container matColumnDef="oldValue">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  Before Data
                </th>
                <td mat-cell *matCellDef="let element">
                  <ul>
                    <li *ngFor="let compareInfo of element?.compareValueInfos">
                      {{ compareInfo.key }} : {{ compareInfo.oldValue }}
                    </li>
                  </ul>
                </td>
              </ng-container>

              <ng-container matColumnDef="newValue">
                <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                  After Data
                </th>
                <td mat-cell *matCellDef="let element">
                  <ul>
                    <li *ngFor="let compareInfo of element?.compareValueInfos">
                      {{ compareInfo.key }} : {{ compareInfo.newValue }}
                    </li>
                  </ul>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="col-12" id="accordion2">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>
                  Login History for <span class="text-capitalize">{{
                  userData?.firstName }} {{ userData?.lastName }}</span>
                </h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="card">
              <div class="col-md-12 d-flex justify-content-between p-2">
                <div>&nbsp;</div>
                <button class="btn btn-primary" (click)="openLoginHistoryExportModal(exportSave);">Export</button>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <mat-table #loginHistory matTableExporter class="table table-responsive table-bordered" [dataSource]="loginHistoryDataSource" matSortDirection="desc"
                             matSort (matSortChange)="sortLoginHistoryData($event)" matSortActive="userLoginTimestamp">
                    <ng-container matColumnDef="userId">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [hidden]="true">
                        User ID
                      </th>
                      <td mat-cell *matCellDef="let element" [hidden]="true">
                        {{ element.userId}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="userLoginId">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef [hidden]="true">
                        User Login Id
                      </th>
                      <td mat-cell *matCellDef="let element"  [hidden]="true">
                        {{ element.userLoginId}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="firstName" >
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef  [hidden]="true">
                        First Name
                      </th>
                      <td mat-cell *matCellDef="let element"  [hidden]="true">
                        {{ element.firstName  }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName" >
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef  [hidden]="true">
                        Last Name
                      </th>
                      <td mat-cell *matCellDef="let element"  [hidden]="true">
                        {{ element.lastName  }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="emailId" >
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef  [hidden]="true">
                        Email Id
                      </th>
                      <td mat-cell *matCellDef="let element"  [hidden]="true">
                        {{ element.emailId  }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="userLoginTimestamp">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        Login Date
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.userLoginTimestamp | date }}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="loginHistoryDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: loginHistoryDisplayedColumns"></tr>
                  </mat-table>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>

  <ng-template #exportSave>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <fa-icon [icon]="faDownload"></fa-icon> {{exportActivityLog?'User Account Activity Log':'Login History'}}
      </h4>
      <div align="end">
        <button mat-icon-button class="close-button" (click)="modalRef.hide()">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="modal-body">
      Please select a file format.

      <div>
        <mat-radio-group aria-labelledby="export-radio-group-label" class="export-radio-group"
                         [(ngModel)]="exportOption">
          <mat-radio-button class="export-radio-button" value="xls">
            Excel file (.xls)
          </mat-radio-button>
          <mat-radio-button class="export-radio-button" value="csv">
            Comma separated values (.csv)
          </mat-radio-button>

        </mat-radio-group>
      </div>

    </div>
    <div class="modal-footer">
      <button class="btn btn-primary btn-function" (click)="modalRef.hide()">Exit</button> <button
      class="btn btn-primary" (click)="exportData(exportOption)">Export</button>
    </div>
  </ng-template>
</div>
