import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../service/shared.service';
import { validateIfAtleastOneRequiredIsPresent } from '../shared/line-item-validator';

@Component({
  selector: 'app-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.css']
})
export class LineItemsComponent {

  @Output('lineItems') public emitLineItems = new EventEmitter(); // eslint-disable-line
  @ViewChild('table') table: MatTable<any>;
  lineItemDetailColumns = ['action', 'productName','productSKU', 'productCode', 'quantity', 'unitPrice', 'taxAmount'];
  lineItemDataSource: MatTableDataSource<AbstractControl>;
  lineItems = new FormArray([]);

  addOrderItems=true;

  constructor(private fb: FormBuilder,
              private sharedService: SharedService) {
    this.lineItemDataSource = new MatTableDataSource(this.lineItems.controls);

    this.sharedService.resetLineItems$.subscribe(isReset =>{
      if (isReset) {
        this.reset();
      }
    });
  }

@Input("allowOrderItems")
  set allowOrderItems(data){
    this.addOrderItems=data;
  }

  addLineItem() {
    const lintItem = this.lineItems;
    lintItem.push(this.fb.group({
      lineNumber: this.lineItems.length,
      productSKU: [null, [Validators.maxLength(20)]],
      productCode: [null, [Validators.maxLength(20)]],
      productName: [null,[Validators.maxLength(255)]],
      unitPrice: [null, [Validators.required]],
      quantity: [null],
      taxAmount: [null]
    }, {validator: validateIfAtleastOneRequiredIsPresent('productSKU','productCode')}));
    this.table?.renderRows();
  }

  deleteLineItem(rowIndex) {
    this.lineItems.removeAt(rowIndex);
    this.table?.renderRows();
    this.emitLineItemsData();
  }

  trackRows(index: number, row: AbstractControl) {
    return row.value.uid;
  }

  reset() {
    this.lineItems.clear();
    this.table?.renderRows();
  }

  emitLineItemsData() {
    this.emitLineItems.emit(this.lineItems);
  }
}
