import {
  HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReconciliationResponce } from '../dashboard/model/reconciliation';
import { ReconciliationFiles } from '../dashboard/model/reconciliation-files';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getReconciliationDetailData(reconciliationType, date, recordType,page?:number,pageSize?:number) {
    return this.http.get<ReconciliationResponce[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/reconciliationDetail`,
      {params: {'reconciliationType': reconciliationType, 'date': date, 'recordType': recordType,page:page,pageSize:pageSize}});
  }
  exportReconciliationDetail(reconciliationType, date, recordType, totalRecords) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/search/reconciliationDetail/export`,
      {responseType:'blob', params: {'reconciliationType': reconciliationType, 'date': date, 'recordType': recordType,'totalRecords':totalRecords}});
  }

  getReconciliationSummaryData(summaryDate) {
    return this.http.get<ReconciliationFiles[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/reconciliationSummary`,
      {params: {'summaryDate': summaryDate}});
  }

  getReconciliationSummaryDataByFile(startDate, endDate, reconciliationType: string) {
    return this.http.get<ReconciliationFiles[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/reconciliationSummaryByFile`,
      {params: {'startDate': startDate, 'endDate': endDate, 'reconciliationType': reconciliationType}});
  }

  getReconciliationDetailByFileData(reconciliationType, fildIdDate, recordType,page?:number,pageSize?:number) {
    return this.http.get<ReconciliationResponce[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/reconciliationDetailByFile`,
      {params: {'reconciliationType': reconciliationType, 'fileIdDate': fildIdDate, 'recordType': recordType,page:page,pageSize:pageSize}});
  }
  exportReconciliationDetailByFileData(reconciliationType, fildIdDate, recordType,totalRecords) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/search/reconciliationDetailByFile/export`,
      {responseType:'blob',params: {'reconciliationType': reconciliationType, 'fileIdDate': fildIdDate, 'recordType': recordType,'totalRecords':totalRecords}});
  }

  getCFSDetailsByFile(reconciliationType, fildIdDate, recordType,processedDate,page?:number,pageSize?:number) {
    return this.http.get<ReconciliationResponce[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/cfsDetailsByFile`,
      {params: {'cfsType': reconciliationType, 'fileIdDate': fildIdDate, 'recordType': recordType,'processedDate':processedDate ,page:page,pageSize:pageSize}});
  }

  getCFSDetails(reconciliationType, date, recordType,page?:number,pageSize?:number) {
    return this.http.get<ReconciliationResponce[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/cfsDetails`,
      {params: {'cfsType': reconciliationType, 'date': date, 'recordType': recordType,page:page,pageSize:pageSize}});
  }

  getCFSDetailsByFileCount(reconciliationType, fildIdDate, recordType,processedDate) {
    return this.http.get<number>(`${environment.ADMIN_API_HOSTNAME}/data/search/cfsDetailsByFile/count`,
      {params: {'cfsType': reconciliationType, 'fileIdDate': fildIdDate, 'recordType': recordType,'date':processedDate}});
  }
  getCFSDetailsCount(reconciliationType, date, recordType) {
    return this.http.get<ReconciliationResponce[]>(`${environment.ADMIN_API_HOSTNAME}/data/search/cfsDetails/count`,
      {params: {'cfsType': reconciliationType, 'date': date, 'recordType': recordType}});
  }
  exportCFSDetails(reconciliationType, date, recordType) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/search/cfsDetails/export`,
      {responseType:'blob',params: {'cfsType': reconciliationType, 'date': date, 'recordType': recordType}});
  }
  exportCFSDetailsByFile(reconciliationType, fildIdDate, recordType,processedDate) {
    return this.http.get(`${environment.ADMIN_API_HOSTNAME}/data/search/cfsDetails/exportByFile`,
      { responseType:'blob', params: {'cfsType': reconciliationType, 'fileIdDate': fildIdDate, 'recordType': recordType,'date':processedDate }});
  }
}
