<div id="general-business-config" *ngIf="loggedInUser?.oscDetails?.length > 0">
  <div class="breadcrumb row">
    <span>OSC and HOP Configuration</span>
  </div>
  <div class="container-xl mt-4">
      <div class="col-md-12">
        <h2>General Business/OSC Configuration</h2>
    </div>
    <div class="pt-3 col-md-12 text-start">
      <button class="btn btn-primary col-lg-2 col-md-4 p-2 col-12 me-4 mb-3" (click)="addNewBusinessOSCConfig()">
        Add New Business Unit
      </button>
      <button
          class="btn btn-primary col-lg-2 col-md-4 p-2 col-12 mb-3" [disabled]="!selectedOSCToClone" (click)="navigate(selectedOSCToClone, true)">
          Clone Business Unit
      </button>
  </div>
    <h4 class="viewEditConfig">
      Select an existing OSC to View and Edit Configuration
    </h4>

    <div class="row">
      <div class="col-lg-2 col-12 col-md-12 pt-3">
        <mat-form-field appearance="outline">
          <mat-label>Filter by OSC</mat-label>
          <input
            matInput
            placeholder="Ex. ium"
            #osc
            (keyup)="applyFilter($event.target.value, 'osc')"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-2 col-12 col-md-12 pt-4 mt-2 fw-bold text-center">
        OR  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Created Date
      </div>
      <div class="col-lg-2 col-12 col-md-12 pt-3 datePicker">
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            #createdDate
            (dateChange)="applyFilter($event.target.value, 'createdDate', true)"
            [matDatepicker]="createdDatePicker"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="createdDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #createdDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-2 col-12 col-md-12 pt-4 mt-2 fw-bold text-center">
        OR  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Updated Date
      </div>
      <div class="col-lg-2 col-12 col-md-12 pt-3 datePicker">
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            #updatedDate
            (dateChange)="applyFilter($event.target.value, 'updatedDate', true)"
            [matDatepicker]="updatedDatePicker"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="updatedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #updatedDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-2 col-12 col-md-12 reset">
        <button class="btn btn-primary mt-3 me-4" (click)="filterReset()">
          Reset Filter
        </button>
      </div>
    </div>
    <div class="col-md-12">
      <mat-table
        [dataSource]="dataSource" matSortDisableClear (matSortChange)="addCustomSort($event)"
        matSort
        class="table table-responsive table-bordered"
      >
      <ng-container matColumnDef="selectToClone">
        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef> Select</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [checked]="element.osc === selectedOSCToClone" (change)="updateSelection($event, element)"></mat-checkbox>
        </td>
      </ng-container>
        <ng-container matColumnDef="osc">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            OSC
          </th>
          <td mat-cell *matCellDef="let element">
            <a
              class="link"
              [routerLink]="[]" (click)="navigate(element.osc)"
            >
              {{ element.osc }}</a
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            Description
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            Created Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.createdDate | date }}
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedDate">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            Updated Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.updatedDate | date }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </mat-table>
    </div>
    <mat-paginator
      [length]="100"
      [pageSize]="100"
      [pageSizeOptions]="[25, 50, 100]"
    >
    </mat-paginator>
  </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
  <div class="col-md-2"></div>
  <div class="col-md-8 alert alert-danger" role="alert">
    Originating System Code or Program Group need to be associated in order to
    access the page.
  </div>
  <div class="col-md-2"></div>
</div>
