import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
export interface IDynamicDialogConfig {
  modalContent?: any;
  txData?: any;
  actionType?: string;
  actionState?: string;
  calculatedRefundAmount?: number;
  errorData?: any;
}

@Component({
  selector: 'app-action-links-modal',
  templateUrl: './action-links-modal.component.html',
  styleUrls: ['./action-links-modal.component.css']
})
export class ActionLinksModalComponent {
  faCheckCircle = faCheckCircle;
  constructor(@Inject(MAT_DIALOG_DATA) public data: IDynamicDialogConfig, public dialogRef: MatDialogRef<ActionLinksModalComponent>){
    dialogRef.disableClose = true;
  }

  exit() {
    this.dialogRef.close();
  }

}
