import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatetext'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: any, length: number): string {

    //https://en.wikipedia.org/wiki/Longest_word_in_English
    const biggestWord = 50;
    const elipses = "...";
    let valToString = "";

    if(typeof value === "undefined") return value;
    if(typeof value === "object")
      valToString = value.toString();

    if(valToString.length <= length)
       return value;
    else {
      //.. truncate to about correct lenght

      let truncatedText = valToString.slice(0, length + biggestWord);

      //.. now nibble ends till correct length
      while (truncatedText.length > length - elipses.length) {
          let lastSpace = truncatedText.lastIndexOf(" ");

          if(lastSpace === -1) break;

          truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?]$/, '');

      };
      return truncatedText + elipses;
    }
  }

}
