import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {ChargebackRequest} from '../model/chargeback-request';

const adminApiBaseUrl = environment.ADMIN_API_HOSTNAME;
@Injectable({
  providedIn: 'root',
})
export class RefundService {
  constructor(private http: HttpClient) {}
  resetRefundForm = new Subject();

  sendRefundTxData(refundRequest) {
    const apiURL = `${adminApiBaseUrl}/refund/refund`;
    return this.makeCallout(apiURL, refundRequest);
  }

  sendOverRideRefundTxData(overRideRefundRequest, orderId) {
    const apiURL = `${adminApiBaseUrl}/payment/refund/linked/orders/${orderId}`;
      return this.makeCallout(apiURL, overRideRefundRequest);
  }

  processChargeBack(chargebackRequest: ChargebackRequest) {
    const url = `${adminApiBaseUrl}/data/create/chargeback`;
    return this.http.post(url, chargebackRequest);
  }

  crateSubscriptionSaleRequest(refundRequest) {
    const apiURL = `${adminApiBaseUrl}/payment/transactions/sale/subscription`;
    return this.makeCallout(apiURL, refundRequest);
  }

  private errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server Error');
  }

  private makeCallout(apiURL: string, requestObject: any) {
    return this.http
      .post<any>(apiURL, requestObject, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((event) => {
          if (event.type == HttpEventType.Response) {
            return event;
          }
        }),
        catchError((err) => {
          return this.errorHandler(err);
        })
      );
  }
}
