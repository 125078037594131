import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OSCSpecificHOPDetails } from '../model/osc-specific-hop-details';
@Injectable({
  providedIn: 'root',
})
export class OSCSpecificHOPService {
  constructor(private http: HttpClient) {}

  getOSCSPecificHOPDetails(OSCCode, HOPId): Observable<OSCSpecificHOPDetails> {
    return this.http.get<OSCSpecificHOPDetails>(
      `${environment.ADMIN_API_HOSTNAME}/config/oscspecifichop/${OSCCode}/hop/${HOPId}`
    );
  }

  saveOSCSPecificHOPDetails(oscSpecificHOPData: OSCSpecificHOPDetails) {
    return this.http.put(`${environment.ADMIN_API_HOSTNAME}/config/oscspecifichop`, oscSpecificHOPData);
  }
}
