import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from 'src/app/service/dashboard.service';
import {summary_bar_data} from '../model/reconciliation';
import { ReconciliationFiles } from '../model/reconciliation-files';


@Component({
  selector: 'app-reconciliation-dashboard',
  templateUrl: './reconciliation-dashboard.component.html',
  styleUrls: ['./reconciliation-dashboard.component.scss'],
})
export class ReconciliationDashboardComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'reconciliationType',
    'fileProcessed',
    'startDate',
    'endDate',
    'totalRecords',
    'errorRecords',
    'successRecords',
    'noMatchRecords',
    'status',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<ReconciliationFiles>();
  selectDate;
  SummaryBardata = [];
  view: any[] = [600, 150];
  colorScheme = {
    domain: ['#dfa320'],
  };
  totalSuccessPercent: number;
  enableReconciliationSummary: boolean;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  ngAfterViewInit() {
    this.sort.sort({ id: 'reconciliationType', start: 'asc' } as MatSortable);
    this.dataSource.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
  }
  ngOnInit(): void {
    this.getData();
  }

  getData(event?) {
    this.selectDate = this.getDate(event);
    this.getReconciliationSummaryData();
  }

  getReconciliationSummaryData(){
    const reconciliationSummaryData =
      this.dashboardService.getReconciliationSummaryData(
        this.getFormatedDate()
      );
    reconciliationSummaryData.subscribe((data) => {
      if (data) {
        this.SummaryBardata = this.getReconciliationSummaryBarData(data);
        this.dataSource.data = this.processStatus(data);
        this.dataSource.paginator = this.paginator;
      } else {
        console.log('No data found for reconciliationSummary');
      }
    });
  }

  moveToPreviousDate() {
    if (this.selectDate) {
      const previousDate = new Date(this.selectDate.getTime());
      previousDate.setDate(this.selectDate.getDate() - 1);
      this.selectDate = previousDate;
      this.getReconciliationSummaryData();
    }
  }

  moveToNextDate() {
    if (this.selectDate) {
      const nextDate = new Date(this.selectDate.getTime());
      nextDate.setDate(this.selectDate.getDate() + 1);
      this.selectDate = nextDate;
      this.getReconciliationSummaryData();
    }
  }

  getDate(event?) {
    let date = this.route.snapshot.queryParamMap.get('date');
    if (event) {
      return event.value;
    } else if (date) {
      let newDate = new Date(date);
      return new Date(newDate.getTime() - newDate.getTimezoneOffset() * -60000);
    } else {
      return new Date();
    }
  }

  processStatus(data) {
    let fileData = [];
    data.forEach((element) => {
      if (element.errorRecords > 0 && element.fileProcessed) {
        element['status'] = 'yellow';
      } else if (element.fileProcessed && element.errorRecords === 0) {
        element['status'] = 'green';
      } else if (!element.fileProcessed) {
        element['status'] = 'red';
      }
      fileData.push(element);
    });
    return fileData;
  }

  getFormatedDate() {
    return new Date(
      this.selectDate.getTime() - this.selectDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0];
  }

  getReconciliationSummaryBarData(data) {
    let summaryData = [];
    let totalData = {errorRecords: 0, noMatchRecords: 0, totalRecords: 0, successRecords: 0}
    data.forEach((file) => {
      totalData.errorRecords = file.errorRecords + totalData.errorRecords;
      totalData.noMatchRecords = file.noMatchRecords + totalData.noMatchRecords;
      totalData.totalRecords = file.totalRecords + totalData.totalRecords;
      totalData.successRecords = file.successRecords + totalData.successRecords;
    });
    this.enableReconciliationSummary = totalData.totalRecords > 0;
    this.totalSuccessPercent = totalData.totalRecords > 0 && (totalData.successRecords / totalData.totalRecords)*100;
    summary_bar_data.forEach(record => {
      summaryData.push({
        name: record.name,
        value: totalData[record.key]
      })
    })
    return summaryData;
  }
}
