<div class="pt-3">
<form [formGroup]="retrieval">
    <div class="mb-3">
        <label for="amount"><span class="required-asterisk">*</span> Amount</label>
        <input class="form-control form-control-md" formControlName="amount" />
    </div>
    <div class="row col-md-12">
        <button class="col-md-1 mt-2 btn btn-primary btn-function" (click)="parentRef.close()">
            Exit
        </button>
        <button class="btn btn-primary btn-function col-md-2 ms-2 mt-2 " (click)="retrieval.reset()">
            Reset Form
        </button>
        <button class="btn btn-primary col-md-3 ms-2 mt-2 " (click)="parentRef.close(); actionLinkService.openDialog('RetrievalConfirmation', 'confirmation')">
            Request For Retrieval
        </button>
    </div>
</form>
</div>