import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynamicDialogConfig, MatDialogComponentComponent } from 'src/app/mat-dialog-component/mat-dialog-component.component';
import { User } from 'src/app/model/user';
import { CountryList } from 'src/app/order-search/order-search.component';
import { CountryRegionService } from 'src/app/service/country-region.service';
import { SharedService } from 'src/app/service/shared.service';
import { PaymentMethodsHOPConfig, addresses_and__options, attemps_timeout_options, avs_cvs_rules, billing_information, fraud_detect_3ds_currency, merchant_defined_fields, payments_types } from '../config';
import { BlockedCountryPayment } from 'src/app/model/blocked-country-payments';
import { BlockedFraudDetectCountry } from 'src/app/model/blocked-fraud-detect-country';
import { OSCSpecificHOPService } from 'src/app/service/osc-specific-hop-config.service';
import { OSCSpecificHOPDetails, MerchantDefinedDetails } from 'src/app/model/osc-specific-hop-details';
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-edit-osc-specific-hop-config',
  templateUrl: './edit-osc-specific-hop-config.component.html',
  styleUrls: ['./edit-osc-specific-hop-config.component.scss'],
})
export class EditOSCSpecificHOPConfigComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  countryList: CountryList[];
  dataSource: MatTableDataSource<BlockedCountryPayment>;
  dataFraudSource: MatTableDataSource<BlockedFraudDetectCountry>;
  dataSource3DS: MatTableDataSource<BlockedCountryPayment>;
  paymentMethods = PaymentMethodsHOPConfig;
  displayedColumns: string[] = ['country', 'paymentMethods'];
  displayedFraudColumns: string[] = ['country'];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, { static: false }) fraudSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild('fraudPaginator') fraudPaginator: MatPaginator;
  //fraudPageSizes = [7, 15, 30];
  tableLength: number;
  isDisabled: boolean;
  isFraudDisabled: boolean;
  hideMerchantDefinedFields=true;
  loggedInUser: User;
  selectedContextValue: string;
  validationMessage: boolean;
  payments_types;
  fraud_3ds_currency;
  avs_cvs_rules;
  billing_information;
  addresses_and__options;
  merchant_defined_fields;
  blocked_country_payment;
  blocked_fraud_detect_country;
  selected_fraud_country;
  three_ds_country_payment:BlockedCountryPayment[]=[];
  sub: any;
  HopConfig: FormGroup;
  oscConfigCodes = [];
  types: FormGroup;
  oscSpecificHOPData: OSCSpecificHOPDetails;
  errorMsg: string;
  threeDsFormData={};
  paymentItemSubCategoryMap={};
  payment3dsItemSubCategoryMap={};
  merchantDefinedDetails: MerchantDefinedDetails[];
  attemps_timeout_options: { name: string; class: string; items: { id: string; label: string; }[]; }[];
  constructor(
    private sharedService: SharedService,
    private countryRegionService: CountryRegionService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private oscSpecificHOPService: OSCSpecificHOPService
  ) {
    this.billing_information = billing_information;
    this.addresses_and__options = addresses_and__options;
    this.merchant_defined_fields = merchant_defined_fields;
    this.attemps_timeout_options = attemps_timeout_options;
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.oscConfigCodes = JSON.parse(sessionStorage.getItem('oscHopList'));
        this.updateBusinessDropDownvalue();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getOSCSPecificHOPDetails() {
    this.oscSpecificHOPService.getOSCSPecificHOPDetails(this.HopConfig.controls['business']?.value, this.selectedContextValue).subscribe({
      next: (data) => {
        this.oscSpecificHOPData = data;
        this.blocked_country_payment = this.oscSpecificHOPData.blockPymtOptRequests;
        this.blocked_fraud_detect_country = this.oscSpecificHOPData.blockFraudDtctnRequests;
        this.three_ds_country_payment=this.populate3dsBlockPaymentFromSrvrResp( this.oscSpecificHOPData);
        this.set3DSData(this.three_ds_country_payment);
        this.merchantDefinedDetails = data.merchantDefinedField;
        this.updateForm();
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
        this.errorMsg = '* ' + err?.error?.message;
      },
    });
  }

  populateFraudDetectCountriesFromSrvrResp(oscSpecificHOPData){
    const countriesLstData=[];
    if(oscSpecificHOPData && oscSpecificHOPData.blockFraudDtctnRequests) {
      for (let fraudDetectCountry of oscSpecificHOPData.blockFraudDtctnRequests) {
        countriesLstData.push(fraudDetectCountry.countryName);
      }
    }
    return countriesLstData;
  }

  populate3dsBlockPaymentFromSrvrResp(oscSpecificHOPData){
    const contryToPymtMethodMap={};
    if(oscSpecificHOPData && oscSpecificHOPData.block3dsByCountries) {
      for (let block3dsByCountry of oscSpecificHOPData.block3dsByCountries) {
        for(let cntry of block3dsByCountry.countryName.split(",")) {
          if (!contryToPymtMethodMap[cntry]) {
            contryToPymtMethodMap[cntry] = [];
          }
          const pymtMethodArr=contryToPymtMethodMap[cntry];
          if(pymtMethodArr.indexOf(block3dsByCountry.paymentMethod.paymentMethodName)==-1)
          {
            contryToPymtMethodMap[cntry].push(block3dsByCountry.paymentMethod.paymentMethodName);
          }
        }
      }
    }

    const three_ds_country_payment=Object.entries(contryToPymtMethodMap).map(d=>{
      return {
        countryName:d[0],
        paymentMethodName:(d[1] as []).join(",")
      };
    });

    return three_ds_country_payment;
  }

  updateForm() {
    this.types = new FormGroup({
      ccPaymentEnabled: new FormControl(this.oscSpecificHOPData?.ccPaymentEnabled),
      visaEnabled: new FormControl(this.oscSpecificHOPData?.visaEnabled),
      masterCardEnabled: new FormControl(this.oscSpecificHOPData?.masterCardEnabled),
      amexEnabled: new FormControl(this.oscSpecificHOPData?.amexEnabled),
      discoverEnabled: new FormControl(this.oscSpecificHOPData?.discoverEnabled),
      unionPayEnabled: new FormControl(this.oscSpecificHOPData?.unionPayEnabled),
      dinersClubEnabled: new FormControl(this.oscSpecificHOPData?.dinersClubEnabled),
      jcbEnabled: new FormControl(this.oscSpecificHOPData?.jcbEnabled),
      rupayGlobalEnabled: new FormControl(this.oscSpecificHOPData?.rupayGlobalEnabled),
      paypalEnabled: new FormControl(this.oscSpecificHOPData?.paypalEnabled),
      visaCSCRequired: new FormControl(this.oscSpecificHOPData?.visaCSCRequired),
      mastercardCSCRequired: new FormControl(this.oscSpecificHOPData?.mastercardCSCRequired),
      amexCSCRequired: new FormControl(this.oscSpecificHOPData?.amexCSCRequired),
      discoveryCSCRequired: new FormControl(this.oscSpecificHOPData?.discoveryCSCRequired),
      unionPayCSCRequired: new FormControl(this.oscSpecificHOPData?.unionPayCSCRequired),
      dinersClubCSCRequired: new FormControl(this.oscSpecificHOPData?.dinersClubCSCRequired),
      jcbCSCRequired: new FormControl(this.oscSpecificHOPData?.jcbCSCRequired),
      rupayGlobalCSCRequired: new FormControl(this.oscSpecificHOPData?.rupayGlobalCSCRequired),
      alipayEnabled: new FormControl(this.oscSpecificHOPData?.alipayEnabled),
      fraudDetectEnabled: new FormControl(this.oscSpecificHOPData?.fraudDetectEnabled),
      dccEnabled: new FormControl(this.oscSpecificHOPData?.dccEnabled),
      threeDSEnabled: new FormControl(this.oscSpecificHOPData?.threeDSEnabled),
      visa3dsEnabled: new FormControl(this.oscSpecificHOPData?.visa3dsEnabled),
      mc3dsEnabled: new FormControl(this.oscSpecificHOPData?.mc3dsEnabled),
      amex3dsEnabled: new FormControl(this.oscSpecificHOPData?.amex3dsEnabled),
      discover3dsEnabled: new FormControl(this.oscSpecificHOPData?.discover3dsEnabled),
      unionPay3dsEnabled: new FormControl(this.oscSpecificHOPData?.unionPay3dsEnabled),
      diners3dsEnabled: new FormControl(this.oscSpecificHOPData?.diners3dsEnabled),
      jcb3dsEnabled: new FormControl(this.oscSpecificHOPData?.jcb3dsEnabled),
      rupay3dsEnabled: new FormControl(this.oscSpecificHOPData?.rupay3dsEnabled),
      allowAuthorizationOnlyRequest: new FormControl(this.oscSpecificHOPData?.allowAuthorizationOnlyRequest),
      country: new FormControl(''),
      fraudDetectCountries: new FormControl([]),
      threeDsCountry: new FormControl([]),
      paymentMethods: new FormControl(''),
      threeDsPaymentMethods: new FormControl([]),
      ignoreAvsDeclineCodes: new FormControl(this.oscSpecificHOPData?.ignoreAvsDeclineCodes),
      avsDeclineCodeList: new FormControl(this.oscSpecificHOPData?.avsDeclineCodeList),
      ignoreCsvDeclineCodes: new FormControl(this.oscSpecificHOPData?.ignoreCsvDeclineCodes),
      csvDeclineCodeList: new FormControl(this.oscSpecificHOPData?.csvDeclineCodeList),
      displayBillingName: new FormControl(this.oscSpecificHOPData?.displayBillingName),
      displayBillingAddress: new FormControl(this.oscSpecificHOPData?.displayBillingAddress),
      displayShpngAsBillngNam: new FormControl(this.oscSpecificHOPData?.displayShpngAsBillngNam),
      displayShippingAsBillingAddr: new FormControl(this.oscSpecificHOPData?.displayShippingAsBillingAddr),
      displayShippingFlds: new FormControl(this.oscSpecificHOPData?.displayShippingFlds),
      editShippingFields: new FormControl(this.oscSpecificHOPData?.editShippingFields),
      merchantPostUrl: new FormControl(this.oscSpecificHOPData?.merchantPostUrl),
      successUrl: new FormControl(this.oscSpecificHOPData?.successUrl),
      maxRejectAtmptCount: new FormControl(this.oscSpecificHOPData?.maxRejectAtmptCount),
      rejectUrl: new FormControl(this.oscSpecificHOPData?.rejectUrl),
      errorUrl: new FormControl(this.oscSpecificHOPData?.errorUrl),
      sessionTimeoutMnts: new FormControl(this.oscSpecificHOPData?.sessionTimeoutMnts),
      timeoutUrl: new FormControl(this.oscSpecificHOPData?.timeoutUrl),
      seqNo_1: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 1)?.['seqNo_1'] || 'seqNo_1'),
      merchantDefinedFieldRequired_1: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 1)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_1: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 1)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_1: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 1)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_1: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 1)?.['displayMerchantDefinedField'] || false),
      seqNo_2: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 2)?.['seqNo_2'] || 'seqNo_2'),
      merchantDefinedFieldRequired_2: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 2)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_2: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 2)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_2: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 2)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_2: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 2)?.['displayMerchantDefinedField'] || false),
      seqNo_3: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 3)?.['seqNo_3'] || 'seqNo_3'),
      merchantDefinedFieldRequired_3: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 3)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_3: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 3)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_3: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 3)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_3: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 3)?.['displayMerchantDefinedField'] || false),
      seqNo_4: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 4)?.['seqNo_4'] || 'seqNo_4'),
      merchantDefinedFieldRequired_4: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 4)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_4: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 4)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_4: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 4)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_4: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 4)?.['displayMerchantDefinedField'] || false),
      seqNo_5: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 5)?.['seqNo_5'] || 'seqNo_5'),
      merchantDefinedFieldRequired_5: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 5)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_5: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 5)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_5: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 5)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_5: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 5)?.['displayMerchantDefinedField'] || false),
      seqNo_6: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 6)?.['seqNo_6'] || 'seqNo_6'),
      merchantDefinedFieldRequired_6: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 6)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_6: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 6)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_6: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 6)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_6: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 6)?.['displayMerchantDefinedField'] || false),
      seqNo_7: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 7)?.['seqNo_7'] || 'seqNo_7'),
      merchantDefinedFieldRequired_7: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 7)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_7: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 7)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_7: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 7)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_7: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 7)?.['displayMerchantDefinedField'] || false),
      seqNo_8: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 8)?.['seqNo_8'] || 'seqNo_8'),
      merchantDefinedFieldRequired_8: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 8)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_8: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 8)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_8: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 8)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_8: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 8)?.['displayMerchantDefinedField'] || false),
      seqNo_9: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 9)?.['seqNo_9'] || 'seqNo_9'),
      merchantDefinedFieldRequired_9: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 9)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_9: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 9)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_9: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 9)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_9: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 9)?.['displayMerchantDefinedField'] || false),
      seqNo_10: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 10)?.['seqNo_10'] || 'seqNo_10'),
      merchantDefinedFieldRequired_10: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 10)?.['merchantDefinedFieldRequired'] || false),
      editMerchantDefinedField_10: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 10)?.['editMerchantDefinedField'] || false),
      merchantDefinedFieldName_10: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 10)?.['merchantDefinedFieldName'] || ''),
      displayMerchantDefinedField_10: new FormControl(this.oscSpecificHOPData?.merchantDefinedField?.find(data => data['seqNo'] === 10)?.['displayMerchantDefinedField'] || false),
    });
    this.types?.disable();
    this.countryList = this.countryRegionService.getCountryCodes();
    this.setData();
    this.setFraudDetectCountriesData(this.blocked_fraud_detect_country);
    this.threeDsFormData=this.types.value;
    this.populateFormInfoValsFor3DS();
    this.populateFormInfoValsForFraudDetectSettings(this.oscSpecificHOPData);
  }

  updateBusinessDropDownvalue() {
    this.HopConfig = new FormGroup({
      business: new FormControl('', Validators.required),
      context: new FormControl('', Validators.required),
    });
    this.sub = this.route.params.subscribe((params) => {
      if (params['osc']) {
        this.isDisabled = !this.isDisabled;
        this.isFraudDisabled = !this.isFraudDisabled;
        
        this.HopConfig.controls['business'].setValue(params['osc']);
        this.HopConfig.controls['business'].disable();
      }
    });
  }

  getCountryName(cntryCode){
    if(this.countryList){
      for(const cntry of this.countryList){
        if(cntry.id==cntryCode){
          return cntry.name;
        }
      }
    }
    return cntryCode;
  }

  applyFilter(filterValue) {
    this._applyFilter(filterValue,this.dataSource);
  }
  apply3DSFilter(filterValue) {
    this._applyFilter(filterValue,this.dataSource3DS);
  }
  _applyFilter(filterValue,dataSource) {
    dataSource.filter = filterValue && filterValue.toLowerCase();
    dataSource.filterPredicate = function (
      data: BlockedCountryPayment,
      filterValue: string
    ) {
      return (
        data.countryName
          .trim()
          .toLocaleLowerCase()
          .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0
      );
    };
    if (dataSource.paginator) {
      dataSource.paginator.firstPage();
    }
  }
  setFraudDetectCountriesData(blockedFraudDetectCountries) {
    this.dataFraudSource = new MatTableDataSource(blockedFraudDetectCountries);
    this.tableLength = blockedFraudDetectCountries?blockedFraudDetectCountries.length:0;
    this.dataFraudSource.sort = this.fraudSort;
    //this.dataFraudSource.paginator = this.paginator;
  }
  setData() {
    this.dataSource = new MatTableDataSource(this.blocked_country_payment);
    this.tableLength = this.blocked_country_payment.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  set3DSData(threeDsData) {
    this.dataSource3DS = new MatTableDataSource(threeDsData);
    this.tableLength = threeDsData.length;
    this.dataSource3DS.sort = this.sort;
    this.dataSource3DS.paginator = this.paginator;
  }

  viewSelected() {
    this.isDisabled = true;
    this.isFraudDisabled = true;
    this.errorMsg = '';
    this.selectedContextValue = this.HopConfig.value.context;
    this.getOSCSPecificHOPDetails();
    if (this.selectedContextValue === 'IHOP') {
      this.payments_types = [
        ...payments_types.slice(0, 1),
        ...avs_cvs_rules.slice(1, 2),
      ];
      this.fraud_3ds_currency = fraud_detect_3ds_currency.slice(0, 2);
      this.avs_cvs_rules = avs_cvs_rules.slice(0, 1);
    } else if(this.selectedContextValue === 'CFHOP') {
      this.payments_types = payments_types.slice(0, 4);
      this.fraud_3ds_currency = [
        ...fraud_detect_3ds_currency,
        ...payments_types.slice(4, 5),
      ];
      this.avs_cvs_rules = avs_cvs_rules;
    } else {
      this.payments_types = payments_types.slice(0, 1);
      this.avs_cvs_rules = avs_cvs_rules.slice(0, 1);
      this.fraud_3ds_currency = fraud_detect_3ds_currency;
    }

  }

  populateFormInfoValsForFraudDetectSettings(oscSpecificHOPData){
    const countriesLst = this.populateFraudDetectCountriesFromSrvrResp(oscSpecificHOPData);
    this.types.controls['fraudDetectCountries'].setValue(countriesLst);
    console.log(countriesLst);
  }

  populateFormInfoValsFor3DS(){
    for(let item of this.payments_types){
      if(item.items) {
        for (let paymentItem of item.items) {
          if(paymentItem.subCategories) {
            for (let subCategory of paymentItem.subCategories) {
              if(item.name=='3DS') {
                this.on3dsPaymentItemSubCategoryChanged(subCategory.id, subCategory.label, this.types.get(subCategory.id).value);
              } else {
                this.onPaymentItemSubCategoryChanged(subCategory.id, subCategory.label, this.types.get(subCategory.id).value);
              }
            }
          }
        }
      }
    }
  }

  isEnabledFor3DS(subCategory) {
    return Object.values(this.paymentItemSubCategoryMap).filter(v => v['value'] && subCategory.label.indexOf(v['label'])>=0).length>0;

  }

  isEnabledFor3DSForPaymentMethod(paymentMethod) {
    // return this.types.get(paymentItem.id)?.value;

    let is3dsEnabled=false;
    let isPymtEnabled=false;
    for(const v of Object.values(this.payment3dsItemSubCategoryMap)){
      if( v['value'] && (paymentMethod.value.indexOf(v['label'])>=0 || paymentMethod.text.indexOf( v['label'] )>=0 ||
        v['label'].indexOf( paymentMethod.value )>=0 ||  v['label'].indexOf( paymentMethod.text )>=0)){

        is3dsEnabled= true;
        break;
      }
    }

    for(const v of Object.values(this.paymentItemSubCategoryMap)){
      if( v['value'] && (paymentMethod.value.indexOf(v['label'])>=0 || paymentMethod.text.indexOf( v['label'] )>=0 ||
        v['label'].indexOf( paymentMethod.value )>=0 ||  v['label'].indexOf( paymentMethod.text )>=0)){

        isPymtEnabled= true;
        break;
      }
    }

    return is3dsEnabled && isPymtEnabled;

  }

  openDialog(data) {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      width: '350px',
      data: <IDynamicDialogConfig>{
        title: 'Do you want to exit without saving?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value && value === 'noExit') {
        this.isDisabled = !this.isDisabled;
        this.isFraudDisabled = !this.isFraudDisabled;
        
        this.types?.disable();
        this.accordion?.closeAll();
      } else if (data === 'back') {
        this.router.navigate(['osc-specific-hop-config']);
      }
    });
  }

  saveExit() {
    this.isDisabled = !this.isDisabled;
    this.isFraudDisabled = !this.isFraudDisabled;
    
    this.accordion?.closeAll();
    this.types?.disable();
    const oscSpecificHOPData = this.prepareOscSpecificHopData();
    const obs = this.oscSpecificHOPService.saveOSCSPecificHOPDetails(oscSpecificHOPData);
    obs.subscribe({
      next: (data) => {
        this.validationMessage = true;
        this.errorMsg = '';
      },
      error: (err) => {
        console.log('error', err);
        this.errorMsg =
          `Error while processing the request. ${err?.error?.message}`;
      },
    });
  }

  prepareOscSpecificHopData() {
    let oscSPecificHOPDetails = {} as OSCSpecificHOPDetails;
    oscSPecificHOPDetails.hopId = this.selectedContextValue;
    oscSPecificHOPDetails.oscCode = this.HopConfig.controls['business'].value;
    oscSPecificHOPDetails.maxRejectAtmptCount = this.types.controls['maxRejectAtmptCount'].value;
    oscSPecificHOPDetails.sessionTimeoutMnts = this.types.controls['sessionTimeoutMnts'].value;
    oscSPecificHOPDetails.amexEnabled = this.types.controls['amexEnabled'].value;
    oscSPecificHOPDetails.dinersClubEnabled = this.types.controls['dinersClubEnabled'].value;
    oscSPecificHOPDetails.discoverEnabled = this.types.controls['discoverEnabled'].value;
    oscSPecificHOPDetails.jcbEnabled = this.types.controls['jcbEnabled'].value;
    oscSPecificHOPDetails.masterCardEnabled = this.types.controls['masterCardEnabled'].value;
    oscSPecificHOPDetails.rupayGlobalEnabled = this.types.controls['rupayGlobalEnabled'].value;
    oscSPecificHOPDetails.unionPayEnabled = this.types.controls['unionPayEnabled'].value;
    oscSPecificHOPDetails.visaEnabled = this.types.controls['visaEnabled'].value;
    oscSPecificHOPDetails.ccPaymentEnabled = this.types.controls['ccPaymentEnabled'].value;
    oscSPecificHOPDetails.paypalEnabled = this.types.controls['paypalEnabled'].value;
    oscSPecificHOPDetails.threeDSEnabled = this.types.controls['threeDSEnabled'].value;
    oscSPecificHOPDetails.dccEnabled = this.types.controls['dccEnabled'].value;
    oscSPecificHOPDetails.fraudDetectEnabled = this.types.controls['fraudDetectEnabled'].value;
    oscSPecificHOPDetails.allowAuthorizationOnlyRequest = this.types.controls['allowAuthorizationOnlyRequest'].value;
    oscSPecificHOPDetails.avsDeclineCodeList = this.types.controls['avsDeclineCodeList'].value;
    oscSPecificHOPDetails.csvDeclineCodeList = this.types.controls['csvDeclineCodeList'].value;
    oscSPecificHOPDetails.ignoreAvsDeclineCodes = this.types.controls['ignoreAvsDeclineCodes'].value;
    oscSPecificHOPDetails.ignoreCsvDeclineCodes = this.types.controls['ignoreCsvDeclineCodes'].value;
    oscSPecificHOPDetails.displayBillingAddress = this.types.controls['displayBillingAddress'].value;
    oscSPecificHOPDetails.displayBillingName = this.types.controls['displayBillingName'].value;
    oscSPecificHOPDetails.displayShippingAsBillingAddr = this.types.controls['displayShippingAsBillingAddr'].value;
    oscSPecificHOPDetails.displayShpngAsBillngNam = this.types.controls['displayShpngAsBillngNam'].value;
    oscSPecificHOPDetails.displayShippingFlds = this.types.controls['displayShippingFlds'].value;
    oscSPecificHOPDetails.editShippingFields = this.types.controls['editShippingFields'].value;
    oscSPecificHOPDetails.amexCSCRequired = this.types.controls['amexCSCRequired'].value;
    oscSPecificHOPDetails.dinersClubCSCRequired = this.types.controls['dinersClubCSCRequired'].value;
    oscSPecificHOPDetails.discoveryCSCRequired = this.types.controls['discoveryCSCRequired'].value;
    oscSPecificHOPDetails.jcbCSCRequired = this.types.controls['jcbCSCRequired'].value;
    oscSPecificHOPDetails.mastercardCSCRequired = this.types.controls['mastercardCSCRequired'].value;
    oscSPecificHOPDetails.rupayGlobalCSCRequired = this.types.controls['rupayGlobalCSCRequired'].value;
    oscSPecificHOPDetails.unionPayCSCRequired = this.types.controls['unionPayCSCRequired'].value;
    oscSPecificHOPDetails.visaCSCRequired = this.types.controls['visaCSCRequired'].value;
    oscSPecificHOPDetails.errorUrl = this.types.controls['errorUrl'].value;
    oscSPecificHOPDetails.merchantPostUrl = this.types.controls['merchantPostUrl'].value;
    oscSPecificHOPDetails.rejectUrl = this.types.controls['rejectUrl'].value;
    oscSPecificHOPDetails.successUrl = this.types.controls['successUrl'].value;
    oscSPecificHOPDetails.timeoutUrl = this.types.controls['timeoutUrl'].value;
    oscSPecificHOPDetails.createdBy = this.oscSpecificHOPData.createdBy;
    oscSPecificHOPDetails.createdDate = this.oscSpecificHOPData.createdDate;
    oscSPecificHOPDetails.updatedBy = this.loggedInUser.emailId;
    oscSPecificHOPDetails.updateDate = new Date().toISOString();
    oscSPecificHOPDetails.amex3dsEnabled = this.types.controls['amex3dsEnabled'].value;
    oscSPecificHOPDetails.diners3dsEnabled = this.types.controls['diners3dsEnabled'].value;
    oscSPecificHOPDetails.discover3dsEnabled = this.types.controls['discover3dsEnabled'].value;
    oscSPecificHOPDetails.jcb3dsEnabled = this.types.controls['jcb3dsEnabled'].value;
    oscSPecificHOPDetails.mc3dsEnabled = this.types.controls['mc3dsEnabled'].value;
    oscSPecificHOPDetails.rupay3dsEnabled = this.types.controls['rupay3dsEnabled'].value;
    oscSPecificHOPDetails.unionPay3dsEnabled = this.types.controls['unionPay3dsEnabled'].value;
    oscSPecificHOPDetails.visa3dsEnabled = this.types.controls['visa3dsEnabled'].value;
    oscSPecificHOPDetails.alipayEnabled = this.types.controls['alipayEnabled'].value;
    oscSPecificHOPDetails.bankAccountEnabled = this.oscSpecificHOPData.bankAccountEnabled;
    oscSPecificHOPDetails.billingFieldsRequired =  oscSPecificHOPDetails.displayBillingAddress|| oscSPecificHOPDetails.displayBillingName;
    oscSPecificHOPDetails.reqShippingFieldsRequired = this.types.controls['displayShippingFlds'].value;
    oscSPecificHOPDetails.cssUrl = this.oscSpecificHOPData.cssUrl;
    oscSPecificHOPDetails.paypalHdrImgUrl = this.oscSpecificHOPData.paypalHdrImgUrl;
    oscSPecificHOPDetails.brandingEnabled = this.oscSpecificHOPData.brandingEnabled;
    oscSPecificHOPDetails.blockPymtOptRequests = this.blocked_country_payment;
    oscSPecificHOPDetails.merchantDefinedField = this.prepareMerchantDefinedFields();
    oscSPecificHOPDetails.programGroup = this.oscSpecificHOPData.programGroup;

    const three_ds_country_payment=this.three_ds_country_payment.map(d=>{
      return {
        countryName:d.countryName,
        paymentMethodName:d.paymentMethodName.split(",")
      };
    });

    oscSPecificHOPDetails.block3dsByCountriesRequests=three_ds_country_payment ;
    oscSPecificHOPDetails.blockFraudDtctnRequests = this.blocked_fraud_detect_country;

    return oscSPecificHOPDetails;
  }

  prepareMerchantDefinedFields() {
    let merchantDefinedField: any = [];

    for (let i = 1; i <= 10; i++) {
      if (this.types.controls['merchantDefinedFieldName_' + i].value !== '' ||
        this.types.controls['merchantDefinedFieldRequired_' + i].value === true ||
        this.types.controls['editMerchantDefinedField_' + i].value === true) {
        merchantDefinedField.push(this.getMerchantObject(i));
      }
      else {
        for (let j = 0; j < this.merchantDefinedDetails.length; j++) {
          if (this.merchantDefinedDetails[j].seqNo == i) {
            merchantDefinedField.push(this.getMerchantObject(i));
          }
        }
      }
    }
    return merchantDefinedField;
  }

  getMerchantObject(index: number) {
    const merchantObject = {} as MerchantDefinedDetails;
    merchantObject.seqNo = index;
    merchantObject.merchantDefinedFieldName = this.types.controls['merchantDefinedFieldName_' + index].value;
    merchantObject.displayMerchantDefinedField = this.types.controls['displayMerchantDefinedField_' + index].value;
    merchantObject.merchantDefinedFieldRequired = this.types.controls['merchantDefinedFieldRequired_' + index].value;
    merchantObject.editMerchantDefinedField = this.types.controls['editMerchantDefinedField_' + index].value;

    return merchantObject;
  }

  exit() {
    this.openDialog('back');
  }

  editConfig() {
    this.isDisabled = !this.isDisabled;
    // (this.blocked_fraud_detect_country.length == 0) ? true : 
    this.isFraudDisabled = !this.isFraudDisabled;
    
    this.types?.enable();
    this.updateCCControls();
    this.update3dsControls();
    this.enableOrDisableMerchantDefinedFields();
    this.validationMessage = false;


  }
  enableCtrl(ctrlName:string){
    this.types.controls[ctrlName].enable()
  }
  disableCtrl(ctrlName:string){
    this.types.controls[ctrlName].disable()
  }

  is3dsEnabled(){
    return this.types.controls['threeDSEnabled'].value;
  }

  update3dsControls(){
    const ctrls=[];
    ctrls.push('visa3dsEnabled');
    ctrls.push('mc3dsEnabled');
    ctrls.push('amex3dsEnabled');
    ctrls.push('unionPay3dsEnabled');
    ctrls.push('diners3dsEnabled');
    ctrls.push('jcb3dsEnabled');
    ctrls.push('rupay3dsEnabled');
    ctrls.push('discover3dsEnabled');
    ctrls.push('threeDsCountry');
    ctrls.push('threeDsPaymentMethods');

    if(!this.types.controls['threeDSEnabled'].value || this.types.controls['threeDSEnabled'].disabled) {
      ctrls.forEach(ctrlName=>this.disableCtrl(ctrlName));
    } else {
      ctrls.forEach(ctrlName=>this.enableCtrl(ctrlName));

    }
  }
//if(this.types.controls['ccPaymentEnabled'].value){
  updateCCControls(){
    const ctrls=[];
    ctrls.push('visa3dsEnabled');
    ctrls.push('mc3dsEnabled');
    ctrls.push('amex3dsEnabled');
    ctrls.push('unionPay3dsEnabled');
    ctrls.push('diners3dsEnabled');
    ctrls.push('jcb3dsEnabled');
    ctrls.push('rupay3dsEnabled');
    ctrls.push('discover3dsEnabled');
    ctrls.push('visaEnabled');
    ctrls.push('masterCardEnabled');
    ctrls.push('amexEnabled');
    ctrls.push('discoverEnabled');
    ctrls.push('unionPayEnabled');
    ctrls.push('dinersClubEnabled');
    ctrls.push('jcbEnabled');
    ctrls.push('rupayGlobalEnabled');
    ctrls.push('threeDSEnabled');
    ctrls.push('visaCSCRequired');
    ctrls.push('mastercardCSCRequired');
    ctrls.push('amexCSCRequired');
    ctrls.push('discoveryCSCRequired');
    ctrls.push('unionPayCSCRequired');
    ctrls.push('dinersClubCSCRequired');
    ctrls.push('jcbCSCRequired');
    ctrls.push('rupayGlobalCSCRequired');
    ctrls.push('threeDsCountry');
    ctrls.push('threeDsPaymentMethods');
    this.types.controls['ccPaymentEnabled'].enable();
    if(this.types.controls['ccPaymentEnabled'].value){
      ctrls.forEach(ctrlName=>this.enableCtrl(ctrlName));
    } else {
      ctrls.forEach(ctrlName=>this.disableCtrl(ctrlName));
    }


  }

  enableOrDisableMerchantDefinedFields() {
    for (let seqNo = 1; seqNo <= 10; seqNo++) {
      this.allowEditOrDisableMerchantDefinedFields(seqNo);
    }
  }

  allowEditOrDisableMerchantDefinedFields(seqNo) {
    if (!this.types.controls['displayMerchantDefinedField_' + seqNo].value) {
      this.types.controls['merchantDefinedFieldRequired_' + seqNo].disable();
      this.types.controls['editMerchantDefinedField_' + seqNo].disable();
      this.types.controls['merchantDefinedFieldName_' + seqNo].disable();
    } else {
      this.types.controls['merchantDefinedFieldRequired_' + seqNo].enable();
      this.types.controls['editMerchantDefinedField_' + seqNo].enable();
      this.types.controls['merchantDefinedFieldName_' + seqNo].enable();
    }
  }

  back() {
    if (this.isDisabled) {
      this.router.navigate(['osc-specific-hop-config']);
    } else {
      this.openDialog('back');
    }
  }

  showMsgDlg(text:string) {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      width: '500px',
      data: <IDynamicDialogConfig>{
        title:  text,
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });

    return dialogRef.afterClosed();
  }

  // Fraud Detect Settings update
  updateFraudDetectSettings() {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      width: '500px',
      data: <IDynamicDialogConfig>{
        title:
          'Are you sure you want to make an update on the list of countries?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value !== 'noExit') {
        this.selected_fraud_country = undefined;
        if(this.types.controls['fraudDetectCountries'].value.length >= 1){
          for(const object of this.types.controls['fraudDetectCountries'].value) {
            if(this.selected_fraud_country != undefined){

                this.selected_fraud_country = [
                  ...this.selected_fraud_country,
                  { countryName: object,}
                ];
            }else {
              this.selected_fraud_country = [
                { countryName: object,}
              ];
            }
          }
        }

        if(this.selected_fraud_country != undefined){
          this.blocked_fraud_detect_country = [
            ...this.selected_fraud_country,
          ];
        } else {
          this.blocked_fraud_detect_country = [];
        }
        this.setFraudDetectCountriesData(this.blocked_fraud_detect_country);
      }
    });
  }

  updateBlockPayment() {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      width: '500px',
      data: <IDynamicDialogConfig>{
        title:
          'Are you sure you want to update the payment method(s) for the selected country?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value !== 'noExit') {
        const countryAdded = this.blocked_country_payment?.find((obj, i) => {
          if (obj.countryName === this.types.controls['country'].value) {
            this.blocked_country_payment[i] = {
              countryName: this.types.controls['country'].value,
              paymentMethodName:
                this.types.controls['paymentMethods'].value,
            };
            return true;
          }
        });
        if (!countryAdded) {
          this.blocked_country_payment = [
            ...this.blocked_country_payment,
            {
              countryName: this.types.controls['country'].value,
              paymentMethodName:
                this.types.controls['paymentMethods'].value,
            },
          ];
        }
        this.setData();
      }
    });
  }

  updatePaymentMethodsList() {
    this.types.controls['paymentMethods'].setValue(this.blocked_country_payment?.find(data => data.countryName === this.types.controls['country'].value)?.paymentMethodName || '');
  }

// (selectionChange)="updatePaymentMethodsList()"

// Fraud Detect counteries select: Enable Update button while selecting countries  
  // updatefraudEnabledCountries(selection:MatSelect){
  //   if(selection.value.length >= 1) {
  //     this.isFraudDisabled = false;
  //   } else {
  //     this.isFraudDisabled = true;
  //   }
  // }

  update3dsCountries(selection:MatSelect){

    if(selection.value.length>1) {
      this.types.controls['threeDsPaymentMethods'].setValue([]);
    }

    if(selection.value.length>0) {
      const three_ds_country_payment= this.three_ds_country_payment.filter(v=>selection.value.indexOf(v.countryName)>=0);

      if(selection.value.length == 1) {

        if(three_ds_country_payment[0] && three_ds_country_payment[0].paymentMethodName) {
          this.types.get("threeDsPaymentMethods").setValue(three_ds_country_payment[0].paymentMethodName.split(","));
        }

      }
      this.set3DSData(three_ds_country_payment);
    } else{
      this.set3DSData(this.three_ds_country_payment);
    }

  }

  uploadFile(file) {
    console.log(file);
  }
//on3dsPaymentItemSubCategoryChanged(paymentItem.id, paymentItem.label,$event)
  onPaymentItemSubCategoryChanged(id,label,val){
    this.paymentItemSubCategoryMap[id]={
      'id':id,
      'label':label,
      'value':val
    }
  }

  on3dsPaymentItemSubCategoryChanged(id,label,val){
    this.payment3dsItemSubCategoryMap[id]={
      'id':id,
      'label':label,
      'value':val
    }
  }
  enableOrDisableCreditCardOr3DS(value, type) {
    if (!value && type === 'ccPaymentEnabled') {
      this.types?.disable();
      this.types.controls['ccPaymentEnabled'].enable();
      this.types.controls['paypalEnabled'].enable();
      this.types.controls['alipayEnabled'].enable();
      this.types.controls['fraudDetectEnabled'].enable();
      this.types.controls['dccEnabled'].enable();
      this.types.controls['allowAuthorizationOnlyRequest'].enable();
      this.types.controls['ignoreAvsDeclineCodes'].enable();
      this.types.controls['avsDeclineCodeList'].enable();
      this.types.controls['ignoreCsvDeclineCodes'].enable();
      this.types.controls['csvDeclineCodeList'].enable();
      this.update3dsControls();
    } else if (!value && type === 'threeDSEnabled') {
      this.types.controls['visa3dsEnabled'].disable();
      this.types.controls['mc3dsEnabled'].disable();
      this.types.controls['amex3dsEnabled'].disable();
      this.types.controls['unionPay3dsEnabled'].disable();
      this.types.controls['diners3dsEnabled'].disable();
      this.types.controls['jcb3dsEnabled'].disable();
      this.types.controls['rupay3dsEnabled'].disable();
      this.types.controls['discover3dsEnabled'].disable();
    } else if (value && (type === 'threeDSEnabled' || type === 'ccPaymentEnabled')) {
      this.types?.enable();
      this.update3dsControls();
    }
  }

  isUpdateDisabled(){
    return this.isDisabled || !this.types.controls['country'].value|| !this.isValidPaymentMethodSelection();
  }

  isValidPaymentMethodSelection(): boolean {
    if (this.anyExistingPayment(this.types.controls['country'].value)) {
      return true;
    } else {
      return this.types.controls['paymentMethods'].value && this.types.controls['paymentMethods'].value.length > 0;
    }
  }

  anyExistingPayment(cntry) {
   return this.blocked_country_payment.filter(bcp => bcp.countryName == cntry).length > 0
  }

  isPaymentOptionDisabled(){
    return this.isDisabled || !this.types.controls['country'].value
  }

  toggleAllPaymentSelection(selectedList) {

    this._toggleAllPaymentSelection(selectedList,'paymentMethods');
  }
  toggleAll3DsPaymentSelection(selectedList) {

    this._toggleAllPaymentSelection(selectedList,'threeDsPaymentMethods');
  }
  toggleAll3DsCountrySelection(selectedList) {

    this._toggleAllCountrySelection(selectedList,'threeDsCountry');
  }

  _toggleAllPaymentSelection(selectedList,formCtrlName) {
    if (selectedList?.length > 0 && selectedList[0] === 'ALL') {
      this.types.controls[formCtrlName].setValue([
        'ALL',
        ...this.paymentMethods.map(i=>i.value),
      ]);
    }else{
      this.types.controls[formCtrlName].setValue([]);
    }
  }

  _toggleAllCountrySelection(selectedList,formCtrlName) {
    if (selectedList?.length > 0 && selectedList[0] === 'ALL') {
      this.types.controls[formCtrlName].setValue([
        'ALL',
        ...this.countryList.map(i=>i.name),
      ]);
    }else{
      this.types.controls[formCtrlName].setValue([]);
    }
  }

  update3DSData() {

    this.showMsgDlg("Are you sure you want to update the 3DS payment method(s) for the selected country?").subscribe(value=>{
      if (value !== 'noExit') {
        const threeDsCountry = this.types.controls['threeDsCountry'].value;
        if(!threeDsCountry || threeDsCountry.length==0){
          return;
        }
        const threeDsPaymentMethods = this.types.controls['threeDsPaymentMethods'].value;
        if (threeDsCountry[0] == 'All') {
          threeDsCountry.pop();
        }

        const cntryMap={};
        const existingList=this.three_ds_country_payment;

        for (let i = 0; i < existingList.length; i++) {
          cntryMap[existingList[i].countryName]=existingList[i].paymentMethodName;
        }

        this.three_ds_country_payment=[];
        for (let i = 0; i < threeDsCountry.length; i++) {

          this.three_ds_country_payment.push({
            countryName: threeDsCountry[i],
            paymentMethodName: threeDsPaymentMethods.join(',')
          });

          if(cntryMap[threeDsCountry[i]]){
            delete cntryMap[threeDsCountry[i]];
          }
        }

       const cntryEnries= Object.entries(cntryMap);
        for(const cntrEntry of cntryEnries){
          this.three_ds_country_payment.push({
            countryName: cntrEntry[0],
            paymentMethodName: cntrEntry[1] as string
          });
        }

        this.set3DSData(this.three_ds_country_payment);
        this.types.controls['threeDsPaymentMethods'].setValue([]);
        this.types.controls['threeDsCountry'].setValue([]);
      }
    })



    // this. this.dataSource = new MatTableDataSource(this.blocked_country_payment);
  }
}
