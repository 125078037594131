import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription-ui',
  template: '<app-refund-ui [isSubscription]=true></app-refund-ui>'
})
export class SubscriptionUiComponent {

  constructor() { }

}
