
<div class="pt-3">

  <div *ngIf="f.amount.touched && f.amount.errors?.required" class="alert alert-danger">
    Refund amount is required.
  </div>
  <div *ngIf="amountError" class="alert alert-danger">
    The refund amount exceeds the remaining balance.
  </div>

  <div *ngIf="f.amount.touched && f.merchantDescriptor.errors?.required" class="alert alert-danger">
    Billing Statement Text is required.
  </div>


  <form name="refundForm" [formGroup]="refund" class="refund-form">
    <div class="mb-3">
      <b  >Amount available to be refunded : </b> {{refundAmt.toFixed(2)}}
    </div>
    <div class="mb-3">
      <label for="amount">Amount</label>
      <input class="form-control form-control-md" formControlName="amount" />
      <div class="text-danger" *ngIf="refund.controls.amount.hasError('pattern')">
        Please enter number only.
      </div>
      <div class="text-danger" *ngIf="refund.controls.amount.hasError('min')">
        Minimum required number is 0.
      </div>
      <div class="text-danger" *ngIf="refund.controls.amount.hasError('max')">
        Maximum number can be {{calculatedRefundAmount}}.
      </div>
    </div>
    <div class="mb-3">
      <label for="programSpecificKey">Program Specific Key</label>
      <input class="form-control form-control-md" formControlName="programSpecificKey" />
      <div class="text-danger" *ngIf="refund.controls.programSpecificKey.hasError('maxlength')">
        Maximum length can be 40.
  </div>
    </div>
    <div class="mb-3">
      <label for="merchantDescriptor"><span class="required-asterisk">*</span> Billing Statement Text</label>
      <input class="form-control form-control-md" formControlName="merchantDescriptor" />
    </div>
    <app-line-items (lineItems)="lineItemData($event)" [allowOrderItems]="allowOrderItems"></app-line-items>
  </form>
  <div class="row col-md-12">
    <button class="col-md-1 mt-2 btn btn-primary btn-function" (click)="parentRef.close()">
      Exit
    </button>
    <button (click)="resetRefundForm()" class="col-md-2 mt-2 ms-2 btn btn-primary btn-function">
      Reset Form
    </button>
    <button class="btn btn-primary col-md-2 mt-2 ms-2" [disabled]="isInvalidData() || isDisabledOverrdieRefund()"
      (click)="confirmRefundSale() && parentRef.close()">
      Process Refund
    </button>
  </div>
</div>
