import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {OrderService} from "../service/order.service";
import {ActivatedRoute} from "@angular/router";

export interface LineItemSummary {
  lineItem: number;
  productSKU: number;
  productName: string;
  productDescription: string;
  totalLine: string;
  transType: string;
  transProcessDate: string;
  details: string;
}

@Component({
  selector: 'app-mat-line-item-summary',
  templateUrl: './mat-line-item-summary.component.html',
  styleUrls: ['./mat-line-item-summary.component.scss'],
})

export class MatLineItemSummaryDetailsComponent {
  displayedColumns: string[] = ['lineItem', 'productSKU','productCode', 'productName', 'productDescription', 'totalLine', 'transType', 'transProcessDate', 'details'];


  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  @Input()
  orderId;

  txData;

  lineItem;

  @ViewChild(MatSort) sort: MatSort;
  modalRef: BsModalRef;
  calculatedRefundAmount;

  constructor(private modalService: BsModalService, private orderService: OrderService,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    const transactionId=this.route.snapshot.paramMap.get('transactionId');
    const linItemObs = this.orderService.getLineItemsData(this.orderId);
    linItemObs.subscribe(data => {
      this.txData = data['transactions'].filter(txData => txData.orderTransactionId === transactionId).filter(tx => tx.lineItems && tx.lineItems.length > 0)[0];
      this.calculatedRefundAmount=this.getCalculatedRefundAmount(data['transactions']);

      const lineItemsData = this.txData ? this.txData['lineItems'] : [];
      this.dataSource = new MatTableDataSource(lineItemsData);
    });

  }

  getCalculatedRefundAmount(transactions) {

    let refundAmt = 0;
    for (const tx of transactions) {
      if ((tx.transactionStatus === 'SUCCESS') || (tx.settled && tx.matched)) {
        if (tx.transactionTypeCode) {
          if (tx.transactionTypeCode.toUpperCase() === 'SALE') {
            refundAmt = refundAmt + tx.amount;
          } else if (tx.transactionTypeCode.toUpperCase() === 'REFUND') {
            refundAmt = refundAmt - tx.amount;
          } else if (tx.transactionTypeCode.toUpperCase() === 'CHARGEBACK') {
            refundAmt = refundAmt - tx.amount;
          } else if (tx.transactionTypeCode.toUpperCase() === 'REVERSE_CHARGEBACK') {
            refundAmt = refundAmt + tx.amount;
          }

        }
      }
    }
    return refundAmt;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  openModal(details: TemplateRef<any>, lineItem) {
    this.modalRef = this.modalService.show(details);
    this.lineItem = lineItem;
  }
}

