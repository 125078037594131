/** user detail model */
export class User {
  userLoginId: string;
  firstName: string;
  lastName: string;
  role?: Role;
  oscDetails?: Array<OscDetails>;
  accountType: string;
  createdDate?: string;
  emailId: string;
  state: string;
  userId?: string;
  preferredName: string;
  middleName?:string;
  userName: string;
  guid: string;
  emailAddress: string;
  roleName: string;
  createdBy?: string;
}
export class UpdateUserInfo{
  accountType: string;
  emailId: string;
  firstName: string;
  guid: string;
  lastName: string;
  middleName: string;
  roleName: string;
  state: string;
  updatedBy: string;
  userId: number;
  userLoginId: string;
}

/** user Osc details model */
export class OscDetails {
  oscCode: string;
  programName: string;
}


/** user Role Model */
export class Role {
  roleName: string;
  roleDesc: string;
  capabilities: Array<RoleCapabilities>;
  privilegeLevelCode : number;
}

/** user Role capabilities Model */
export class RoleCapabilities {
  capabilityDesc: string;
  capabilityName: string;
}

export class ExternalUser {
  firstName: string;
  lastName: string;
  middleName?: string;
  emailAddress: string;
  groupName: string;
  userType?: string;
}
