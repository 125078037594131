import * as FileSaver from 'file-saver';
export class TableUtil {
  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  static saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], {type: fileType});
    FileSaver.saveAs(data, fileName);
  }

  static exportToXml(rowDataObjs: any[], name?: string) {

    if (rowDataObjs.length > 0) {
      const rows = [];

      rows.push('<data>');
      for (const rowObj of rowDataObjs) {

        const keys = Object.keys(rowObj);
        const rowStr = [];
        rowStr.push('<row>');
        for (const key of keys) {
          let val = rowObj[key];
          if (typeof val === 'string') {
            val = val.trim();
          }
          rowStr.push(`<${key}>${val}</${key}>`);
        }
        rowStr.push('</row>');
        rows.push(rowStr.join(''));
      }
      rows.push('</data>');

      const xmlContent = rows.join('\r\n');
      TableUtil.saveAsFile(xmlContent, `${name}${'.xml'}`, 'application/xml');
    }
  }

  static exportToCsv(rowDataObjs: any[], name?: string) {

    if (rowDataObjs.length > 0) {
      const CSV_EXTENSION = 'csv';
      const separator = ',';
      const firstRowObj = rowDataObjs[0];
      const keys = Object.keys(firstRowObj);
      const firstRowStr = keys.join(separator);
      const rows = [];
      rows.push(firstRowStr);
      for (const rowObj of rowDataObjs) {

        const values = Object.values(rowObj);
        const rowStr = [];
        for (const val of values) {
          rowStr.push(val);
        }
        rows.push(rowStr.join(separator));
      }

      const csvContent = rows.join('\r\n');
      TableUtil.saveAsFile(csvContent, `${name}${'.' + CSV_EXTENSION}`, CSV_EXTENSION);
    }

  }


}
