<div class="card mt-4">
  <div class="card-body">
    <h2 *ngIf="isSale">Sale Transaction</h2>
    <h2 *ngIf="!isSale">Override Refund</h2>
    <form [formGroup]="processForm">
      <div class="row">
        <div class="col-md-12">
          <h3>Payment Details</h3>
        </div>
      </div>
      <div formGroupName="paymentDetails" class="row g-3">
        <div class="col-md-4 form-controls">
          <label class="form-label" for="amount">
            Amount</label>
          <input class="form-control form-control-md" id="amount" currencyInput formControlName="amount" />
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="modeOfReceipt"><span class="required-asterisk">*</span>Mode Of Receipt</label>

          <mat-select class="form-select form-select-md" formControlName="modeOfReceipt">
            <mat-option disabled>Select</mat-option>
            <mat-option id="fax" value="F">Fax</mat-option>
            <mat-option id="in-person" value="I">In Person</mat-option>
            <mat-option id="mail" value="M">Mail</mat-option>
            <mat-option id="phone" value="T">Phone</mat-option>
            <mat-option id="vrs" value="V">VRS</mat-option>
          </mat-select>
          <span *ngIf="
              !processForm.get('paymentDetails.modeOfReceipt')
                .valid &&
              processForm.get('paymentDetails.modeOfReceipt')
                .touched
            " class="tip">Mode Of Receipt is a required field an must be specified.</span>
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="billingStatementText"><span *ngIf="!isSale"
              class="required-asterisk">*</span>Billing
            Statement Text</label>
          <input class="form-control form-control-md" formControlName="billingStatementText" id="billingStatementText" />
          <span *ngIf="
              (!processForm.get(
                'paymentDetails.billingStatementText'
              ).valid &&
                processForm.get(
                  'paymentDetails.billingStatementText'
                ).touched) ||
              processForm.get(
                'paymentDetails.billingStatementText'
              ).errors?.minlength
            " class="tip">Billing Statement<span *ngIf="!isSale">is a required field and</span>
            must contain 25 characters</span>
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="program-specific-key">Program Specific Key</label>
          <input class="form-control form-control-md" id="program-specific-key" formControlName="program-spec-key" />
        </div>
      </div>
      <app-line-items (lineItems)="lineItemData($event)" [allowOrderItems]="allowOrderItems"></app-line-items>
      <div class="row">
        <div class="col-md-12 mt-4">
          <h3>Contact/Shipping Information</h3>
        </div>
      </div>

      <div formGroupName="shippingInfo" class="row g-3">
        <div class="col-md-4 form-controls">
          <label class="form-label" for="first-name"><span class="required-asterisk">*</span>First Name</label>
          <input class="form-control form-control-md" id="first-name" formControlName="firstName" />
          <span *ngIf="
              !processForm.get('shippingInfo.firstName').valid &&
              processForm.get('shippingInfo.firstName').touched
            " class="tip">First name is a required field and must be specified.</span>
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="last-name"><span class="required-asterisk">*</span>Last Name</label>
          <input class="form-control form-control-md" id="last-name" formControlName="lastName" />
          <span *ngIf="
              !processForm.get('shippingInfo.lastName').valid &&
              processForm.get('shippingInfo.lastName').touched
            " class="tip">Last name is a required field and must be specified.</span>
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="customerID">Customer ID</label>
          <input class="form-control form-control-md" id="customerID" formControlName="customerID" />
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="last-name"><span class="required-asterisk">*</span>Street Address 1</label>
          <input class="form-control form-control-md" id="street-address-1" formControlName="streetAddress1" />
          <span *ngIf="
              !processForm.get('shippingInfo.streetAddress1')
                .valid &&
              processForm.get('shippingInfo.streetAddress1')
                .touched
            " class="tip">Street Address 1 is a required field. and must be specified</span>
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="last-name">Street Address 2</label>
          <input class="form-control form-control-md" id="street-address-2" formControlName="streetAddress2" placeholder="(optional)" />
        </div>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="last-name"><span class="required-asterisk">*</span>City</label>
          <input class="form-control form-control-md" formControlName="city" id="last-name" />
          <span *ngIf="
              !processForm.get('shippingInfo.city').valid &&
              processForm.get('shippingInfo.city').touched
            " class="tip">City is a required field and must be specified.</span>
        </div>
        <div class="col-lg-4 form-controls">
          <label class="form-label" class="control-label"><span class="required-asterisk">*</span>Country</label>
          <mat-select class="form-select form-select-md" name="country" formControlName="country" id="billingCountry" aria-label="Country"
            (selectionChange)="changeCountry($event)">
            <mat-option disabled>- Select -</mat-option>
            <mat-option *ngFor="let country of countryList" [id]="country.id" [value]="country.id">
              {{ country.name }}
            </mat-option>
          </mat-select>
          <span *ngIf="
              !processForm.get('shippingInfo.country').valid &&
              processForm.get('shippingInfo.country').touched
            " class="tip">Country is a required field and must be specified.</span>
        </div>

        <div *ngIf="
            selectedCountry === 'US' || selectedCountry === 'CA';
      else region
          " class="col-lg-4 form-controls">
          <label *ngIf="selectedCountry === 'US'" class="control-label">
            <span class="required-asterisk">*</span>
            State
          </label>
          <label *ngIf="selectedCountry === 'CA'" class="control-label">
            <span class="required-asterisk">*</span>Province
          </label>
          <div *ngIf="selectedCountry === 'US'" class="form-controls-dropdown payment-fields disabled empty">
            <mat-select class="form-select form-select-md" name="state" formControlName="state" (selectionChange)="changeRegion($event)"
              aria-label="State">
              <mat-option disabled>- Select -</mat-option>
              <mat-option *ngFor="let region of regionList" [value]="region.id">
                {{ region.name }}
              </mat-option>
            </mat-select>
            <span *ngIf="
                !processForm.get('shippingInfo.state').valid &&
                processForm.get('shippingInfo.state').touched
              " class="tip">State is a required field and must be specified.</span>
          </div>

          <div *ngIf="selectedCountry === 'CA'" class="form-controls-dropdown payment-fields disabled empty">
            <mat-select class="form-select form-select-md" name="provice" formControlName="province" (selectionChange)="changeProvince($event)"
              aria-label="Province">
              <mat-option disabled>- Select -</mat-option>
              <mat-option *ngFor="let province of provinceList" [value]="province.id">
                {{ province.name }}
              </mat-option>
            </mat-select>
            <span *ngIf="
                !processForm.get('shippingInfo.province').valid &&
                processForm.get('shippingInfo.province').touched
              " class="tip">Province is a required field and must be specified.</span>
          </div>
        </div>

        <ng-template #region>
          <div class="form-controls col-lg-4">
            <label>State/Province</label>
            <div class="payment-fields disabled empty">
              <input class="form-control form-control-md" type="text" name="state" formControlName="state-province" id="state-province"
                aria-label="State/Province" placeholder="(optional)" />
            </div>
          </div>
        </ng-template>

        <div *ngIf="
            selectedCountry === 'US' || selectedCountry === 'CA';
            else postal
          " class="col-lg-4 form-controls">
          <label *ngIf="selectedCountry === 'US'" class="control-label"><span class="required-asterisk">*</span>Zip
            Code</label>
          <label *ngIf="selectedCountry === 'CA'" class="control-label"><span class="required-asterisk">*</span>Postal
            Code</label>
          <div *ngIf="selectedCountry === 'US'">
            <div class="">
              <input class="form-control form-control-md" type="tel" name="postalcode" formControlName="zip" minlength="5" maxlength="9"
                pattern="^0?[0-9]+$" />
              <span *ngIf="
                  !processForm.get('shippingInfo.zip').valid &&
                  processForm.get('shippingInfo.zip').touched
                " class="tip">Zip Code is a required field and must be specified.</span>
            </div>
          </div>

          <div *ngIf="selectedCountry === 'CA'">
            <div class="">
              <input class="form-control form-control-md" type="tel" name="postalcodeCA" formControlName="postalCA" minlength="6"
                maxlength="9" pattern="^[_A-z0-9\s]*([_A-z0-9\s])*$" />
              <span *ngIf="
                  !processForm.get('shippingInfo.postalCA')
                    .valid &&
                  processForm.get('shippingInfo.postalCA').touched
                " class="tip">Postal Code is a required field and must be specified.</span>
            </div>
          </div>
        </div>

        <ng-template #postal>
          <div class="col-lg-4 form-controls">
            <label> Zip/Postal Code </label>
            <div class="payment-fields disabled empty">
              <input class="form-control form-control-md" type="text" name="postalcode" formControlName="zipPostal" maxlength="9"
                aria-label="Zip/Postal Code" placeholder="(optional)" />
              <span *ngIf="
                  !processForm.get('shippingInfo.zipPostal')
                    .valid &&
                  processForm.get('shippingInfo.zipPostal').touched
                " class="tip">Zip/Postal Code is a required field and must be
                specified.</span>
            </div>
          </div>
        </ng-template>
        <div class="col-md-4 form-controls">
          <label class="form-label" for="first-name">Company</label>
          <input class="form-control form-control-md" formControlName="company" id="Company" placeholder="(optional)" />
        </div>
        <div class="col-lg-4 form-controls">
          <label class="control-label">Phone Number</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="tel" name="phone" formControlName="phone" id="txt-shipping-phone"
              placeholder="(optional)" aria-label="Phone Number" minlength="10" pattern="^[0-9]+$" />
          </div>
        </div>
        <div class="col-lg-4 form-controls">
          <label class="control-label">Email</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="email" name="email" formControlName="email" id="email"
              placeholder="(optional)" aria-label="Email" pattern="[A-z0-9._+-]+@[A-z0-9.-]+\.[A-z]{2,4}$" />
            <span *ngIf="
                !processForm.get('shippingInfo.email').valid &&
                processForm.get('shippingInfo.email').touched
              " class="tip">Email is optional. You must enter a valid email address.</span>
          </div>
        </div>
        <div class="col-lg-12 mt-4">
          <h3>Peoplesoft Details</h3>
        </div>
        <div class="col-lg-4 form-controls">
          <label class="control-label"><span class="required-asterisk">*</span> Project</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="tel" name="project" id="project" maxlength="5" formControlName="project"
              aria-label="Project" />
          </div>
          <div *ngIf="
              !processForm.get('shippingInfo.project').valid &&
              processForm.get('shippingInfo.project').touched
            " class="tip">
            Project is a required field and must be specified.
          </div>

          <div *ngIf="
              (!processForm.get('shippingInfo.project').valid &&
                processForm.get('shippingInfo.project').touched) ||
              processForm.get('shippingInfo.project').errors
                ?.minlength
            " class="tip">
            Project must contain 5 characters.
          </div>
          <div *ngIf="
              processForm.get('shippingInfo.project').errors
                ?.pattern
            " class="tip">
            Project must be numeric characters only.
          </div>
        </div>

        <div class="col-lg-4 form-controls">
          <label class="control-label">Product</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="tel" name="product" id="product" maxlength="5" formControlName="product"
              aria-label="Product" />
          </div>

          <div *ngIf="
              (!processForm.get('shippingInfo.product').valid &&
                processForm.get('shippingInfo.product').touched) ||
              processForm.get('shippingInfo.product').errors
                ?.minlength
            " class="tip">
            Product must contain 5 characters.
          </div>
          <div *ngIf="
              processForm.get('shippingInfo.product').errors
                ?.pattern
            " class="tip">
            Product must be numeric characters only.
          </div>
        </div>

        <div class="col-lg-4 form-controls">
          <label class="control-label"><span *ngIf="!isSale" class="required-asterisk">*</span>Journal
            Entry Acct #</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="tel" formControlName="journal-entry-num" name="journal-acct-num"
              id="journal-acct-num" maxlength="6" aria-label="Journal Entry Account Number" />
            <div *ngIf="
                !processForm.get('shippingInfo.journal-entry-num')
                  .valid &&
                processForm.get('shippingInfo.journal-entry-num')
                  .touched
              " class="tip">
              Journal Entry Acct is a required field and must be specified.
            </div>
          </div>

          <div *ngIf="
              (!processForm.get('shippingInfo.journal-entry-num')
                .valid &&
                processForm.get('shippingInfo.journal-entry-num')
                  .touched) ||
              processForm.get('shippingInfo.journal-entry-num')
                .errors?.minlength
            " class="tip">
            Journal Entry Acct # must contain 6 characters.
          </div>
          <div *ngIf="
              processForm.get('shippingInfo.journal-entry-num')
                .errors?.pattern
            " class="tip">
            Journal Entry Acct # must be numeric characters only.
          </div>
        </div>

        <div class="col-lg-4 form-controls">
          <label class="control-label">Activity</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="text" name="activity" id="activity" maxlength="3"
              formControlName="activity" aria-label="Activity" />
          </div>
          <div *ngIf="
              (!processForm.get('shippingInfo.activity').valid &&
                processForm.get('shippingInfo.activity')
                  .touched) ||
              processForm.get('shippingInfo.activity').errors
                ?.minlength
            " class="tip">
            Activity must contain 3 characters.
          </div>
          <div *ngIf="
              processForm.get('shippingInfo.activity').errors
                ?.pattern
            " class="tip">
            Activity must be numeric characters only.
          </div>
        </div>
        <div class="col-lg-4 form-controls">
          <label class="control-label">Resource</label>
          <div class="payment-fields disabled empty">
            <input class="form-control form-control-md" type="text" name="resource" id="resource" maxlength="2"
              formControlName="resource" aria-label="Resource" />
          </div>
          <div *ngIf="
              (!processForm.get('shippingInfo.resource').valid &&
                processForm.get('shippingInfo.resource')
                  .touched) ||
              processForm.get('shippingInfo.resource').errors
                ?.minlength
            " class="tip">
            Resource must contain 2 characters.
          </div>
          <div *ngIf="
              processForm.get('shippingInfo.resource').errors
                ?.pattern
            " class="tip">
            Resource must be numeric characters only.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4 mb-4">
          <button class="btn btn-primary" id="next-button" type="submit" [disabled]="isInValidForm()"
            (click)="isSale ? confirmSale() : onSubmit()">
            Continue
          </button>
          &nbsp;
          <button class="btn btn-light" (click)="onExit()">Exit</button>
        </div>
      </div>
    </form>
  </div>
</div>
