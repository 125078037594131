<div class="breadcrumb row">
    <a [routerLink]="['/order-search']">Order Search</a>
</div>
<div class="container-xl mt-4">
    <div class="row">
        <div class="col-12">
            <h2>Order Search Result</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="btn btn-primary btn-function mb-4" (click)="onRevise()">
                <fa-icon [icon]="faChevronLeft"></fa-icon> Revise Search Criteria
            </button>
        </div>
    </div>
    <div class="row mt-4" (click)="toggle()">
        <div class="col-12">
            <div class="card">
                <div class="grey card-body d-flex justify-content-between align-items-center">
                    <h3>
                        <fa-icon [icon]="faSearch"></fa-icon> Search Criteria
                    </h3>
                    <div class="toggle-state">
                        <div class="arrow-down" *ngIf="display === false">
                            <fa-icon [icon]="faSortDown"></fa-icon>
                        </div>
                        <div class="arrow-up" *ngIf="display === true">
                            <fa-icon [icon]="faSortUp"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="display" @fadeIn>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <mat-list>
                            <div *ngIf="isCriteriaTransactionDate">
                                <div mat-subheader><h5>Transaction Date</h5></div>
                                <mat-list-item  class="col-12" *ngFor="let el of criteriaTransactionType">
                                    <span class="span-pad-right"><strong>{{el.field}}</strong></span><span>{{el.value | date:'medium'}}</span>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                            <div *ngIf="isCriteriaOrderDetailsInfo">
                                <div mat-subheader class="col-12">
                                    <h5>Order Details Information</h5>
                                </div>
                                <mat-list-item *ngFor="let el of criteriaOrderDetailsInfo">
                                    <span class="span-pad-right"><strong>{{el.field}}</strong></span><span>{{el.value}}</span>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                            <div *ngIf="isCriteriaBillShipInfo">
                                <div mat-subheader class="col-12">
                                    <h5>Bill to & Ship to Customer Information</h5>
                                </div>
                                <mat-list-item *ngFor="let el of criteriaBillShipInfo">
                                    <span class="span-pad-right"><strong>{{el.field}}</strong></span><span>{{el.value}}</span>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                            <div *ngIf="isCriteriaProgSpecInfo">
                                <div mat-subheader class="col-12">
                                    <h5>Program Specific Information</h5>
                                </div>
                                <mat-list-item *ngFor="let el of criteriaProgSpecInfo">
                                    <span class="span-pad-right"><strong>{{el.field}}</strong></span><span>{{el.value}}</span>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                            <div *ngIf="isCriteriaPaymentInfo">
                                <div mat-subheader class="col-12">
                                    <h5>Payment Information</h5>
                                </div>
                                <mat-list-item *ngFor="let el of criteriaPaymentInfo">
                                    <span class="span-pad-right"><strong>{{el.field}}</strong></span><span>{{el.value=='PAYMENT_CARD'?'Credit Card':el.value}}</span>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isResult" class="row mt-4">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-md-12">
                        <h3>Filter By</h3>
                        <form [formGroup]="filterBy">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <label for="orderID">Order ID</label>
                                    <input type="text" class="form-control form-control-md" formControlName="orderId">
                                </div>
                                <div class="col-md-4 mb-3" id="dropdown-arrow-mat">
                                    <label>Order State</label>
                                    <mat-select class="form-select form-select-md" formControlName="orderState">
                                        <mat-option disabled>- Select -</mat-option>
                                        <mat-option *ngFor="let orderState of OrderState" [value]="orderState.value">
                                            {{orderState.text}}
                                          </mat-option>
                                    </mat-select>
                                </div>
                                <div id="osc-code" class="col-md-4 mb-3">
                                    <label>OSC</label>
                                    <mat-select class="form-select form-select-md" name="originatingSystemCode"
                                        formControlName="originatingSystemCode" multiple>
                                      <mat-option value="ALL" (click)="toggleAllOscCodeSelection(filterBy.controls['originatingSystemCode']?.value)">All</mat-option>
                                        <mat-option *ngFor="let oscConfigCode of oscConfigCodes" [value]="oscConfigCode">
                                            {{oscConfigCode}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 mb-3" id="dropdown-arrow-mat">
                                    <label>Payment Method</label>
                                    <mat-select name="paymentMethod" id="paymentMethod" formControlName="paymentMethod"
                                        class="form-select form-select-md">
                                      <mat-option disabled>- Select -</mat-option>
                                      <mat-option *ngFor="let paymentMethod of PaymentMethods" [value]="paymentMethod.value">
                                        {{paymentMethod.text}}
                                      </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="USD Amount">Amount</label>
                                    <input type="text" class="form-control form-control-md" formControlName="amount"
                                    onfocus="this.placeholder='Ex. &lt;10, =10, &gt;10.'"
                                    onblur="this.placeholder=''">
                                </div>
                            </div>
                        </form>
                        <button class="btn btn-primary btn-function" (click)="resetForm()" >Clear</button>
                            &nbsp;
                            <button class="btn btn-primary" (click)="onSubmit()" [disabled]="!filterBy.valid">Go</button>
                        <div *ngIf="searchError || searchInfo">
                            <hr />
                            <div class="alert alert-danger" role="alert" *ngIf="searchError">
                                {{errorMessage}}
                            </div>
                            <div class="alert alert-info" role="alert" *ngIf="searchInfo">
                                {{infoMessage}}
                            </div>
                        </div>
                    </div>
                    <app-mat-fiter-table #filterGrid [orderSearchResult]=orderSearchResult [sourceRoute]=sourceRoute></app-mat-fiter-table>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isResult" class="row mt-4">
        <div class="col-12">
            <app-search-fail></app-search-fail>
        </div>
    </div>
</div>
