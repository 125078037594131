import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, Validators} from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { User } from '../model/user';
import { RefundService } from '../service/refund.service';
import { SharedService } from '../service/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddressDetails, ContactDetails, LineItemDetails, RefundRequest } from '../model/refund-request';
import { Constants } from '../constant/constants';

@Component({
  selector: 'app-refund-ui',
  templateUrl: './refund-ui.component.html',
  styleUrls: ['./refund-ui.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefundUiComponent implements OnInit {

  @Input() isSubscription = false;
  oscConfigCodes = [];
  loggedInUser: User;
  refundResponseDetails: any;
  modalRef: BsModalRef;
  allowOrderItems = true;

  @ViewChild('table') table: MatTable<any>;

  lineItemDetailColumns = ['action', 'productSKU', 'productName', 'productCode', 'quantity', 'unitPrice', 'taxAmount'];
  lineItemDataSource: MatTableDataSource<AbstractControl>;

  constructor(private fb: FormBuilder,
              private refundService: RefundService,
              private sharedService: SharedService,
              private modalService: BsModalService) {
                this.lineItemDataSource = new MatTableDataSource(this.lineItems.controls);
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.oscConfigCodes = user.oscDetails.map(osc => osc.oscCode).sort();
        this.loggedInUser = user;
      }
    });
    if (this.isSubscription) {
      this.refundFormGroup.patchValue(this.getDefaultSubRefundFormValues() as any);
    }
    this.addListeners();
    this.initNullListeners();
  }


  refundFormGroup = this.fb.group({
    originatingSystemCode: [null, Validators.required],
    oscSessionId: [null],
    epmsOrderId: [null],
    orderAmount: [null],
    glProjectText: [null, Validators.required],
    glProductText: [null],
    glActivityText: [null],
    programSpecificKey: [null],
    serviceCode: [null],
    programDefinedDate: [null],
    merchantDescriptor: [null],
    modeOfReceipt: [Constants.RECEIPT_MODE],
    resourceText: [null],
    lineItems: this.fb.array([]),
    shippingInfo: [],
    shippingAdressCompany: [null],
    epmsSubscriptionId: [null]
  });

  initNullListeners(){
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('resourceText'));
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('glProductText'));
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('glActivityText'));
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('programSpecificKey'));
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('serviceCode'));
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('programDefinedDate'));
    this.addNullValForEmptyValsListener( this.refundFormGroup.get('merchantDescriptor'));
    if(this.isSubscription){
      this.addNullValForEmptyValsListener( this.refundFormGroup.get('epmsOrderId'));
    }
  }
  addNullValForEmptyValsListener(ctrl:AbstractControl){
    if (ctrl) {
      ctrl.valueChanges.subscribe(data => {
        if (!data) {
          ctrl.setValue(null, {onlySelf: true, emitEvent: false});
        }
      })
    }
  }

  resetCvaRefundForm() {
    this.refundFormGroup.reset({
      modeOfReceipt: Constants.RECEIPT_MODE
    });
    if (this.isSubscription) {
      this.refundFormGroup.reset(this.getDefaultSubRefundFormValues() as any);
    }
    this.allowOrderItems = true;
    this.lineItems.clear();
    this.table?.renderRows();
    this.refundFormGroup.controls['orderAmount'].enable();
  }

  get lineItems() {
    return this.refundFormGroup.get('lineItems') as FormArray;
  }

  addLineItem() {
    const lintItem = this.lineItems;
    lintItem.push(this.fb.group({
      lineNumber: this.lineItems.length,
      productSKU: [null],
      productCode: [null],
      productName: [null],
      quantity: [null],
      unitPrice: [null, Validators.required],
      taxAmount: [null]
    }));
    this.table.renderRows();
    this.updateAmountStatus();
  }

  onSubmit(refundResponseTemplate: TemplateRef<any>) {
    const requestObject = this.extractRequestObject();
    const apiObservable = this.isSubscription ? this.refundService.crateSubscriptionSaleRequest(requestObject) : this.refundService.sendRefundTxData(requestObject);
    apiObservable.subscribe((res: any) => {
      this.passResponseToModal(res, refundResponseTemplate);
    }, (err: any) => {
      console.error(`error processing through CVA`);
      this.passResponseToModal(err?.error, refundResponseTemplate);
    });
  }

  private extractRequestObject(): RefundRequest {
    const refundRequest = {} as RefundRequest;
    Object.assign(refundRequest, this.refundFormGroup.value);
    let lineItems: Array<LineItemDetails> = [];
    if (this.refundFormGroup.value?.lineItems) {
      Object.assign(lineItems, this.refundFormGroup.value?.lineItems);
      lineItems = lineItems.map(li => {
        if (!li.quantity) {
          li.quantity = 1;
        }
        if (!li.taxAmount) {
          li.taxAmount = 0;
        }

        return li;
      });
    }

    refundRequest.lineItems = lineItems;
    refundRequest.sourceType = 'credit';
    if (this.isSubscription) {
      refundRequest.sourceType = 'Sale';
      refundRequest.epmsSubscriptionId = this.refundFormGroup.value.epmsSubscriptionId;
      // setting address details
      const sAddress = {} as AddressDetails;
      sAddress.firstName = this.refundFormGroup.value.shippingInfo?.firstName;
      sAddress.lastName = this.refundFormGroup.value.shippingInfo?.lastName;
      sAddress.addressLine1 = this.refundFormGroup.value.shippingInfo?.address1;
      sAddress.addressLine2 = this.refundFormGroup.value.shippingInfo?.address2;
      sAddress.city = this.refundFormGroup.value.shippingInfo?.city;
      sAddress.country = this.refundFormGroup.value.shippingInfo?.country;
      sAddress.region = this.refundFormGroup.value.shippingInfo?.state;
      sAddress.postalCode = this.refundFormGroup.value.shippingInfo?.zip;

      refundRequest.shippingAddress = sAddress;

      // setting contact details
      const sContact = {} as ContactDetails;
      sContact.email = this.refundFormGroup.value.shippingInfo?.email;
      sContact.phoneNumber = this.refundFormGroup.value.shippingInfo?.phoneNumber;

      refundRequest.shippingContact = sContact;
    }

    return refundRequest;
  }
  deleteLineItem(rowIndex) {
    this.lineItems.removeAt(rowIndex);
    this.table.renderRows();
    this.updateAmountStatus();
  }

  updateAmountStatus() {
    const amountField = this.refundFormGroup.controls['orderAmount'];
    if (this.lineItems.length == 0) {
      amountField.enable();
    } else {
      amountField.disable();
    }
  }

  trackRows(index: number, row: AbstractControl) {
    return row.value.uid;
  }

  private passResponseToModal(data: any, refundResponseTemplate: TemplateRef<any>) {
    this.refundResponseDetails = data;
    this.resetCvaRefundForm();
    this.modalRef = this.modalService.show(
      refundResponseTemplate, {class: 'gray modal-lg', ignoreBackdropClick: true}
    );
  }

  private getDefaultSubRefundFormValues() {
    return {
      glProjectText: Constants.GL_PROJECT_TEXT,
      merchantDescriptor: Constants.MERCHANT_DESCRIPTOR,
      modeOfReceipt: Constants.RECEIPT_MODE,
      shippingInfo: {
        firstName: Constants.DEFAULT_FIRST_NAME,
        lastName: Constants.DEFAULT_LAST_NAME,
        address1: Constants.DEFAULT_ADDRESS1,
        address2: Constants.DEFAULT_ADDRESS2,
        city: Constants.DEFAULT_CITY,
        country: Constants.DEFAULT_COUNTRY,
        state: Constants.DEFAULT_STATE,
        zip: Constants.DEFAULT_ZIP,
        phoneNumber: Constants.DEFAULT_PHONE,
        email: Constants.DEFAULT_EMAIL,
        company: Constants.DEFAULT_COMPANY
      }
    };
  }
  enableDisableSubmit(): boolean {
    return !this.refundFormGroup.valid
      || ((this.refundFormGroup.value.orderAmount === null
        || this.refundFormGroup.value.orderAmount === "")
        && this.refundFormGroup.value.lineItems.length === 0);
  }

  addListeners() {
    this.refundFormGroup.controls['orderAmount'].valueChanges.subscribe(amount => {

      if (amount) {
        this.allowOrderItems = false;
      } else {
        this.allowOrderItems = true;
      }
    });
  }

  isDisabledOverrdieRefund() {

    if (this.lineItems && this.lineItems.length > 0) {
      for (let i = 0; this.lineItems.length; i++) {
        const lineItem: any = this.lineItems.at(i);
        if (!lineItem.unitPrice) {
          return true;
        }
      }


    } else {
      const amountField = this.refundFormGroup.controls['orderAmount'];
      return !amountField.value;
    }
  }
}
