import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from 'src/app/model/user';
import {SharedService} from 'src/app/service/shared.service';
import {
  IDynamicDialogConfig,
  MatDialogComponentComponent
} from '../../mat-dialog-component/mat-dialog-component.component';
import {OSCInfo} from "../../model/osc-info";
import {OrderService} from "../../service/order.service";
import { AddOSCInfo } from 'src/app/model/add-osc-info';
import { Constants } from 'src/app/constant/constants';

@Component({
  selector: 'add-edit-business-osc-config',
  templateUrl: './add-edit-business-osc-config.component.html',
  styleUrls: ['./add-edit-business-osc-config.component.scss']
})
export class AddEditBusinessOscConfigComponent implements OnInit {
  loggedInUser: User;
  addForm: FormGroup;
  dailyTransctionReportChecked: boolean;
  oscConfigDetailsList: any;
  programNames: any;
  paypalMerchantAccountNames: any;
  paypalMerchantAccountEmails: any;
  peopleSoftInterfaceCodes: any;
  id: string;
  private sub: any;
  errMsg:string;
  oscDataInfo:OSCInfo;
  cloneOSC: boolean;

  constructor(private router: Router, private sharedService: SharedService,
              private dialog: MatDialog, private route: ActivatedRoute, private orderService: OrderService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
      }
    });

     this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cloneOSC = this.id === 'clone' ? true : false;
      this.oscConfigDetailsList = JSON.parse(sessionStorage.getItem('oscConfigDetailsList'));
      this.oscDataInfo = JSON.parse(sessionStorage.getItem('oscConfigDetails'));
      this.populateListItems();
      if (this.id) {
        this.addForm.setValue({
          originatingSystemCode: this.cloneOSC ? '' : this.oscDataInfo.oscCode,
          description: this.cloneOSC ? '' : this.oscDataInfo.oscDescription || '',
          MerchantIDStoreID: this.cloneOSC ? '' : this.oscDataInfo.fiservStoreId || '',
          reportingID: this.cloneOSC ? '' : this.oscDataInfo.reportStoreNumber || '',
          interfaceName: this.cloneOSC ? '' : this.oscDataInfo.applicationName || '',
          selectProgramHierarchyGrp: this.oscDataInfo.programGroup?.programGroupName || '',
          addProgramHierarchyGrp: '',
          payPalMerchantAccountID: this.oscDataInfo.paypalMerchantAccountName || '',
          payPalMerchantemailAddress: this.oscDataInfo.paypalMerchantAccountEmail || '',
          americanExpressServiceEstablishment: this.oscDataInfo.amexServiceEstablishmentNumber || '',
          peopleSoftInterface: this.oscDataInfo.extractFileFormatCode || '',
          defaultPeopleSoftProjectPJ: this.oscDataInfo.defaultPeopleSoftProjCode || '',
          reconciliationIDPrefix: (this.oscDataInfo.orderIdPrefix || '').trim(),
          defaultMerchantDescriptor: this.oscDataInfo.defaultMerchantDesc || '',
          merchantDescriptorContact: this.oscDataInfo.merchantPhoneNumber || '',
          avsDeclineFlags: this.oscDataInfo.avsDeclineCodeList || '',
          csvDeclineFlags: this.oscDataInfo.csvDeclineCodeList || '',
          assignsOrderReconciliationID: this.oscDataInfo.orderReconIdGeneration || false,
          ignoreAVS: this.oscDataInfo.ignoreAvsDeclineCodes || false,
          ignoreCSV: this.oscDataInfo.ignoreCsvDeclineCodes || false,
          sale: this.oscDataInfo.allowSale || false,
          overrideRefund: this.oscDataInfo.allowOverrideRefund || false,
          refund: this.oscDataInfo.allowRefund || false,
          chargeback: this.oscDataInfo.allowChargebacks || false,
          cfhop: this.oscDataInfo.cfhop || false,
          ihop: this.oscDataInfo.ihop || false,
          vthop: this.oscDataInfo.vthop || false

        });
        if (this.oscDataInfo.cfhop) {
          this.addForm.get('cfhop').disable({onlySelf: true});
        }
        if (this.oscDataInfo.ihop) {
          this.addForm.get('ihop').disable({onlySelf: true});
        }
        if (this.oscDataInfo.vthop) {
          this.addForm.get('vthop').disable({onlySelf: true});
        }
        this.fieldsChange({target: {checked: this.oscDataInfo.avsDeclineCodeList || ''}}, 'avsDeclineFlags', false);
        this.fieldsChange({target: {checked: this.oscDataInfo.csvDeclineCodeList || ''}}, 'csvDeclineFlags', false);
        this.fieldsChange({target: {checked: this.oscDataInfo.orderReconIdGeneration || false}}, 'reconciliationIDPrefix', true);
        this.addForm.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  populateListItems() {
    const programNamesArr = this.oscConfigDetailsList?.map(osc => osc?.programGroup?.programGroupName).filter(pgm => pgm);
    this.programNames = [...new Set(programNamesArr)];

    const paypalMerchantAccountNamesArr = this.oscConfigDetailsList?.map(osc => osc?.paypalMerchantAccountName? osc?.paypalMerchantAccountName.trim():'').filter(actName => actName);
    this.paypalMerchantAccountNames = [...new Set(paypalMerchantAccountNamesArr)];

    const paypalMerchantAccountEmailsArr = this.oscConfigDetailsList?.map(osc => osc?.paypalMerchantAccountEmail?osc?.paypalMerchantAccountEmail.trim():'').filter(email => email);
    this.paypalMerchantAccountEmails = [...new Set(paypalMerchantAccountEmailsArr)];

    const peoleSoftInterfaceCodesArr = this.oscConfigDetailsList?.map(osc => osc?.extractFileFormatCode);
    this.peopleSoftInterfaceCodes = [...new Set(peoleSoftInterfaceCodesArr)];
  }

  createForm() {
    this.addForm = new FormGroup({
      'originatingSystemCode': new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3), Validators.pattern(Constants.ALPHA_NUMERIC_PATTERN)]),
      'description': new FormControl('',[Validators.required, Validators.maxLength(25), Validators.minLength(1)]),
      'MerchantIDStoreID': new FormControl('', [Validators.required, Validators.maxLength(25), Validators.minLength(1)]),
      'reportingID': new FormControl('', [Validators.required, Validators.maxLength(25), Validators.minLength(1)]),
      'interfaceName': new FormControl('', [Validators.required, Validators.maxLength(35), Validators.minLength(1)]),
      'selectProgramHierarchyGrp': new FormControl('', Validators.required),
      'addProgramHierarchyGrp': new FormControl('', [Validators.maxLength(25), Validators.minLength(1)]),
      'payPalMerchantAccountID': new FormControl(''),
      'payPalMerchantemailAddress': new FormControl(''),
      'americanExpressServiceEstablishment': new FormControl('', [Validators.maxLength(20), Validators.minLength(1), Validators.pattern(Constants.NUMERIC_PATTERN)]),
      'peopleSoftInterface': new FormControl('', Validators.required),
      'defaultPeopleSoftProjectPJ': new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5), Validators.pattern(Constants.NUMERIC_PATTERN)]),
      'reconciliationIDPrefix': new FormControl(''),
      'defaultMerchantDescriptor': new FormControl('', [Validators.required, Validators.maxLength(25), Validators.minLength(1)]),
      'merchantDescriptorContact': new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(10), Validators.pattern(Constants.NUMERIC_PATTERN)]),
      'avsDeclineFlags': new FormControl('', Validators.required),
      'csvDeclineFlags': new FormControl('', Validators.required),
      'assignsOrderReconciliationID': new FormControl(null),
      'ignoreAVS': new FormControl(null),
      'ignoreCSV': new FormControl(null),
      'sale': new FormControl(true),
      'overrideRefund': new FormControl(true),
      'refund': new FormControl(true),
      'chargeback': new FormControl(true),
      'cfhop': new FormControl(false),
      'ihop': new FormControl(false),
      'vthop': new FormControl(false)
    })
  }


  onAddProgramHierarchyGrpChange(){
    if(this.addForm.get('addProgramHierarchyGrp').value) {
      this.addForm.get('selectProgramHierarchyGrp').disable();
      this.addForm.get('selectProgramHierarchyGrp').clearValidators();
      this.addForm.controls['selectProgramHierarchyGrp'].setValue('')
    }else{
      this.addForm.get('selectProgramHierarchyGrp').enable();
      this.addForm.get('selectProgramHierarchyGrp').setValidators([Validators.required]);
    }
    this.addForm.get('selectProgramHierarchyGrp').updateValueAndValidity();
  }

  exit() {
    this.openDialog();
  }

  openDialog() {
    // const dialogConfig = new MatDialogConfig();
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      data: <IDynamicDialogConfig>{
        title: 'Do you want to exit without saving?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value !== 'noExit') {
        this.router.navigate(['general-business-config']);
      }
    });
  }

  getOSCInfoFromUI() {
    const formData = this.addForm.value;
    let oscInfo;
    if(this.id){
      oscInfo = {} as OSCInfo; // update existing OSC data
      oscInfo.orderIdPrefix = formData.reconciliationIDPrefix?formData.reconciliationIDPrefix.trim():'';
      oscInfo.avsDeclineCodeList = formData.avsDeclineFlags;
      oscInfo.oscDailyExtractFile = this.oscDataInfo?.oscDailyExtractFile;
      oscInfo.generateDailyTransReport = this.oscDataInfo?.generateDailyTransReport;
      oscInfo.defaultPeopleSoftAccCode = this.oscDataInfo?.defaultPeopleSoftAccCode;
      oscInfo.ccDescriptorPrefix = this.oscDataInfo?.ccDescriptorPrefix;
      oscInfo.ecDescriptorPrefix = this.oscDataInfo?.ecDescriptorPrefix;
      oscInfo.discoveryServiceEstablishmentNumber = this.oscDataInfo?.discoveryServiceEstablishmentNumber;
    } else {
      oscInfo = {} as AddOSCInfo; // Add new osc
    }
    oscInfo.oscCode = formData.originatingSystemCode;
    oscInfo.oscDescription = formData.description;
    oscInfo.fiservStoreId = formData.MerchantIDStoreID;
    oscInfo.reportStoreNumber = formData.reportingID;
    oscInfo.applicationName = formData.interfaceName;
    oscInfo.programGroup = {
      programGroupName: formData.addProgramHierarchyGrp||formData.selectProgramHierarchyGrp
    };
    oscInfo.paypalMerchantAccountName = this.isEmpty(formData.payPalMerchantAccountID) ? null : formData.payPalMerchantAccountID;
    oscInfo.paypalMerchantAccountEmail = this.isEmpty(formData.payPalMerchantemailAddress) ? null : formData.payPalMerchantemailAddress;;
    oscInfo.amexServiceEstablishmentNumber = this.isEmpty(formData.americanExpressServiceEstablishment) ? null : formData.americanExpressServiceEstablishment;

    oscInfo.defaultPeopleSoftProjCode = formData.defaultPeopleSoftProjectPJ;
    oscInfo.extractFileFormatCode = formData.peopleSoftInterface;
    oscInfo.orderReconIdGeneration = formData.assignsOrderReconciliationID;
    oscInfo.defaultMerchantDesc = formData.defaultMerchantDescriptor;
    oscInfo.merchantPhoneNumber = formData.merchantDescriptorContact;
    oscInfo.csvDeclineCodeList = formData.csvDeclineFlags;
    oscInfo.ignoreAvsDeclineCodes = formData.ignoreAVS;
    oscInfo.ignoreCsvDeclineCodes = formData.ignoreCSV;
    oscInfo.allowSale = formData.sale;
    oscInfo.allowRefund = formData.refund;
    oscInfo.allowChargebacks = formData.chargeback;
    oscInfo.allowOverrideRefund = formData.overrideRefund;
    oscInfo.createdBy = this.oscDataInfo?.createdBy ? this.oscDataInfo?.createdBy : this.loggedInUser.emailId;
    oscInfo.updatedBy = this.oscDataInfo?.createdBy ? this.loggedInUser.emailId : '';
    oscInfo.createdDate = this.oscDataInfo?.createdDate ? this.oscDataInfo?.createdDate : new Date().toISOString();
    oscInfo.updateDate = this.oscDataInfo?.createdDate ? new Date().toISOString() : '';

    if (this.addForm.get('cfhop').enabled) {
      oscInfo.cfhop = formData.cfhop;
    }
    if (this.addForm.get('vthop').enabled) {
      oscInfo.vthop = formData.vthop;
    }
    if (this.addForm.get('ihop').enabled) {
      oscInfo.ihop = formData.ihop;
    }

    return oscInfo;
  }

  saveAndExit() {
    const oscInfo = this.getOSCInfoFromUI();
    const obs = this.id && this.id !== 'clone' ? this.orderService.saveOSCsInfo(oscInfo) : this.orderService.addNewOSC(oscInfo);
    this.errMsg='';
    obs.subscribe({
      next: data => {
        this.router.navigate(['general-business-config']);
      }, error: (err) => {
        console.log('error', err);
        if(err?.error?.message){
        this.errMsg='Error while processing the request. ' + err?.error?.message;
        }
      }
    });

  }

  fieldsChange(event, formName, required) {
    if (event.target.checked === required) {
      this.addForm.get(formName).setValidators([
        Validators.required
      ]);
    } else {
      this.addForm.controls[formName].clearValidators();
    }
    this.addForm.controls[formName].updateValueAndValidity();
  }

  isRequiredField(formName: string) {
    const form_field = this.addForm.get(formName);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required) || false;
  }

  isEmpty(text: string): boolean {
    return !(text && text.length > 0 && text.trim().length > 0);
  }
}
