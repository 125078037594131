export const ActionLink = {
  LinkedOverrideRefund: {
    title: 'Linked Override Refund',
  },
  Refund: {
    title: 'Refund',
  },
  Chargeback: {
    title: 'Manual Chargeback/Reverse Chargeback',
  },
  Retrieval: {
    title: 'Request For Retrieval',
  },
  AuditLog: {
    title: 'Note Log',
  },
  PostAudit: {
    title: 'Post Note',
  },
  RefundConfirmation: {
    title: 'Confirmation',
    messageHeading: 'Refund Results',
    message: 'Refund has been Successfully Processed.',
  },
  RefundFailed: {
    title: 'Refund Status',
    messageHeading: 'Refund Results',
    message: 'Refund has been failed.'
  },
  RetrievalConfirmation: {
    title: 'Confirmation',
    messageHeading: 'Request For Retrieval',
    message: 'Retrieval has been Successfully Processed.',
  },
  PostauditConfirmation: {
    title: 'Confirmation',
    messageHeading: 'Post Notes',
    message: 'Post Notes has been Successfully Processed.',
  },
};

// Action_Links_config conditions determine if action link is enabled or disabled
export const Action_Links_config = {
  LinkedOverrideRefund: {
    queryParam_orderState_enabled:
      'Sale Reconciliation pending|Sale Settled|Sale NoMatch Settled|Sale Updated settlement',
      data_paymentMethodCode_disabled: 'PayPal',
      data_paymentMethodCode_disabled_alipay: 'Alipay',
      data_originatingSystemCode_disabled: 'ING|INT',
      oscdata_allowOverrideRefund_disable: false
  },
  Refund: {
    queryParam_orderState_enabled:
    'Sale Reconciliation pending|Sale Settled|Sale NoMatch Settled|Sale Updated settlement',
  },
  Chargeback: {
    queryParam_orderState_enabled:
    'Sale Reconciliation pending|Sale Settled|Sale NoMatch Settled|Sale Updated settlement',
    oscdata_allowChargebacks_disable: false
  },
  Retrieval: {
    queryParam_orderState_enabled:
      'Sale Reconciliation pending|Sale Settled|Sale NoMatch Settled|Sale Updated settlement',
  },
};

// Data_Path must be added below to retrive data from txData
export const Data_Path = {
  paymentMethodCode: ['paymentMethodCode'],
  originatingSystemCode: ['order', 'originatingSystemCode'],
  allowOverrideRefund: ['allowOverrideRefund'],
  allowChargebacks: ['allowChargebacks']

};

// Action_Links_defaults will determine if action links enable by default(true) or conditionally(Action_Links_config)
export const Action_Links_defaults = {
  LinkedOverrideRefund: 'conditional',
  Refund: 'conditional',
  Chargeback: 'conditional',
  Retrieval: 'conditional',
  AuditLog: true,
  PostAudit: true,
};
