import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RefundService } from 'src/app/service/refund.service';
import {ChargebackRequest} from "../../model/chargeback-request";
import {User} from "../../model/user";
import {SharedService} from "../../service/shared.service";

@Component({
  selector: 'app-manual-reverse-chargeback',
  templateUrl: './manual-reverse-chargeback.component.html',
  styleUrls: ['./manual-reverse-chargeback.component.scss']
})
export class ReverseChargebackComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  reverse: FormGroup;
  modalRef: BsModalRef;
  userInfo: User;

  @Input()
  txData: any;
  @Input()
  parentRef: any;
  chargeBackResp: any;

  constructor(private refundService: RefundService,private sharedService: SharedService, private modalService: BsModalService,) {
  }

  ngOnInit(): void {

    this.initForm();

    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.userInfo = user;
      }
    });
  }

  initForm(){
    this.reverse = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0.1), Validators.pattern("^[.0-9]*$")]),
      reasonCode: new FormControl(null, Validators.required),
      reverse: new FormControl(null),
      currencyCode: new FormControl(this.txData?.amountDetail?.baseCurrencyCode),
    });
    if(this.txData.paymentTypeCode === 'PAYPAL'){
      this.reverse.controls['reasonCode'].clearValidators();
      this.reverse.controls['reasonCode'].updateValueAndValidity();
    }
  }

  getChargeBackRequest() {
    const chargebackRequest: ChargebackRequest = new ChargebackRequest();
    const formData = this.reverse.value;
    chargebackRequest.chargebackAmount = formData.amount;
    chargebackRequest.createdBy = this.userInfo?.emailAddress;
    chargebackRequest.processorCode = formData.reasonCode;
    chargebackRequest.transactionId = this.txData?.orderTransactionId;
    chargebackRequest.reverseChargeback = formData.reverse;
    if(formData.currencyCode) {
      chargebackRequest.currencyCode = formData.currencyCode;
    }else{
      chargebackRequest.currencyCode =null;
    }
    return chargebackRequest;

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {backdrop: 'static'});
  }

  processChargeBack(reversechargebackConfirmation) {
    this.chargeBackResp = undefined;
    const obs = this.refundService.processChargeBack(this.getChargeBackRequest());
    obs.subscribe(data => {
      this.chargeBackResp = data;
      this.parentRef.close();
      this.openModal(reversechargebackConfirmation);
    }, err => {
      this.chargeBackResp = {
        errorInfo: err.error.message||err.error.error,
        error: true
      };
      console.log('error = ' + err);
      this.parentRef.close();
      this.openModal(reversechargebackConfirmation);
    });
  }
}
