<div class="ml-2 me-2 mb-2">
<div class="row">
  <h2 class="col-10" mat-dialog-title><span *ngIf="data.title">{{ data.title }}</span></h2>
  <div class="col-2" align="end">
    <button mat-icon-button class="close-button" (click)="noExit()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <ng-container *ngIf="!data.htmlContent" [ngTemplateOutlet]="data.dialogContent">
  </ng-container>
  <div *ngIf="data.htmlContent || data.error" [ngClass]="{
      'alert-success': data.actionState === 'confirmation',
      'alert-danger': data.actionState === 'failed'
    }" class="alert">
    <div class="alert-message">
      <div class="row">
        <div class="col-md-1 hl">
          <fa-icon *ngIf="data.actionState === 'confirmation'" [icon]="faCheckCircle" class="check"></fa-icon>
          <fa-icon *ngIf="data.actionState === 'failed'" [icon]="faExclamationTriangle" class="check"></fa-icon>
        </div>
        <div class="col-md-11">
          <div *ngIf="data.htmlContent" [innerHtml]="data.htmlContent"></div>
          <h4 *ngIf="data.errorTitle">{{ data.errorTitle }}</h4>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data.error" class="col-12">
    <table class="table grey borderless">
      <tr>
        <th>Message</th>
        <td>{{ (data?.error?.error | json) || data?.error?.message }}</td>
      </tr>
      <tr>
        <th>Status Code</th>
        <td>{{ data?.error?.status }}</td>
      </tr>
      <tr>
        <th>Status text</th>
        <td>{{ data?.error?.statusText }}</td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.acceptButtonTitle">
  <button class="btn btn-primary btn-function" (click)="exit()">
    {{ data.acceptButtonTitle }}
  </button>
  <button class="btn btn-primary" (click)="noExit()">
    {{ data.declineButtonTitle }}
  </button>
</mat-dialog-actions>
</div>