import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {OrderService} from "../../service/order.service";
import {OrdMsg} from "../../model/ord-msg";
import {User} from "../../model/user";
import {SharedService} from "../../service/shared.service";
import { ActionLinkService } from 'src/app/service/action-links.service';

@Component({
  selector: 'app-audit-message',
  templateUrl: './audit-message.component.html',
  styleUrls: ['./audit-message.component.scss']
})
export class AuditMessageComponent implements OnInit {
  dateCreated;
  createdBy;
  message;
  auditMessage: FormGroup;
  userInfo: User;
  modalRef;

  @Input()
  txData: any;
  @Input()
  parentRef: any;
  ordMsgList: OrdMsg[] = [];

  constructor(private sharedService: SharedService, private orderService: OrderService, private actionLinkService: ActionLinkService) {
  }

  ngOnInit(): void {

    this.auditMessage = new FormGroup({
      message: new FormControl(null)
    });

    const obs = this.orderService.getAllMsgs(this.txData.order.epmsOrderId);
    obs.subscribe(data => {
      this.ordMsgList = data;
      const ordMsg = this.ordMsgList[0];
      if (ordMsg) {
        this.dateCreated = ordMsg.createdTimestamp;
        this.createdBy = ordMsg.createdBy;
        this.message = ordMsg.message;
      }
    }, err => {
      console.log('error = ' + err);
    });

    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.userInfo = user;
      }
    });

  }

  saveMsg() {
    const orderMsg: OrdMsg = new OrdMsg();
    orderMsg.createdBy = this.userInfo?.userLoginId;
    orderMsg.epmsOrderId = this.txData.order.epmsOrderId;
    orderMsg.message = this.auditMessage.value.message;
    const obs = this.orderService.save(orderMsg);
    obs.subscribe(data => {
      this.parentRef.close();
      this.actionLinkService.openDialog('PostauditConfirmation', 'confirmation');
    }, err => {
      console.log('err = ' + err);
    });
  }

  reset(){
    this.auditMessage = new FormGroup({
      message: new FormControl(null)
    });
  }
}

