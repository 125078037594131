import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OrderSearchResponse } from '../model/order-search-response';
@Injectable({
  providedIn: 'root'
})
export class OrderSearchService {
  private dataSource = new ReplaySubject(1);
  private orderSearchCriteria = new BehaviorSubject('');
  private orderFilterCriteria = new BehaviorSubject('');

  currentOrderSearchResult = this.dataSource.asObservable();
  currentSearchCriteria = this.orderSearchCriteria.asObservable();
  currentFilterCriteria = this.orderFilterCriteria.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
  }

  changeOrderSearchCriteria(criteria: string){
    this.orderSearchCriteria.next(criteria);
  }

  changeOrderFilterCriteria(filterBy: string) {
    this.orderFilterCriteria.next(filterBy);
  }

  /**
   * This method get the bulk order search record detail. 
   * @param orderSearchRequest 
   * @returns 
   */
  orderSearch(orderSearchRequest): Observable<OrderSearchResponse> {
    // searchUrl = this.hostUrl + '/data/search?size='+this.orderSearchSize +'&page='+ this.orderSearchPage;
    return this.http.post<OrderSearchResponse>(`${environment.ADMIN_API_HOSTNAME}/data/search`, orderSearchRequest, this.httpOptions)
      .pipe(
        tap( response => {
          this.dataSource.next(response);
        }),
        catchError( error => {
          return this.handleErrorMessage(error);
        })
      );
  }

  /**
   * This method gets the order search record detail for Particular record.
   * @param orderSearchRequest 
   * @returns 
   */
  orderSearchRecordDetail(orderSearchRequest): Observable<OrderSearchResponse> {
    return this.http.post<OrderSearchResponse>(`${environment.ADMIN_API_HOSTNAME}/data/search`, orderSearchRequest, this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(error => {
          return this.handleErrorMessage(error);
        })
      );
  }

  private handleErrorMessage(error: any) {
    let errorMsg: string;
    if (error.error instanceof ErrorEvent) {
      errorMsg = `Error: ${error.error.message}`;
    } else {
      console.log('Order search failure: ' + JSON.stringify(error));
      errorMsg = this.getServerErrorMessage(error);
    }
    return throwError(errorMsg);
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400: {
        return `Bad Request: ${error?.error?.error}`;
      }
      case 500: {
        return `Internal Server Error: ${error?.error?.error}`;
      }
      default: {
        return `Unknown Server Error: ${error?.error?.error}`;
      }
    }
  }
}
