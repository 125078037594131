<div id="dropdown-arrow-mat" class="mt-4 container-xl" *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div id="virtual-terminal" class="row">
        <div class="col-md-12">
            <h1>Virtual Terminal</h1>
            <p></p>
            <div class="card">
                <div class="card-body ">
                    <form [formGroup]="transactionForm" class="row d-flex align-items-center">
                        <div class="col-md-5 form-controls">
                            <label class="form-label">Transaction Type</label>
                            <mat-select class="form-select form-select-md" formControlName="transaction" id="transaction"
                                (selectionChange)="ChangeOption($event)">
                                <mat-option  disabled>Select...</mat-option>
                                <mat-option *ngIf="profile.hasVtSaleAcc" id="sale" value="SALE">Sale</mat-option>
                                <mat-option *ngIf="profile.hasVtRefundAcc" id="refund" value="REFUND">Override Refund</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-5 form-controls">
                            <label class="form-label">Originating System Code</label>
                            <mat-select class="form-select form-select-md" formControlName="OSC">
                                <mat-option Value="null" disabled>Select...</mat-option>
                                <ng-container *ngFor="let oscCode of oscCodesUserSelected">
                                    <mat-option [value]="oscCode">{{oscCode}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                        <div class="col-md-2 pt-3">
                            <button class="btn btn-primary" type="submit" (click)="submit()"
                                [disabled]="!transactionForm.valid">Go</button>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="submitted === 'SALE'">
                <app-payment-processing [virtualTerminalData]="transactionForm.value" [txData]="txData" [processType]="submitted">
                </app-payment-processing>
            </div>
            <div *ngIf="submitted === 'REFUND'">
                <app-payment-processing [virtualTerminalData]="transactionForm.value"
                    [txData]="txData" [processType]="submitted"></app-payment-processing>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8  alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to access the page.
    </div>
    <div class="col-2"></div>
</div>
