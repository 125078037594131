<div align="end">
<button mat-icon-button class="close-button" (click)="exit()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
</div>
<h2 mat-dialog-title>{{ data?.modalContent?.title }}</h2>
<mat-dialog-content>
  <div *ngIf="data.actionState === 'action'">
    <app-linked-override-refund *ngIf="data?.actionType === 'LinkedOverrideRefund'" #actionLink [parentRef]="dialogRef"
      [txData]="data?.txData" [calculatedRefundAmount]="data?.calculatedRefundAmount"></app-linked-override-refund>
    <app-refund *ngIf="data?.actionType === 'Refund'" [txData]="data?.txData" [calculatedRefundAmount]="data?.calculatedRefundAmount" [parentRef]="dialogRef"></app-refund>
    <app-manual-reverse-chargeback *ngIf="data?.actionType === 'Chargeback'" [txData]="data?.txData"
      [parentRef]="dialogRef"></app-manual-reverse-chargeback>
    <app-request-retrieval *ngIf="data?.actionType === 'Retrieval'" [parentRef]="dialogRef"></app-request-retrieval>
    <app-audit-log *ngIf="data?.actionType === 'AuditLog'" [txData]="data?.txData" [parentRef]="dialogRef">
    </app-audit-log>
    <app-audit-message *ngIf="data?.actionType === 'PostAudit'" [txData]="data?.txData" [parentRef]="dialogRef">
    </app-audit-message>
  </div>
  <div [hidden]="data.actionState === 'action'">
    <div [ngClass]="{
        'alert-success': data.actionState === 'confirmation',
        'alert-danger': data.actionState === 'failed'
      }" class="alert">
      <div class="alert-message">
        <div class="row">
          <div class="col-md-1 hl">
            <fa-icon [icon]="faCheckCircle" class="check"></fa-icon>
          </div>
          <div class="col-md-11">
            <strong class="hl">{{ data?.modalContent?.messageHeading }}</strong><br />
            {{ data?.modalContent?.message }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data?.errorData" class="row">
      <div class="col-12">
        <table class="table grey borderless">
          <tr>
            <th>Response Code</th>
            <td>{{ data?.errorData?.pgmResponseCode  }}</td>
          </tr>
          <tr>
            <th>Response Message</th>
            <td>{{ data?.errorData?.pgmResponseMessage || data?.errorData?.decision }}</td>
          </tr>
          <tr>
            <th>Response Status Code</th>
            <td>{{ data?.errorData?.status || data?.errorData?.reasonCode}}</td>
          </tr>
          <tr>
            <th>Response Error</th>
            <td>{{ data?.errorData?.error || data?.errorData?.reasonDescription }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>
