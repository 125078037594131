import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {faDownload, faSearch} from '@fortawesome/free-solid-svg-icons';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {OrderService} from '../service/order.service';
import {FormControl, FormGroup} from '@angular/forms';
import {InternalExternalUserComponent} from './internal-external-list/internal-external-list.component';
import {Subscription} from 'rxjs';
import {AssignBusinessService} from '../service/assign-business.service';
import {User} from '../model/user';
import {SharedService} from '../service/shared.service';
import {DatePipe} from '@angular/common';
import {RoleInfo} from '../model/role-info';
import {ProgramGroup} from "../model/program-group";
import {UserSearchInfo} from "../model/user-search-info";


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  providers: [DatePipe],
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  faSearch = faSearch;
  faDownload = faDownload;
  modalRef: BsModalRef;
  userSearch: FormGroup;
  @ViewChild('internalExternalUserComponent')
  internalExternalUser: InternalExternalUserComponent;
  messageFromAssignBus: Subscription;
  showErrorInfo: any;
  showError = false;
  showSuccessInfo: any;
  showSuccess = false;
  sourceRoute: string;
  loggedInUser: User;
  oscConfigCodes = [];
  allOscConfigCodes = [];
  programGroupList: ProgramGroup[] = [];
  roleInfoList: RoleInfo[] = [];
  selectedRoleInfo: any;
  exportOption = 'xls';
  userSearchInfo: UserSearchInfo;

  roleNameCtrl;
  roleDescCtrl;

  roleNameChage;
  oscCodeChange;
  roleDescChage;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private assignBusService: AssignBusinessService,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private sharedService: SharedService,
    private datePipe: DatePipe
  ) {
    this.sourceRoute = this.activatedRoute.snapshot.paramMap.get('source');
  }

  createUserSearchForm() {
    this.roleNameCtrl = new FormControl('');
    this.roleDescCtrl = new FormControl('');
    this.roleNameChage = undefined;
    this.roleDescChage = undefined;
    this.oscCodeChange = undefined;
    this.userSearch = new FormGroup({
      'userLoginID': new FormControl(null),
      'userID': new FormControl(null),
      'prgGrp': new FormControl(''),
      'firstName': new FormControl(null),
      'lastName': new FormControl(null),
      'OSC': new FormControl(''),
      'status': new FormControl(''),
      'roleName': this.roleNameCtrl,
      'roleDesc': this.roleDescCtrl,
      'accountType': new FormControl(this.loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_max' ? '' : this.loggedInUser.accountType),
      'createdDateFrom' : new FormControl(''),
      'createdDateTo' : new FormControl(''),
      'loginDateFrom' : new FormControl(''),
      'loginDateTo' : new FormControl(''),
    });
    this.onProgramGroupNameChange(undefined);
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
        window.scrollTo(0, 0)

      }
      this.createUserSearchForm();
      this.getOscConfigCodes();

    });
    this.getRoleNameCodes();
    this.getAllProgramGroupNames();
    window.scrollTo(0, 0);
    this.showError = false;
    this.showSuccess = false;

    if (this.sourceRoute === 'assignRoleBusinessError') {
      this.messageFromAssignBus = this.assignBusService.msgFromAssignBusiness.subscribe(value => this.showErrorInfo = value);
      if (this.showErrorInfo) {
        this.showError = true;
      }
    } else if (this.sourceRoute === 'assignRoleBusinessSuccess') {
      this.messageFromAssignBus = this.assignBusService.msgFromAssignBusiness.subscribe(value => this.showSuccessInfo = value);
      if (this.showSuccessInfo) {
        this.showSuccess = true;
      }
    } else {
      // set message null if route is not from assign business
      this.showError = this.showSuccess = false;
      this.showErrorInfo = null;
      this.showSuccessInfo = null;
      this.assignBusService.sendMessage('');
    }

    console.log("ERROR FROM ASSIGNED BUSINESS: " + this.showErrorInfo);
  }

  addCreateDateEvent() {
  }
  addLoginDateEvent() {
  }

  ngOnDestroy(): void {
    if (this.messageFromAssignBus)
      this.messageFromAssignBus.unsubscribe();
  }

  onAssign() {
    const usersAssignData = this.internalExternalUser.getSelectedUserId();
    if (usersAssignData) {
      sessionStorage.setItem('usersAssignRoleBusiness', JSON.stringify(usersAssignData))
      this.router.navigate(['/assign-role-and-business']);
    }
  }

  openModal(exportSave: TemplateRef<any>) {
    this.modalRef = this.modalService.show(exportSave);
  }

  getOscConfigCodes() {
    const obs = this.orderService.getOscConfigCodes();
    obs.subscribe(data => {
      this.oscConfigCodes = data;
      this.allOscConfigCodes = data;
    }, err => {
      console.log('err = ' + JSON.stringify(err));
      this.oscConfigCodes = [];
    });
  }

  getRoleNameCodes() {
    const obs = this.orderService.getRoleNameCodes();
    obs.subscribe(data => {
        this.roleInfoList = data.filter(role => 
          (this.loggedInUser.role.privilegeLevelCode <= role.privilegeLevelCode && 
            ((this.loggedInUser.role.roleName === 'app_epms-admin_ui_aws_ets_all_max') || (this.loggedInUser.accountType === 'internal' && !role.roleName.includes('_ext_'))
           || (this.loggedInUser.accountType === 'external' && role.roleName.includes('_ext_')))));
    }, err => {
      console.log('err =' + JSON.stringify(err));
      this.roleInfoList = [];
    });
  }

  getAllProgramGroupNames() {
    const obs = this.orderService.getAllProgramGroupNames();
    obs.subscribe(data => {
      this.programGroupList = data;
    }, err => {
      console.log('err =' + JSON.stringify(err));
      this.programGroupList = [];
    });
  }

  onRoleNameChange(roleId) {
    this.roleNameChage = true;
    this.onRoleChange(roleId);
  }


  onOscCodeChange(oscCode) {
    this.oscCodeChange = true;

  }

  onRoleChange(roleId) {

    const filteredRoleInfo = this.roleInfoList.filter(roleInfo => roleInfo.roleId == roleId)[0];
    this.selectedRoleInfo = '';
    if (filteredRoleInfo) {
      this.selectedRoleInfo = filteredRoleInfo.roleId;
    }
  }

  onRoleDescChange(roleId) {
    this.roleDescChage = true;
    this.onRoleChange(roleId);
  }

  onProgramGroupNameChange(programGroupName) {
    if (programGroupName) {
      for (const pgGrpInfo of this.programGroupList) {
        if (pgGrpInfo.programGroupName === programGroupName) {
          // pgGrpInfo.oscList;
          this.oscConfigCodes = pgGrpInfo['oscList'];
          break;
        }
      }
    } else {
      this.oscConfigCodes = this.allOscConfigCodes;
    }
  }


  getTableData(fileType?:string) {
    const clonedDataList = this.internalExternalUser.dataSource.data.map(row => {
      const cloneRow = {...row};

      if (cloneRow.createdDate) {
        // @ts-ignore
        cloneRow.createdDate = this.datePipe.transform(new Date(cloneRow.createdDate), 'yyyy-MM-dd HH:mm:ss');
      }
      if ('csv' === fileType) {

        if(cloneRow.programGroup){
          cloneRow.programGroup = '"' + cloneRow.programGroup + '"';
        }
        if(cloneRow.osc){
          cloneRow.osc = '"' + cloneRow.osc + '"';
        }
      }

      return cloneRow;
    });
    return clonedDataList;

  }

  clearForm() {
    this.selectedRoleInfo = '';
    this.createUserSearchForm();
  }

  onGoClicks() {
    const selectedRole = this.selectedRoleInfo;
    const formData = this.userSearch.value;
    const filteredRoleInfo = this.roleInfoList.filter(roleInfo => roleInfo.roleId == selectedRole)[0];
    const info: UserSearchInfo = new UserSearchInfo();
    info.firstName = formData.firstName;
    info.lastName = formData.lastName;
    info.prgGrp = formData.prgGrp;
    info.userLoginId = formData.userLoginID;
    info.userId = formData.userID;
    info.oscCde = formData.OSC;
    info.state = formData.status;
    info.accountType = formData.accountType;
    if(this.loggedInUser.role.roleName != "app_epms-admin_ui_aws_ets_all_max"){
    info.loggedInUserPrivilegeLevelCode = this.loggedInUser.role.privilegeLevelCode;
    }
    const  startTime:string = '00:00';
    const endTime:string = '23:59';
    if(formData.createdDateFrom && formData.createdDateTo){
      info.createdStartDate=this.datePipe.transform(formData.createdDateFrom, 'yyyy-MM-ddT'+startTime+'ZZZZZ');
      info.createdEndDate=this.datePipe.transform(formData.createdDateTo, 'yyyy-MM-ddT'+endTime+'ZZZZZ');
    }
    if(formData.loginDateFrom && formData.loginDateTo){
      info.loginStartDate=this.datePipe.transform(formData.loginDateFrom, 'yyyy-MM-ddT'+startTime+'ZZZZZ');
      info.loginEndDate=this.datePipe.transform(formData.loginDateTo, 'yyyy-MM-ddT'+endTime+'ZZZZZ');
    }


    if (filteredRoleInfo) {
      info.roleName = filteredRoleInfo.roleName;
      info.roleDesc = filteredRoleInfo.roleDesc;
    }

    this.userSearchInfo = info;
  }
  clearDate(){
    this.userSearch.controls.createdDateFrom.setValue('');
    this.userSearch.controls.createdDateTo.setValue('');
  }

  clearQuickDate() {
    this.userSearch.controls.loginDateFrom.setValue('');
    this.userSearch.controls.loginDateTo.setValue('');
  }
}

