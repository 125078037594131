import { FormGroup, ValidationErrors } from "@angular/forms";

const isFieldEmpty = (fieldName: string, formGroup: FormGroup) => {
    const field = formGroup.get(fieldName).value;
    if (typeof field === 'string') { return field && field.length > 0 ? true : false; }
};

export function validateIfAtleastOneRequiredIsPresent(...fields: Array<string>) {
    return (formGroup: FormGroup): ValidationErrors | null => {
        return fields.some(fieldName => isFieldEmpty(fieldName, formGroup))
            ? null
            : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
    };
}