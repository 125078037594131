<div class="btn-group" dropdown>
  <button
    id="button-basic"
    dropdownToggle
    type="button"
    class="btn dropdown-toggle btn-action"
    aria-controls="dropdown-basic"
  >
    Action Links<span class="caret"></span>
  </button>
  <ul
    id="dropdown-basic"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    aria-labelledby="button-basic"
  >
    <li *ngIf="profile.hasOsOverRefundAcc" role="menuitem">
      <a class="dropdown-item" [class]="!actionLinks['LinkedOverrideRefund']? 'disabled-link':''" (click)="actionLinkService.openDialog('LinkedOverrideRefund', 'action', txData, calculatedRefundAmount)"
        >Linked Override Refund</a
      >
    </li>
    <li *ngIf="profile.hasOsRefundAcc" role="menuitem">
      <a class="dropdown-item" [class]="!actionLinks['Refund']? 'disabled-link':''" (click)="actionLinkService.openDialog('Refund', 'action', txData, calculatedRefundAmount)">Refund</a>
    </li>
    <li *ngIf="profile.hasOsChargebkAcc " role="menuitem"  >
        <a class="dropdown-item" [class]="!actionLinks['Chargeback']? 'disabled-link':''" (click)="actionLinkService.openDialog('Chargeback', 'action', txData)"
        >Manual Chargeback/Reverse Chargeback</a
        >
    </li>
    <li *ngIf="profile.hasOsRetReqAcc" role="menuitem">
      <a class="dropdown-item" [class]="!actionLinks['Retrieval']? 'disabled-link':''" (click)="actionLinkService.openDialog('Retrieval', 'action')"

        >Request For Retrieval</a
      >
    </li>
    <li *ngIf="profile.hasOsAuditAcc" role="menuitem">
      <a class="dropdown-item" [class]="!actionLinks['AuditLog']? 'disabled-link':''" (click)="actionLinkService.openDialog('AuditLog', 'action', txData)">Note Log</a>
    </li>
    <li *ngIf="profile.hasOsPostAuditAcc" role="menuitem">
      <a class="dropdown-item" [class]="!actionLinks['PostAudit']? 'disabled-link':''" (click)="actionLinkService.openDialog('PostAudit', 'action',txData)">Post Note</a>
    </li>
  </ul>
</div>

<ng-template #successConfirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <div align="end">
    <button mat-icon-button class="close-button" (click)="modalRef.hide()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  </div>
  <div class="modal-body">
    <div class="confirmation">
      <div class="alert alert-success" type="success">
        <div class="alert-message">
          <div class="row">
            <div class="col-md-1 hl">
              <fa-icon [icon]="faCheckCircle" class="check"></fa-icon>
            </div>
            <div class="col-md-11">
              <strong class="hl">Override Refund Result</strong><br />
              Override Refund Successfully Processed.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table grey borderless">
            <tr>
              <th>Amount</th>
              <td>{{ initialState.amount }}</td>
            </tr>
            <tr>
              <th>Originating System Code (OSC)</th>
              <td>{{ initialState.oscCode }}</td>
            </tr>
            <tr>
              <th>Order ID</th>
              <td>{{ initialState.orderId }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #errorConfirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <div align="end">
    <button mat-icon-button class="close-button" (click)="modalRef.hide()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="fail">
      <div class="alert alert-danger grey" type="danger">
        <div class="alert-message">
          <div class="row">
            <div class="col-md-1 red hl">
              <fa-icon [icon]="faExclamationTriangle" class="check"></fa-icon>
            </div>
            <div class="col-md-11">
              <strong class="red hl"> Override Refund Transaction Result</strong
              ><br />
              <span class="red" *ngIf="initialState.decision === 'DECLINED'"
                >Your Refund was declined.</span
              ><br *ngIf="initialState.decision === 'DECLINED'" />
              <span class="red" *ngIf="initialState.decision === 'REJECT'"
                >Your Refund was rejected.</span
              ><br *ngIf="initialState.decision === 'REJECT'" />
              <span class="red" *ngIf="initialState.decision === 'ERROR'"
                >Your Refund was in error.</span
              ><br *ngIf="initialState.decision === 'ERROR'" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table grey borderless">
            <tr>
              <th>Amount</th>
              <td>{{ initialState.amount }}</td>
            </tr>
            <tr>
              <th>OSC</th>
              <td>{{ initialState.oscCode }}</td>
            </tr>
            <tr>
              <th>Order ID</th>
              <td>{{ initialState.orderId }}</td>
            </tr>
            <tr>
              <th>Decision</th>
              <td>{{ initialState.decision }}</td>
            </tr>
            <tr>
              <th>Response Code</th>
              <td>{{ initialState.errCode }}</td>
            </tr>
            <tr>
              <th>Response Message</th>
              <td>{{ initialState.errMsg }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
