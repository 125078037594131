import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminHomeComponent} from '../app/admin-home/admin-home.component';
import {OrderSearchComponent} from '../app/order-search/order-search.component';
import {VirtualTerminalComponent} from '../app/virtual-terminal/virtual-terminal.component';
import {OrderSearchResultComponent} from '../app/order-search-result/order-search-result.component';
import {OrderSearchDetailsComponent} from '../app/order-search-result/order-search-details/order-search-details.component';
import {UserManagementComponent} from '../app/user-management/user-management.component';
import {UserDetailsComponent} from './user-management/user-details/user-details.component';
import {MasterGlobalConfigComponent} from '../app/oschop-config/master-global-config/master-global-config.component';
import {GeneralBusinessConfigComponent} from '../app/oschop-config/general-business-config/general-business-config.component';
import {CountryRegionService} from "./service/country-region.service";
import { LogoutComponent } from './logout/logout.component';
import { MasterVirtualTerminalHOPConfigComponent } from './oschop-config/master-and-virtual-terminal-hop-config/master-and-virtual-terminal-hop-config.component';
import { OSCSpecificHOPConfigComponent } from './oschop-config/osc-specific-hop-config/osc-specific-hop-config.component';
import { AddEditBusinessOscConfigComponent } from './oschop-config/add-edit-business-osc-config/add-edit-business-osc-config.component';
import { ViewBusinessOscConfigComponent } from './oschop-config/view-business-osc-config/view-business-osc-config.component';
import { VirtualTerminalConfigurationComponent } from './oschop-config/virtual-terminal-configuration/virtual-terminal-configuration.component';
import { OrderUiComponent } from './order-ui/order-ui.component';
import { RefundUiComponent } from './refund-ui/refund-ui.component';
import { SubscriptionUiComponent } from './subscription-ui/subscription-ui.component';
import { EditOSCSpecificHOPConfigComponent } from './oschop-config/edit-osc-specific-hop-config/edit-osc-specific-hop-config.component';
import { VirtualTerminalAuditLogComponent } from './virtual-terminal-audit-log/virtual-terminal-audit-log.component';
import { ReconciliationDashboardByFileComponent } from './dashboard/reconciliation-dashboard-by-file/reconciliation-dashboard-by-file.component';
import { ReconciliationDashboardComponent } from './dashboard/reconciliation-dashboard/reconciliation-dashboard.component';
import { ReconciliationDashboardDetailsComponent } from './dashboard/reconciliation-dashboard-details/reconciliation-dashboard-details.component';
import { ConfirmationComponent } from './virtual-terminal/confirmation/confirmation.component';
import { ManageExternalUserComponent } from './user-management/manage-external-user/manage-external-user.component';
import { AssignRoleAndBusinessComponent } from './assign-role-and-business/assign-role-and-business.component';

const routes: Routes = [
{ path: 'epms-admin-home', component:AdminHomeComponent },
{ path: 'order-search', component:OrderSearchComponent },
{
  path: 'virtual-terminal', component: VirtualTerminalComponent, 
  resolve: { countries: CountryRegionService }
},
{ path: 'virtual-terminal-audit-log', component:VirtualTerminalAuditLogComponent},
{ path: 'order-search-result', component:OrderSearchResultComponent},
{ path: 'order-search-result/:source', component:OrderSearchResultComponent},
{ path: 'virtual-terminal/confirmation', component:ConfirmationComponent},
{ path: 'order-search-details/:transactionId', component:OrderSearchDetailsComponent},
{ path: 'user-management', component:UserManagementComponent},
{ path: 'manage-external-user', component:ManageExternalUserComponent},
{ path: 'user-management/user-details', component:UserDetailsComponent},
{ path: 'user-management/:source', component:UserManagementComponent},
{ path: 'assign-role-and-business', component:AssignRoleAndBusinessComponent},
{ path: 'master-global-config', component:MasterGlobalConfigComponent},
{ path: 'add-edit-business-osc-config', component:AddEditBusinessOscConfigComponent},
{ path: 'add-edit-business-osc-config/:id', component:AddEditBusinessOscConfigComponent},
{ path: 'edit-osc-specific-hop-config', component:EditOSCSpecificHOPConfigComponent},
{ path: 'edit-osc-specific-hop-config/:osc', component:EditOSCSpecificHOPConfigComponent},
{ path: 'master-and-virtual-terminal-hop-config', component:MasterVirtualTerminalHOPConfigComponent},
{ path: 'master-and-virtual-terminal-hop-config/virtual-terminal', component:VirtualTerminalConfigurationComponent},
{ path: 'osc-specific-hop-config', component:OSCSpecificHOPConfigComponent},
{ path: 'general-business-config', component:GeneralBusinessConfigComponent},
{ path: 'view-business-osc-config', component:ViewBusinessOscConfigComponent},
{ path: 'logout', component:LogoutComponent},
{ path: 'order-ui', component:OrderUiComponent },
{ path: 'order-ui/virtual-terminal', component:OrderUiComponent },
{ path: 'refund', component:RefundUiComponent },
{ path: 'subscription', component:SubscriptionUiComponent },
{ path: 'reconciliation-dashboard', component:ReconciliationDashboardComponent },
{ path: 'reconciliation-dashboard-details', component:ReconciliationDashboardDetailsComponent },
{ path: 'reconciliation-dashboard-by-file', component:ReconciliationDashboardByFileComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    //  debugging purposes only
    { enableTracing: false , onSameUrlNavigation:'reload'}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
