<br>
<div class="row">
    <div class="col-md-12">
        <h5>Line Item Details: </h5>
    </div>
</div>
<div class="col-md-12">
    <mat-card class="mat-elevation-z8">
        <mat-card-actions>
            <button [disabled]="!addOrderItems" (click)="addLineItem();emitLineItemsData()" class="add-icon-btn">
                <mat-icon aria-hidden="true" aria-label="Add Lineitem icon">add</mat-icon>
            </button>
        </mat-card-actions>
        <mat-table *ngIf="lineItems?.length > 0" #table class="mat-elevation-z0" [dataSource]="lineItemDataSource"
            [trackBy]="trackRows">
            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <button (click)="deleteLineItem(i)" class="add-icon-btn">
                            <mat-icon aria-hidden="true" aria-label="Delete Lineitem icon">delete</mat-icon>
                        </button>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Product Column -->
            <ng-container matColumnDef="productName">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <mat-form-field>
                            <mat-label> Product Name</mat-label>
                            <input matInput placeholder="Product Name" id="productName{{i}}"
                                name="productName{{i}}" formControlName="productName" (change)="emitLineItemsData()"/>
                        </mat-form-field>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="productSKU">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <mat-form-field>
                            <mat-label> Product SKU</mat-label>
                            <input matInput placeholder="Product SKU" id="productSku_{{i}}"
                                name="productSku_{{i}}" formControlName="productSKU" (change)="emitLineItemsData()" />
                        </mat-form-field>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="productCode">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <mat-form-field>
                            <mat-label> Product Code</mat-label>
                            <input matInput placeholder="Product Code" id="productCode{{i}}"
                                name="productCode{{i}}" formControlName="productCode" (change)="emitLineItemsData()"/>
                        </mat-form-field>
                        <!-- <br>
                        <span *ngIf="row.hasError('atLeastOne') && (row.get('productCode').dirty || row.get('productCode').touched)" class="tip">Product SKU or product Code must be specified.</span> -->
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <mat-form-field>
                            <mat-label> Quantity</mat-label>
                            <input matInput placeholder="Quantity" id="quantity{{i}}"
                                name="quantity{{i}}" formControlName="quantity" (change)="emitLineItemsData()"/>
                        </mat-form-field>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="unitPrice">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <mat-form-field>
                            <mat-label><span class="required-asterisk">*</span> Unit Price</mat-label>
                            <input matInput currencyInput placeholder="Unit Price" id="unitPrice{{i}}" name="unitPrice{{i}}"
                                formControlName="unitPrice" (change)="emitLineItemsData()" />
                                <br>
                            </mat-form-field>
                            <br>
                            <span *ngIf="row.get('unitPrice').invalid && (row.get('unitPrice').dirty || row.get('unitPrice').touched)"
                                class="tip">Amount is a required field and must be specified.</span>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="taxAmount">
                <mat-cell *matCellDef="let row; let i = index">
                    <ng-container [formGroup]="row">
                        <mat-form-field>
                            <mat-label> Tax Amount</mat-label>
                            <input matInput currencyInput placeholder="Tax Amount" id="taxAmount{{i}}"
                                name="taxAmount{{i}}" formControlName="taxAmount" (change)="emitLineItemsData()"/>
                        </mat-form-field>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: lineItemDetailColumns"></mat-row>
        </mat-table>
    </mat-card>
</div>
