import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { OSCHOPService } from 'src/app/service/osc-hop-config.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-master-and-virtual-terminal-hop-config',
  templateUrl: './master-and-virtual-terminal-hop-config.component.html',
  styleUrls: ['./master-and-virtual-terminal-hop-config.component.scss'],
})
export class MasterVirtualTerminalHOPConfigComponent implements OnInit {
  isDisabled: boolean;
  loggedInUser: User;
  dropdownValue = 'default';
  disableButtonMode: boolean = true;
  selectedContextValue: string;
  constructor(private sharedService: SharedService, private router: Router,
    private oscHOPService: OSCHOPService) {}

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        if(!JSON.parse(sessionStorage.getItem('masterHOPDetails'))){
        this.oscHOPService.getAndStoreMasterHOPDetails();
        }
      }
    });
  }

  disableButton() {
    this.disableButtonMode = this.dropdownValue == 'default' ? true : false;
  }

  viewSelected(){
    this.selectedContextValue = this.dropdownValue;
    if(this.selectedContextValue === 'VirtualTerminal'){
      this.router.navigate(['master-and-virtual-terminal-hop-config/virtual-terminal']);
    }
  }
}
