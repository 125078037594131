import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VirtualTerminalAuditLogService {
  private orderFilterCriteria = new BehaviorSubject('');
  currentFilterCriteria = this.orderFilterCriteria.asObservable();

  private filterResultCriteria = new BehaviorSubject('');
  currentFilterResultCriteria = this.filterResultCriteria.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor() {
  }

  changeOrderFilterCriteria(filterBy: string) {
    this.orderFilterCriteria.next(filterBy);
  }

  storeFilterResult(result: any) {
    this.filterResultCriteria.next(result);
  }

}
