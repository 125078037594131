<div id="edit-osc-hop-config" *ngIf="loggedInUser?.oscDetails?.length > 0">
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a>OSC and HOP Configuration</a></li>
          <li class="breadcrumb-item"><a (click)="exit()">OSC Specific HOP Configuration</a></li>
      </ol>
  </nav>
  <div class="container-xl mt-4 page">
      <div class="col-12 heading padding">
          <h2>
              <span *ngIf="!HopConfig.controls['business']?.value">Edit OSC Specific</span>
              <span *ngIf="HopConfig.controls['business']?.value">{{HopConfig.controls['business']?.value}}</span> HOP
              Configuration
          </h2>
      </div>

        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="HopConfig">
                        <div class="row" id="dropdown-arrow-mat">
                            <div class="col-md-4">
                                <label>Select a Business</label>
                                <mat-select class="form-select form-select-md" formControlName="business">
                                    <mat-option disabled value='' selected>
                                        Select a Business
                                    </mat-option>
                                    <mat-option *ngFor="let oscConfigCode of oscConfigCodes" [value]="oscConfigCode">
                                        {{oscConfigCode}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="col-md-4">
                                <label>Select a Context </label>
                                <mat-select class="form-select form-select-md" formControlName="context">
                                    <mat-option disabled value="" selected>
                                        Select a Context
                                    </mat-option>
                                    <mat-option value="CFHOP">CFHOP</mat-option>
                                    <mat-option value="IHOP">IHOP</mat-option>
                                    <mat-option value="VTHOP">VTHOP</mat-option>
                                </mat-select>
                            </div>
                            <div class="col-md-4 button">
                                <button class="btn btn-primary" [disabled]="!HopConfig.valid" (click)="viewSelected()">
                                    Go
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="selectedContextValue" class="col-md-12">
            <div class="pt-3 col-md-12 text-start">
                <button class="btn btn-primary btn-function col-lg-1 col-md-2 p-2 col-12 me-2 mb-3" (click)="back()">
                    <i class="arrow left"></i> Back
                </button>
                <button *ngIf="isDisabled && selectedContextValue && !errorMsg"
                    class="col-lg-2 col-md-4 col-12 btn btn-primary mb-3" (click)="editConfig()">
                    Edit Configuration
                </button>
            </div>
            <div class="tip pt-3" *ngIf="validationMessage">
                *Your changes have been saved.
            </div>
            <span class="tip" *ngIf="errorMsg">{{errorMsg}}</span>
            <div *ngIf="!errorMsg" class="col-md-12 text-end mb-1">
                <button class="btn btn-primary btn-function expand-collapse" (click)="accordion.openAll()">
                    Expand All
                </button>
                <button class="btn btn-primary btn-function expand-collapse ms-2" (click)="accordion.closeAll()">
                    Collapse All
                </button>
            </div>
            <div *ngIf="!errorMsg" class="row">
                <div class="col-md-12">
                    <form *ngIf="types" [formGroup]="types">
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Payment Methods </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="card">
                                    <div class="card-body ps-5">
                                        <h4>Payment Types</h4>
                                        <div class="row mt-4">
                                            <ng-container *ngFor="let item of payments_types">
                                                <div *ngIf="item.name!='3DS'"
                                                    [class]="selectedContextValue !== 'IHOP' ? item.class : 'col-lg-4'">
                                                    <h5 class="heading">{{ item.name }}</h5>
                                                    <div *ngFor="let paymentItem of item.items" class="mb-3 form-check">
                                                        <input type="checkbox" [id]="paymentItem.id"
                                                            class="form-check-input" [formControlName]="paymentItem.id"
                                                            (change)="enableOrDisableCreditCardOr3DS(types.controls[paymentItem.id].value, paymentItem.id)" />
                                                        <label [for]="paymentItem.id" class="form-check-label">{{
                                                            paymentItem.label
                                                            }}</label>
                                                        <ul *ngIf="paymentItem.subCategories" class="list-style">
                                                            <li *ngFor="let subCategory of paymentItem.subCategories">
                                                                <div class="row">
                                                                    <div [class]="paymentItem.class ? paymentItem.class : 'col-lg-5 col-md-4'"
                                                                        class="inline col-12">
                                                                        <input type="checkbox" [id]="subCategory.id"
                                                                            class="form-check-input"
                                                                               (ngModelChange)="onPaymentItemSubCategoryChanged(subCategory.id, subCategory.label,$event)"
                                                                            [formControlName]="subCategory.id" />
                                                                        <label [for]="subCategory.id"
                                                                            class="form-check-label">{{
                                                                            subCategory.label }}</label>
                                                                    </div>
                                                                    <div *ngIf="subCategory.requireAndDisplayID"
                                                                        [class]="selectedContextValue !== 'IHOP' ? 'col-lg-5 col-md-5' : 'col-lg-7 col-md-7'"
                                                                        class="inline col-12">
                                                                        <input type="checkbox"
                                                                            [id]="subCategory.requireAndDisplayID"
                                                                            class="form-check-input"
                                                                            [formControlName]="subCategory.requireAndDisplayID" />
                                                                        <label [for]="subCategory.requireAndDisplayID"
                                                                            class="form-check-label">{{
                                                                            subCategory.requireAndDisplay
                                                                            }}</label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="row mt-5">
                                            <ng-container *ngFor="let item of avs_cvs_rules">
                                                <div [class]="item.class">
                                                    <h5 class="heading">{{ item.name }}</h5>
                                                    <div class="mb-3 row ms-1">
                                                        <ng-container *ngFor="let data of item.items">
                                                            <div class="col-md-6 col-12">
                                                                <input type="checkbox" [id]="data.id"
                                                                    class="form-check-input me-2"
                                                                    [formControlName]="data.id" />
                                                                <label [for]="data.id" class="form-check-label">{{
                                                                    data.label
                                                                    }}</label>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="row margin">
                                                        <ng-container *ngFor="let data of item.items">
                                                            <div *ngIf="data.inputLabel" class="col-md-6 col-12 mb-3">
                                                                <label [for]="data.inputId">
                                                                    {{ data.inputLabel }}
                                                                </label>
                                                                <input type="text" class="form-control form-control-md"
                                                                    [formControlName]="data.inputId" />
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="row mt-5">
                                            <div *ngFor="let item of fraud_3ds_currency" class="col-md-4">
                                                <h5 class="heading">{{ item.name }}</h5>
                                                <div class="mb-3 form-check">
                                                    <input type="checkbox" [id]="item.id" class="form-check-input"
                                                        [formControlName]="item.id" />
                                                    <label [for]="item.id" class="form-check-label">{{
                                                        item.label
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                          <mat-expansion-panel *ngIf="oscSpecificHOPData.hopId=='CFHOP'">
                            <mat-expansion-panel-header>
                              <mat-panel-title> 3DS Settings </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="card">
                              <div class="card-body ps-5">
                                <h6 class="pt-4">Globally Enable or Disable Payment Method(s) 3DS.</h6>
                                <p >
                                  Please click on the checkbox below to enable or disable 3DS globally for this OSC
                                </p>
                                <div class="row mt-4">
                                  <ng-container *ngFor="let item of payments_types">
                                    <div *ngIf="item.name=='3DS'"
                                      [class]="selectedContextValue !== 'IHOP' ? item.class : 'col-lg-4'">
                                      <h5 class="heading"><input type="checkbox" id="threeDSEnabled"  formControlName="threeDSEnabled"
                                                                 (change)="enableOrDisableCreditCardOr3DS(types.controls['threeDSEnabled'].value, 'threeDSEnabled')"
                                                                 > Enable {{ item.name }}</h5>
                                      <div *ngFor="let paymentItem of item.items" class="mb-3 form-check">
                                        <ng-container *ngIf="isEnabledFor3DS(paymentItem)">
                                        <input type="checkbox" [id]="paymentItem.id"
                                               class="form-check-input" [formControlName]="paymentItem.id"
                                               (change)="enableOrDisableCreditCardOr3DS(types.controls[paymentItem.id].value, paymentItem.id)" />
                                        <label [for]="paymentItem.id" class="form-check-label">{{
                                          paymentItem.label
                                          }}</label>
                                        </ng-container>
                                        <ul *ngIf="paymentItem.subCategories" class="list-style">
                                          <li *ngFor="let subCategory of paymentItem.subCategories">
                                            <div class="row">
                                              <div [class]="paymentItem.class ? paymentItem.class : 'col-lg-5 col-md-4'"
                                                   class="inline col-12"  *ngIf="isEnabledFor3DS(subCategory)">
                                                <input type="checkbox" [id]="subCategory.id"    class="form-check-input" (ngModelChange)="on3dsPaymentItemSubCategoryChanged(subCategory.id, subCategory.label,$event)"
                                                       [formControlName]="subCategory.id" />
                                                <label [for]="subCategory.id"
                                                       class="form-check-label">{{
                                                  subCategory.label }}</label>
                                              </div>
                                              <div *ngIf="subCategory.requireAndDisplayID"
                                                   [class]="selectedContextValue !== 'IHOP' ? 'col-lg-5 col-md-5' : 'col-lg-7 col-md-7'"
                                                   class="inline col-12">
                                                <input type="checkbox"
                                                       [id]="subCategory.requireAndDisplayID"
                                                       class="form-check-input"
                                                       [formControlName]="subCategory.requireAndDisplayID" />
                                                <label [for]="subCategory.requireAndDisplayID"
                                                       class="form-check-label">{{
                                                  subCategory.requireAndDisplay
                                                  }}</label>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                                <h6 class="row pt-4">Enable or Disable 3DS by Country and Payment Method(s) 3DS.</h6>
                                <div class="row mt-4">
                                  Select multiple countries when you need to enable 3DS for the same Payment Method(s) and restrict the Payment Method(s) 3DS across all selected countries.
                                  If the Payment Method(s) 3DS vary by country, select the country individually and make the necessary updates.
                                </div>
                                <div class="row mt-4">

                                  <div class="col-md-4 col-11 mb-3">
                                    <label >Country(ies)</label>
                                    <mat-form-field >
                                      <mat-label>Select</mat-label>

                                      <mat-select    multiple formControlName="threeDsCountry" (selectionChange)="update3dsCountries($event)">
                                        <mat-select-trigger>
                                                    <span *ngIf="types.controls['threeDsCountry']?.value?.length >= 1">
                                                        {{ types.controls['threeDsCountry']?.value.length }} selected
                                                    </span>
                                        </mat-select-trigger>
<!--                                        <mat-option value="ALL" (click)="toggleAll3DsCountrySelection(types.controls['threeDsCountry']?.value)" >All</mat-option>-->
                                        <mat-option *ngFor="let country of countryList" [value]="country.id">
                                          {{ country.name }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-4 col-11 mb-3">
                                    <label >Payment Method(s) 3DS</label>
                                    <mat-form-field appearance="outline" >
                                      <mat-label>Select</mat-label>
                                      <mat-select multiple formControlName="threeDsPaymentMethods" >
                                        <mat-select-trigger>
                                                    <span *ngIf="types.controls['threeDsPaymentMethods']?.value?.length >= 1">
                                                        {{ types.controls['threeDsPaymentMethods']?.value.length }} selected
                                                    </span>
                                        </mat-select-trigger>
<!--                                        <mat-option value="ALL" (click)="toggleAll3DsPaymentSelection(types.controls['threeDsPaymentMethods']?.value)" >All</mat-option>-->
                                       <ng-container  *ngFor="let paymentMethod of paymentMethods">
                                        <mat-option *ngIf="isEnabledFor3DSForPaymentMethod(paymentMethod)"
                                                    [value]="paymentMethod.value">
                                          {{paymentMethod.text}}

                                        </mat-option>
                                       </ng-container>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-4 col-12 mt-2 pt-3">
                                    <button class="btn btn-primary" (click)="update3DSData()" [disabled]="!is3dsEnabled()">
                                      Update
                                    </button>
                                  </div>

                                </div>

                                <div class="row  pt-3">
                                  <div class="col-sm-12">

                                    <div class="pe-5">

                                      <mat-table [dataSource]="dataSource3DS" matSort *ngIf="dataSource3DS.data.length>0"
                                                 class="table table-responsive table-bordered">
                                        <ng-container matColumnDef="country">
                                          <th class="header width-textArea" mat-header-cell mat-sort-header
                                              *matHeaderCellDef>
                                            Country
                                          </th>
                                          <td mat-cell *matCellDef="let element" class="width-textArea">
                                            {{getCountryName(element.countryName ) }}
                                          </td>
                                        </ng-container>

                                        <ng-container matColumnDef="paymentMethods">
                                          <th class="header" mat-header-cell mat-sort-header
                                              *matHeaderCellDef>

                                            Payment Methods(s) 3DS
                                          </th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.paymentMethodName }}
                                          </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                      </mat-table>
                                          <div  *ngIf="dataSource3DS.data.length===0">
                                            <h3>No 3DS Data available.</h3>
                                          </div>


                                    </div>
                                    <mat-paginator [length]="tableLength" [pageSize]="15"  *ngIf="dataSource3DS.data.length>0"
                                                   [pageSizeOptions]="[10, 15, 25]">
                                    </mat-paginator>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </mat-expansion-panel>

                            <!--Fraud detect Settings -->
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Fraud Detect Settings </mat-panel-title>
                                </mat-expansion-panel-header>
                                <h6 class="ps-5 pt-4">Globally Enable or Disable Fraud Detect</h6>

                                <div class="row mt-2 ps-5 pt-4">
                                    <div class="col-md-12">
                                        <input [disabled]="isFraudDisabled" type="checkbox" id="fraudDetectEnabled" formControlName="fraudDetectEnabled"
                                            class="form-check-input me-2" />
                                        <label for="fraudDetectEnabled" class="form-check-label">Enable Fraud Detect</label>
                                    </div>
                                </div>

                                <h6 class="ps-5 pt-4">Disable Fraud Detect by Country</h6>
                                <p class="ps-5">
                                    Please select a country to Disable Fraud Detect and click update.
                                </p>

                                <div id="dropdown" class="row ps-5">
                                    <div class="col-md-4 col-11 mb-3">
                                        <label class="fds-label">Country(ies)</label>
                                        <mat-form-field >
                                          <mat-label>Select</mat-label>
                                          <!-- (selectionChange)="updatefraudEnabledCountries($event)" -->
                                          <mat-select multiple formControlName="fraudDetectCountries">
                                            <mat-select-trigger>
                                                        <span *ngIf="types.controls['fraudDetectCountries']?.value?.length >= 1">
                                                            {{ types.controls['fraudDetectCountries']?.value.length }} selected
                                                        </span>
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let country of countryList" [value]="country.name">
                                              {{ country.name }}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2 col-12 mt-2 pt-3">
                                        <button class="btn btn-primary" [disabled]="isFraudDisabled || !this.types.controls['fraudDetectEnabled'].value"
                                            (click)="updateFraudDetectSettings()">
                                            Update
                                        </button>
                                    </div>
                                </div>

                                <div class="row ps-5 pt-3">
                                    <div class="col-sm-3">
                                        <div class="pe-5">
                                            <mat-table [dataSource]="dataFraudSource" matSort
                                                class="table table-responsive table-bordered ml-4 fds-table" *ngIf="dataFraudSource.data.length > 0">
                                                <ng-container matColumnDef="country">
                                                    <th class="header width-textArea" mat-header-cell mat-sort-header
                                                        *matHeaderCellDef style="border: 1px solid black;">
                                                        Country(ies)
                                                    </th>
                                                    <td mat-cell *matCellDef="let element" class="fd-s-border width-textArea">
                                                        {{ element.countryName }}
                                                    </td>
                                                    
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedFraudColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedFraudColumns"></tr>
                                            </mat-table>
                                        </div>
                                        <div class="mb-4" *ngIf="dataFraudSource.data.length === 0">
                                            <h4  style="font-size: 16px;font-weight: 420;">No Fraud Detect Settings Data available.</h4>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row ps-5 pb-3 fds-pr">
                                    <div class="col-sm-12">
                                        <mat-paginator
                                            #fraudPaginator 
                                            [length]="tableLength" 
                                            [pageSize]="10"
                                            *ngIf="dataFraudSource.data.length > 0"
                                            [pageSizeOptions]="fraudPageSizes">
                                        </mat-paginator>
                                    </div>
                                </div> -->
                            </mat-expansion-panel>
                            <!--Fraud detect Settings -->

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Billing/Shipping Information and options
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="card">
                                    <div class="card-body ps-5">
                                        <div class="row mt-4">
                                            <ng-container *ngFor="let item of billing_information">
                                                <div [class]="item.class">
                                                    <h5 class="heading">{{ item.name }}</h5>
                                                    <div *ngFor="let billingItem of item.items" class="mb-3 form-check">
                                                        <input type="checkbox" [id]="billingItem.id"
                                                            class="form-check-input"
                                                            [formControlName]="billingItem.id" />
                                                        <label [for]="billingItem.id" class="form-check-label">{{
                                                            billingItem.label }}</label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-container *ngIf="selectedContextValue !== 'VTHOP'">
                                            <ng-container *ngFor="let item of addresses_and__options">
                                                <div [class]="item.class">
                                                    <h5 class="heading">{{ item.name }}</h5>
                                                    <div class="margin">
                                                        <div class="row" *ngFor="let input of item.inputs">
                                                            <div class="mt-3" [class]="element.class"
                                                                *ngFor="let element of input.items">
                                                                <label [for]="element.id">
                                                                    {{ element.label }}
                                                                </label>
                                                                <input type="text" class="form-control form-control-md"
                                                                    [formControlName]="element.id" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="selectedContextValue === 'VTHOP'">
                                            <ng-container *ngFor="let item of attemps_timeout_options">
                                                <div [class]="item.class">
                                                    <h5 class="heading">{{ item.name }}</h5>
                                                    <div class="margin">
                                                        <ng-container *ngFor="let data of item.items">
                                                            <div class="col-md-6 col-12 mb-3">
                                                                <label [for]="data.id">
                                                                    {{ data.label }}
                                                                </label>
                                                                <input type="text"
                                                                    class="form-control form-control-md"
                                                                    [formControlName]="data.id" />
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                          <mat-expansion-panel *ngIf="selectedContextValue !== 'VTHOP' && !hideMerchantDefinedFields">
                              <mat-expansion-panel-header>
                                  <mat-panel-title> Merchant Defined Fields </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div class="card">
                                  <div class="card-body ps-5">
                                      <ng-container *ngFor="let item of merchant_defined_fields">
                                          <div class="row">
                                              <div class="col-lg-3 col-md-3 col-3 mb-3">
                                                  <input type="checkbox" [id]="item.displayLabelId"
                                                      class="form-check-input me-2"
                                                      [formControlName]="item.displayLabelId" (change)="allowEditOrDisableMerchantDefinedFields(item.seqNo)"/>
                                                  <label [for]="item.displayLabelId" class="form-check-label">{{
                                                      item.displayLabel }}</label>
                                              </div>
                                              <div class="col-lg-2 col-md-2 col-4 mb-3">
                                                  <input type="checkbox" [id]="item.fieldLabelId"
                                                      class="form-check-input me-2"
                                                      [formControlName]="item.fieldLabelId" />
                                                  <label [for]="item.fieldLabelId" class="form-check-label">{{
                                                      item.fieldLabel }}</label>
                                              </div>
                                              <div class="col-lg-2 col-md-2 col-5 mb-3">
                                                  <input type="checkbox" [id]="item.allowEditLabelId"
                                                      class="form-check-input me-2"
                                                      [formControlName]="item.allowEditLabelId" />
                                                  <label [for]="item.allowEditLabelId" class="form-check-label">{{
                                                      item.allowEditLabel }}</label>
                                              </div>
                                              <div class="col-lg-5 col-md-5 col-12 mb-3">
                                                  <label [for]="item.inputLabelId">
                                                      {{ item.inputLabel }}
                                                  </label>
                                                  <input type="text" class="form-control form-control-md"
                                                      [formControlName]="item.inputLabelId" />
                                              </div>
                                          </div>
                                      </ng-container>
                                  </div>
                              </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel *ngIf="selectedContextValue !== 'VTHOP'">
                              <mat-expansion-panel-header>
                                  <mat-panel-title> Block Payment </mat-panel-title>
                              </mat-expansion-panel-header>
                              <h5 class="ps-5 pt-4">Block Payment Methods</h5>
                              <p class="ps-5">
                                  Please select a country and Payment Method to block and click
                                  Add.
                              </p>
                              <div id="dropdown" class="row ps-5">
                                  <div class="col-md-4 col-11 mb-3">
                                      <label [for]="country">Country</label>
                                      <mat-form-field appearance="outline">
                                          <mat-label>Country</mat-label>
                                          <mat-select name="country" formControlName="country"
                                              (selectionChange)="updatePaymentMethodsList()">
                                              <mat-option value="" disabled>
                                                  Select
                                              </mat-option>
                                              <mat-option *ngFor="let country of countryList" [value]="country.name">
                                                  {{ country.name }}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-4 col-11 mb-3">
                                      <label [for]="country">Payment Method(s) - Multiple Selection</label>
                                      <mat-form-field appearance="outline">
                                          <mat-label>Payment Methods</mat-label>
                                          <mat-select multiple formControlName="paymentMethods">
                                              <mat-select-trigger>
                                                  <span *ngIf="types.controls['paymentMethods']?.value?.length >= 1">
                                                      {{ types.controls['paymentMethods']?.value.length }} selected
                                                  </span>
                                              </mat-select-trigger>
                                              <mat-option value="ALL" [disabled]="isPaymentOptionDisabled()" (click)="toggleAllPaymentSelection(types.controls['paymentMethods']?.value)">All</mat-option>
                                              <mat-option *ngFor="let paymentMethod of paymentMethods" [disabled]="isPaymentOptionDisabled()"
                                                  [value]="paymentMethod.value">
                                                  {{paymentMethod.text}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-4 col-12 mt-2 pt-3">
                                      <button class="btn btn-primary" [disabled]="isUpdateDisabled()"
                                          (click)="updateBlockPayment()">
                                          Update
                                      </button>
                                  </div>
                              </div>

                              <h5 class="ps-5">Blocked Country(ies) and Payment(s)</h5>
                              <div class="row ps-5 pt-3">
                                  <div class="col-sm-12">
                                      <div class="row">
                                          <div class="col-md-3 col-11">
                                              <mat-form-field appearance="outline">
                                                  <mat-label>Filter by Country</mat-label>
                                                  <input matInput placeholder="Ex. ium" #country
                                                      (keyup)="applyFilter($event.target.value)" />
                                              </mat-form-field>
                                          </div>
                                      </div>
                                      <div class="pe-5">
                                          <mat-table [dataSource]="dataSource" matSort
                                              class="table table-responsive table-bordered">
                                              <ng-container matColumnDef="country">
                                                  <th class="header width-textArea" mat-header-cell mat-sort-header
                                                      *matHeaderCellDef>
                                                      Country
                                                  </th>
                                                  <td mat-cell *matCellDef="let element" class="width-textArea">
                                                      {{ element.countryName }}
                                                  </td>
                                              </ng-container>

                                              <ng-container matColumnDef="paymentMethods">
                                                  <th class="header" mat-header-cell mat-sort-header
                                                      *matHeaderCellDef>
                                                      Payment Methods(s)
                                                  </th>
                                                  <td mat-cell *matCellDef="let element">
                                                      {{ element.paymentMethodName }}
                                                  </td>
                                              </ng-container>
                                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                          </mat-table>
                                      </div>
                                      <mat-paginator [length]="tableLength" [pageSize]="15"
                                          [pageSizeOptions]="[10, 15, 25]">
                                      </mat-paginator>
                                  </div>
                              </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel *ngIf="selectedContextValue !== 'VTHOP'">
                              <mat-expansion-panel-header>
                                  <mat-panel-title> Custom Header/ Footer </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div class="card">
                                  <div class="card-body ps-5">
                                      <div class="row mt-4">
                                          <div class="col-md-12 mb-4">
                                              <input [disabled]="isDisabled" type="checkbox" id="custom_header_footer"
                                                  class="form-check-input me-2" />
                                              <label for="custom_header_footer" class="form-check-label">Allow Custom
                                                  Header/ Footer</label>
                                          </div>
                                          <div class="col-md-12 mb-4">
                                              <label for="Custom_Header" class="me-2 form-check-label">Upload Custom
                                                  Header: </label>
                                              <input [disabled]="isDisabled" type="file" id="Custom_Header"
                                                  (change)="uploadFile($event.target.files)" name="Custom_Header">
                                          </div>
                                          <div class="col-md-12">
                                              <label for="Custom_Footer" class="me-2 form-check-label">Upload Custom
                                                  Footer: </label>
                                              <input [disabled]="isDisabled" type="file" id="Custom_Footer"
                                                  (change)="uploadFile($event.target.files)" name="Custom_Footer">
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div *ngIf="!isDisabled" class="row pt-5">
                          <div class="col-12 text-end">
                              <button class="btn btn-primary btn-function" (click)="exit()">Exit</button>
                              <button class="btn btn-primary ms-3" (click)="saveExit()">
                                  Save and Exit
                              </button>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
  <div class="col-2"></div>
  <div class="col-8 alert alert-danger" role="alert">
      Originating System Code or Program Group need to be associated in order to
      access the page.
  </div>
  <div class="col-2"></div>
</div>
