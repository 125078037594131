<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>OSC and HOP Configuration</a></li>
        <li class="breadcrumb-item"><a (click)="back()">HOP Master and Virtual Terminal Configuration</a></li>
    </ol>
</nav>
<div *ngIf="loggedInUser?.oscDetails?.length > 0" class="container-xl padding">
    <h2 *ngIf="isDisabled">View Virtual Terminal HOP Configuration Settings</h2>
    <h2 *ngIf="!isDisabled">Edit Virtual Terminal HOP Configuration Settings</h2>
    <div class="pt-3 col-md-12 text-start">
        <button class="btn btn-primary btn-function col-lg-1 col-md-2 p-2 col-12 me-2 mb-3" (click)="back()">
            <i class="arrow left"></i> Back
        </button>

        <button *ngIf="isDisabled" class="col-lg-2 col-md-4 col-12 btn btn-primary mb-3" (click)="editConfig()">
            Edit Configuration
        </button>
    </div>
    <div class="tip pt-3" *ngIf="validationMessage">
        *Your changes have been saved.
    </div>
    <span class="tip pt-3" *ngIf="errMsg">{{errMsg}}</span>
    <div class="col-md-12 text-end mb-1">
        <button class="btn btn-primary btn-function expand-collapse" (click)="accordion.openAll()">
            Expand All
        </button>
        <button class="btn btn-primary btn-function expand-collapse ms-2" (click)="accordion.closeAll()">
            Collapse All
        </button>
    </div>
    <div class="row" id="accordian-mat">
        <div class="col-md-12">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title> Payment Methods </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="card">
                        <div class="card-body ps-5">
                            <form [formGroup]="types">
                                <h4>Payment Types</h4>
                                <div class="row mt-4">
                                    <ng-container *ngFor="let item of payments_types">
                                        <div [class]="item.virtual_term_class" *ngIf="!item.virtualTerminal">
                                            <h5 class="heading">{{ item.name }}</h5>
                                            <div *ngFor="let paymentItem of item.items" class="mb-3 form-check">
                                                <input type="checkbox" [id]="paymentItem.id" class="form-check-input"
                                                    [formControlName]="paymentItem.id" (change)="enableOrDisableCreditCard(types.controls['ccPaymentEnabled'].value)"/>
                                                <label [for]="paymentItem.id" class="form-check-label">{{
                                                    paymentItem.label
                                                    }}</label>
                                                <ul *ngIf="paymentItem.subCategories" class="list-style">
                                                    <li *ngFor="
                              let subCategory of paymentItem.subCategories
                            ">
                                                        <div class="row">
                                                            <div class="inline col-md-4 col-5">
                                                                <input type="checkbox" [id]="subCategory.id"
                                                                    class="form-check-input"
                                                                    [formControlName]="subCategory.id" />
                                                                <label [for]="subCategory.id"
                                                                    class="form-check-label">{{
                                                                    subCategory.label }}</label>
                                                            </div>
                                                            <div class="inline col-md-8 col-7">
                                                                <input type="checkbox"
                                                                    [id]="subCategory.requireAndDisplayID"
                                                                    class="form-check-input"
                                                                    [formControlName]="subCategory.requireAndDisplayID" />
                                                                <label [for]="subCategory.requireAndDisplayID"
                                                                    class="form-check-label">{{
                                                                    subCategory.requireAndDisplay }}</label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row mt-4">
                                    <ng-container *ngFor="let item of avs_cvs_rules">
                                        <div [class]="item.class">
                                            <h5 class="heading">{{ item.name }}</h5>
                                            <div class="mb-3 row ms-1">
                                                <ng-container *ngFor="let data of item.items">
                                                    <div class="col-md-6 col-12 form-check">
                                                        <input [disabled]="isDisabled" type="checkbox" id="data.id"
                                                            class="form-check-input" />
                                                        <label [for]="data.id" class="form-check-label">{{
                                                            data.label
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="row margin">
                                                <ng-container *ngFor="let data of item.items">
                                                    <div *ngIf="data.inputLabel" class="col-md-6 col-12 mb-3">
                                                        <label [for]="data.inputId">
                                                            {{ data.inputLabel }}
                                                        </label>
                                                        <input [disabled]="isDisabled" type="text"
                                                            class="form-control form-control-md"/>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row mt-4">
                                    <div *ngFor="let item of fraud_3ds_currency" class="col-md-4">
                                        <h5 class="heading">{{ item.name }}</h5>
                                        <div class="mb-3 form-check">
                                            <input type="checkbox" id="item.id" class="form-check-input"
                                                [formControlName]="item.id" />
                                            <label [for]="item.id" class="form-check-label">{{
                                                item.label
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Billing/Shipping Information and Options
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="card">
                        <div class="card-body ps-5">
                            <div class="row mt-4">
                                <ng-container *ngFor="let item of billing_information">
                                    <div [class]="item.class">
                                        <h5 class="heading">{{ item.name }}</h5>
                                        <div *ngFor="let billingItem of item.items" class="mb-3 form-check">
                                            <input [disabled]="isDisabled" type="checkbox" [id]="billingItem.id"
                                                class="form-check-input" />
                                            <label [for]="billingItem.id" class="form-check-label">{{
                                                billingItem.label
                                                }}</label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row mt-4">
                                <ng-container *ngFor="let item of attemps_timeout_options">
                                    <div [class]="item.class">
                                        <h5 class="heading">{{ item.name }}</h5>
                                        <div class="margin">
                                            <ng-container *ngFor="let data of item.items">
                                                <div class="col-md-6 col-12 mb-3">
                                                    <label [for]="data.id">
                                                        {{ data.label }}
                                                    </label>
                                                    <input [disabled]="isDisabled" type="text"
                                                        class="form-control form-control-md"/>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div *ngIf="!isDisabled" class="row pt-5">
                <div class="col-12 text-end">
                    <button class="btn btn-primary btn-function" (click)="exit()">Exit</button>
                    <button class="btn btn-primary ms-3" (click)="saveExit()">
                        Save and Exit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
