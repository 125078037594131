import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionLinkService } from 'src/app/service/action-links.service';

@Component({
  selector: 'app-request-retrieval',
  templateUrl: './request-for-retrieval.component.html',
  styleUrls: ['./request-for-retrieval.component.scss'],
})
export class RetrievalComponent implements OnInit {
  @Input() parentRef;
  constructor(public actionLinkService: ActionLinkService) {}
  retrieval: FormGroup;
  ngOnInit(): void {
    this.retrieval = new FormGroup({
      amount: new FormControl(null, Validators.required),
    });
  }
}
