<div >
  <div [ngClass]="{'auth-txt':showMore,'auth-txt-more':!showMore}">
    {{inputData }}
  </div>
  <div>
    <a (click)="showMore=false" *ngIf="showMore">Show More</a>
    <a (click)="showMore=true" *ngIf="!showMore">Show Less</a>
  </div>
</div>


