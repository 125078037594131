import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/model/user';
import { RefundService } from 'src/app/service/refund.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-linked-override-refund',
  templateUrl: './linked-override-refund.component.html',
  styleUrls: ['./linked-override-refund.component.scss'],
})
export class LinkedOverrideRefundComponent implements OnInit {
  @Input() txData;
  @Input() calculatedRefundAmount;
  @Input() parentRef;
  overrideRefund: FormGroup;
  isLineItemInvalid = false;
  lineItems: Array<any>;
  allowOrderItems=true;
  refundAmt:any;
  userInfo: User;

  constructor(private refundService: RefundService,
              private sharedService: SharedService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.refundAmt = this.calculatedRefundAmount;
    this.overrideRefund = new FormGroup({
      amount: new FormControl('', Validators.required),
      merchantDescriptor: new FormControl(
        this.txData.merchantDescriptor,
        Validators.required
      ),
      product: new FormControl(this.txData.glProductText),
      activity: new FormControl(this.txData.glActivityText),
      resource: new FormControl(this.txData.resourceText),
      programSpecificKey: new FormControl(this.txData.programSpecificKey),
      projectText: new FormControl(
        this.txData.glProjectText,
        Validators.required
      ),
      journalAccNo: new FormControl(
        this.txData.order.jeAccountNumber,
        Validators.required
      ),
      customerID: new FormControl(null, [Validators.maxLength(16)])
    });
    this.addListeners();
    this.sharedService.userInfo$.subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
  }

  addListeners(){
    this.overrideRefund.controls['amount'].valueChanges.subscribe(amount=>{

      if(amount){
        this.allowOrderItems=false;
      }else{
        this.allowOrderItems=true;
      }
    });
  }

  resetOverrideRefundForm(): void {
    this.overrideRefund.reset();
    this.overrideRefund.controls['amount'].enable();
    this.sharedService.onResetLineItems(true);
  }

  sendOverRideRefundData() {
    let overrideRefundData = {
      glActivityText: this.overrideRefund.value.activity,
      glProductText: this.overrideRefund.value.product,
      glProjectText: this.overrideRefund.value.projectText,
      jeAccountNumber: this.overrideRefund.value.journalAccNo,
      merchantDescriptor: this.overrideRefund.value.merchantDescriptor,
      orderAmount: this.overrideRefund.value.amount,
      programSpecificKey: this.overrideRefund.value.programSpecificKey,
      resourceText: this.overrideRefund.value.resource === "" ? null : this.overrideRefund.value.resource,
      customerID: this.overrideRefund.value.customerID,
      lineItems: null,
      createdBy: this.userInfo?.emailAddress
    };
    this.parentRef.close();
    let totalLineItemAmount = 0;
    if (this.lineItems && this.lineItems.length > 0) {
      this.lineItems.forEach(element => {
        element.quantity = element.quantity ? element.quantity : 1;
        totalLineItemAmount += (element.quantity * element.unitPrice)
      });
      overrideRefundData.lineItems = this.lineItems;
      overrideRefundData.orderAmount = null;
    }
    this.refundService
      .sendOverRideRefundTxData(
        overrideRefundData,
        this.txData.order.epmsOrderId
      )
      .subscribe(
        (res) => {
          if (res && res.status == 200 && res.body.status === 'APPROVED') {
            this.refundService.resetRefundForm.next('GET_TXDATA');
            const initialState = {
              amount: this.overrideRefund.value.amount ? this.overrideRefund.value.amount : totalLineItemAmount,
              orderId: res.body.newRefundId,
              oscCode: this.txData.order.originatingSystemCode,
              modalType: 'SUCCESS',
            };
            this.refundService.resetRefundForm.next({
              initialState: initialState,
            });
          } else {
            const err = {
              error: {
                decision: res.body.decision,
                pgmResponseCode: res.body.pgmResponseCode,
                pgmResponseMessage: res.body.pgmResponseMessage
              },
              orderId: res.body.newRefundId,
            }
            this.handleError(err);
          }
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  handleError(err) {
    const initialState = {
      amount: this.overrideRefund.value.amount,
      orderId: err.orderId || this.txData.order.epmsOrderId ,
      oscCode: this.txData.order.originatingSystemCode,
      decision: err.error.decision,
      errCode: err.error.pgmResponseCode,
      errMsg: err.error.pgmResponseMessage,
      modalType: 'ERROR',
    };
    this.refundService.resetRefundForm.next({
      initialState: initialState,
    });
  }

  lineItemData(event) {
    this.isLineItemInvalid = event.status === 'INVALID' ? true : false;
    this.lineItems = event.value;

    if(this.lineItems && this.lineItems.length>0){
      this.overrideRefund.controls['amount'].disable();
    }else{
      this.overrideRefund.controls['amount'].enable();
    }
  }

  confirmLinkOverrideRefund() {
    if (!this.overrideRefund.valid) {
      return;
    }
    if (this.lineItems && this.isLineItemInvalid) {
      const message = `You are attempting to process line item details with out a
                      Product SKU or Product Code.  Press continue to submit your
                      transaction or exit to correct your line items`;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: new ConfirmDialogModel(message)
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.sendOverRideRefundData();
        }
      });
    } else {
      this.sendOverRideRefundData();
    }
  }

  isDisabledOverrdieRefund(){

    if(this.lineItems && this.lineItems.length>0){
      for(const lineItem of this.lineItems){
        if(!lineItem.unitPrice){
          return true;
        }
      }

    }else{
      const amountField=this.overrideRefund.controls['amount'];
      return !amountField.value ;
    }

  }
}
