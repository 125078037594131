import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssignBusinessService {

  private assignBusinessMsg = new BehaviorSubject('');
  msgFromAssignBusiness = this.assignBusinessMsg.asObservable();


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  hostUrl = environment.ADMIN_API_HOSTNAME + '/config';

  constructor(private http: HttpClient) { }

  sendMessage(msg: string) {
    this.assignBusinessMsg.next(msg);
  }

  
  getProgramGroupList(): Observable<any> {
    let url = this.hostUrl+'/programGroupList';
    return this.http.get(url, this.httpOptions);    
  }

  addUserOSC(userOSCDetail): Observable<any> {
    let url = this.hostUrl+'/users/addUserOsc';
    return this.http.post(url, userOSCDetail, this.httpOptions)
      .pipe(
        tap( response => {
          return response;
        }),
        catchError( error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            console.log('Add User OSC failed: '+ JSON.stringify(error));
          }
          return throwError(errorMsg);
        })
      );      
  }

  deleteUserOSC(userOSCDetail): Observable<any> {
    let url = this.hostUrl+'/users/deleteUserOsc';
    const httpDelOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: userOSCDetail };
    return this.http.delete(url, httpDelOptions)
      .pipe(
        tap( response => {
          return response;
        }),
        catchError( error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            console.log('Remove user OSC failed: '+ JSON.stringify(error));
          }
          return throwError(errorMsg);
        })
      );      
  }

  deleteUserProgGroup(userOSCDetail): Observable<any> {
    let url = this.hostUrl+'/users/deleteProgramGroup';
    const httpDelOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: userOSCDetail };
    return this.http.delete(url, httpDelOptions)
      .pipe(
        tap( response => {
          return response;
        }),
        catchError( error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = error.error;       
            console.log('Remove user OSC failed: '+ JSON.stringify(error));
            
          }
          return throwError(errorMsg);
        })
      );      
  }
}
