<form [formGroup]="reverse">
    <div class="mb-3">
        <label for="amount"><span class="required-asterisk">*</span> Amount</label>
        <input class="form-control form-control-md" formControlName="amount" />
        <div class="text-danger" *ngIf="reverse.controls.amount.hasError('pattern')">
            Please enter number only.
          </div>
          <div class="text-danger" *ngIf="reverse.controls.amount.hasError('min')">
            Minimum required number is 0.1
          </div>
    </div>
    <div class="mb-3">
        <label for="amount"><span class="required-asterisk" *ngIf="txData.paymentTypeCode!=='PAYPAL'">*</span>Reason Code</label>
        <input class="form-control form-control-md" formControlName="reasonCode" />
    </div>
  <div class="mb-3">
    <label for="currency">Currency Code</label>
    <input class="form-control form-control-md" formControlName="currencyCode" readonly  maxLength="3"  />
  </div>
    <div class="mb-3">
        <input formControlName="reverse" type="checkbox" id="reverse" />
        <label for="reverse">Reverse Chargeback</label>
    </div>

    <div class="row col-md-12">
        <button class="col-md-1 mt-2 btn btn-primary btn-function" (click)="parentRef.close()">
            Exit
        </button>
        <button class="col-md-2 ms-2 mt-2 btn btn-primary btn-function" (click)="initForm()">
            Reset Form
        </button>
        <button class="col-md-2 ms-2 mt-2 btn btn-primary" [disabled]="reverse.invalid" (click)="processChargeBack(reversechargebackConfirmation)">
            Process Chargeback
        </button>
    </div>
</form>

<ng-template #reversechargebackConfirmation>
    <div class="modal-header">
        <ng-container *ngIf="!chargeBackResp.error">
            <h4 class="modal-title pull-left">Confirmation</h4>
        </ng-container>

        <ng-container *ngIf="chargeBackResp.error">
            <h4 class="modal-title pull-left">Error</h4>
        </ng-container>
        <div align="end">
        <button mat-icon-button class="close-button" (click)="modalRef.hide()">
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!chargeBackResp.error">
            <div class="alert alert-success" type="success">
                <div class="alert-message">
                    <div class="row">
                        <div class="col-md-1 hl">
                            <fa-icon [icon]="faCheckCircle" class="check"></fa-icon>
                        </div>
                        <div class="col-md-11">
                            <ng-container *ngIf="!chargeBackResp.reverseChargeback">
                                <strong class="hl"> Manual Chargeback Result</strong><br />
                                Manual Chargeback has been Successfully Processed.
                            </ng-container>

                            <ng-container *ngIf="chargeBackResp.reverseChargeback">
                                <strong class="hl"> Reverse Chargeback Result</strong><br />
                                Reverse Chargeback has been Successfully Processed.
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="chargeBackResp.error">
            <div class="alert alert-danger" type="danger">
                <div class="alert-message">
                    <div class="row">
                        <div class="col-md-1 hl">
                            <fa-icon [icon]="faCheckCircle" class="check"></fa-icon>
                        </div>
                        <div class="col-md-11">
                            <ng-container *ngIf="chargeBackResp.reverseChargeback === undefined">
                                <strong class="hl"> Chargeback Result</strong><br />
                            </ng-container>
                            <ng-container *ngIf="chargeBackResp.reverseChargeback !== undefined">
                                <strong class="hl">
                                    <span *ngIf="!chargeBackResp.reverseChargeback"> Manual</span>
                                    <span *ngIf="chargeBackResp.reverseChargeback"> Reverse</span>
                                    Chargeback Result</strong><br />
                            </ng-container>

                            {{ chargeBackResp.errorInfo }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
