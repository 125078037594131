<div id="reconciliation-details" class="mt-4 container-xl">
  <h2 class="ps-3">{{ reconciliationType }}</h2>
  <div class="row">
    <div class="col-md-12 text-start">
      <button class="btn btn-primary btn-function col-lg-4 col-md-7 p-2 col-12 me-2 mb-3" (click)="back()">
        <i class="arrow left"></i> {{backButtonLabel}}
      </button>
      <button *ngIf="dataSource?.length > 0" class="col-lg-1 col-md-2 col-12 btn btn-primary float-end mb-3"
        (click)="exportData()">
        Export
      </button>
    </div>
  </div>
  <div class="card grey mt-4">
    <h4 class="ps-3 pt-3">{{ colName }}</h4>
    <div id="reconciliation-filter">
      <mat-form-field appearance="outline" *ngIf="dataSource?.length > 0">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ex. ium" #osc />
      </mat-form-field>
    </div>
    <mat-table [dataSource]="dataSourceTable" class="mat-elevation-z8">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef>
          {{ displayedColumnNames[column] }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element[column] || "__" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      <div class="p-3" *matNoDataRow>No data found</div>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons [pageSize]="pageSize"
      (page)="handlePaging($event)" [length]="totalRecords" aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>
