import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Profile } from '../model/profile-permissions';
import { User } from '../model/user';
import { AuthenticationService } from '../service/authentication.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userInfo: User;
  isUserLoggedIn = false;
  profile: Profile;
  @ViewChild('menuCollapse') menuCollapse: ElementRef;
  constructor(private sharedService: SharedService,
    private _authService: AuthenticationService) { }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.userInfo = user;
        this.isUserLoggedIn = true;
      }
    });
    this.sharedService.profileInfo$.subscribe(profileInfo => {
      this.profile = profileInfo;
    });
  }

  logoutUser(): void {
    this._authService.sessionLogout();
  }

  collapseMenu(){
    this.menuCollapse.nativeElement.click();
  }

}
