<div [formGroup]="form" class="row d-flex align-items-center">
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="first-name"><span class="required-asterisk">*</span> First Name</label>
        <input class="form-control form-control-md" formControlName="firstName" id="first-name" />
    </div>
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="last-name"><span class="required-asterisk">*</span> Last Name</label>
        <input class="form-control form-control-md" formControlName="lastName" id="last-name" />
    </div>
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="street-address1"><span class="required-asterisk">*</span> Street Address 1</label>
        <input class="form-control form-control-md" formControlName="address1" id="street-address1" />
    </div>
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="street-address2"> Street Address 2</label>
        <input class="form-control form-control-md" formControlName="address2" id="street-address2" />
    </div>
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="city"><span class="required-asterisk">*</span> City</label>
        <input class="form-control form-control-md" formControlName="city" id="city" />
    </div>
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="country"><span class="required-asterisk">*</span> Country</label>
        <mat-select class="form-select form-select-md" formControlName="country" (selectionChange)="changeCountry($event)">
            <mat-option *ngFor="let country of countryList" [value]="country.id">{{country.name}}</mat-option>
        </mat-select>
    </div>
    <div *ngIf="isStateRequired; else region" [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="state-province"><span class="required-asterisk">*</span> State\Province</label>
        <input class="form-control form-control-md" formControlName="state" id="state-province" />
    </div>
    <div *ngIf="isStateRequired; else zipOptional"  [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="zip-postal"><span class="required-asterisk">*</span> Zip\Postal</label>
        <input class="form-control form-control-md" formControlName="zip" id="zip-postal" />
    </div>
    <ng-template #region>
        <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
            <label for="state-province"> Region</label>
            <input class="form-control form-control-md" formControlName="state" id="state-province" />
        </div>
    </ng-template>
    <ng-template #zipOptional>
        <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
            <label for="zip-postal"> Zip\Postal</label>
            <input class="form-control form-control-md" formControlName="zip" id="zip-postal" />
        </div>
    </ng-template>

    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="phone-number"> Phone Number</label>
        <input class="form-control form-control-md" formControlName="phoneNumber" id="phone-number" />
    </div>
    <div [ngClass]="isDropDown ? 'col-md-4 mb-3' : 'col-md-6 mb-3'">
        <label for="email"> Email</label>
        <input class="form-control form-control-md" formControlName="email" type="email" id="email" />
    </div>
    <div *ngIf="isDropDown" class="col-md-4 mb-3">
        <label for="company"> Company</label>
        <input class="form-control form-control-md" formControlName="company" />
    </div>
    <!-- <div *ngIf="emailControl.touched && emailControl.hasError('required')" class="error">
      email is required
    </div> -->
</div>