<div id="reconciliation" class="mt-4 container-xl">
    <h2 class="pt-5">Reconciliation Dashboard</h2>
    <div class="row mt-4">
        <div class="col-md-12 pe-0">
            <div class="card grey card-height">
                <div class="card-body row">
                    <div class="float-start pt-3 ps-5" id="select-date">
                        <mat-form-field appearance="outline">
                          <mat-label>Select Date</mat-label>
                          <div class="prv-dt-con">

                            <button (click)="moveToPreviousDate()" mat-icon-button >
                              <mat-icon>chevron_left</mat-icon>
                            </button>
                            <input matInput [matDatepicker]="picker1" ngDefaultControl [ngModel]="selectDate" (dateChange)="getData($event)" />
                            <button (click)="moveToNextDate()" mat-icon-button >
                              <mat-icon>chevron_right</mat-icon>
                            </button>
                            <mat-datepicker-toggle [ngModel]="selectDate" ngDefaultControl matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>

                          </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>
             <div *ngIf="enableReconciliationSummary" class="card grey mt-3">
                <h3 class="ms-2 mt-2">Daily Reconciliation Summary</h3>
                <div class="card-body mt-1">
                  <div class="row">
                    <div class="ms-5 col-lg-2 col-md-4">
                        <circle-progress [percent]="totalSuccessPercent" [radius]="50" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
                            [outerStrokeColor]="'#dfa320'" [innerStrokeColor]="'#d7dbd2'" [animation]="true"
                            [animationDuration]="300" [showSubtitle]="false" [titleFontSize]="30" [unitsFontSize]="20">
                        </circle-progress>
                        <div class="ms-3 fw-bold">Total Success</div>
                    </div>
                    <div class="col-lg-6 col-md-6 ps-0">
                    <ngx-charts-bar-horizontal [view]="view" [scheme]="colorScheme" [results]="SummaryBardata" [gradient]="false"
                        [xAxis]="false" [yAxis]="true" [legend]="false" [view]="[380,150]" [showXAxisLabel]="true" [showYAxisLabel]="false"
                        [showDataLabel]="true" [barPadding]="30" [xAxisLabel]="" [yAxisLabel]="">
                    </ngx-charts-bar-horizontal>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="col-md-12 pt-3">
            <div class="card grey">
                <mat-table class="table table-responsive table-bordered" [dataSource]="dataSource" matSort>

                    <!-- File Name(s) -->
                    <ng-container matColumnDef="reconciliationType">
                      <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header> File Name(s)</th>
                      <td mat-cell *matCellDef="let element"><a [routerLink]="['/reconciliation-dashboard-details']" class="link" [queryParams]="{ reconciliationType: element.reconciliationType, colName: 'File Name(s)', date: getFormatedDate(), recordType: 'all',totalRecords:element.totalRecords }"> {{element.reconciliationType}}</a> </td>
                    </ng-container>

                    <!-- File Sent/Received -->
                    <ng-container matColumnDef="fileProcessed">
                      <th class="header" mat-header-cell *matHeaderCellDef> File Sent/Received </th>
                      <td mat-cell *matCellDef="let element"> {{element.fileProcessed ? 'yes' : 'no'}} </td>
                    </ng-container>

                    <!-- Start Date/Time -->
                    <ng-container matColumnDef="startDate">
                      <th class="header" mat-header-cell *matHeaderCellDef> Start Date/Time </th>
                      <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
                    </ng-container>

                      <!-- End Date/Time -->
                      <ng-container matColumnDef="endDate">
                        <th class="header" mat-header-cell *matHeaderCellDef> End Date/Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
                      </ng-container>

                      <!-- # of Records -->
                      <ng-container matColumnDef="totalRecords">
                        <th class="header" mat-header-cell *matHeaderCellDef> # of Records </th>
                        <td mat-cell *matCellDef="let element"><a [class.disabled]="element.totalRecords === 0" class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                                  [queryParams]="{ reconciliationType: element.reconciliationType, colName:'Number of Records', date: getFormatedDate(), recordType: 'all',totalRecords:element.totalRecords }"> {{element.totalRecords}} </a></td>
                      </ng-container>

                      <!-- # of Errors -->
                      <ng-container matColumnDef="errorRecords">
                        <th class="header" mat-header-cell *matHeaderCellDef> # of Errors </th>
                        <td mat-cell *matCellDef="let element"><a [class.disabled]="element.errorRecords === 0" class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                                  [queryParams]="{ reconciliationType: element.reconciliationType, colName: 'Number of Errors', date: getFormatedDate(), recordType: 'error',totalRecords:element.errorRecords  }">{{element.errorRecords}}</a> </td>
                      </ng-container>

                      <!-- # of Sucessfull -->
                      <ng-container matColumnDef="successRecords">
                        <th class="header" mat-header-cell *matHeaderCellDef> # of Successful </th>
                        <td mat-cell *matCellDef="let element"><a [class.disabled]="element.successRecords === 0" class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                                  [queryParams]="{ reconciliationType: element.reconciliationType, colName: 'Number of Successfull', date: getFormatedDate(), recordType: 'success',totalRecords:element.successRecords  }"> {{element.successRecords}}</a> </td>
                      </ng-container>

                       <!-- # of No Match -->
                       <ng-container matColumnDef="noMatchRecords">
                        <th class="header" mat-header-cell *matHeaderCellDef> # of No Match </th>
                        <td mat-cell *matCellDef="let element"><a [class.disabled]="element.noMatchRecords === 0" class="link" [routerLink]="['/reconciliation-dashboard-details']"
                                                                  [queryParams]="{ reconciliationType: element.reconciliationType, colName: 'Number of No Match', date: getFormatedDate(), recordType: 'nomatch',totalRecords:element.noMatchRecords  }"> {{element.noMatchRecords}}</a> </td>
                      </ng-container>

                      <!-- Status -->
                      <ng-container matColumnDef="status">
                        <th class="header" mat-header-cell *matHeaderCellDef> Status (R/Y/G) </th>
                        <td mat-cell [class]="element.status + ' status'" *matCellDef="let element"><div class="recon-table-status">{{element.status && element.status.charAt(0).toUpperCase()}}</div></td>
                      </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <div class="p-3" *matNoDataRow>No data found</div>
                  </mat-table>

                  <mat-paginator [pageSizeOptions]="[25, 50, 100]"
                                 showFirstLastButtons
                                 aria-label="Select page of periodic elements">
                  </mat-paginator>
            </div>

        </div>
    </div>
</div>
