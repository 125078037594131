import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faChevronLeft, faSearch, faSortDown, faSortUp,} from '@fortawesome/free-solid-svg-icons';
import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderSearchService} from '../service/order-search.service';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {MatFiterTableComponent} from '../mat-fiter-table/mat-fiter-table.component';
import { Constants } from '../constant/constants';
import { OrderState, PaymentMethods } from '../model/enums/order-search';

export interface searchCriteriaObj    {
  field: any,
  value: any
}

@Component({
  selector: 'app-order-search-result',
  templateUrl: './order-search-result.component.html',
  styleUrls: ['./order-search-result.component.scss'],
  animations: [
    trigger('fadeIn', [
      // ...
      state(
        'open',
        style({
          height: 'auto',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('* => closed', [animate('1s')]),
      transition('* => open', [animate('0.5s')]),
    ]),
  ],
})
export class OrderSearchResultComponent implements OnInit, OnDestroy {
  faSearch = faSearch;
  faSortDown = faSortDown;
  faSortUp = faSortUp;
  faChevronLeft = faChevronLeft;
  filterBy: FormGroup;
  public display: boolean = false;
  orderSearchResult: any;
  orderSearchResultSub: Subscription;
  searchCriteriaSub: Subscription;
  filterCriteriaSub: Subscription;
  searchCriteriaValue: string = '';
  filterCriteriaValue: string = '';
  isResult: boolean = false;
  oscConfigCodes = [];
  searchByFilterRequest = [];
  searchError: boolean = false;
  errorMessage = '';
  searchInfo: boolean = false;
  infoMessage = '';
  pipeCustomDate = new DatePipe('en-US');
  startTime:string = '00:00';
  endTime:string = '23:59';
  loading: boolean = false;
  disableClearLoad: boolean = true;
  criteriaTransactionType: searchCriteriaObj[] = [];
  isCriteriaTransactionDate: boolean = false;
  criteriaOrderDetailsInfo: searchCriteriaObj[] = [];
  isCriteriaOrderDetailsInfo: boolean = false;
  criteriaBillShipInfo: searchCriteriaObj[] = [];
  isCriteriaBillShipInfo: boolean = false;
  criteriaProgSpecInfo: searchCriteriaObj[] = [];
  isCriteriaProgSpecInfo: boolean = false;
  criteriaPaymentInfo: searchCriteriaObj[] = [];
  isCriteriaPaymentInfo: boolean = false;
  @ViewChild('filterGrid') matFilterGrid : MatFiterTableComponent;
  sourceRoute: string;
  searchObjVal;
  OrderState = OrderState;
  PaymentMethods = PaymentMethods;
  saleValues = ["SALE","SALE RECONCILIATION PENDING", "SALE SETTLED","SALE ERROR","SALE REJECTED","SALE PENDING AUTHORIZED","SALE NOMATCH SETTLED", "SALE UPDATED SETTLEMENT"];
  refundValues = ["REFUND","REFUND RECONCILIATION PENDING", "REFUND SETTLED","REFUND REJECTED","REFUND ERROR", "REFUND UPDATED SETTLEMENT"];
  searchedOrderState;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private OrderSearch: OrderSearchService
  ) {
    this.sourceRoute = this.activatedRoute.snapshot.paramMap.get('source');
  }

  ngOnInit(): void {
    this.oscConfigCodes = JSON.parse(sessionStorage.getItem('oscConfigCodes'));
    window.scrollTo(0, 0);
    this.createForm();

      //subscribe to latest order search performed
      this.orderSearchResultSub = this.OrderSearch.currentOrderSearchResult.subscribe(result => this.orderSearchResult = result);
      if (this.orderSearchResult?.content?.length > 0) {
        this.isResult = true;
      }

    //subscribe to latest search criteria performed in order search page
    this.searchCriteriaSub = this.OrderSearch.currentSearchCriteria.subscribe(value => this.searchCriteriaValue = value);
    //subscribe to latest filter criteria performed in order search page
    if (this.sourceRoute === 'order-search') {
      this.filterCriteriaValue = ''; // request came from order-search thus reset previous filter criteria
      this.OrderSearch.changeOrderFilterCriteria("");//set filter fields to empty
    }
    else // request came from order-details
      this.filterCriteriaSub = this.OrderSearch.currentFilterCriteria.subscribe(value => this.filterCriteriaValue = value);
    if (this.filterCriteriaValue) {
      this.refillFilterForm(this.filterCriteriaValue);
    }
    this.buildSearchCriteriaCategoryList(this.searchCriteriaValue);
  }

  ngOnDestroy(): void {
    this.orderSearchResultSub.unsubscribe();
    this.searchCriteriaSub.unsubscribe();
    if (this.filterCriteriaSub)
      this.filterCriteriaSub.unsubscribe();
  }
  resetFilterFormValues() {
    try {

      if (this.filterBy.get('orderId').enabled) {
        this.filterBy.get('orderId').setValue('');
      }
      if (this.filterBy.get('orderState').enabled) {
        this.filterBy.get('orderState').setValue('');
      }
      if (this.filterBy.get('paymentMethod').enabled) {
        this.filterBy.get('paymentMethod').setValue('');
      }
      if (this.filterBy.get('originatingSystemCode').enabled) {
        this.filterBy.get('originatingSystemCode').setValue('');
      }
      if (this.filterBy.get('amount').enabled) {
        this.filterBy.get('amount').setValue('');
      }
      if (this.filterBy.get('transactionDate').enabled) {
        this.filterBy.get('transactionDate').setValue('');
      }
    } catch (err) {
      console.error('Failure in setting FilterBy form: '+ JSON.stringify(err));
    }
  }


  refillFilterForm(inputData: any){
    try {
      this.filterBy.get('orderId').setValue(inputData.orderId);
      this.filterBy.get('orderState').setValue(inputData.orderState);
      this.filterBy.get('paymentMethod').setValue(inputData.paymentMethod);
      this.filterBy.get('originatingSystemCode').setValue(inputData.originatingSystemCode);
      this.filterBy.get('amount').setValue(inputData.amount);
      this.filterBy.get('transactionDate').setValue(inputData.transactionDate);
      //reset
      this.filterCriteriaValue = '';
      this.disableClearLoad = false;
    } catch (err) {
      console.error('Failure in setting FilterBy form: '+ JSON.stringify(err));
    }
  }

  createForm() {
    this.filterBy = new FormGroup({
      'orderId': new FormControl(null),
      'orderState': new FormControl(null),
      'paymentMethod': new FormControl(null),
      'originatingSystemCode': new FormControl(null),
      'amount': new FormControl(null),
      'transactionDate': new FormControl(null)
    }, [this.atLeastOneValue]);
  }

  resetForm() {
    // this.filterBy.reset();
    this.resetFilterFormValues();
    this.searchError = false;
    this.searchInfo = false;
    this.OrderSearch.changeOrderFilterCriteria("");//set filter fields to empty
    //call order search with old criteria
    const searchCriteriaInfo=JSON.parse(this.searchCriteriaValue);
    if (!this.disableClearLoad){
        this.disableClearLoad = true; // prevent calling search api on multiple clicks on clear button
        this.addOscCodeIfNotPresent(searchCriteriaInfo);
        this.getOrderResults(searchCriteriaInfo);
    }
  }

  atLeastOneValue(form: FormGroup): ValidationErrors {
    return Object.keys(form.value).some((key) => !!form.value[key])
      ? null //false;
      : { atLeastOneRequired: 'At least one should be selected' };
  }

  toggle() {
    this.display = !this.display;
  }

  onRevise() {
    this.router.navigate(['/order-search'],{queryParams:{'fromAction':'revise'}});
  }

  createOrderSearchRequest(group: FormGroup): void {
    let toDate = '', fromDate = '';
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl.value) {
        switch (key) {
          case 'transactionDate':
            toDate = this.pipeCustomDate.transform(abstractControl.value,'MM-dd-yyyy');
            fromDate = this.pipeCustomDate.transform(abstractControl.value,'MM-dd-yyyy');
            this.addSearchQuickDate(fromDate, toDate, this.startTime, this.endTime);
            break;
          default:
            this.searchByFilterRequest.push({
              field: key,
              value: abstractControl.value,
            });
        }
      }
    });
  }

  addSearchQuickDate(fromDate:any, toDate:any, startTime:any, toTime:any) {
    this.searchByFilterRequest.push({'field':'fromDate', 'value': this.pipeCustomDate.transform(fromDate, 'yyyy-MM-ddT'+startTime+'ZZZZZ')});
    this.searchByFilterRequest.push({'field':'toDate', 'value': this.pipeCustomDate.transform(toDate, 'yyyy-MM-ddT'+toTime+'ZZZZZ')});
  }

  filterBySearchCriteria() {
    let resultArray = [];
    if (this.searchCriteriaValue.length && this.searchByFilterRequest.length) {
      const convertToArray = JSON.parse(this.searchCriteriaValue);
      resultArray = convertToArray.filter(obj => {
        return !this.searchByFilterRequest.find(item => {
          return item.field === obj.field;
        });
      });
    }
    return resultArray;
  }

  validTransactionDate() {
    const filterVal = this.filterBy.value;
    let valid = true;
    if (filterVal.transactionDate) {

      const fromDate = this.searchObjVal.fromDate;
      const toDate = this.searchObjVal.toDate;
      if (fromDate && toDate) {
        const fromTime = new Date(fromDate).getTime();
        const toTime = new Date(toDate).getTime();
        const txTime = new Date(filterVal.transactionDate).getTime();
        if (!(txTime >= fromTime && txTime <= toTime)) {
          valid = false;
        }
      }

    }
    return valid;
  }

  validateOrderState() {
    const orderStateVal = this.filterBy.get('orderState').value;
    if (orderStateVal && this.searchedOrderState) {
      if (this.searchedOrderState === 'SALE') {
        return this.saleValues.indexOf(orderStateVal) >= 0;
      } else if (this.searchedOrderState === 'REFUND') {
        return this.refundValues.indexOf(orderStateVal) >= 0;
      } else {
        return this.searchedOrderState === orderStateVal;
      }
    } else {
      return true;
    }
  }
  validAmount() {
    const filterVal = this.filterBy.value;
    let valid = true;
    if (filterVal) {
      const amountModifiedLabel = this.filterBy.value.amount;
      if (amountModifiedLabel && this.searchObjVal && this.searchObjVal.amount) {
        const existingAmountLabel = this.searchObjVal.amount;
        const existingAmountOp = existingAmountLabel.substring(0, 1);
        const existingAmountVal = Number(existingAmountLabel.substring(1));

        const amountModifiedOp = amountModifiedLabel.substring(0, 1);
        const amountModifiedVal = Number(amountModifiedLabel.substring(1));

        if (amountModifiedOp === existingAmountOp) {
          switch (existingAmountOp) {
            case '<':
              if (!(amountModifiedVal <= existingAmountVal)) {
                valid = false;
              }
              break;
            case '=':
              if (amountModifiedVal != existingAmountVal) {
                valid = false;
              }
              break;
            case '=':
              if (!(amountModifiedVal >= existingAmountVal)) {
                valid = false;
              }
              break;
          }
        } else {
          valid = false;
        }

      }
    }
    return valid;
  }

  onSubmit() {

    if(!this.validAmount()){
      this.errorMessage = 'invalid amount';
      this.searchError = true;
    }else if(!this.validTransactionDate()){
      this.errorMessage = 'invalid transaction date';
      this.searchError = true;
    } else if(!this.validateOrderState()){
      this.errorMessage = 'Invalid Order State';
      this.searchError = true;
    } else {
      this.disableClearLoad = this.searchInfo = this.searchError = false;//reset
      this.searchByFilterRequest = []; //reset on each submit
      this.OrderSearch.changeOrderFilterCriteria(this.filterBy.value);//set filter fields
      this.createOrderSearchRequest(this.filterBy);

      let filterResult = this.filterBySearchCriteria();
      if (filterResult.length) {
        this.searchByFilterRequest = this.searchByFilterRequest.concat(filterResult);
      }
      this.addOscCodeIfNotPresent(this.searchByFilterRequest);
      this.getOrderResults(this.searchByFilterRequest);
    }
  }

  addOscCodeIfNotPresent(searchByFilterRequest) {
    const originatingSystemCodeInfo = searchByFilterRequest.filter(req => req.field == 'originatingSystemCode');
    if (originatingSystemCodeInfo && originatingSystemCodeInfo.length == 0) {
      if (sessionStorage.getItem('oscConfigCodes')) {
        searchByFilterRequest.push({
          "field": "originatingSystemCode",
          "value": JSON.parse(sessionStorage.getItem('oscConfigCodes'))
        });
      }
    }
  }


  getOrderResults(searchRequest){
    searchRequest=[...searchRequest];
    let originatingSystemCode = searchRequest.filter(sr => sr.field == 'originatingSystemCode')[0];
    if (originatingSystemCode) {
      originatingSystemCode.value = originatingSystemCode.value.join(',');
    }


    this.loading = true;
    this.OrderSearch.orderSearch(searchRequest).subscribe(
      (result: any) => {
        this.loading = false;
        this.disableClearLoad = false;
        if (result.content.length == 1) {
          const searchResult= result.content[0];
          this.router.navigate([
            '/order-search-details',
            searchResult.transactionId
          ], {queryParams: {orderState: searchResult?.orderState ? searchResult.orderState : ''}});
        } else if (result.content.length != 0){
          this.matFilterGrid.filterGrid(result);
          this.orderSearchResult = result;
          this.isResult = true;
        } else {
          this.searchInfo = true;
          this.infoMessage = "No results found with filter criteria provided, retaining previous results!";
        }
      },
      (error: any) => {
        this.disableClearLoad = false;
        this.searchError = true;
        this.errorMessage = error;
        console.error(
          'Error Occurred while searching for order => ' + JSON.stringify(error)
        );
      }
    );
  }
  buildSearchCriteriaCategoryList(searchObj) {

    const convertToArray = JSON.parse(searchObj);
    this.searchObjVal = {};
    let fromDateVal;
    let toDateVal;
    for(const index of convertToArray){
      const key = index.field;

      this.searchObjVal[key] = index.value;

      if(this.filterBy.get(key)) {

        this.filterBy.get(key).setValue(index.value);
        this.filterBy.get(key).disable();
      }
      switch (key) {
        case 'createdFromDate':
        case 'reconcilationFromDate':
        case 'settlementFromDate':
        case 'createdToDate':
        case 'reconcilationToDate':
        case 'settlementToDate':
          const dateType = key?.replace(Constants.SPLIT_CAMELCASE_REGEX, '$1 $2');
          index.field = dateType && dateType[0].toUpperCase() + dateType?.slice(1).toLowerCase();
          this.isCriteriaTransactionDate = true;
          toDateVal = index.value;
          this.criteriaTransactionType.push(index);
        break;
        case 'orderState':
          index.field = 'Order State:';
          this.searchedOrderState = index.value;
          this.isCriteriaOrderDetailsInfo = true;
          this.filterBy.get(key).enable();
          this.criteriaOrderDetailsInfo.push(index);
        break;
        case 'decision':
          index.field = 'Decision:';
          this.isCriteriaOrderDetailsInfo = true;
          this.criteriaOrderDetailsInfo.push(index);
        break;
        case 'amount':
          index.field = 'Order Amount:';
          this.isCriteriaOrderDetailsInfo = true;
          this.criteriaOrderDetailsInfo.push(index);
          this.filterBy.get(key).enable();

        break;
        case 'reasonCode':
          index.field = 'Auth Response or Reason Code:';
          this.isCriteriaOrderDetailsInfo = true;
          this.criteriaOrderDetailsInfo.push(index);
        break;
        case 'lastReasonCode':
          index.field = 'Last Reason Code:';
          this.isCriteriaOrderDetailsInfo = true;
          this.criteriaOrderDetailsInfo.push(index);
        break;
        case 'billToFirstName':
          index.field = 'Bill to First Name:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'billToLastName':
          index.field = 'Bill to Last Name:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'customerId':
          index.field = 'Customer ID:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'billToPhone':
          index.field = 'Bill to Customer Phone:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'billToEmail':
          index.field = 'Bill to Customer email:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'billToCity':
          index.field = 'Bill to City:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'billToCountry':
          index.field = 'Bill to Country:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'shipToFirstName':
          index.field = 'Ship to First Name:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'shipToLastName':
          index.field = 'Ship to Last Name:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'shipToEmail':
          index.field = 'Ship to Customer Email:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'shipToCity':
          index.field = 'Ship to City:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'shipToCountry':
          index.field = 'Ship to Country:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'testCenterCountry':
          index.field = 'Test Center Country:';
          this.isCriteriaBillShipInfo = true;
          this.criteriaBillShipInfo.push(index);
        break;
        case 'originatingSystemCode':
          index.field = 'Originating System Code (OSC):';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
        break;
        case 'programSpecificDate':
          index.field = 'Program Specific Date:';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
        break;
        case 'programSpecificKey':
          index.field = 'Program Specific Key:';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
        break;
        case 'oscSessionId':
          index.field = 'OSC Session ID:';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
        break;
        case 'subscriptionId':
          index.field = 'Subscription ID:';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
        break;
        case 'orderId':
          index.field = 'Order (Reconciliation) ID:';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
        break;
        case 'paymentMethod':
          index.field = 'Payment Method:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'paymentType':
          index.field = 'Payment Type:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'cardType':
          index.field = 'Card Type:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
          break;
        case 'maskedAccountNumber':
          index.field = 'Masked Account Number:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'last4DigitOfCreditCard':
          index.field = 'Last 4 Digits of Credit Card:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'currency':
          index.field = 'Currency:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'transactionType':
          index.field = 'Transaction Type:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'bankAaccountNumber':
          index.field = 'Bank Account Number:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
        case 'bankRoutingNumber':
          index.field = 'Bank Routing Number:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
        break;
          break;
        case 'bin':
          index.field = 'Bank Identification Number(BIN):';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
          break;
        case 'fraudIndicator':
          index.field = 'TC-40 Flag:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
          break;
        case 'fraudCustomerType':
          index.field = 'Fd Customer Type:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
          break;
        case 'cfsFlag':
          index.field = 'CFS:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
          break;
        case 'noMtchVndrSuppliedId':
          index.field = 'No Match Vendor Supplied Order ID:';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
          break;
        case 'arnRrnNo':
          index.field = 'ARN/RRN No:';
          this.isCriteriaPaymentInfo = true;
          this.criteriaPaymentInfo.push(index);
          break;
        case 'transactionAttemptCount':
          index.field = 'Transaction Attempt Count ';
          this.isCriteriaProgSpecInfo = true;
          this.criteriaProgSpecInfo.push(index);
      }


    }
    if(this.isDatesSame(fromDateVal, toDateVal) && this.isTodayDate(fromDateVal)){
      this.filterBy.get('transactionDate').setValue(new Date());
    }
  }
  isTodayDate(fromDateVal) {
    if (fromDateVal ) {
      const fromDtObj = new Date(fromDateVal);
      const toDateObj=new Date();
      if (fromDtObj.getDate() === toDateObj.getDate()
        && fromDtObj.getMonth() === toDateObj.getMonth()
        && fromDtObj.getFullYear() === toDateObj.getFullYear()) {
        return true;
      } else {
        return false;
      }
    }
  }
  isDatesSame(fromDateVal, toDateVal) {
    if (fromDateVal && toDateVal) {
      const fromDtObj = new Date(fromDateVal);
      const toDateObj = new Date(toDateVal);
      if (fromDtObj.getDate() === toDateObj.getDate()
        && fromDtObj.getMonth() === toDateObj.getMonth()
        && fromDtObj.getFullYear() === toDateObj.getFullYear()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  toggleAllOscCodeSelection(selectedList) {
    if (selectedList?.length > 0 && selectedList[0] === 'ALL') {
      this.filterBy.controls['originatingSystemCode'].setValue([
        'ALL',
        ...this.oscConfigCodes,
      ]);
    }else{
      this.filterBy.controls['originatingSystemCode'].setValue([]);
    }
  }
}
