<div *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div *ngIf="isDisabled" class="row">
        <div class="col-12">
            <button class="btn btn-primary mt-4 heading" (click)="editConfig()">
                Edit Configuration
            </button>
        </div>
    </div>
    <h4 *ngIf="isDisabled">
        View Existing HOP Master and Virtual Terminal Configuration Settings
    </h4>
    <span class="tip" *ngIf="validationMessage">*Your changes have been saved.</span>
    <span class="tip" *ngIf="errMsg">{{errMsg}}</span>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body ms-3">
                    <div class="pb-3">
                        <h2 *ngIf="isDisabled">
                            {{ selectedContextValue }} Master Configuration
                        </h2>
                        <h2 *ngIf="!isDisabled">
                            Edit {{ selectedContextValue }} Master Configuration
                        </h2>
                    </div>
                    <h4>Payment Types</h4>
                    <form [formGroup]="types">
                        <div class="row mt-4">
                            <ng-container *ngFor="let item of payments_types">
                                <div [class]="selectedContextValue !== 'IHOP' ? item.class : 'col-lg-4'">
                                    <h5 class="heading">{{ item.name }}</h5>
                                    <div *ngFor="let paymentItem of item.items" class="mb-3 form-check">
                                        <input type="checkbox" [id]="paymentItem.id" class="form-check-input"
                                            (change)="enableOrDisableCreditCardOr3DS(types.controls[paymentItem.id].value, paymentItem.id)"
                                            [formControlName]="paymentItem.id" />
                                        <label [for]="paymentItem.id" class="form-check-label">{{
                                            paymentItem.label
                                            }}</label>
                                        <ul *ngIf="paymentItem.subCategories" class="list-style">
                                            <li *ngFor="let subCategory of paymentItem.subCategories">
                                                <div class="row">
                                                    <div [class]="paymentItem.class ? paymentItem.class : 'col-lg-5 col-md-4'"
                                                        class="inline col-12">
                                                        <input type="checkbox" [id]="subCategory.id"
                                                            [formControlName]="subCategory.id"
                                                            class="form-check-input" />
                                                        <label [for]="subCategory.id" class="form-check-label">{{
                                                            subCategory.label }}</label>
                                                    </div>
                                                    <div *ngIf="subCategory.requireAndDisplayID"
                                                        [class]="selectedContextValue !== 'IHOP' ? 'col-lg-5 col-md-5' : 'col-lg-7 col-md-7'"
                                                        class="inline col-12">
                                                        <input type="checkbox" [id]="subCategory.requireAndDisplayID"
                                                            [formControlName]="subCategory.requireAndDisplayID"
                                                            class="form-check-input" />
                                                        <label [for]="subCategory.requireAndDisplayID"
                                                            class="form-check-label">{{ subCategory.requireAndDisplay
                                                            }}</label>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <hr class="mt-5 mb-5">
                        <div class="row mt-4">
                            <div *ngFor="let item of fraud_3ds_currency" class="col-md-4">
                                <h5 class="heading">{{ item.name }}</h5>
                                <div class="mb-3 form-check">
                                    <input type="checkbox" [id]="item.id" class="form-check-input"
                                        [formControlName]="item.id" />
                                    <label [for]="item.id" class="form-check-label">{{
                                        item.label
                                        }}</label>
                                </div>
                            </div>
                            <div *ngIf="selectedContextValue !== 'IHOP'" [class]="avs_cvs_rules[1]?.class">
                                <h5 class="heading">{{ avs_cvs_rules[1]?.name }}</h5>
                                <div class="mb-3 form-check">
                                    <ng-container *ngFor="let data of avs_cvs_rules[1]?.items">
                                        <div class="col-6">
                                            <input type="checkbox" [id]="data.id" class="form-check-input"
                                                [formControlName]="data.id" />
                                            <label [for]="data.id" class="form-check-label">{{
                                                data.label
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isDisabled" class="row">
                            <div class="col-12">
                                <button class="btn btn-primary btn-function" (click)="exit()">
                                    Exit
                                </button>
                                <button class="btn btn-primary" (click)="saveExit()">
                                    Save and Exit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8 alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to
        access the page.
    </div>
    <div class="col-2"></div>
</div>
