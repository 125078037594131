import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import {
  IDynamicDialogConfig,
  MatDialogComponentComponent,
} from 'src/app/mat-dialog-component/mat-dialog-component.component';
import { User } from 'src/app/model/user';
import { SharedService } from 'src/app/service/shared.service';
import {
  billing_information,
  attemps_timeout_options,
  payments_types,
  fraud_3ds_currency,
  avs_cvs_rules
} from '../config';
import { MatDialog } from '@angular/material/dialog';
import { MasterHOPDetails } from 'src/app/model/master-hop-details';
import { OSCHOPService } from 'src/app/service/osc-hop-config.service';

@Component({
  selector: 'app-virtual-terminal-configuration',
  templateUrl: './virtual-terminal-configuration.component.html',
  styleUrls: ['./virtual-terminal-configuration.component.scss']
})
export class VirtualTerminalConfigurationComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  isDisabled = true;
  validationMessage: boolean;
  loggedInUser: User;
  payments_types;
  fraud_3ds_currency;
  avs_cvs_rules;
  billing_information;
  attemps_timeout_options;
  types: FormGroup;
  masterHOPDetails: MasterHOPDetails[];
  selectedHOPData: MasterHOPDetails;
  errMsg: string;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private dialog: MatDialog,
    private oscHOPService: OSCHOPService
  ) {
    this.payments_types = payments_types.filter(item => item.name !== '3DS');
    this.fraud_3ds_currency = fraud_3ds_currency.slice(0,2);
    this.avs_cvs_rules = avs_cvs_rules.slice(0,1);
    this.billing_information = billing_information;
    this.attemps_timeout_options = attemps_timeout_options;
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.setFormData();
      }
    });
  }

  setFormData(){
    this.masterHOPDetails = JSON.parse(sessionStorage.getItem('masterHOPDetails'));
    this.selectedHOPData = this.masterHOPDetails?.find(
      (data) => data.hopId === 'VTHOP'
    );
    this.types = new FormGroup({
      ccPaymentEnabled: new FormControl(this.selectedHOPData.ccPaymentEnabled),
      visaEnabled: new FormControl(this.selectedHOPData.visaEnabled),
      masterCardEnabled: new FormControl(this.selectedHOPData.masterCardEnabled),
      amexEnabled: new FormControl(this.selectedHOPData.amexEnabled),
      discoverEnabled: new FormControl(this.selectedHOPData.discoverEnabled),
      unionPayEnabled: new FormControl(this.selectedHOPData.unionPayEnabled),
      dinersClubEnabled: new FormControl(this.selectedHOPData.dinersClubEnabled),
      jcbEnabled: new FormControl(this.selectedHOPData.jcbEnabled),
      rupayGlobalEnabled: new FormControl(this.selectedHOPData.rupayGlobalEnabled),
      visaCSCRequired: new FormControl(this.selectedHOPData.visaCSCRequired),
      mastercardCSCRequired: new FormControl(this.selectedHOPData.mastercardCSCRequired),
      amexCSCRequired: new FormControl(this.selectedHOPData.amexCSCRequired),
      discoveryCSCRequired: new FormControl(this.selectedHOPData.discoveryCSCRequired),
      unionPayCSCRequired: new FormControl(this.selectedHOPData.unionPayCSCRequired),
      dinersClubCSCRequired: new FormControl(this.selectedHOPData.dinersClubCSCRequired),
      jcbCSCRequired: new FormControl(this.selectedHOPData.jcbCSCRequired),
      rupayGlobalCSCRequired: new FormControl(this.selectedHOPData.rupayGlobalCSCRequired),
      fraudDetectEnabled: new FormControl(this.selectedHOPData.fraudDetectEnabled),
      dccEnabled: new FormControl(this.selectedHOPData.dccEnabled),
    });
    this.types.disable();
  }

  editConfig() {
    this.isDisabled = !this.isDisabled;
    this.types.enable();
    this.validationMessage = false;
  }

  back() {
    if (this.isDisabled) {
      this.router.navigate(['master-and-virtual-terminal-hop-config']);
    } else {
      this.openDialog('back');
    }
  }

  saveExit() {
    this.isDisabled = !this.isDisabled;
    const masterHopDetailsData = this.getMasterHopDetailsData();
    const obs = this.oscHOPService.saveMasterHOPDetails(masterHopDetailsData);
    obs.subscribe({
      next: (data) => {
        this.validationMessage = true;
        this.oscHOPService.getAndStoreMasterHOPDetails();
      },
      error: (err) => {
        console.log('error', err);
        this.errMsg =
          'Error while processing the request. ' + err?.error?.message;
      },
    });
    this.accordion.closeAll()
    this.types.disable();
  }

  getMasterHopDetailsData() {
    let masterHopDetails = {} as MasterHOPDetails;
    masterHopDetails.hopId = 'VTHOP';
    masterHopDetails.amexEnabled = this.types.controls['amexEnabled'].value;
    masterHopDetails.dinersClubEnabled =
      this.types.controls['dinersClubEnabled'].value;
    masterHopDetails.discoverEnabled =
      this.types.controls['discoverEnabled'].value;
    masterHopDetails.jcbEnabled = this.types.controls['jcbEnabled'].value;
    masterHopDetails.masterCardEnabled =
      this.types.controls['masterCardEnabled'].value;
    masterHopDetails.rupayGlobalEnabled =
      this.types.controls['rupayGlobalEnabled'].value;
    masterHopDetails.unionPayEnabled =
      this.types.controls['unionPayEnabled'].value;
    masterHopDetails.visaEnabled = this.types.controls['visaEnabled'].value;
    masterHopDetails.ccPaymentEnabled =
      this.types.controls['ccPaymentEnabled'].value;
    masterHopDetails.dccEnabled = this.types.controls['dccEnabled'].value;
    masterHopDetails.fraudDetectEnabled =
      this.types.controls['fraudDetectEnabled'].value;
    masterHopDetails.amexCSCRequired =
      this.types.controls['amexCSCRequired'].value;
    masterHopDetails.dinersClubCSCRequired =
      this.types.controls['dinersClubCSCRequired'].value;
    masterHopDetails.discoveryCSCRequired =
      this.types.controls['discoveryCSCRequired'].value;
    masterHopDetails.jcbCSCRequired =
      this.types.controls['jcbCSCRequired'].value;
    masterHopDetails.mastercardCSCRequired =
      this.types.controls['mastercardCSCRequired'].value;
    masterHopDetails.rupayGlobalCSCRequired =
      this.types.controls['rupayGlobalCSCRequired'].value;
    masterHopDetails.unionPayCSCRequired =
      this.types.controls['unionPayCSCRequired'].value;
    masterHopDetails.visaCSCRequired =
      this.types.controls['visaCSCRequired'].value;
    masterHopDetails.createdBy = this.selectedHOPData.createdBy;
    masterHopDetails.createdDate = this.selectedHOPData.createdDate;
    masterHopDetails.updatedBy = this.loggedInUser.emailId;
    masterHopDetails.updateDate = new Date().toISOString();
    return masterHopDetails;
  }

  exit() {
    this.openDialog('exit');
  }

  openDialog(data) {
    let dialogRef = this.dialog.open(MatDialogComponentComponent, {
      data: <IDynamicDialogConfig>{
        title: 'Do you want to exit without saving?',
        acceptButtonTitle: 'Yes',
        declineButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value !== 'noExit' && data !== 'back') {
        this.isDisabled = !this.isDisabled;
        this.types.disable();
        this.accordion.closeAll()
      } else if (data === 'back' && value !== 'noExit') {
        this.router.navigate(['master-and-virtual-terminal-hop-config']);
      }
    });
  }

  enableOrDisableCreditCard(value) {
    if (!value) {
      this.types.disable();
      this.types.controls['ccPaymentEnabled'].enable();
      this.types.controls['fraudDetectEnabled'].enable();
      this.types.controls['dccEnabled'].enable();
    } else {
      this.types.enable();
    }
  }

}
