import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormBuilder,
  FormControl,
  FormGroup,
  ControlValueAccessor,
  AbstractControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CountryRegionService } from '../service/country-region.service';

export interface ContactFormValues {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  phoneNumber: string;
  email: string;
  company: string;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent implements ControlValueAccessor, OnDestroy,OnInit {

  @Input() isDropDown = false;
  countryList: any;

  form: FormGroup;
  subscriptions: Subscription[] = [];
  isStateRequired = true;


  get value(): ContactFormValues {
    return this.form.value;
  }

  set value(value: ContactFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private formBuilder: FormBuilder,
    private countryRegionService: CountryRegionService) {
      this.countryList = this.countryRegionService.getCountryCodes();
      this.form = this.formBuilder.group({
        firstName: [null],
        lastName: [null],
        address1: [null],
        address2: [null],
        city: [null],
        country: [null],
        state: [null],
        zip: [null],
        phoneNumber: [null],
        email: [null],
        company: [null]
      });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnInit() {
    this.addNullValForEmptyValsListener(this.form.get('email'));
  }
  addNullValForEmptyValsListener(ctrl:AbstractControl){
    if (ctrl) {
      ctrl.valueChanges.subscribe(data => {
        if (!data) {
          ctrl.setValue(null, {onlySelf: true, emitEvent: false});
        }
      })
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false, }, };
  }

  changeCountry(e) {
    const selectedCountry = e.target.value;
    this.isStateRequired = (selectedCountry === 'US' || selectedCountry === 'CA');
  }

   /*  get emailControl() {
    return this.form.controls.email;
  } */

}
