<div class="row">
    <div class="col-lg-12">
        <div class="card nav">
            <div class="card-body">
                <ul class="list-inline">
                    <li class="list-inline-item"><a (click)="scrollToService?.scrollTo('order-details')">Order Details</a></li>
                    <li class="list-inline-item"><a (click)="scrollToService?.scrollTo('line-details')">Line Item Summary & Details</a></li>
                    <li class="list-inline-item"><a (click)="scrollToService?.scrollTo('transaction-details')">Transaction Details</a></li>
                    <li class="list-inline-item"><a (click)="scrollToService?.scrollTo('related-orders')">Related Orders</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>