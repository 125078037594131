import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActionLinkService } from 'src/app/service/action-links.service';
import { OrderService } from 'src/app/service/order.service';
import { RefundService } from 'src/app/service/refund.service';
import { SharedService } from 'src/app/service/shared.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnInit {
  @Input() txData;
  @Input() calculatedRefundAmount;
  @Input() parentRef;
  modalRef: BsModalRef;
  lineItemErrorConfirmModalRef: BsModalRef;
  userInfo: User;

  constructor(
    private refundFormData: RefundService,
    private orderservice: OrderService,
    private actionLinkService: ActionLinkService,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {}

  programKey = 'XXXXXXX';
  refund: FormGroup;
  isLineItemInvalid = false;
  lineItems: Array<any>;
  refundAmt:any;
  allowOrderItems=true;

  ngOnInit(): void {
    this.refundAmt=this.calculatedRefundAmount;
    // calculate net amount freom order amount - refund amount
    this.refund = new FormGroup({
      amount: new FormControl('',
      [Validators.required, Validators.min(0), Validators.pattern("^[.0-9]*$"), Validators.max(this.calculatedRefundAmount)]
      ),
      merchantDescriptor: new FormControl(
        this.txData.merchantDescriptor,
        Validators.required
      ),
      programSpecificKey: new FormControl(this.txData.programSpecificKey, Validators.maxLength(40)),
    });
    this.onChanges();
    this.addListeners();
    this.sharedService.userInfo$.subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
  }

  isInvalidData() {
    let invalid = false;
    if (this.refund.invalid) {
      invalid = true;
    } else if (this.lineItems && this.lineItems.length > 0) {
      invalid =
        this.isLineItemInvalid &&
        this.lineItems.findIndex((li) => !li.unitPrice) >= 0;
    } else if (!this.refund.value.amount) {
      invalid = true;
    }
    return invalid;
  }

  onChanges() {
    this.refund.get('amount').valueChanges.subscribe((val) => {
      if (val && val.length == 0) {
        this.amountError = false;
      }
    });
  }

  get f() {
    return this.refund.controls;
  }

  resetRefundForm() {
    this.refund.reset();
    this.sharedService.onResetLineItems(true);
  }
  confirmRefundSale(): boolean {
    if (this.refund.valid) {
      //alert('confirmRefundSale');
      if (
        this.lineItems &&
        this.lineItems.length > 0 &&
        this.isLineItemInvalid
      ) {
        const message = `You are attempting to process line item details with out a
                      Product SKU or Product Code.  Press continue to submit your
                      transaction or exit to correct your line items`;

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: new ConfirmDialogModel(message),
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
          if (dialogResult) {
            this.parentRef.close();
            this.sendRefundTxData();
          }
        });
      } else {
        this.sendRefundTxData();
        return true;
      }
    } else {
      return false;
    }
  }
  amountError = false;
  sendRefundTxData() {
    if (!this.refund.valid) {
      return false;
    } else {
      if (
        this.refund.value.amount &&
        this.refund.value.amount >
        this.calculatedRefundAmount
      ) {
        this.amountError = true;
        this.actionLinkService.openDialog('RefundFailed', 'failed');
        return false;
      } else {
        let refundData = {
          epmsOrderId: this.txData.order.epmsOrderId,
          glActivityText: this.txData.glActivityText,
          glProductText: this.txData.glProductText,
          merchantDescriptor: this.refund.value.merchantDescriptor,
          orderAmount: this.refund.value.amount,
          originatingSystemCode: this.txData.order.originatingSystemCode,
          programDefinedDate: this.txData.programDefinedDate,
          programSpecificKey: this.refund.value.programSpecificKey,
          resourceText: this.txData.resourceText,
          serviceCode: this.txData.serviceCode,
          sourceType: 'credit', //this.txData.order.sourceType
          glProjectText: this.txData.glProjectText,
          modeOfReceipt: this.txData.order.modeOfReceipt,
          lineItems: null,
          createdBy: this.userInfo?.emailAddress
        };
        if (this.lineItems && this.lineItems.length > 0) {
          this.lineItems.forEach((element) => {
            element.quantity = element.quantity ? element.quantity : 1;
          });
          refundData.lineItems = this.lineItems;
          refundData.orderAmount = null;
        }
        this.refundFormData.sendRefundTxData(refundData).subscribe(
          (res: any) => {
            if (res && res.status == 200 && res.body.decision === 'ACCEPT') {
              this.actionLinkService.openDialog(
                'RefundConfirmation',
                'confirmation'
              );
              const transactionId = this.txData.orderTransactionId;
              this.loadTransactionData(transactionId);
            } else {
              this.actionLinkService.openDialog('RefundFailed', 'failed', null,null, res?.body);
            }
          },
          (err) => {
            this.actionLinkService.openDialog('RefundFailed', 'failed', null,null, err?.error);
          }
        );
        return true;
      }
    }
  }

  loadTransactionData(txId) {
    const obs = this.orderservice.getTxData(txId);
    obs.subscribe((data) => {
      this.txData = data;
    });
  }

  lineItemData(event) {
    this.isLineItemInvalid = event.status === 'INVALID' ? true : false;
    this.lineItems = event.value;
    if(this.lineItems && this.lineItems.length>0){
      this.refund.controls['amount'].disable();
    }else{
      this.refund.controls['amount'].enable();
    }
  }

  isDisabledOverrdieRefund(){

    if(this.lineItems && this.lineItems.length>0){
      for(const lineItem of this.lineItems){
        if(!lineItem.unitPrice){
          return true;
        }
      }

    }else{
      const amountField=this.refund.controls['amount'];
      return !amountField.value ;
    }

  }

  addListeners(){
    this.refund.controls['amount'].valueChanges.subscribe(amount=>{

      if(amount){
        this.allowOrderItems=false;
      }else{
        this.allowOrderItems=true;
      }
    });
  }
}
