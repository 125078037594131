<div class="mt-4 container-xl" id="dropdown-arrow-mat" *ngIf="loggedInUser?.oscDetails?.length > 0">
    <div class="row">
        <div class="col-12">
            <h2>Manage External Users</h2>
            <h4 class="pt-2">Manage Account</h4>
            <div id="manage-account" class="row pb-4">
                <label>Create, Reset Password & Update User Account Attributes </label>
                <div class="col-md-4 col-9">
                    <mat-select class="form-select form-select-md" [(ngModel)]="dropdownValue" (selectionChange)="disableButton()">
                        <mat-option disabled value="default" selected>Select</mat-option>
                        <mat-option value="createUser">Create User</mat-option>
                        <mat-option value="resetPassword">Reset Password</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-4 col-3 button">
                    <button class="btn btn-primary" [disabled]="disableButtonMode" (click)="viewSelected()">
                        Go
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="viewForm === 'createUser'" class="col-12">
        <div class="card">
            <div class="card-body">
                <h3>Create Users</h3>
                <form [formGroup]="createUser">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>First Name</label> <span class="text-danger">* </span>
                            <input class="form-control form-control-md" formControlName="firstName" type="text" />
                            <span *ngIf="
                                                  (createUser.controls['firstName'].hasError('required') || createUser.controls['firstName'].hasError('minLength')) &&
                                                  createUser.controls['firstName'].touched
                                                " class="text-danger">
                                First Name is a required field and must be specified.</span>
                
                            <span *ngIf="
                                                  createUser.controls['firstName'].hasError('maxlength') &&
                                                  createUser.controls['firstName'].touched
                                                " class="text-danger">
                                First Name cannot exceed 64 characters.</span>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Middle Name</label>
                            <input class="form-control form-control-md" formControlName="middleName" type="text" />
                            <span *ngIf="
                                                  createUser.controls['middleName'].hasError('maxlength') &&
                                                  createUser.controls['middleName'].touched
                                                " class="text-danger">
                                Middle Name cannot exceed 64 characters.</span>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Last Name</label> <span class="text-danger">* </span>
                            <input class="form-control form-control-md" formControlName="lastName" type="text" />
                            <span *ngIf="
                                                  createUser.controls['lastName'].hasError('required') &&
                                                  createUser.controls['lastName'].touched
                                                " class="text-danger">
                                Last Name is a required field and must be specified</span>
                            <span *ngIf="createUser.controls['lastName'].hasError('maxlength') &&
                                                                createUser.controls['lastName'].touched" class="text-danger">
                                Last Name cannot exceed 64 characters.</span>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label>Email Address</label><span class="text-danger">* </span>
                        <input class="form-control form-control-md" formControlName="emailAddress" />
                        <span *ngIf="
                                                createUser.controls['emailAddress'].hasError('required') &&
                                                createUser.controls['emailAddress'].touched
                                              " class="text-danger">
                            Email Address is a required field and must be specified</span>
                        <span *ngIf="
                                                            createUser.controls['emailAddress'].hasError('pattern') &&
                                                            createUser.controls['emailAddress'].touched
                                                          " class="text-danger">
                            Invalid Email Address</span>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label>User Type</label>
                        <mat-select class="form-select form-select-md" formControlName="userType">
                            <mat-option disabled value="default" selected>Select</mat-option>
                            <mat-option value="external">External</mat-option>
                        </mat-select>
                    </div>
                    <div class="pt-3">
                        <button class="btn btn-primary" [disabled]="!createUser.valid" (click)="createUserAction()">
                            Create User
                        </button>
                        <button class="btn btn-primary btn-function ms-3" (click)="openDialog('clear')">
                            clear
                        </button>
                        <button class="btn btn-primary btn-function ms-3" (click)="openDialog('exit')">
                            Exit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
    <div class="col-2"></div>
    <div class="col-8 alert alert-danger" role="alert">
        Originating System Code or Program Group need to be associated in order to
        access the page.
    </div>
    <div class="col-2"></div>
</div>