import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { RefundService } from '../service/refund.service';
import { SharedService } from '../service/shared.service';
import { Profile } from '../model/profile-permissions';
import { ChargebackRequest } from '../model/chargeback-request';
import { ActionLinkService } from '../service/action-links.service';
import {
  Action_Links_config,
  Action_Links_defaults,
  Data_Path,
} from '../constant/action-links';
import { ActivatedRoute } from '@angular/router';
import { ActionLinks } from '../model/action-links';
import { OrderService } from '../service/order.service';


@Component({
  selector: 'app-action-links',
  templateUrl: './action-links.component.html',
  styleUrls: ['./action-links.component.scss'],
})
export class ActionLinksComponent implements OnInit, OnDestroy {
  faCheckCircle = faCheckCircle;
  faExclamationTriangle = faExclamationTriangle;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modal-emps',
  };
  modalHandlerEvent: any;
  @ViewChild('errorConfirm') overrideConfirm: TemplateRef<any>;
  @ViewChild('successConfirm') overrideSuccessConfirm: TemplateRef<any>;

  initialState: any;
  profile: Profile;
  chargeBackResp: any;

  @Input()
  calculatedRefundAmount;
  actionLinks;
  oscData: any;

  constructor(
    private modalService: BsModalService,
    private refundService: RefundService,
    public actionLinkService: ActionLinkService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {
    this.modalHandlerEvent = this.refundService.resetRefundForm.subscribe(
      (res: any) => {
        if (res.initialState && res.initialState.modalType == 'ERROR') {
          this.initialState = res.initialState;
          this.openModal(this.overrideConfirm);
        } else if (
          res.initialState &&
          res.initialState.modalType == 'SUCCESS'
        ) {
          this.initialState = res.initialState;
          this.openModal(this.overrideSuccessConfirm);
        }
      }
    );
  }
  txData:any;
  @Input('txData') set transactionData(data){
    this.txData=data;
    this.getOscData();
  }

  ngOnInit(): void {
    this.sharedService.profileInfo$.subscribe((profileInfo) => {
      this.profile = profileInfo;
      this.getOscData();
    });
  }

  ngOnDestroy() {
    this.modalHandlerEvent.unsubscribe();
  }

  openModal(linkedoverriderefund: TemplateRef<any>) {
    this.modalRef = this.modalService.show(linkedoverriderefund, {
      backdrop: 'static',
    });
  }
  closeModal() {
    this.modalService.hide();
  }

  getOscData(){
    this.orderService.getETSData(this.txData?.order?.originatingSystemCode).subscribe({
      next: (data) => {
        this.oscData = data;
        this.enableOrDisableActionLinks();
      },
      error: (err: any) => {
        console.log('err =' + JSON.stringify(err));
        this.oscData = [];
      },
    });
  }

  processChargeBack(
    modalRef,
    reversechargebackConfirmation,
    chargebackRequest: ChargebackRequest
  ) {
    this.chargeBackResp = undefined;

    const obs = this.refundService.processChargeBack(chargebackRequest);
    obs.subscribe(
      (data) => {
        this.chargeBackResp = data;
        modalRef.hide();
        this.openModal(reversechargebackConfirmation);
      },
      (err) => {
        this.chargeBackResp = {
          errorInfo: err.error.message,
        };
        console.log('error = ' + err);

        modalRef.hide();
        this.openModal(reversechargebackConfirmation);
      }
    );
  }

  /***
    * enable Or Disable ActionLinks based on config details in action-link.ts file
   *  */
  private enableOrDisableActionLinks() {
    let actionLink = {};
    try {
      let actionLinks = Object.keys(Action_Links_defaults);
      for (const link of actionLinks) {
        if (Action_Links_defaults[link] === 'conditional') {
          let enable = false;
          let disable = false;
          let linkDataFilterKeys =
            Action_Links_config[link] && Object.keys(Action_Links_config[link]);
          linkDataFilterKeys?.forEach(async (val) => {
            let keyValue = Action_Links_config[link][val];
            if (val.includes('data')) {
              const dataType = val.includes('osc') ? 'oscdata' : 'data';
              const data = val.includes('osc') ? this.oscData : this.txData;
              let dataCheckValue = await this.checkData(
                val,
                keyValue,
                data,
                dataType
              );
              if (val.includes('enabled') && dataCheckValue) {
                enable = dataCheckValue;
              } else if (dataCheckValue) {
                disable = dataCheckValue;
              }
            } else if (val.includes('queryParam')) {
              if (val.includes('enabled')) {
                enable = this.checkQueryParamData(val, '_enabled', keyValue);
              } else {
                disable = this.checkQueryParamData(val, '_disabled', keyValue);
              }
            }
            this.updateActionLinks(actionLink, link, enable, disable);
          });
        } else {
          this.updateActionLinks(actionLink, link);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  }

  /***
   * enable or disable action links based for condition
   * @actionLink is objectkeys of Action_Links_defaults
   * @link eg:LinkedOverrideRefund
   * @enable boolean value to enable link
   * @disable boolean value to disable link
   *  */
  updateActionLinks(actionLink, link, enable?, disable?) {
    actionLink[link] =
      Action_Links_defaults[link] === 'conditional'
        ? enable && !disable
          ? true
          : false
        : Action_Links_defaults[link];
    this.actionLinks = actionLink as ActionLinks;
  }

   /***
    * get data from transaction data using path
   * @path eg: ['order', 'originatingSystemCode'] read from data_path
   * @data order transaction data
   *  */
  getValueUsingPath(data, path) {
    let dataValue = path?.reduce((data, item) => data[item], data);
    return typeof dataValue === 'boolean' ? dataValue : dataValue && dataValue.toLocaleLowerCase();
  }


  /***
   * check data if it matches conditional data
   * @val data_originatingSystemCode_disabled
   * @keyValue ING|INT
   * @data order transaction data
   *  */
  private async checkData(val, keyValue, data, dataType): Promise<boolean> {
    let resultMatch = false;
    let key = val.replace(dataType+'_', '').split('_')[0];
    let dataValue = await this.getValueUsingPath(data, Data_Path[key]);
    if (typeof keyValue == "string" && keyValue.includes('|') && dataValue) {
      let result = keyValue.split('|');
      result.forEach((res) => {
        if (dataValue === res.toLocaleLowerCase()) {
          resultMatch = true;
        }
      });
    } else {
      if ((typeof keyValue == "string" && dataValue === keyValue.toLocaleLowerCase()) || (typeof keyValue == "boolean" && dataValue === keyValue)) {
        resultMatch = true;
      }
    }
    return resultMatch
  }

  private checkQueryParamData(val, type, keyValue): boolean {
    let value;
    let valueMatch;
    let key = val.replace('queryParam_', '').replace(type, ''); //orderState_enabled
    value = this.route.snapshot.queryParamMap.get(key);
    if (keyValue.includes('|')) {
      let result = keyValue.split('|');
      result.forEach((res) => {
        if (value && value.toLocaleLowerCase() === res.toLocaleLowerCase()) {
          valueMatch = true;
        }
      });
    } else {
      valueMatch =
        value && value.toLocaleLowerCase() === keyValue.toLocaleLowerCase();
    }
    return valueMatch;
  }
}
