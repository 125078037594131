<div *ngIf="loggedInUser?.oscDetails?.length > 0">
  <div class="breadcrumb row">
    <span>Global Configuration</span>
  </div>
  <div class="container-xl mt-4">
    <div *ngIf="isDisabled" class="row">
      <div class="col-12">
        <h2>Master/Global Configuration</h2>
      </div>
    </div>
    <div *ngIf="!isDisabled" class="row">
      <div class="col-12">
        <h2>Edit Master/Global Configuration</h2>
      </div>
    </div>
    <div *ngIf="isDisabled" class="row">
      <div class="col-12">
        <button class="btn btn-primary mt-4 heading" (click)="editConfig()">
          Edit Configuration
        </button>
      </div>
    </div>
    <h4 *ngIf="isDisabled">View Existing Master/Global Configuration Settings</h4>
    <span class="tip" *ngIf="validationMessage">*Your changes have been saved.</span>
    <span class="tip" *ngIf="errMsg && !validationMessage">{{errMsg}}</span>
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="ms-3 card-body">
            <h4>Payment Types</h4>
            <form [formGroup]="types">
              <div class="row mt-4">
                <ng-container *ngFor="let item of payments_types">
                  <div class="col-md-5">
                    <h5 class="heading">{{item.name}}</h5>
                    <div *ngFor="let paymentItem of item.items" class="mb-3 form-check">
                      <input type="checkbox" [id]="paymentItem.id" (change)="enableOrDisableCreditCardOr3DS(types.controls[paymentItem.id].value, paymentItem.id)" [formControlName]="paymentItem.id"
                        class="form-check-input" />
                      <label [for]="paymentItem.id" class="form-check-label">{{paymentItem.label}}</label>
                      <ul *ngIf="paymentItem.subCategories" style="list-style-type:none;">
                        <li *ngFor="let subCategory of paymentItem.subCategories">
                          <input type="checkbox" [formControlName]="subCategory.id" [id]="subCategory.id"
                            class="form-check-input" />
                          <label [for]="subCategory.id" class="form-check-label">{{subCategory.label}}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr *ngIf="item.name === '3DS'" class="mt-3 mb-3">
                </ng-container>
                <hr class="mt-3 mb-3">
                <div class="row mt-4">
                  <ng-container *ngFor="let item of fraud_3ds_currency">
                    <div class="col-md-5">
                      <h5 class="heading">{{item.name}}</h5>
                      <div class="mb-3 form-check">
                        <input type="checkbox" [id]="item.id" [formControlName]="item.id" class="form-check-input" />
                        <label [for]="item.id" class="form-check-label">{{item.label}}</label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div *ngIf="!isDisabled" class="row">
                <div class="col-12">
                  <button class="btn btn-primary btn-function" (click)="exit()">
                    Exit
                  </button>
                  <button class="btn btn-primary" (click)="saveExit()">
                    Save and Exit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Conditionally Rendered-->
<div class="row" *ngIf="!(loggedInUser?.oscDetails?.length > 0)">
  <div class="col-2"></div>
  <div class="col-8  alert alert-danger" role="alert">
    Originating System Code or Program Group need to be associated in order to access the page.
  </div>
  <div class="col-2"></div>
</div>