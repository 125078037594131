import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderService} from '../service/order.service';
import {ActivatedRoute} from '@angular/router';
import { SharedService } from '../service/shared.service';
import { User } from '../model/user';
import { Profile } from '../model/profile-permissions';
import { PaymentProcessingComponent } from './payment-processing/payment-processing.component';


@Component({
  selector: 'app-virtual-terminal',
  templateUrl: './virtual-terminal.component.html',
  styleUrls: ['./virtual-terminal.component.scss'],
})
export class VirtualTerminalComponent implements OnInit {
  @ViewChild('PaymentProcessingComponent')
  PaymentProcessingComponent: PaymentProcessingComponent;

  @Input() formContent: any;
  @Output() previousStepClicked = new EventEmitter();
  @Output() nextStepClicked = new EventEmitter();
  submitted: string;

  oscConfigCodes = [];
  oscVtHopConfigCodes = [];
  oscCodesUserSelected = [];
  step: any = 1;
  selectedTransaction = '';
  selectedRegion = '';
  selectedProvince = '';
  selectedCountry = '';
  txData: any;
  loggedInUser: User;
  profile: Profile;

  transactionForm: FormGroup = new FormGroup({
    transaction: new FormControl('', Validators.required),
    OSC: new FormControl(null, Validators.required),
  });

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  parseQueryParams() {
    this.route.queryParams.subscribe((data) => {
      const sourceType = data['sourceType'];
      const txId = data['t'];

      if (sourceType) {
        this.transactionForm.get('transaction').setValue(sourceType);
        this.selectedTransaction = sourceType;
        this.loadTxData(txId);
      } else {
        // Setting default transaction as 'SALE', later update based on logged in profile.
        this.setDefaultTransaction('SALE');
      }
    });
  }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      if (user) {
        this.loggedInUser = user;
        this.oscConfigCodes = user.oscDetails.map((osc) => osc.oscCode).sort();
        this.parseQueryParams();
        this.getOscCodesData();
      }
    });
    this.sharedService.profileInfo$.subscribe((profileInfo) => {
      this.profile = profileInfo;
      // If logged in user has only OverrideRefund access, populating the OverrideRefund by default, instead of SALE.
      if (this.profile.hasVtRefundAcc && !this.profile.hasVtSaleAcc) {
        this.setDefaultTransaction('REFUND');
      }
    });
    this.sharedService.vtHopOscConfigCodes$.subscribe((vtHopOscConfigCodes) => {
      this.oscVtHopConfigCodes = vtHopOscConfigCodes;
    });
  }

  setDefaultTransaction(transactionType) {
    this.transactionForm.get('transaction').setValue(transactionType);
    this.selectedTransaction = transactionType;
  }

  loadTxData(txId) {
    const obs = this.orderService.getTxData(txId);
    obs.subscribe((txDataResp) => {
      this.txData = txDataResp;
      this.transactionForm
        .get('OSC')
        .setValue(txDataResp['order']['originatingSystemCode']);
    });
  }

  submit() {
    this.submitted = this.selectedTransaction;
    if (this.PaymentProcessingComponent) {
      this.PaymentProcessingComponent.clearForm();
    }
  }

  ChangeOption(e) {
    this.transactionForm?.get('OSC')?.setValue(null);
    this.selectedTransaction = e.value;
    this.transactionForm.get('transaction').setValue(e.value, {
      onlySelf: true,
    });
    this.getOscCodesData();
  }

  getOscCodesData() {
    const txType = this.transactionForm?.get('transaction')?.value;
    const hopId = 'VTHOP';
    const obs = this.orderService.getOSCCodesByHopAndTxType(hopId, txType);
    obs.subscribe((data) => {
      if (data?.length > 0 && this.oscConfigCodes?.length > 0) {
        this.oscCodesUserSelected = data
          .filter((value) => this.oscConfigCodes.includes(value))
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    });
  }
}
