<header *ngIf="isUserLoggedIn" class="navbar-fixed-top ets-header">
  <nav class="navbar navbar-dark navbar-expanded ets-main-banner">
    <div class="container-header d-flex align-content-center">
      <!-- <div class="branding d-flex">
        <div class="img">
          <img src="../../assets/img/ets-logo.png" />
        </div>
        <div class="title ">
          <h1>EPMS Admin</h1>
        </div>
      </div> -->
      <div></div>
      <div class="img">
        <img class="navbar-logo " src="../../assets/img/ETS_header_logo_medium.new.png" />
      </div>
      <div class="nav-area">
        <div class="user">
          <span class="header__title_dis">{{userInfo?.preferredName}}</span>
        </div>
        <div class="user" *ngIf="isUserLoggedIn">
          <button (click)="logoutUser()" mat-button><span class="header__title_dis">Sign Out</span>></button>
        </div>
      </div>
    </div>
  </nav>
  <nav class="navbar navbar-expand-xl navbar-light bg-light no-padding-top">
    <div class="container-fluid">
      <a class="navbar-brand menuHide" href="#">Menu</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" #menuCollapse data-bs-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ng-template [ngTemplateOutlet]="nav"></ng-template>
      </div>
    </div>
  </nav>
  
  <ng-template #nav >
    <ul class="navbar-nav me-auto ets-portal-nav">
      <li class="nav-item ets-portal-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/epms-admin-home']" (click)="collapseMenu()">EPMS Admin Home</a>
      </li>
      <li *ngIf="profile.hasOrderSearchAccess" class="nav-item ets-portal-item" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"><a class="nav-link" [routerLink]="['/order-search']" (click)="collapseMenu()">Order
          Search</a></li>
      <li *ngIf="profile.hasVtAccess" class='nav-item btn-group ets-portal-item' routerLinkActive="active" dropdown>
        <a class='nav-link dropdown-toggle' dropdownToggle aria-controls="dropdown-animated">Virtual Terminal
          <span class="caret"></span></a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem" *ngIf="profile.hasVtAccess"><a class="nav-link"
              [routerLink]="['/virtual-terminal']" (click)="collapseMenu()">Virtual Terminal</a></li>
          <li role="menuitem" *ngIf="profile.hasVtAuditAcc"><a class="nav-link"
              [routerLink]="['/virtual-terminal-audit-log']" (click)="collapseMenu()">Virtual Terminal Audit Log</a></li>
        </ul>
      </li>
      <li *ngIf="profile.hasOscConfigAcc" class='nav-item btn-group ets-portal-item' routerLinkActive="active"
        dropdown>
        <a class='nav-link dropdown-toggle' data-bs-display="static" dropdownToggle aria-controls="dropdown-animated">OSC and HOP Configuration
          <span class="caret"></span></a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem" *ngIf="profile.hasOscConfigAcc" (click)="collapseMenu()"><a class="nav-link"
              [routerLink]="['/general-business-config']">General Business/OSC
              Configuration</a></li>
          <li role="menuitem" *ngIf="profile.hasSpecificOscAcc"><a class="nav-link"
              [routerLink]="['/osc-specific-hop-config']" (click)="collapseMenu()">OSC Specific HOP Configuration</a></li>
        </ul>
      </li>
      <li *ngIf="profile.hasOscConfigAcc" class='nav-item btn-group ets-portal-item' routerLinkActive="active"
        dropdown>
        <a class='nav-link dropdown-toggle' data-bs-display="static" dropdownToggle aria-controls="dropdown-animated">Global Configuration
          <span class="caret"></span></a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem" *ngIf="profile.hasGlobalOscAcc"><a class="nav-link"
              [routerLink]="['/master-global-config']" (click)="collapseMenu()">Master/Global Configuration</a></li>
          <li role="menuitem" *ngIf="profile.hasOscConfigAcc"><a class="nav-link"
              [routerLink]="['/master-and-virtual-terminal-hop-config']" (click)="collapseMenu()">Global OSC Master and Virtual Terminal HOP Configuration</a></li>
        </ul>
      </li>
      <li *ngIf="profile.hasUserMgmtAccess" class='nav-item btn-group ets-portal-item' routerLinkActive="active"
        dropdown>
        <a class='nav-link dropdown-toggle' dropdownToggle aria-controls="dropdown-animated">Manage Users <span
            class="caret"></span></a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem"><a class="nav-link" [routerLink]="['/user-management']" (click)="collapseMenu()">Manage All Users</a></li>
          <li  *ngIf="![2,3].includes(userInfo.role.privilegeLevelCode)"  role="menuitem"><a class="nav-link" [routerLink]="['/manage-external-user']" (click)="collapseMenu()">Manage External Users</a></li>
        </ul>
      </li>
      <li *ngIf="profile.hasDashboardAccess" class="nav-item btn-group ets-portal-item" routerLinkActive="active" dropdown>
        <a class="nav-link dropdown-toggle" dropdownToggle aria-controls="dropdown-animated">Dashboard<span
          class="caret"></span></a>
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
            <li role="menuitem"><a class="nav-link" [routerLink]="['/reconciliation-dashboard']" (click)="collapseMenu()">Reconciliation Dashboard</a></li>
            <li role="menuitem"><a class="nav-link" [routerLink]="['/reconciliation-dashboard-by-file']" (click)="collapseMenu()">Reconciliation Dashboard by File</a></li>
          </ul>
      </li>
      <li *ngIf="profile.hasCvaToolAccess" class="nav-item btn-group ets-portal-item" routerLinkActive="active" dropdown>
        <a class="nav-link dropdown-toggle" dropdownToggle aria-controls="dropdown-animated">CVA Tool <span
            class="caret"></span></a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem"><a class="nav-link" [routerLink]="['/order-ui']" (click)="collapseMenu()">Order UI</a></li>
          <li role="menuitem"><a class="nav-link" [routerLink]="['/refund']" (click)="collapseMenu()">Refund</a></li>
          <li role="menuitem"><a class="nav-link" [routerLink]="['/subscription']" (click)="collapseMenu()">Subscription</a></li>
        </ul>
      </li>
    </ul>
</ng-template>
</header>